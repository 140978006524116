import { showMessage } from 'react-native-flash-message'

import { AxiosError } from 'axios'

import { paymentActionHooks } from '../../../core/store/payment'
import { WalletAPI } from '../../../core/api/wallet'

export const useConfirmation = () => {
  const getPaymentMethods = paymentActionHooks.getPaymentMethods()

  const confirm = async (transactionId: string) => {
    try {
      await WalletAPI.confirmCard(transactionId)
    } catch (error) {
      if (error instanceof AxiosError) {
        const message = error.response?.data.replace('. ', '\n')
        const style = { width: '100%', borderRadius: 16 }

        showMessage({ message, type: 'danger', style })
      }
    } finally {
      getPaymentMethods()
    }
  }

  return confirm
}
