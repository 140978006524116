import { IOptions, IUseWrapper } from '../../../../core/helpers/withWrapper'
import { useTheme } from '../../../../core/theme'

export const useSelectorModalWrapper: IUseWrapper = (options?: IOptions) => {
  const { useStyles } = options!
  const theme = useTheme()
  const styles = useStyles()

  return { styles, theme }
}
