import { createReducer } from '@reduxjs/toolkit'
import { setIsLoading, setPaymentMethods } from './actions'
import { PaymentState } from './types'

const initialState: PaymentState = {
  cards: [],
  rfids: [],
  loading: false,
}

export const paymentReducer = createReducer(initialState, (builder) => {
  builder.addCase(setPaymentMethods, (state, { payload }) => {
    const { rfids, cards } = payload
    return { ...state, rfids, cards }
  })
  builder.addCase(setIsLoading, (state, action) => {
    return { ...state, loading: action.payload }
  })
})
