export const RTL_LANGAUGES = ['he']

export const SUPPORTED_LANGUAGES = ['en', 'ru', ...RTL_LANGAUGES]

export const DEFALUT_LANGUAGE = 'en'

const EUROPE_COUNTRIES = [
  'AL',
  'AD',
  'AM',
  'AT',
  'BY',
  'BE',
  'BA',
  'BG',
  'CH',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FO',
  'FI',
  'FR',
  'GB',
  'GE',
  'GI',
  'GR',
  'HU',
  'HR',
  'IE',
  'IS',
  'IT',
  'LT',
  'LU',
  'LV',
  'MC',
  'MK',
  'MT',
  'NO',
  'NL',
  'PO',
  'PT',
  'RO',
  'RU',
  'SE',
  'SI',
  'SK',
  'SM',
  'TR',
  'UA',
  'VA',
]

export const SUPPORTED_COUNTRIES = ['US', 'IL', ...EUROPE_COUNTRIES].sort()
