import { RefObject } from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import { withWrapper } from '../../../../core/helpers/withWrapper'
import { withStyles } from '../../../../core/theme'
import Typography from '../Typography'
import { IProgressButtonProps } from './types'
import { useProgressButtonWrapper } from './wrapper'

const useStyles = withStyles(() => ({
  container: {
    borderWidth: 1,
    minHeight: 50,
    borderRadius: 10,
    justifyContent: 'center',
  },
  fill: {
    position: 'absolute',
    borderRadius: 9,
    height: '100%',
  },
  text: {
    position: 'absolute',
    alignSelf: 'center',
    flexDirection: 'row',
  },
}))

interface IProgressButton extends IProgressButtonProps {
  styles: ReturnType<typeof useStyles>
  viewRef: RefObject<View>
  textRef: RefObject<Text>
  getTextColor: (i: number) => { color: string }
  buttonStyle: { width: string; backgroundColor: string }
}

const ProgressButton = ({
  styles,
  onPress,
  text,
  viewRef,
  textRef,
  getTextColor,
  buttonStyle,
}: IProgressButton) => (
  <TouchableOpacity
    style={[styles.container, { borderColor: buttonStyle.backgroundColor }]}
    onPress={onPress}
  >
    <View ref={viewRef} style={[styles.fill, buttonStyle]} />
    {text && (
      <View ref={textRef} style={styles.text}>
        {text.split('').map((letter, i) => (
          <Typography key={i} text={letter} style={getTextColor(i)} />
        ))}
      </View>
    )}
  </TouchableOpacity>
)

export default withWrapper<IProgressButtonProps>(ProgressButton, useProgressButtonWrapper, {
  useStyles,
})
