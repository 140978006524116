import { withStyles } from '../../../../../core/theme'

const SIZE = 40

export const useStyles = withStyles((theme) => ({
  cluster: {
    backgroundColor: theme.colors.primary,
    justifyContent: 'center',
    alignItems: 'center',
    width: SIZE,
    height: SIZE,
    borderRadius: SIZE,
  },
}))
