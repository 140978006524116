import { useState } from 'react'
import { Dimensions, ImageBackground, View, Pressable, Modal, Platform } from 'react-native'
import { GET_STATION_IMAGE } from '../../../core/api/stations/constants'
import ImageView from 'react-native-image-viewing'
import { API_URL } from '@env'

interface ImageGridSectionProps {
  imagesId: string[]
}

const AVAILABLE_WIDTH = Dimensions.get('screen').width - 30

const getWidth = (index: number, arrayLength: number) => {
  if (Platform.OS === 'web') return 300
  if (arrayLength <= 2) {
    return arrayLength === 2 ? AVAILABLE_WIDTH / 2 - 5 : AVAILABLE_WIDTH
  }
  if (index === 0) return AVAILABLE_WIDTH
  return AVAILABLE_WIDTH / (arrayLength - 1) - 10
}

const getHeight = (index: number, arrayLength: number) => {
  if (Platform.OS === 'web') return 220
  if (arrayLength <= 2 || !index) return 220
  return 220 / (arrayLength * 0.7)
}

const createUri = (id: string) => `${API_URL}/${GET_STATION_IMAGE(id)}`

export const ImageGridSection = ({ imagesId }: ImageGridSectionProps) => {
  const [selectedImage, setSelectedImage] = useState('')

  if (!!imagesId.length) {
    return null
  }

  return (
    <View>
      <View
        style={{
          flexDirection: 'row',
          flexWrap: 'wrap',
          justifyContent: Platform.OS === 'web' ? 'flex-start' : 'space-between',
        }}
      >
        {imagesId.map((image, index) => (
          <Pressable
            key={image}
            onPress={() => setSelectedImage(image)}
            style={{ paddingVertical: 5 }}
          >
            <ImageBackground
              source={{
                uri: createUri(image),
              }}
              style={{
                height: getHeight(index, imagesId.length),
                width: getWidth(index, imagesId.length),
                borderRadius: 10,
                overflow: 'hidden',
                margin: Platform.OS === 'web' ? 10 : 0,
              }}
            />
          </Pressable>
        ))}
      </View>

      {Platform.OS !== 'web' && (
        <Modal
          visible={!!selectedImage}
          transparent
          onRequestClose={() => {
            setSelectedImage('')
          }}
          style={{ position: 'relative' }}
        >
          <ImageView
            images={[
              {
                uri: createUri(selectedImage),
              },
            ]}
            imageIndex={0}
            visible={!!selectedImage}
            onRequestClose={() => setSelectedImage('')}
          />
        </Modal>
      )}
    </View>
  )
}
