import { useEffect, useMemo, useState } from 'react'

import { DateData } from 'react-native-calendars'
import { useTheme } from '@react-navigation/native'

import {
  transactionsActionHooks,
  transactionsSelectorHooks,
} from '../../../../core/store/transactions'
import { ICar, vehiclesSelectorHooks } from '../../../../core/store/vehicles'
import { convertTimestampToDate, convertTimestampToDateData } from '../../../../core/helpers/date'
import { MarkedDatesType } from '../../../components/calendar'
import { IOptions, IUseWrapper } from '../../../../core/helpers/withWrapper'
import { IUserStation } from '../../../../core/api/stations/types'
import { stationsApi } from '../../../../core/api/stations'
import {
  FilterItem,
  FilterType,
  IFilterCarItem,
  IFilterStationItem,
  ITransactionFilterContentProps,
} from './types'

export const useTransactionFilterContentWrapper: IUseWrapper<ITransactionFilterContentProps> = (
  { useStyles }: IOptions,
  { onFilter }
) => {
  const styles = useStyles()
  const theme = useTheme()
  const [dates, setDates] = useState<DateData[]>([])

  const [cars, setCars] = useState<IFilterCarItem[]>([])
  const [stations, setStations] = useState<IFilterStationItem[]>([])

  const [loading, setLoading] = useState(false)

  const [isVisible, setVisible] = useState(false)

  const setTransactionsFilter = transactionsActionHooks.setTransactionsFilter()
  const resetTransactionsFilter = transactionsActionHooks.resetTransactionsFilter()
  const userCars = vehiclesSelectorHooks.getCars()

  const {
    chargerStationsId: selectedStations,
    vehiclesId: selectedVehicles,
    transactionEndTime,
    transactionStartTime,
  } = transactionsSelectorHooks.getTransactionFilter()

  useEffect(() => {
    setLoading(true)

    const datesInMemory = [
      convertTimestampToDateData(transactionStartTime),
      convertTimestampToDateData(transactionEndTime),
    ].filter(Boolean) as DateData[]

    setDates(datesInMemory)

    stationsApi
      .getUserStations()
      .then((userStations) => {
        const isEmpty = selectedStations.length === 0
        const stations = userStations.map((station) =>
          mapToFilterStationItem(station, isEmpty ? true : selectedStations.includes(station.id))
        )
        setStations(stations)
      })
      .finally(() => setLoading(false))
  }, [])

  useEffect(() => {
    const isEmpty = selectedVehicles.length === 0
    const cars = userCars.map((car) =>
      mapToFilterCarItem(car, isEmpty ? true : selectedVehicles.includes(car.vehicleId))
    )
    setCars(cars)
  }, [userCars])

  const markedDates = useMemo<MarkedDatesType>(() => {
    const markedDate = (obj: any) =>
      Object.assign({ color: theme.colors.primary, textColor: theme.colors.background }, obj)

    if (dates.length === 1 || (dates.length === 2 && dates[0].dateString === dates[1].dateString)) {
      return {
        [`${dates[0].dateString.toString()}`]: markedDate({
          startingDay: true,
          endingDay: true,
        }),
      }
    }

    const [start, finish] = dates.map(({ timestamp }) => convertTimestampToDate(timestamp))

    const datesToMap: string[] = []
    for (let d = start; d <= finish; d.setDate(d.getDate() + 1)) {
      datesToMap.push(d.toISOString().split('T')[0])
    }

    const map = datesToMap.map((date, i) => {
      switch (i) {
        case 0:
          return [`${date}`, markedDate({ startingDay: true })]
        case datesToMap.length - 1:
          return [`${date}`, markedDate({ endingDay: true })]
        default:
          return [`${date}`, markedDate({ selected: true })]
      }
    })
    return Object.fromEntries(map)
  }, [dates])

  const onItemPress = (item: FilterItem) => {
    const { id, type } = item
    switch (type) {
      case FilterType.car: {
        setCars((cars) => cars.map((x) => (x.id === id ? item : x)))
        break
      }
      case FilterType.station: {
        setStations((stations) => stations.map((x) => (x.id === id ? item : x)))
        break
      }
    }
  }

  const getAllChecked = (key: FilterType) => {
    switch (key) {
      case FilterType.car: {
        return cars.every(({ selected }) => selected)
      }
      case FilterType.station: {
        return stations.every(({ selected }) => selected)
      }
    }
  }

  const resetAll = () => {
    setCars((cars) => cars.map((item) => ({ ...item, selected: true })))
    setStations((stations) => stations.map((item) => ({ ...item, selected: true })))
    resetTransactionsFilter({})
    setDates([])
  }

  const fillDate = (selectedDate: DateData) => {
    if (dates.length !== 1) {
      setDates([selectedDate])
    } else if (selectedDate.dateString !== dates[0].dateString) {
      setDates((oldDate) => [...oldDate, selectedDate].sort((a, b) => a.timestamp - b.timestamp))
    }
  }

  const onDateInputPress = () => setVisible(true)

  const onAllPress = (type: FilterType) => {
    return (selected: boolean) => {
      switch (type) {
        case FilterType.car: {
          setCars((cars) => cars.map((item) => ({ ...item, selected })))
          break
        }
        case FilterType.station: {
          setStations((stations) => stations.map((item) => ({ ...item, selected })))
          break
        }
      }
    }
  }

  const filter = () => {
    const stationsId = stations.filter(({ selected }) => selected).map(({ item }) => item.id)
    const carsId = cars.filter(({ selected }) => selected).map(({ item }) => item.vehicleId)

    setTransactionsFilter({
      transactionStartTime: dates[0]?.timestamp,
      transactionEndTime: dates[dates.length - 1]?.timestamp,
      chargerStationsId: stationsId.length === cars.length ? [] : stationsId,
      vehiclesId: carsId.length === stations.length ? [] : carsId,
    })

    setVisible(false)
    onFilter()
  }

  const sections = [
    { title: FilterType.car, data: cars },
    { title: FilterType.station, data: stations },
  ]

  return {
    styles,
    resetAll,
    dates,
    fillDate,
    onDateInputPress,
    markedDates,
    filterData: {
      [FilterType.car]: cars,
      [FilterType.station]: stations,
    },
    sections,
    getAllChecked,
    onItemPress,
    theme,
    onAllPress,
    filter,
    loading,
    isVisible,
    setVisible,
  }
}

const mapToFilterStationItem = (station: IUserStation, selected: boolean): IFilterStationItem => ({
  item: station,
  type: FilterType.station,
  selected,
  id: station.id,
})

const mapToFilterCarItem = (car: ICar, selected: boolean): IFilterCarItem => ({
  item: car,
  type: FilterType.car,
  selected,
  id: car.vehicleId,
})
