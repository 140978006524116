import { createReducer } from '@reduxjs/toolkit'
import { setCars, setManufacturers, setModels, setVehicle, setVersions, setYears } from './actions'
import { VehiclesState } from './types'

const initialState: VehiclesState = {
  manufacturers: [],
  models: [],
  years: [],
  versions: [],
  cars: [],
  vehicle: null,
}

export const vehiclesReducer = createReducer(initialState, (builder) => {
  builder.addCase(setManufacturers, (state, action) => {
    return { ...state, manufacturers: action.payload }
  })
  builder.addCase(setModels, (state, action) => {
    return { ...state, models: action.payload }
  })
  builder.addCase(setYears, (state, action) => {
    return { ...state, years: action.payload }
  })
  builder.addCase(setVersions, (state, action) => {
    return { ...state, versions: action.payload }
  })
  builder.addCase(setCars, (state, action) => {
    return { ...state, cars: action.payload }
  })
  builder.addCase(setVehicle, (state, action) => {
    return { ...state, vehicle: action.payload }
  })
})
