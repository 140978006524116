import { withWrapper } from '../../../../core/helpers/withWrapper'
import { withStyles } from '../../../../core/theme'
import { useSelectorWithModalWrapper } from './wrapper'
import { ITheme } from '../../../../core/theme/themes'
import { FlatList, Image, TouchableOpacity, View } from 'react-native'
import { Icon } from '../../icon'
import Typography from '../Typography'
import Spacer from '../Spacer'

export interface IListItem {
  firstString: string
  secondString?: string
  img?: string
  tag?: string
  id?: string
}

interface IClickableListProps {
  data: IListItem[]
  onItemPress: (item: IListItem) => void
  withBorder?: boolean
}

interface IClickableListModal extends IClickableListProps {
  styles: ReturnType<typeof useStyles>
  theme: ITheme
}

interface IRenderListItem {
  item: IListItem
  index: number
}

const useStyles = withStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
  },
  itemContainer: {
    marginVertical: theme.spacing.s,
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
    paddingRight: theme.spacing.s,
    paddingBottom: theme.spacing.l,
  },
  withBorder: {
    borderBottomWidth: 1,
    borderBottomColor: '#ddeaf3',
  },
  textWrapper: {
    alignContent: 'center',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  leftBlock: { flex: 0.7 },
  imgWrapper: {
    height: theme.spacing.m * 2,
    width: theme.spacing.m * 2,
    marginRight: theme.spacing.m,
  },
  textsWrapper: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
  },
  tagWrapper: {},
  icon: {
    marginLeft: theme.spacing.s,
  },
}))

const ClickableList = ({ styles, data, onItemPress, withBorder }: IClickableListModal) => {
  const renderListItem = ({ item, index }: IRenderListItem) => {
    const { firstString, secondString } = item

    return (
      <TouchableOpacity
        key={item.id || index}
        style={[styles.itemContainer, withBorder && styles.withBorder]}
        onPress={() => onItemPress(item)}
      >
        <View style={styles.textWrapper}>
          {item.img ? (
            <View style={styles.imgWrapper}>
              <Image source={{ uri: item.img }} style={{ flex: 1 }} />
            </View>
          ) : null}
          <View style={styles.textsWrapper}>
            <View style={styles.leftBlock}>
              <Typography text={firstString} size={'m'} />
              {secondString ? (
                <>
                  <Spacer top={'s'} />
                  <Typography text={secondString} fontWeight={'100'} />
                </>
              ) : null}
            </View>
          </View>
          {item.tag && item.tag !== '' ? (
            <View style={styles.tagWrapper}>
              <Typography text={item.tag} size={'m'} color={'primary'} align={'center'} />
            </View>
          ) : null}
        </View>
        <Icon family={'Feather'} name={'chevron-right'} size={20} style={styles.icon} />
      </TouchableOpacity>
    )
  }

  return (
    <FlatList
      data={data}
      renderItem={renderListItem}
      style={styles.container}
      keyExtractor={(_, index) => index.toString()}
    />
  )
}

export default withWrapper<IClickableListProps>(ClickableList, useSelectorWithModalWrapper, {
  useStyles,
})
