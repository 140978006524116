import { TranslationLanguageCode } from 'qem-react-native-country-picker-modal'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PhoneInput from 'qem-react-native-phone-number-input'
import { getLanguage } from '../../../../core/helpers/getLanguage'
import { IUseWrapper } from '../../../../core/helpers/withWrapper'
import { settingsSelectorHooks } from '../../../../core/store/settings'
import { useDarkTheme } from '../../../../core/theme'
import { IPhoneNumberProps } from './component'

export const usePhoneNumberWrapper: IUseWrapper<IPhoneNumberProps> = (
  options,
  { onChange, showMessage: ownShowMessage, handleBlur, value: ownValue }
) => {
  const { t } = useTranslation()
  const [value, setValue] = useState(ownValue || '')
  const [formattedValue, setFormattedValue] = useState('')
  const [valid, setValid] = useState(true)
  const [isShowMessage, setShowMessage] = useState(false)
  const phoneInput = useRef<PhoneInput>(null)
  const { useStyles } = options!
  const styles = useStyles()
  const darkTheme = useDarkTheme()
  const language = settingsSelectorHooks.getLanguage()
  const [focused, setFocused] = useState(false)

  const validate = () => {
    const checkValid = phoneInput.current?.isValidNumber(value)

    setValid(checkValid ? checkValid : false)

    setShowMessage(!checkValid)

    setFocused(false)

    if (handleBlur) {
      handleBlur()
    }
  }

  const handleChange = (text: string) => {
    setValue(text)

    if (onChange) {
      onChange(text)
    }
  }

  const handleFocus = () => {
    setValid(true)
    setShowMessage(false)
    setFocused(true)
  }

  const getCountrySelectorLanguage = (): TranslationLanguageCode =>
    getLanguage(language.toUpperCase())

  return {
    darkTheme,
    formattedValue,
    handleChange,
    handleFocus,
    phoneInput,
    setFormattedValue,
    showMessage: Boolean(typeof ownShowMessage !== undefined ? ownShowMessage : isShowMessage),
    styles,
    t,
    valid,
    validate,
    value,
    focused,
    getCountrySelectorLanguage,
  }
}
