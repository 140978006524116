import { Platform, ScrollView, StyleSheet, View } from 'react-native'
import { EdgeInsets } from 'react-native-safe-area-context'

import { DefaultWrapperProps, withWrapper } from '../../../core/helpers/withWrapper'
import { withStyles } from '../../../core/theme'
import {
  Button,
  Divider,
  EmptyPlaceholder,
  Paper,
  ProgressButton,
  RadioModal,
  ScreenLoader,
  Spacer,
  Typography,
} from '../../components'

import { useStationScreenWrapper } from './wrapper'
import { RouterNames } from '../../../core/constants'
import {
  HeaderSection,
  PaymentsSection,
  VehicleSection,
  StationInfoSection,
} from '../../components/station'

import { Icon } from '../../components/icon'
import { getCardIcon } from '../../../core/helpers/utils'
import { ICar } from '../../../core/store/vehicles'
import { StationDetailsModal } from '../StationDetails'
import { useDeviceType } from '../../../core/hooks'
import { Card, Connector, StationDetails } from '../../../core/models'
import { Connectors } from './Connectors'
import { Reviews } from './Reviews'
import { Report } from './Report'
import { Map } from '../../components/map'

const useStyles = withStyles((theme, insets, _, isWeb) => ({
  container: {
    paddingBottom: isWeb ? 0 : insets.bottom + 80,
  },
  header: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    top: 0,
    right: isWeb ? 580 : 0,
    paddingRight: isWeb ? 0 : 20,
    paddingTop: isWeb ? 20 : insets.top + theme.spacing.m,
  },
  info: {
    zIndex: 1,
    bottom: -theme.spacing.m,
    paddingHorizontal: theme.spacing.m,
  },
  content: {
    flex: 1,
    width: '100%',
    maxWidth: 550,
    margin: theme.spacing.m,
    backgroundColor: theme.colors.background,
    padding: theme.spacing.m,
    borderRadius: 20,
    alignSelf: isWeb ? 'flex-end' : 'flex-start',
  },
  map: {
    height: isWeb ? '100%' : 400,
    ...(isWeb ? StyleSheet.absoluteFillObject : { width: '100%' }),
    marginBottom: -100,
  },
  footer: {
    padding: theme.spacing.m,
    paddingBottom: insets.bottom || theme.spacing.m,
    backgroundColor: theme.colors.background,
  },
}))

interface IStationScreen extends DefaultWrapperProps<RouterNames.station> {
  styles: ReturnType<typeof useStyles>
  insets: EdgeInsets
  loading: boolean
  stationId: string
  station: StationDetails | null
  serverErrorMessage: string
  retry: () => void
  isCardsOpen: boolean
  isCarsOpen: boolean
  toggleCards: (open?: boolean) => void
  toggleCars: (open?: boolean) => void
  addNewCard: () => void
  addNewCar: () => void
  cars: Array<ICar>
  sectionCar?: ICar
  vehicleId?: string
  setVehicle: (id: string) => void
  onButtonPress: () => void
  showCharging: () => void
  connectors: Map<string, Connector>
  connectorId: string
  setConnectorId: (id: string) => void
  isChargeButtonDisabled: boolean
  buttonText: string
  isChargingShown: boolean
  setIsChargingShown: (value: boolean) => void
  progress?: { cost: string; energy: string; percent: number }
  cards: Card[]
  setDefaultCard: (cardId: string) => Promise<void>
  selectedCard?: Card
}

const StationScreen = ({
  t,
  styles,
  loading,
  serverErrorMessage,
  station,
  stationId,
  retry,
  isCardsOpen,
  isCarsOpen,
  toggleCards,
  toggleCars,
  addNewCard,
  addNewCar,
  cars,
  sectionCar,
  vehicleId,
  setVehicle,
  onButtonPress,
  showCharging,
  connectors,
  connectorId,
  setConnectorId,
  isChargeButtonDisabled,
  buttonText,
  isChargingShown,
  setIsChargingShown,
  progress,
  cards,
  setDefaultCard,
  selectedCard,
}: IStationScreen) => {
  const type = useDeviceType()

  const button = progress?.percent ? (
    <ProgressButton progress={progress.percent} text={buttonText} onPress={showCharging} />
  ) : (
    <Button
      disabled={isChargeButtonDisabled}
      startIcon={{ name: 'zap', family: 'Feather' }}
      label={buttonText}
      onPress={onButtonPress}
    />
  )

  const map = station?.address.latitude && station.address.longitude && (
    <View style={styles.map}>
      <Map
        disabled
        position={{ latitude: station.address.latitude, longitude: station.address.longitude }}
      />
    </View>
  )

  const description = station?.description && (
    <>
      <Spacer vertical={'s'} />
      <Typography text={station.description} numberOfLines={6} />
    </>
  )

  const payment = !station?.deferPayment && (
    <>
      <Divider vertical={'m'} />
      <PaymentsSection onPress={toggleCards} card={selectedCard} showArrowRight />
    </>
  )

  const common = () => {
    if (!station) return null

    const { id, price, reviews } = station

    return (
      <>
        {description}
        <Divider vertical={'m'} />
        <Connectors
          connectors={connectors}
          connectorId={connectorId}
          onConnectorPress={setConnectorId}
          price={price}
        />
        <Divider vertical={'m'} />
        <VehicleSection car={sectionCar} onPress={toggleCars} onAddNew={addNewCar} />
        {payment}
        <Divider vertical={'m'} />
        <Report chargeStationId={id} />
        <Divider vertical={'m'} />
        <Reviews reviews={reviews} />
      </>
    )
  }

  return (
    <>
      {loading && <ScreenLoader />}
      {!loading && !station && (
        <EmptyPlaceholder
          icon={serverErrorMessage ? 'alert-circle' : 'layer'}
          text={serverErrorMessage || t('noStationsFound')}
          retry={serverErrorMessage ? retry : undefined}
          goBack
        />
      )}
      {!loading && station && (
        <>
          {type === 'web' ? (
            <>
              {map}
              <HeaderSection station={station} style={styles.header} />
              <View style={styles.content}>
                <ScrollView>
                  <StationInfoSection station={station} />
                  {common()}
                </ScrollView>
                {button}
              </View>
            </>
          ) : (
            <>
              <ScrollView contentContainerStyle={styles.container}>
                <HeaderSection station={station} style={styles.header} />
                {map}
                <StationInfoSection gradient station={station} style={styles.info} />
                <Paper disableRadius disableShadow>
                  {common()}
                </Paper>
              </ScrollView>
              <View style={styles.footer}>{button}</View>
            </>
          )}
        </>
      )}
      {Platform.OS === 'web' && (
        <StationDetailsModal
          stationId={stationId}
          connectorId={connectorId}
          isVisible={isChargingShown}
          setVisible={setIsChargingShown}
        />
      )}
      <RadioModal
        items={cards.map((card) => ({ id: card.cardId, data: card }))}
        isVisible={isCardsOpen}
        setVisible={toggleCards}
        rowItem={RadioCardItem}
        addNew={addNewCard}
        onCheck={setDefaultCard}
        checkedId={selectedCard?.cardId}
        title={t('stations:payment')}
      />
      <RadioModal
        items={cars.map((car) => ({ id: car.vehicleId, data: car }))}
        isVisible={isCarsOpen}
        setVisible={toggleCars}
        rowItem={RadioCarItem}
        addNew={addNewCar}
        title={t('vehicle:title')}
        checkedId={vehicleId}
        onCheck={setVehicle}
      />
    </>
  )
}

export default withWrapper(StationScreen, useStationScreenWrapper, {
  useStyles,
})

const RadioCarItem = ({ vehicleVersion }: ICar) => (
  <View>
    <Typography
      bold
      size={14}
      text={`${vehicleVersion.vehicleManufacturerName} ${vehicleVersion.vehicleModelName}`}
    />
    <Typography text={vehicleVersion.year} color={'placeholder'} />
  </View>
)

const RadioCardItem = (card: Card) => (
  <View style={{ flexDirection: 'row', alignItems: 'center' }}>
    <Icon family={'FontAwesome5'} name={getCardIcon(card.cardBrand ?? 'Unknown')} size={43} />
    <Spacer left={'s'} />
    <View>
      <Typography text={card.cardBrand ?? ''} size={14} bold />
      <Typography
        size={14}
        color={'placeholder'}
        text={`${card.type !== 'RFID' ? '****' : ''} ${card.lastDigits} `}
      />
    </View>
  </View>
)
