import { createReducer } from '@reduxjs/toolkit'
import { setLanguage } from './actions'
import { SettingsState } from './types'
import RNLocalize from 'react-native-localize'
import { DEFALUT_LANGUAGE, SUPPORTED_LANGUAGES } from '../../config'

const detectedLanguage = RNLocalize.findBestAvailableLanguage(SUPPORTED_LANGUAGES)

const initialState: SettingsState = {
  language: detectedLanguage?.languageTag || DEFALUT_LANGUAGE,
}

export const settingsReducer = createReducer(initialState, (builder) => {
  builder.addCase(setLanguage, (state, action) => {
    return { ...state, language: action.payload }
  })
})
