import { withWrapper } from '../../../../core/helpers/withWrapper'
import { withStyles } from '../../../../core/theme'
import ModalContainer from '../../../components/common/ModalContainer'
import StationReportContent from '../content'
import { useStationReportModalWrapper } from './wrapper'

const useStyles = withStyles(() => ({
  content: {
    height: 280,
    width: 600,
    maxWidth: '90%',
  },
}))

interface IStationReportModalProps {
  isVisible: boolean
  setVisible: (value: boolean) => void
  chargeStationId: string
}

interface IStationReportModal extends IStationReportModalProps {
  styles: ReturnType<typeof useStyles>
}

const StationReportModal = ({
  styles,
  isVisible,
  setVisible,
  chargeStationId,
}: IStationReportModal) => (
  <ModalContainer isVisible={isVisible} setVisible={setVisible} contentStyles={styles.content}>
    <StationReportContent chargeStationId={chargeStationId} />
  </ModalContainer>
)

export default withWrapper<IStationReportModalProps>(
  StationReportModal,
  useStationReportModalWrapper,
  { useStyles }
)
