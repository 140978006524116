import { useCallback, useEffect, useState } from 'react'
import { Platform } from 'react-native'
import { RouteProp, useFocusEffect, useNavigation, useRoute } from '@react-navigation/native'
import { generateError } from '../../../core/helpers/utils'
import { IOptions, IUseWrapper } from '../../../core/helpers/withWrapper'
import { paymentSelectorHooks } from '../../../core/store/payment'
import { stationsActionHooks, stationsSelectorHooks } from '../../../core/store/stations'
import { vehiclesSelectorHooks } from '../../../core/store/vehicles'
import { RouterNames } from '../../../core/constants'
import { RootStackParamList } from '../../router/types'
import { useConnectorStatuses, useStatuses } from '../../../core/hooks'
import { useCharging } from '../../providers/charging'
import { useTranslation } from 'react-i18next'
import { Card, Charging, Connector } from '../../../core/models'
import { ConnectorStatus } from '../../../core/models'

export const useStationScreenWrapper: IUseWrapper = (options: IOptions) => {
  const { useStyles } = options
  const styles = useStyles()

  const { params } = useRoute<RouteProp<RootStackParamList, RouterNames.station>>()
  const { stationId } = params
  const { navigate } = useNavigation()

  const { t } = useTranslation()

  const cars = vehiclesSelectorHooks.getCars()
  const primaryCar = vehiclesSelectorHooks.getPrimary()

  const station = stationsSelectorHooks.getStation()
  const getStation = stationsActionHooks.getStation()

  const { cards } = paymentSelectorHooks.getPaymentMethods()

  const [loading, setLoading] = useState(true)
  const [serverErrorMessage, setServerErrorMessage] = useState('')
  const [connectors, setConnectors] = useState<Map<string, Connector>>(new Map())
  const [isCarsOpen, setIsCarsOpen] = useState(false)
  const [isCardsOpen, setIsCardsOpen] = useState(false)
  const [vehicleId, setVehicleId] = useState<string>()
  const [connectorId, setConnectorId] = useState<string>()
  const [selectedCard, setSelectedCard] = useState<Card>()
  const [isChargingShown, setIsChargingShown] = useState(false)

  const { startCharging, setChargingData } = useCharging()
  const { connectorStatuses } = useConnectorStatuses(stationId)
  const { session, chargingStatus } = useStatuses(connectorId!)

  const chosenConnector = connectorId ? connectors.get(connectorId) : null

  useEffect(() => {
    const connectors = station?.connectors?.map<[string, Connector]>((item) => {
      const connectorStatus = station.hardwareStatus
        ? connectorStatuses[item.connectorId] ?? item.connectorStatus
        : ConnectorStatus.Unavailable

      return [item.connectorId, { ...item, connectorStatus }]
    })

    connectors && setConnectors(new Map(connectors))
  }, [station, connectorStatuses])

  useEffect(() => {
    station && setConnectorId(station.connectors[0].connectorId)
  }, [station])

  useEffect(() => {
    const defaultCard = cards.find((card) => card.default)
    setSelectedCard(defaultCard)
  }, [cards])

  useFocusEffect(
    useCallback(() => {
      getCurrentStation()

      return () => setConnectors(new Map())
    }, [stationId])
  )

  useEffect(() => {
    setVehicleId((primaryCar || cars[0])?.vehicleId)
  }, [primaryCar, cars])

  const getCurrentStation = () => {
    setLoading(true)
    getStation({
      stationId,
      callback(error) {
        if (error) {
          const errorMessage = generateError(error, {
            500: 'Something went wrong',
          })
          errorMessage && setServerErrorMessage(errorMessage)
        }
        setLoading(false)
      },
    })
  }

  const toggleCards = (isOpen = true) => {
    setIsCardsOpen(isOpen)
  }

  const toggleCars = (isOpen = true) => {
    setIsCarsOpen(isOpen)
  }

  const addNewCard = () => {
    navigate(RouterNames.addCard)
    toggleCards(false)
  }

  const addNewCar = () => {
    navigate(RouterNames.addCar)
    toggleCars(false)
  }

  const onButtonPress = () => {
    if (!chargingStatus) {
      const car = cars.find((car) => car.vehicleId === vehicleId)

      if (!car || !chosenConnector || !station) return

      const payload: Charging.StartChargingPayload = Object.assign(
        {
          vehicleid: car.vehicleId,
          stationid: station.id,
          deferPayment: station.deferPayment,
          connectorid: chosenConnector.connectorId,
        },
        !station.deferPayment && { cardid: selectedCard?.cardId }
      )

      setChargingData(chosenConnector.connectorId, {
        car,
        connector: chosenConnector,
        station,
        card: selectedCard,
      })

      startCharging(payload)
    }

    showCharging()
  }

  const showCharging = () => {
    if (Platform.OS === 'web') {
      setIsChargingShown(true)
    } else {
      connectorId && navigate(RouterNames.stationDetails, { stationId, connectorId })
    }
  }

  const setDefaultCard = async (cardId: string) => {
    const defaultCard = cards.find((c) => c.cardId === cardId)
    setSelectedCard(defaultCard)
    toggleCards(false)
  }

  const setVehicle = (vehicleId: string) => {
    setVehicleId(vehicleId)
    toggleCars(false)
  }

  const sectionCar = cars.find((car) => car.vehicleId === vehicleId)

  const progress = chargingStatus?.status !== Charging.Status.Finished ? chargingStatus : undefined

  const isPreparing = chosenConnector?.connectorStatus === ConnectorStatus.Preparing
  const hasPayment = station?.deferPayment || !!selectedCard
  const isChargeButtonDisabled = !((isPreparing && vehicleId && hasPayment) || !!chargingStatus)

  const buttonText =
    session?.chargeStationId === stationId && session.connectorId === connectorId
      ? t('charging:status')
      : t('charging:start')

  return {
    styles,
    loading,
    station,
    stationId,
    serverErrorMessage,
    retry: getCurrentStation,
    addNewCard,
    isCardsOpen,
    toggleCards,
    addNewCar,
    isCarsOpen,
    toggleCars,
    cars,
    vehicleId,
    setVehicle,
    sectionCar,
    onButtonPress,
    showCharging,
    connectors,
    connectorId,
    setConnectorId,
    isChargingShown,
    setIsChargingShown,
    progress,
    isChargeButtonDisabled,
    buttonText,
    cards,
    setDefaultCard,
    selectedCard,
  }
}
