import { getPathFromState, LinkingOptions } from '@react-navigation/native'
import {
  AccountDetailsScreen,
  AddCardScreen,
  AddCarScreen,
  AddOwnCarScreen,
  RfidScreen,
  CarDetailsScreen,
  CardInfoScreen,
  CarsScreen,
  FAQScreen,
  FavoritesScreen,
  HomeScreen,
  EditAccountDetailsScreen,
  LanguagesScreen,
  LoginScreen,
  PrivacyScreen,
  ProfileScreen,
  RegistrationScreen,
  ReportIssueScreen,
  SelectModelScreen,
  SendFeedbackScreen,
  StationDetailsScreen,
  StationFeedbackScreen,
  StationScreen,
  SupportRequestScreen,
  SupportScreen,
  TransactionFilterScreen,
  TransactionHistoryScreen,
  TransactionInfoScreen,
  UserGuideScreen,
  WalletScreen,
  YearAndVersionScreen,
  StationGuideScreen,
  StationReportScreen,
  SessionsScreen,
  RecoveryScreen,
  OTPScreen,
  SettingsScreen,
  StationFilterScreen,
  ScanQrScreen,
} from '../screens'
import { RouterNames } from '../../core/constants'
import { RootStackParamList } from './types'

export const privateRoutes = [
  {
    name: RouterNames.private,
    nestedTabs: true,
    options: {
      headerShown: false,
    },
    tabs: [
      {
        name: RouterNames.home,
        component: HomeScreen,
        options: {
          title: 'map:title',
          headerShown: false,
        },
      },
      {
        name: RouterNames.favorites,
        component: FavoritesScreen,
        options: {
          title: 'favorites:title',
        },
      },
      {
        name: RouterNames.scanQr,
        component: ScanQrScreen,
        options: {
          headerShown: false,
          title: 'scan:title',
        },
      },
      {
        name: RouterNames.sessions,
        component: SessionsScreen,
        options: {
          title: 'sessions:title',
          headerShown: true,
        },
      },
      {
        name: RouterNames.account,
        component: ProfileScreen,
        options: {
          title: 'profile:title',
          headerShown: false,
        },
      },
    ],
  },
  {
    name: RouterNames.accountDetails,
    component: AccountDetailsScreen,
    options: {
      title: 'profile:general.menu.personal',
    },
  },
  {
    name: RouterNames.wallet,
    component: WalletScreen,
    options: {
      title: 'profile:general.menu.wallet',
    },
  },
  {
    name: RouterNames.support,
    component: SupportScreen,
    options: {
      title: 'profile:general.menu.support',
    },
  },

  {
    name: RouterNames.supportRequest,
    component: SupportRequestScreen,
    options: {
      title: 'support:request',
    },
  },
  {
    name: RouterNames.sendFeedback,
    component: SendFeedbackScreen,
    options: {
      title: 'support:feedback',
    },
  },
  {
    name: RouterNames.userGuide,
    component: UserGuideScreen,
    options: {
      title: 'support:guide',
    },
  },
  {
    name: RouterNames.faq,
    component: FAQScreen,
    options: {
      title: 'support:faq',
    },
  },
  {
    name: RouterNames.cardInfo,
    component: CardInfoScreen,
    options: {
      title: 'card:info',
      headerShown: true,
    },
  },
  {
    name: RouterNames.addCard,
    component: AddCardScreen,
    options: {
      title: 'card:common.add',
    },
  },
  {
    name: RouterNames.rfid,
    component: RfidScreen,
    options: {
      title: 'card:rfid.add',
    },
  },
  {
    name: RouterNames.transactionHistory,
    component: TransactionHistoryScreen,
    options: {
      title: 'transaction:history',
    },
  },
  {
    name: RouterNames.cars,
    component: CarsScreen,
    options: {
      title: 'profile:general.menu.vehicles',
    },
  },
  {
    name: RouterNames.addCar,
    component: AddCarScreen,
    options: {
      title: 'cars:addCar.addVehicle',
    },
  },
  {
    name: RouterNames.carDetails,
    component: CarDetailsScreen,
    options: {
      title: 'feedback:details',
    },
  },
  {
    name: RouterNames.selectModel,
    component: SelectModelScreen,
    options: {
      title: 'cars:selectModel',
    },
  },
  {
    name: RouterNames.yearAndVersion,
    component: YearAndVersionScreen,
    options: {
      title: 'cars:selectYear&Version',
    },
  },
  {
    name: RouterNames.transactionFilter,
    component: TransactionFilterScreen,
    options: {
      title: 'transaction:filter.filter',
    },
  },
  {
    name: RouterNames.editAccountDetails,
    component: EditAccountDetailsScreen,
    options: {
      title: 'editAccountDetails:title',
    },
  },
  {
    name: RouterNames.stationFilter,
    component: StationFilterScreen,
    options: {
      title: 'filters:filter',
    },
  },
  {
    name: RouterNames.transactionInfo,
    component: TransactionInfoScreen,
    options: {
      title: 'transaction:info',
    },
  },
  {
    name: RouterNames.sessionInfo,
    component: TransactionInfoScreen,
    options: {
      title: 'transaction:info',
    },
  },
  {
    name: RouterNames.stationDetails,
    component: StationDetailsScreen,
    options: {
      title: 'stations:charging',
    },
  },
  {
    name: RouterNames.station,
    component: StationScreen,
    options: {
      headerShown: false,
      title: 'details:station',
    },
  },
  {
    name: RouterNames.stationFeedback,
    component: StationFeedbackScreen,
    options: {
      headerShown: false,
      title: 'charging:feedback',
    },
  },
  {
    name: RouterNames.reportIssue,
    component: ReportIssueScreen,
  },
  {
    name: RouterNames.addOwnCar,
    component: AddOwnCarScreen,
    options: {
      title: 'cars:addCar.addMyVehicle',
    },
  },
  {
    name: RouterNames.stationReport,
    component: StationReportScreen,
    options: {
      title: 'stations:menu.report',
    },
  },
  {
    name: RouterNames.stationGuide,
    component: StationGuideScreen,
    options: {
      title: 'stations:menu.guide',
    },
  },
  {
    name: RouterNames.settings,
    component: SettingsScreen,
    options: {
      title: 'profile:general.menu.settings',
    },
  },
]

export const publicRoutes = [
  {
    name: RouterNames.languages,
    component: LanguagesScreen,
    options: {
      title: 'languages:select',
    },
  },
  {
    name: RouterNames.otp,
    component: OTPScreen,
    options: {
      title: 'otp:title',
      headerShown: false,
    },
  },
  {
    name: RouterNames.privacy,
    component: PrivacyScreen,
    options: {
      title: 'profile:general.menu.privacy',
    },
  },
]

export const guestRoutes = [
  {
    name: RouterNames.login,
    component: LoginScreen,
    options: {
      headerShown: false,
      title: 'login:title',
    },
  },
  {
    name: RouterNames.recovery,
    component: RecoveryScreen,
    options: {
      headerShown: false,
      title: 'recovery:title',
    },
  },
  {
    name: RouterNames.registration,
    component: RegistrationScreen,
    options: {
      headerShown: false,
      title: 'registration:title',
    },
  },
]

const config = {
  screens: {
    [RouterNames.private]: {
      screens: {
        [RouterNames.home]: 'map',
        [RouterNames.favorites]: 'favorites',
        [RouterNames.scanQr]: 'scan',
        [RouterNames.sessions]: 'sessions',
        [RouterNames.account]: 'account',
      },
    },
    [RouterNames.station]: 'station/:stationId',
    [RouterNames.login]: 'login',
    [RouterNames.registration]: 'signup',
    [RouterNames.otp]: 'otp/:type/:code?',
    [RouterNames.stationFilter]: 'stations/filter',
    [RouterNames.stationGuide]: 'station/guide',
    [RouterNames.stationReport]: 'station/:chargerStationId/report',
    [RouterNames.stationDetails]: 'station/:id/charge',
    [RouterNames.stationFeedback]: 'station/:id/feedback',

    [RouterNames.accountDetails]: 'account/details',
    [RouterNames.editAccountDetails]: 'account/details/edit',
    [RouterNames.cars]: 'account/cars',
    [RouterNames.addCar]: 'account/add-car',
    [RouterNames.addOwnCar]: 'account/add-car/own',
    [RouterNames.selectModel]: 'account/add-car/select-model',
    [RouterNames.yearAndVersion]: 'account/add-car/set-info',
    [RouterNames.wallet]: 'account/wallet',
    [RouterNames.cardInfo]: 'account/wallet/card/:id',
    [RouterNames.addCard]: 'account/wallet/add-card',
    [RouterNames.rfid]: 'account/wallet/rfid/:type/:id?',
    [RouterNames.transactionInfo]: 'account/wallet/transaction/:transactionId',
    [RouterNames.transactionFilter]: 'account/wallet/filter-transactions',
    [RouterNames.transactionHistory]: 'account/wallet/transaction-history',
    [RouterNames.support]: 'account/support',
    [RouterNames.supportRequest]: 'account/support/request',
    [RouterNames.sendFeedback]: 'account/support/feedback',
    [RouterNames.faq]: 'account/support/faq',
    [RouterNames.userGuide]: 'account/support/guide',
    [RouterNames.settings]: 'account/settings',

    [RouterNames.carDetails]: 'car/:carId',

    [RouterNames.privacy]: 'privacy-policy',
  },
}

export const linking: LinkingOptions<RootStackParamList> = {
  config,
  getPathFromState(state, config) {
    const cleanState: typeof state = {
      ...state,
      routes: state.routes.map(({ key, name, params }) => {
        const newKey = key || Date.now().toString()

        if (!params) return { key: newKey, name }

        const newParams = Object.entries(params).reduce<Record<string, string>>(
          (acc, [key, value]) => {
            if (typeof value === 'object') return { ...acc, ...value }
            acc[key] = value
            return acc
          },
          {}
        )

        return { key: newKey, name, params: newParams, title: '12' }
      }),
    }

    const path = getPathFromState(cleanState, config)
    const index = path.indexOf('?')

    return index >= 0 ? path.slice(0, index) : path
  },
  prefixes: ['com.quantexinnovation.qem://'],
}
