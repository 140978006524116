import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import { IOptions, IUseWrapper } from '../../../core/helpers/withWrapper'
import { useState } from 'react'

export const useReportIssueScreenWrapper: IUseWrapper = (options?: IOptions) => {
  const { useStyles } = options!
  const { t } = useTranslation()
  const styles = useStyles()
  const navigation = useNavigation()

  const [report, setReport] = useState('')

  return {
    styles,
    t,
    navigation,
    report,
    setReport,
  }
}
