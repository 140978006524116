import { withStyles } from '../../../../core/theme'

const SIZE = 24

export const useStyles = withStyles((theme) => ({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  item: {
    flex: 1,
    height: '100%',
    gap: theme.spacing.xs,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing.m,
  },
  count: {
    width: SIZE,
    height: SIZE,
    borderRadius: SIZE,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: `${theme.colors.primary}90`,
  },
  selected: {
    borderBottomWidth: 1,
    borderBottomColor: theme.colors.primary,
  },
}))
