import React from 'react'
import { SafeAreaView, ScrollView, View } from 'react-native'
import { withWrapper } from '../../../core/helpers/withWrapper'
import { withStyles } from '../../../core/theme'
import { useAddOwnCarScreenWrapper } from './wrapper'
import { Button, KeyboardAvoid, Paper, SelectorWithModal, TextField } from '../../components'
import Spacer from '../../components/common/Spacer/component'
import { TFunction } from 'react-i18next'

const useStyles = withStyles((theme) => ({
  wrapper: {
    flex: 1,
    backgroundColor: theme.colors.backgroundOffset,
    height: '100%',
  },
  outerContainer: {
    padding: theme.spacing.m,
    flex: 1,
    backgroundColor: theme.colors.backgroundOffset,
    height: '100%',
  },
  innerContainer: {
    height: '100%',
    width: '100%',
  },
  selectorCustomStyle: {
    height: theme.spacing.xxl,
    borderWidth: 2,
    borderColor: theme.colors.border,
  },
}))

interface Car {
  make: string
  model: string
  year: string
  color: string
  number: string
}

interface IFormik<T, B> {
  handleSubmit: () => void
  setFieldValue: (key: string, value: string) => void
  setFieldTouched: (key: string, value: boolean) => void
  values: T
  isValid: boolean
  errors: B
  touched: B
}

interface IAddOwnCarScreenProps {}

interface IAddOwnCarScreen extends IAddOwnCarScreenProps {
  styles: ReturnType<typeof useStyles>
  car: IFormik<Car, Car>
  colors: string[]
  isLoading: boolean
  isButtonEnabled: boolean
  setSearch: (value: string) => void
  t: TFunction<'translation', undefined>
}

function generateField<T = Car>(formik: IFormik<T, T>, key: keyof T) {
  return {
    onChange: (text: string) => {
      formik.setFieldValue(key as string, text)
    },
    handleBlur: () => {
      if (!formik.touched[key]) {
        formik.setFieldTouched(key as string, true)
      }
    },
    value: formik.values[key],
    showMessage: formik.touched[key] ? formik.errors[key] : undefined,
    fullWidth: true,
  }
}

function generateSelectorField<T = Car>(formik: IFormik<T, T>, key: keyof T) {
  return {
    onSelectItem: (text: string) => {
      formik.setFieldValue(key as string, text)
    },
    handleBlur: () => {
      if (!formik.touched[key]) {
        formik.setFieldTouched(key as string, true)
      }
    },
    value: formik.values[key],
    showMessage: formik.touched[key] ? formik.errors[key] : undefined,
    fullWidth: true,
  }
}

const AddOwnCarScreen = ({
  styles,
  car,
  colors,
  isLoading,
  isButtonEnabled,
  setSearch,
  t,
}: IAddOwnCarScreen) => (
  <SafeAreaView style={styles.wrapper}>
    <View style={styles.outerContainer}>
      <KeyboardAvoid>
        <Paper style={{ height: '100%' }} containerStyle={{ height: '100%' }}>
          <View style={styles.innerContainer}>
            <ScrollView>
              <TextField label={t('cars:addCar.fields.make')} {...generateField(car, 'make')} />
              <Spacer vertical="s" />
              <TextField label={t('cars:addCar.fields.model')} {...generateField(car, 'model')} />
              <Spacer vertical="s" />
              <TextField label={t('cars:addCar.fields.year')} {...generateField(car, 'year')} />
              <Spacer vertical="s" />
              <SelectorWithModal
                colors
                data={colors}
                rightIcon={'chevron-down'}
                label={t('cars:addCar.fields.color')}
                onSearchInput={setSearch}
                noItemSelectedString={t('cars:addCar.placeholders.color')}
                customStyle={styles.selectorCustomStyle}
                {...generateSelectorField(car, 'color')}
              />
              <Spacer vertical="s" />
              <TextField label={t('cars:addCar.fields.number')} {...generateField(car, 'number')} />
            </ScrollView>
            <Spacer vertical="s" />
            <Button
              loading={isLoading}
              label={t('buttons:add')}
              onPress={car.handleSubmit}
              disabled={!isButtonEnabled}
            />
          </View>
        </Paper>
      </KeyboardAvoid>
    </View>
  </SafeAreaView>
)

export default withWrapper(AddOwnCarScreen, useAddOwnCarScreenWrapper, {
  useStyles,
})
