import { withWrapper } from '../../../../core/helpers/withWrapper'
import { withStyles } from '../../../../core/theme'
import ModalContainer from '../../../components/common/ModalContainer'
import StationGuideContent from '../content'
import { useStationGuideModalWrapper } from './wrapper'

const useStyles = withStyles(() => ({
  content: {
    height: 700,
    width: 500,
    maxWidth: '90%',
    borderRadius: 10,
  },
}))

interface IStationGuideModalProps {
  isVisible: boolean
  setVisible: (value: boolean) => void
}

interface IStationGuideModal extends IStationGuideModalProps {
  styles: ReturnType<typeof useStyles>
}

const StationGuideModal = ({ isVisible, setVisible, styles }: IStationGuideModal) => (
  <ModalContainer isVisible={isVisible} setVisible={setVisible} contentStyles={styles.content}>
    <StationGuideContent />
  </ModalContainer>
)

export default withWrapper<IStationGuideModalProps>(
  StationGuideModal,
  useStationGuideModalWrapper,
  { useStyles }
)
