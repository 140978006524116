import { View } from 'react-native'
import { useTranslation } from 'react-i18next'

import ModalContainer from '../../../components/common/ModalContainer'
import { Button, Spacer, Typography } from '../../../components/common'
import { ITransaction, OrderType } from '../../../../core/store/transactions'
import { useStyles } from './styles'

type SortType = keyof ITransaction

interface Props {
  isVisible: boolean
  onClose: () => void
  sortField: SortType
  order: OrderType
  onSortPress: (field: SortType) => void
}

export const SortModal = ({ isVisible, order, sortField, onSortPress, onClose }: Props) => {
  const { t } = useTranslation()

  const styles = useStyles()

  const getSortIcon = (type: SortType): { name: string; iconSet: IconFamily } | undefined => {
    if (type !== sortField) return undefined
    return { name: order === 'asc' ? 'arrow-up' : 'arrow-down', iconSet: 'Feather' }
  }

  return (
    <ModalContainer isVisible={isVisible} setVisible={onClose}>
      <View style={styles.modal}>
        <Typography text={t('transaction:sort.title')} size={20} bold />
        <Spacer vertical={'s'} />
        <View>
          <Button
            textSize={14}
            align={'left'}
            variant={'text'}
            textStyle={styles.text}
            endIcon={getSortIcon('cost')}
            label={t('transaction:sort.price')}
            onPress={() => onSortPress('cost')}
          />
          <Button
            textSize={14}
            align={'left'}
            variant={'text'}
            textStyle={styles.text}
            label={t('transaction:sort.date')}
            endIcon={getSortIcon('endTimeOfCharge')}
            onPress={() => onSortPress('endTimeOfCharge')}
          />
        </View>
        <Spacer vertical={'s'} />
        <Button label={t('transaction:sort.sort')} onPress={onClose} />
      </View>
    </ModalContainer>
  )
}
