import { SafeAreaView, View } from 'react-native'
import { withWrapper } from '../../../../core/helpers/withWrapper'
import { withStyles } from '../../../../core/theme'
import { useStationFeedbackScreenWrapper } from './wrapper'
import StationFeedbackContent from '../content'
import { Charging } from '../../../../core/models'

const useStyles = withStyles((theme) => ({
  container: {
    backgroundColor: theme.colors.backgroundOffset,
  },
  content: {
    padding: theme.spacing.m,
  },
}))

interface IStationFeedbackScreen {
  styles: ReturnType<typeof useStyles>
  session: Charging.ActiveSession
  showReport: () => void
}

const StationFeedbackScreen = ({ styles, session, showReport }: IStationFeedbackScreen) => (
  <SafeAreaView style={styles.container}>
    <View style={styles.content}>
      <StationFeedbackContent session={session} showReport={showReport} />
    </View>
  </SafeAreaView>
)

export default withWrapper(StationFeedbackScreen, useStationFeedbackScreenWrapper, { useStyles })
