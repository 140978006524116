import { showMessage } from 'react-native-flash-message'
import { generateError } from '../../../core/helpers/utils'
import { IUseWrapper, IOptions } from '../../../core/helpers/withWrapper'
import { accountActionHooks, accountSelectorHooks } from '../../../core/store/account'

export const useAccountScreenWrapper: IUseWrapper = (options?: IOptions) => {
  const { useStyles } = options!
  const styles = useStyles()

  const uploadImage = accountActionHooks.updateUserImage()

  const account = accountSelectorHooks.getUser()
  const image = accountSelectorHooks.getImage()

  const onImageChange = (url: string) => {
    uploadImage({
      image: url,
      callback: (error) => {
        const errorMessage = generateError(error, {
          413: 'File is too large',
        })

        if (error) {
          showMessage({
            message: 'Error',
            description: error ? 'Something went wrong' : errorMessage,
            type: 'danger',
          })
        } else {
          showMessage({
            message: 'Image updated',
            description: 'User profile image changed',
            type: 'success',
          })
        }
      },
    })
  }

  return {
    styles,
    account,
    image,
    onImageChange,
  }
}
