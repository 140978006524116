import { FlatList, Modal, TextInput, TouchableWithoutFeedback, View } from 'react-native'
import { useSelectorModalWrapper } from './wrapper'
import { DefaultWrapperProps, withWrapper } from '../../../../core/helpers/withWrapper'
import { withStyles } from '../../../../core/theme'
import SelectorListItem from '../SelectorListItem'
import EmptyPlaceholder from '../../common/EmptyPlaceholder'
import { Icon } from '../../icon'

interface SelectorModalProps {
  data: string[]
  modalVisible: boolean
  setModalVisible: (value: boolean) => void
  inputValue: string
  setInputValue: (value: string) => void
  onSearchInput: (inputString: string) => void
  placeholder?: string
  setSelectedString: (value: string) => void
  onSelectItem: (item: string) => void
  selectorLeftIcon?: string
  colors?: boolean
}

interface ISelectorModal extends SelectorModalProps, DefaultWrapperProps {
  styles: ReturnType<typeof useStyles>
}

const useStyles = withStyles((theme) => ({
  modalContentContainer: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
    backgroundColor: theme.colors.white,
    marginTop: theme.spacing.xl,
  },
  header: {
    width: '100%',
    height: theme.spacing.xl,
    flexDirection: 'row',
    alignItems: 'center',
  },
  closeIcon: {
    marginHorizontal: theme.spacing.m,
  },
  innerContainer: {
    height: '100%',
    width: '100%',
  },
  textInput: {
    width: '80%',
  },
  modalInnerWrapper: {
    flex: 1,
  },
  itemsListContainer: {
    height: '100%',
  },
}))

const SelectorModal = ({
  t,
  styles,
  modalVisible,
  setModalVisible,
  inputValue,
  setInputValue,
  onSearchInput,
  placeholder,
  data,
  setSelectedString,
  onSelectItem,
  selectorLeftIcon,
  colors,
}: ISelectorModal) => (
  <Modal
    transparent
    statusBarTranslucent
    visible={modalVisible}
    animationType="slide"
    onRequestClose={() => setModalVisible(false)}
  >
    <TouchableWithoutFeedback onPress={() => setModalVisible(false)}>
      <View style={styles.modalContentContainer}>
        <TouchableWithoutFeedback>
          <View style={styles.innerContainer}>
            <View style={styles.header}>
              <Icon
                family={'Feather'}
                name={'x'}
                size={18}
                color={'black'}
                onPress={() => setModalVisible(false)}
                style={styles.closeIcon}
              />
              <TextInput
                value={inputValue}
                onChangeText={(value) => {
                  setInputValue(value)
                  onSearchInput(value)
                }}
                style={styles.textInput}
                placeholder={placeholder}
              />
            </View>
            <View style={styles.modalInnerWrapper}>
              {data.length > 0 ? (
                <FlatList
                  data={data}
                  renderItem={({ item }) => (
                    <SelectorListItem
                      isModalVisible={modalVisible}
                      setModalVisible={setModalVisible}
                      setSelectedString={setSelectedString}
                      onSelectItem={onSelectItem}
                      item={colors ? t(`carColors:maps.${item.toLowerCase()}`) : item}
                      color={colors ? item.toLowerCase() : undefined}
                      selectorLeftIcon={selectorLeftIcon}
                    />
                  )}
                  style={styles.itemsListContainer}
                />
              ) : (
                <EmptyPlaceholder text={t('notFound:anything')} icon={'frown'} />
              )}
            </View>
          </View>
        </TouchableWithoutFeedback>
      </View>
    </TouchableWithoutFeedback>
  </Modal>
)

export default withWrapper<SelectorModalProps>(SelectorModal, useSelectorModalWrapper, {
  useStyles,
})
