import { View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { Icon } from '../icon'
import { withStyles } from '../../../core/theme'
import { Box, Spacer, Typography } from '../common'
import { getCardIcon } from '../../../core/helpers/utils'
import { Card as CardModel } from '../../../core/models'

const useStyles = withStyles((theme) => ({
  container: {
    gap: theme.spacing.m,
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  info: {
    justifyContent: 'space-between',
  },
  hiddenDefault: {
    display: 'none',
    margin: 0,
  },
}))

interface Props {
  card: CardModel
  showArrowRight?: boolean
  showIsDefault?: boolean
  showErrorMessage?: boolean
}

export const Card = ({
  card,
  showArrowRight = false,
  showErrorMessage = false,
  showIsDefault = false,
}: Props) => {
  const { type, cardBrand, expiryMonth, expiryYear, lastDigits, errorMessage } = card

  const { t } = useTranslation()

  const styles = useStyles()

  const isCredit = type === 'CREDIT'
  const isDefault = showIsDefault && card.default
  const hasError = showErrorMessage && !!errorMessage

  const expiryDate =
    expiryMonth && expiryYear && `${('0' + expiryMonth).slice(-2)}/${expiryYear % 100}`

  return (
    <View style={styles.container}>
      <View style={styles.header}>
        {isCredit && (
          <>
            <Icon family={'FontAwesome'} name={getCardIcon(cardBrand)} size={58} />
            <Spacer horizontal={'s'} />
          </>
        )}
        <View style={styles.info}>
          <Typography text={cardBrand} size={14} bold />
          {isCredit && <Typography text={`**** ${lastDigits}`} size={14} color={'foreground'} />}
          {expiryDate && <Typography size={14} text={expiryDate} />}
        </View>
        {showArrowRight && (
          <>
            <Spacer flex />
            <Icon family={'Feather'} name={'chevron-right'} size={18} />
          </>
        )}
      </View>
      {(isDefault || hasError) && (
        <Box justifyContent={'space-between'}>
          {isDefault && <Typography size={14} color={'foreground'} text={t('card:default')} />}
          {hasError && <Typography size={14} color={'danger'} text={errorMessage} />}
        </Box>
      )}
    </View>
  )
}
