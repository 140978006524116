import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TouchableOpacity, Platform } from 'react-native'
import { IUseWrapper, withWrapper } from '../../core/helpers/withWrapper'
import { Box, Spacer, Typography } from './common'
import { useNavigation } from '@react-navigation/native'
import { ITheme } from '../../core/theme/themes'
import { useTheme } from '../../core/theme'
import { LanguagesModal } from '../screens/Languages'
import { RouterNames } from '../../core/constants'
import { Icon } from './icon'

interface IHeaderLanguageSwitcher {
  theme: ITheme
  language: string
  languagesShown: boolean
  setLanguagesShown: (value: boolean) => void
  openLanguageSelection: (value: boolean) => void
}

const HeaderLanguageSwitcher = ({
  theme,
  language,
  languagesShown,
  setLanguagesShown,
  openLanguageSelection,
}: IHeaderLanguageSwitcher) => {
  const isWeb = Platform.OS === 'web'

  return (
    <>
      <TouchableOpacity
        style={{ alignSelf: 'flex-end' }}
        onPress={() => openLanguageSelection(isWeb)}
      >
        <Box>
          <Typography text={language.toLocaleUpperCase()} variant="secondary" />
          <Spacer horizontal="xs" />
          <Icon family={'Feather'} name="globe" size={30} color={theme.colors.foreground} />
        </Box>
      </TouchableOpacity>
      {isWeb && <LanguagesModal isVisible={languagesShown} setVisible={setLanguagesShown} />}
    </>
  )
}

const useHeaderLanguageSwitcherWrapper: IUseWrapper = () => {
  const {
    i18n: { language },
  } = useTranslation()
  const navigation = useNavigation()
  const theme = useTheme()

  const [languagesShown, setLanguagesShown] = useState(false)

  const openLanguageSelection = (isWeb: boolean) => {
    isWeb ? setLanguagesShown(true) : navigation.navigate(RouterNames.languages)
  }

  return { language, openLanguageSelection, languagesShown, setLanguagesShown, theme }
}

export default withWrapper(HeaderLanguageSwitcher, useHeaderLanguageSwitcherWrapper)
