import { Linking, Platform } from 'react-native'

export const openNavigator = (lat: number, lng: number, label: string = 'Navigate') => {
  const scheme = Platform.select({
    ios: 'maps:0,0?q=',
    android: 'geo:0,0?q=',
    web: 'geo:',
  })
  const latLng = `${lat},${lng}`

  const url = Platform.select({
    ios: `${scheme}${label}@${latLng}`,
    android: `${scheme}${latLng}(${label})`,
    web: `https://maps.google.com/maps?saddr=${latLng}`,
  })

  if (url) {
    Linking.openURL(url)
  }
}
