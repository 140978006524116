import isEqual from 'lodash.isequal'

import { createSelectorHook } from '../../helpers/store'
import { RootState } from '../rootReducer'

const getFavoriteStations = () => (state: RootState) => {
  return state.stations.favorites
}

const getStations = () => (state: RootState) => {
  return state.stations.list
}

const getStation = () => (state: RootState) => {
  return state.stations.current
}

const getFilterData = () => (state: RootState) => {
  return state.stations.filters
}

const getInitialFilter = () => (state: RootState) => {
  return state.stations.initialFilter
}

const getSearch = () => (state: RootState) => {
  return state.stations.search
}

const getIsFilterActive = () => (state: RootState) => {
  const { filters, initialFilter } = state.stations

  return !isEqual(filters, initialFilter)
}

const getConnectors = () => (state: RootState) => {
  return state.stations.connectors
}

export const stationsSelectorHooks = {
  getFavoriteStations: createSelectorHook<RootState['stations']['favorites']>(getFavoriteStations),
  getStations: createSelectorHook<RootState['stations']['list']>(getStations),
  getStation: createSelectorHook<RootState['stations']['current']>(getStation),
  getIsFilterActive: createSelectorHook(getIsFilterActive),
  getInitialFilter: createSelectorHook(getInitialFilter),
  getFilterData: createSelectorHook(getFilterData),
  getSearch: createSelectorHook(getSearch),
  getConnectors: createSelectorHook<RootState['stations']['connectors']>(getConnectors),
}
