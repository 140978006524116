export const PREFIX = 'STATIONS_'

export const GET_STATIONS = `${PREFIX}GET_STATIONS`
export const SET_STATIONS = `${PREFIX}SET_STATIONS`

export const ADD_FAVORITE = `${PREFIX}ADD_FAVORITE`
export const REMOVE_FAVORITE = `${PREFIX}REMOVE_FAVORITE`

export const GET_STATION = `${PREFIX}GET_STATION`
export const SET_STATION = `${PREFIX}SET_STATION`

export const GET_FAVORITE_STATIONS = `${PREFIX}GET_FAVORITE_STATIONS`
export const SET_FAVORITE_STATIONS = `${PREFIX}SET_FAVORITE_STATIONS`

export const CREATE_REVIEW = `${PREFIX}CREATE_REVIEW`

export const SET_FILTERS = `${PREFIX}SET_FILTERS`
export const INIT_FILTERS = `${PREFIX}INIT_FILTERS`

export const SET_SEARCH = `${PREFIX}SET_SEARCH`

export const GET_CONNECTORS = `${PREFIX}GET_CONNECTORS`
export const SET_CONNECTORS = `${PREFIX}SET_CONNECTORS`
