import { StyleSheet } from 'react-native'

import { withStyles } from '../../../core/theme'

export const useStyles = withStyles((theme) => ({
  camera: {
    flex: 1,
  },
  container: {
    ...StyleSheet.absoluteFillObject,
    alignItems: 'center',
    justifyContent: 'center',
  },
  overlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: theme.colors.backgroundOffset,
    opacity: 0.4,
  },
  loader: {
    position: 'absolute',
    backgroundColor: 'transparent',
    zIndex: 1,
  },
}))
