import { withStyles } from '../../../core/theme'

export const useStyles = withStyles((theme, insets) => ({
  container: {
    paddingTop: insets.top !== 0 ? insets.top : theme.spacing.m,
    backgroundColor: theme.colors.background,
    paddingHorizontal: theme.spacing.m,
    paddingBottom: theme.spacing.m,
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomColor: theme.colors.border,
    borderBottomWidth: 1,
  },
  absolute: {
    position: 'absolute',
    zIndex: 1,
    bottom: theme.spacing.m,
  },
  left: {
    left: theme.spacing.m,
  },
  right: {
    right: theme.spacing.m,
  },
}))
