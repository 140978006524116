import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { View } from 'react-native'
import { useNavigation } from '@react-navigation/native'

import { IconButton } from '..'
import { stationsActionHooks, stationsSelectorHooks } from '../../../core/store/stations'
import { TextField } from '../forms'
import { useDeviceType } from '../../../core/hooks'
import { withStyles } from '../../../core/theme'
import { IOptions, withWrapper } from '../../../core/helpers/withWrapper'
import { RouterNames } from '../../../core/constants'
import { StationFilterModal } from '../../screens/StationFilter/modal'
import { debounce } from '../../../core/helpers/debounce'

const useStyles = withStyles((theme, insets, _, isWeb) => ({
  container: {
    zIndex: 1,
    position: 'absolute',
    top: insets.top || theme.spacing.m,
    flexDirection: 'row',
    paddingHorizontal: theme.spacing.m,
    width: isWeb ? undefined : '100%',
  },
  searchField: {
    ...(isWeb ? { width: 360, maxWidth: 360 } : { flex: 1 }),
  },
}))

interface IHomeHeaderProps {
  handleFocus: (value: boolean) => void
}

interface IHomeHeader extends IHomeHeaderProps {
  styles: ReturnType<typeof useStyles>
}

const Component = ({ styles, handleFocus }: IHomeHeader) => {
  const { t } = useTranslation()

  const { navigate } = useNavigation()

  const isFilterActive = stationsSelectorHooks.getIsFilterActive()

  const setSearch = stationsActionHooks.setSearch()

  const [isModalVisisble, setIsModalVisible] = useState(false)

  const showFilters = () => {
    type === 'web' ? setIsModalVisible(true) : navigate(RouterNames.stationFilter)
  }

  const type = useDeviceType()

  return (
    <>
      <View style={styles.container}>
        <View style={styles.searchField}>
          <TextField
            placeholder={t('search:title')}
            icon="search"
            rounded
            disableFocusStyles
            disableBorders
            boxSize={54.5}
            iconSize={24}
            onChange={debounce(setSearch)}
            handleFocus={handleFocus}
            shadow
          />
        </View>
        <IconButton
          icon="filter"
          size={22}
          boxSize={42}
          onPress={showFilters}
          shadow
          badge={isFilterActive}
        />
      </View>
      {type === 'web' && (
        <StationFilterModal isVisible={isModalVisisble} setVisible={setIsModalVisible} />
      )}
    </>
  )
}

const useHomeHeaderWrapper = ({ useStyles }: IOptions) => {
  const styles = useStyles()
  return { styles }
}

export const HomeHeader = withWrapper<IHomeHeaderProps>(Component, useHomeHeaderWrapper, {
  useStyles,
})
