import { Modal, TouchableOpacity, TouchableWithoutFeedback, View } from 'react-native'
import { withWrapper } from '../../../../core/helpers/withWrapper'
import { withStyles } from '../../../../core/theme'
import { IModalContainerProps } from './types'
import { useModalContainerWrapper } from './wrapper'

const useStyles = withStyles((theme) => ({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,.3)',
  },
  content: {
    width: 400,
    maxWidth: '90vw',
    borderRadius: theme.spacing.m,
    backgroundColor: theme.colors.background,
    maxHeight: '75vh',
  },
}))

interface IModalContainer extends IModalContainerProps {
  styles: ReturnType<typeof useStyles>
  close: () => void
}

const Component = ({ isVisible, close, styles, contentStyles, children }: IModalContainer) => (
  <TouchableOpacity onPress={close}>
    <Modal animationType={'fade'} transparent visible={isVisible} onRequestClose={close}>
      <View style={styles.container}>
        <TouchableWithoutFeedback>
          <View style={[styles.content, contentStyles]}>{children}</View>
        </TouchableWithoutFeedback>
      </View>
    </Modal>
  </TouchableOpacity>
)

export const ModalContainer = withWrapper<IModalContainerProps>(
  Component,
  useModalContainerWrapper,
  { useStyles }
)
