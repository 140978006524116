import { AxiosError } from 'axios'
import { all, put, call, takeLatest, takeLeading } from 'redux-saga/effects'
import { accountApi } from '../../api/account'
import { SignupPayload } from '../../api/account/types'
import { getMontlySubscription } from '../notification'
import { logout, setAccountProfile, setFaq, setImage, setIsAuthorized } from './actions'
import * as CONSTANTS from './constants'
import {
  ILoginSaga,
  ISignupSaga,
  ILoginResponse,
  IUpdateUserSaga,
  IRecoverySaga,
  IDeactivateSaga,
  IUpdateUserImageSaga,
  IValidateUserSaga,
  IConfirmRegistrationUserSaga,
  IResetPasswordSaga,
  IGetFaqSaga,
  IQuestion,
  Account,
} from './types'
import { Storage } from '../../helpers/storage'
import { driverApi } from '../../api/drivers'

function* loginSaga({ payload }: ILoginSaga) {
  try {
    const { jwt }: ILoginResponse = yield call(accountApi.login, payload)

    yield call(Storage.setItem, 'token', jwt)

    yield put(setIsAuthorized(true))

    setTimeout(function* () {
      yield put(getMontlySubscription())
    }, 250)
  } catch (error) {
    if (payload.callback) {
      payload.callback(error as Error)
    }
  }
}

function* userDetailsSaga() {
  const [account, image]: [Account, string] = yield all([
    call(accountApi.getCurrentUser),
    call(driverApi.getDriverImage),
  ])

  yield all([put(setAccountProfile(account)), put(setImage(image))])
}

function* recoverySaga({ payload }: IRecoverySaga) {
  try {
    yield call(accountApi.recovery, payload.email)

    if (payload.callback) {
      payload.callback(null)
    }
  } catch (error) {
    if (payload.callback) {
      payload.callback(error as AxiosError)
    }
  }
}

function* validateUserSaga({ payload }: IValidateUserSaga) {
  try {
    const valid: boolean = yield call(accountApi.validateUser, payload.email, payload.username)

    if (payload.callback) {
      payload.callback(null, valid)
    }
  } catch (error) {
    if (payload.callback) {
      payload.callback(error as Error)
    }
  }
}

function* confirmRegistrationUserSaga({ payload }: IConfirmRegistrationUserSaga) {
  try {
    yield call(accountApi.confirmRegistration, payload.otp)

    if (payload.callback) {
      payload.callback(null)
    }
  } catch (error) {
    if (payload.callback) {
      payload.callback(error as Error)
    }
  }
}

function* resetPasswordSaga({ payload }: IResetPasswordSaga) {
  try {
    yield call(accountApi.resetPassword, payload)
    if (payload.callback) {
      payload.callback(null)
    }
  } catch (error) {
    if (payload.callback) {
      payload.callback(error as Error)
    }
  }
}

function* deactivateUserSaga({ payload }: IDeactivateSaga) {
  try {
    yield call(accountApi.deactivateUser)

    yield put(logout())

    if (payload?.callback) {
      payload.callback(null)
    }
  } catch (error) {
    if (payload?.callback) {
      payload.callback(error as Error)
    }
  }
}

function* updateUserImageSaga({ payload }: IUpdateUserImageSaga) {
  try {
    yield call(driverApi.uploadDriverImage, payload.image)

    const image: string | null = yield call(driverApi.getDriverImage)

    yield put(setImage(image))

    if (payload.callback) {
      payload.callback(null)
    }
  } catch (error) {
    if (payload.callback) {
      payload.callback(error as Error)
    }
  }
}

function* signupSaga({ payload }: ISignupSaga) {
  try {
    const targetPayload: SignupPayload = {
      userName: payload.userName,
      firstName: payload.firstName,
      lastName: payload.lastName,
      email: payload.email,
      password: payload.password,
      phoneNumber: payload.phoneNumber,
      address: {
        postCode: payload.zip,
        streetAddress: payload.address,
        country: payload.country,
        locality: payload.city,
        administrativeArea: payload.state,
      },
    }

    yield call(accountApi.signup, targetPayload)

    if (payload.callback) {
      payload.callback(null)
    }
  } catch (error) {
    if (payload.callback) {
      payload.callback(error as Error)
    }
  }
}

function* logoutSaga() {
  yield call(Storage.removeItem, 'token')
  yield put(setIsAuthorized(false))
}

function* updateUserSaga({ payload: { callback, ...data } }: IUpdateUserSaga) {
  try {
    yield call(accountApi.updateUser, data)

    const account: Account = yield call(accountApi.getCurrentUser)

    yield put(setAccountProfile(account))

    callback?.call(null, null, account)
  } catch (error) {
    callback?.call(null, error as Error)
  }
}

function* getFaqSaga({ payload }: IGetFaqSaga) {
  try {
    const data: IQuestion[] = yield call(accountApi.getFaq, payload.lang)

    yield put(setFaq(data))
  } catch (error) {
    if (payload.callback) {
      payload.callback(error as Error)
    }
  }
}

export function* accountSagas(): Generator<any> {
  yield all([
    yield takeLatest<any>(CONSTANTS.CONFIRM_REGISTRATION, confirmRegistrationUserSaga),
    yield takeLatest<any>(CONSTANTS.DEACTIVATE_USER, deactivateUserSaga),
    yield takeLatest<any>(CONSTANTS.LOGIN, loginSaga),
    yield takeLatest<any>(CONSTANTS.LOGOUT, logoutSaga),
    yield takeLeading<any>(CONSTANTS.RECOVERY, recoverySaga),
    yield takeLatest<any>(CONSTANTS.SIGNIN, signupSaga),
    yield takeLatest<any>(CONSTANTS.UPDATE_USER_IMAGE, updateUserImageSaga),
    yield takeLatest<any>(CONSTANTS.UPDATE_USER_PROFILE, updateUserSaga),
    yield takeLatest<any>(CONSTANTS.VALIDATE_USER, validateUserSaga),
    yield takeLatest<any>(CONSTANTS.RESET_PASSWORD, resetPasswordSaga),
    yield takeLatest<any>(CONSTANTS.GET_FAQ, getFaqSaga),
    yield takeLatest<any>(CONSTANTS.GET_USER_DETAILS, userDetailsSaga),
  ])
}
