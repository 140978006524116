import { useTranslation } from 'react-i18next'

import { EmptyPlaceholder, Paper } from '../../components'

export const Placeholder = () => {
  const { t } = useTranslation()

  return (
    <Paper>
      <EmptyPlaceholder text={t('empty:sessions')} icon={'calendar'} />
    </Paper>
  )
}
