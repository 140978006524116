import { TranslationLanguageCode } from 'qem-react-native-country-picker-modal'

export const getLanguage = (language: string): TranslationLanguageCode => {
  switch (language.toUpperCase()) {
    case 'DE':
      return 'deu'
    case 'FI':
      return 'fin'
    case 'HR':
      return 'hrv'
    case 'IT':
      return 'ita'
    case 'NL':
      return 'nld'
    case 'RU':
      return 'rus'
    case 'HE':
    case 'IS':
      return 'isr'
    case 'FR':
      return 'fra'
    case 'AR':
      return 'ara'
    default:
      return 'common'
  }
}
