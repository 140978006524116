import { useEffect, useReducer, Reducer } from 'react'

import { PagedData, PaginationParams } from '../models'

interface Params<T> {
  size?: number
  page?: number
  fetch: (params: PaginationParams) => Promise<PagedData<T>>
}

interface State<T> {
  page: number
  isLoading: boolean
  isRefreshing: boolean
  items: T[]
  hasMore: boolean
}

type Action<T> = Partial<State<T>>

const initials = { page: 1, isLoading: false, isRefreshing: false, items: [], hasMore: true }

const reducer = <T>(state: State<T>, action: Action<T>) => {
  return { ...state, ...action }
}

export const useInfiniteScroll = <T>({ page = 1, size = 10, fetch }: Params<T>) => {
  const [state, dispatch] = useReducer<Reducer<State<T>, Action<T>>>(reducer, initials)

  const get = async (page: number) => {
    dispatch({ isLoading: true })
    try {
      const { items, pages } = await fetch({ page, size })
      dispatch({ page: page + 1, hasMore: page < pages })

      return items
    } catch {
      return []
    } finally {
      dispatch({ isLoading: false })
    }
  }

  useEffect(() => {
    get(page).then((items) => dispatch({ items }))
  }, [page])

  const reset = async () => {
    dispatch({ page, isRefreshing: true, hasMore: true })

    try {
      const items = await get(page)
      dispatch({ items })
    } finally {
      dispatch({ isRefreshing: false })
    }
  }

  const fetchMore = async () => {
    if (state.isLoading || !state.hasMore) return

    const newItems = await get(state.page)
    const items = [...state.items, ...newItems]
    dispatch({ items })
  }

  return { ...state, reset, fetchMore }
}
