import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import { IOptions, IUseWrapper } from '../../../core/helpers/withWrapper'
import { vehiclesActionHooks, vehiclesSelectorHooks } from '../../../core/store/vehicles'
import { useEffect } from 'react'
import { RouterNames } from '../../../core/constants'
import { IListItem } from '../../components/common/ClickableList/component'

export const useAddCarScreenWrapper: IUseWrapper = (options?: IOptions) => {
  const { useStyles } = options!
  const { t } = useTranslation()
  const styles = useStyles()
  const navigation = useNavigation()

  const loadManufacturers = vehiclesActionHooks.getManufacturers()
  const manufacturers = vehiclesSelectorHooks.getVehicles()

  const onListItemClick = (item: IListItem) => {
    if (item.id !== 'isOther') {
      navigation.navigate(RouterNames.selectModel, {
        id: item.id!,
        pageTitle: item.firstString,
      })
    } else {
      navigation.navigate(RouterNames.addOwnCar)
    }
  }

  useEffect(() => {
    loadManufacturers()
  }, [])

  return {
    styles,
    t,
    navigation,
    manufacturers,
    onListItemClick,
  }
}
