import { FormikProps, useFormik } from 'formik'
import { TFunction } from 'i18next'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Keyboard } from 'react-native'
import { showMessage } from 'react-native-flash-message'
import * as Yup from 'yup'
import { supportApi } from '../../../../core/api/support'
import { ISupportType } from '../../../../core/api/support/types'
import { IOptions, IUseWrapper } from '../../../../core/helpers/withWrapper'
import { useDeviceType } from '../../../../core/hooks'
import { ISuppoRequestContentProps } from './types'

function generateField<T = Support>(formik: FormikProps<T>, key: keyof T) {
  return {
    onChange: (text: string) => {
      formik.setFieldValue(key as string, text)
    },
    handleBlur: () => {
      if (!formik.touched[key]) {
        formik.setFieldTouched(key as string, true)
      }
    },
    value: formik.values[key],
    showMessage: formik.touched[key] ? formik.errors[key] : undefined,
    fullWidth: true,
  }
}

const SupportSchema = (t: TFunction) =>
  Yup.object().shape({
    feedback: Yup.string().max(200).required(t('forms:common.required')),
  })

interface Support {
  feedback: string
}

export const useSupportRequestComponentWrapper: IUseWrapper<ISuppoRequestContentProps> = (
  { useStyles }: IOptions,
  { chargeStationId }
) => {
  const styles = useStyles()
  const [isVisible, setVisible] = useState(false)
  const { t } = useTranslation()

  const [supportTypes, setSupportTypes] = useState<ISupportType[]>([])
  const [selectedSupportType, setSelectedSupportType] = useState<ISupportType>()
  const [showError, setShowError] = useState(false)
  const [loading, setLoading] = useState(false)

  const type = useDeviceType()

  const support = useFormik({
    initialValues: {
      feedback: '',
    },
    onSubmit: (values, { resetForm }) => {
      createSupport(values.feedback, resetForm)
    },
    validationSchema: SupportSchema(t),
  })

  useEffect(() => {
    supportApi.types().then(setSupportTypes)
  }, [])

  const onSupportTypeSelect = (type: ISupportType) => {
    setSelectedSupportType(type)
  }

  const onSupportTypePress = () => {
    if (supportTypes.length === 0) {
      showMessage({
        message: 'Support Type empty',
        type: 'danger',
      })
      return
    }
    setShowError(false)
    setVisible(true)
  }

  const createSupport = async (description: string, callback: () => void) => {
    try {
      if (selectedSupportType) {
        setLoading(true)
        await supportApi.createTicket({
          chargeStationID: chargeStationId,
          description: description,
          supportType: selectedSupportType.supportTypeId,
        })
        callback()
        showMessage({
          message: t('support:success.sendSupport'),
          type: 'success',
        })
      }
    } catch (e) {
      showMessage({
        message: t('support:error.send'),
        type: 'warning',
      })
    } finally {
      setLoading(false)
    }
  }

  const onSubmit = () => {
    Keyboard.dismiss()
    if (!selectedSupportType) {
      setShowError(true)
    }
    support.handleSubmit()
  }

  return {
    type,
    styles,
    isVisible,
    setVisible,
    onSupportTypePress,
    supportTypes,
    selectedSupportType,
    onSupportTypeSelect,
    generateField: (key: keyof Support) => generateField(support, key),
    onSubmit,
    showError,
    loading,
  }
}
