import { Calendar, DateData } from 'react-native-calendars'
import { MarkingTypes } from 'react-native-calendars/src/types'

import { DefaultWrapperProps, withWrapper } from '../../../core/helpers/withWrapper'
import { useCalendarWrapper } from './wrapper'
import { MarkingProps } from 'react-native-calendars/src/calendar/day/marking'
import { Icon } from '../icon'

export type MarkedDatesType = Record<string, MarkingProps> | undefined

interface ICalendarProps {
  markingType?: MarkingTypes
  onDayPress?: (date: DateData) => void
  onMonthChange?: (date: DateData) => void
  markedDates?: MarkedDatesType
}

interface ICalendar extends ICalendarProps, DefaultWrapperProps {}

const RNCalendar = ({
  theme,
  onDayPress,
  onMonthChange,
  markedDates,
  markingType,
  i18n,
}: ICalendar) => (
  <Calendar
    firstDay={i18n.language === 'ru' ? 1 : 0}
    renderArrow={(direction) => <Icon family={'Feather'} name={`chevron-${direction}`} size={20} />}
    markingType={markingType}
    hideExtraDays
    onDayPress={onDayPress}
    onMonthChange={onMonthChange}
    markedDates={markedDates}
    theme={{
      arrowColor: theme.colors.primary,
      todayTextColor: theme.colors.primary,
    }}
  />
)

export default withWrapper<ICalendarProps>(RNCalendar, useCalendarWrapper)
