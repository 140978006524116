import React, { Dispatch, SetStateAction } from 'react'
import { SafeAreaView, TextInput, View } from 'react-native'
import { DefaultWrapperProps, withWrapper } from '../../../core/helpers/withWrapper'
import { withStyles } from '../../../core/theme'
import { useReportIssueScreenWrapper } from './wrapper'
import { ITheme } from '../../../core/theme/themes'
import { Button, KeyboardAvoid, Paper, Spacer, Typography } from '../../components'
import { KeyboardDismissView } from '../../components/KeyboardDismissView'

const useStyles = withStyles((theme) => ({
  wrapper: {
    flex: 1,
    backgroundColor: theme.colors.backgroundOffset,
  },
  outerContainer: {
    padding: theme.spacing.m,
    backgroundColor: theme.colors.backgroundOffset,
  },
  textInput: {
    minHeight: theme.spacing.xxl * 2,
    borderColor: '#DDEAF3',
    borderRadius: theme.spacing.s,
    borderWidth: 1,
    paddingTop: theme.spacing.m,
    paddingBottom: theme.spacing.m,
    paddingHorizontal: theme.spacing.m,
  },
  innerWrapper: {
    height: '100%',
    justifyContent: 'space-between',
  },
}))

interface IReportIssueProps {}

interface IReportIssueScreen extends IReportIssueProps, DefaultWrapperProps {
  styles: ReturnType<typeof useStyles>
  theme: ITheme
  report: string
  setReport: Dispatch<SetStateAction<string>>
}

const ReportIssueScreen = ({ t, styles, report, setReport }: IReportIssueScreen) => (
  <SafeAreaView style={styles.wrapper}>
    <KeyboardDismissView>
      <KeyboardAvoid>
        <View style={styles.outerContainer}>
          <Paper>
            <View style={styles.innerWrapper}>
              <View>
                <Spacer top={'s'} />
                <TextInput
                  style={styles.textInput}
                  multiline
                  maxLength={200}
                  onChangeText={setReport}
                  value={report}
                />
                <Spacer top={'s'} />
                <Typography
                  text={`${report.length}/200`}
                  fontWeight={'100'}
                  size={'m'}
                  align={'right'}
                />
              </View>
              <Button label={t('buttons:submit')} />
            </View>
          </Paper>
        </View>
      </KeyboardAvoid>
    </KeyboardDismissView>
  </SafeAreaView>
)

export default withWrapper(ReportIssueScreen, useReportIssueScreenWrapper, {
  useStyles,
})
