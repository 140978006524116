import { createReducer } from '@reduxjs/toolkit'
import { logout } from '../account'
import {
  initFilters,
  setConnectors,
  setFavoriteStations,
  setFilters,
  setSearch,
  setStation,
  setStations,
} from './actions'
import { SpeedType, StationsState } from './types'
import { Station } from '../../models'

const defaultFilter: Station.Filter = {
  available: undefined,
  connectors: new Map(),
  speed: SpeedType.all,
  distanceMax: undefined,
  minPrice: undefined,
  maxPrice: undefined,
}

const initialState: StationsState = {
  current: null,
  list: [],
  favorites: [],
  filters: null,
  connectors: [],
  search: '',
  initialFilter: defaultFilter,
}

export const stationsReducer = createReducer(initialState, (builder) => {
  builder.addCase(setFavoriteStations, (state, action) => {
    return { ...state, favorites: action.payload }
  })
  builder.addCase(setStations, (state, action) => {
    return { ...state, list: action.payload }
  })
  builder.addCase(setStation, (state, action) => {
    return { ...state, current: action.payload }
  })
  builder.addCase(setFilters, (state, action) => {
    return { ...state, filters: action.payload }
  })
  builder.addCase(initFilters, (state) => {
    return { ...state, filters: state.initialFilter }
  })
  builder.addCase(setSearch, (state, action) => {
    return { ...state, search: action.payload }
  })
  builder.addCase(logout, () => {
    return initialState
  })
  builder.addCase(setConnectors, (state, action) => {
    const connectors = action.payload.reduce(
      (acc, { connectorTypeId }) => acc.set(connectorTypeId, true),
      new Map<string, boolean>()
    )

    const initialFilter = { ...defaultFilter, connectors }

    return { ...state, initialFilter, connectors: action.payload }
  })
})
