export enum ConnectorStatus {
  Available = 'Available',
  Preparing = 'Preparing',
  Unavailable = 'Unavailable',
  Charging = 'Charging',
  Finishing = 'Finishing',
  SuspendedEv = 'SuspendedEV',
  SuspendedEvSe = 'SuspendedEVSE',
  Faulted = 'Faulted',
}

export interface Connector {
  connectorId: string
  connectorNumber: number
  connectorStatus: ConnectorStatus
  connectorTypeName: string
  icon: string
  operationalStatus: boolean
  powerRating: number
}

export interface ConnectorType {
  connectorTypeId: string
  name: string
  isDeleted: boolean
  icon: string
}
