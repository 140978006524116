import { withWrapper } from '../../../../core/helpers/withWrapper'
import { withStyles } from '../../../../core/theme'
import { useCustomCheckboxWrapper } from './wrapper'
import { ITheme } from '../../../../core/theme/themes'
import { TouchableOpacity } from 'react-native'
import { Icon } from '../../icon'

interface ICustomCheckboxProps {
  size: number
  onClick?: () => void
  isChecked: boolean
}

interface ICustomCheckbox extends ICustomCheckboxProps {
  styles: ReturnType<typeof useStyles>
  theme: ITheme
}

const useStyles = withStyles((theme) => ({
  container: {
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  activeItem: {
    color: theme.colors.primary,
  },
}))

const CustomCheckbox = ({ styles, size, onClick, isChecked }: ICustomCheckbox) => (
  <TouchableOpacity style={[styles.container, { width: size, height: size }]} onPress={onClick}>
    <Icon size={24} family={'Feather'} name={isChecked ? 'disc' : 'circle'} />
  </TouchableOpacity>
)

export default withWrapper<ICustomCheckboxProps>(CustomCheckbox, useCustomCheckboxWrapper, {
  useStyles,
})
