import { useEffect, useState } from 'react'
import { Linking, ScrollView, View } from 'react-native'
import { showMessage } from 'react-native-flash-message'
import { useTranslation } from 'react-i18next'

import {
  transactionsActionHooks,
  transactionsSelectorHooks,
} from '../../../core/store/transactions'
import { Button, EmptyPlaceholder, Paper, ScreenLoader, Typography } from '../../components'
import { getDurationString, timeStampToString } from '../../../core/helpers/date'
import { Card } from '../../components/card'
import { ICar } from '../../../core/store/vehicles'
import { RouterNames } from '../../../core/constants'
import { vehiclesApi } from '../../../core/api/vehicles'
import { WalletAPI } from '../../../core/api/wallet'
import { Card as CardModel } from '../../../core/models'
import { useStyles } from './styles'

export const TransactionInfoScreen = ({ route }: ScreenProps<RouterNames.transactionInfo>) => {
  const { transactionId } = route.params

  const { t } = useTranslation()

  const styles = useStyles()

  const [vehicle, setVehicle] = useState<ICar | null>(null)
  const [card, setCard] = useState<CardModel | null>(null)

  const getTransaction = transactionsActionHooks.getTransaction()

  const transactionDetails = transactionsSelectorHooks.getCurrentTransaction()
  const loading = transactionsSelectorHooks.getTransactionLoading()
  const error = transactionsSelectorHooks.getTransactionError()

  const fetch = () => {
    getTransaction({ transactionId })
  }

  useEffect(fetch, [transactionId])

  useEffect(() => {
    if (!transactionDetails) return

    const { vehicleId, paymentCard } = transactionDetails

    vehicleId && vehiclesApi.getVehicleDetails(vehicleId).then(setVehicle)
    paymentCard && WalletAPI.getCard(paymentCard).then(setCard)
  }, [transactionDetails])

  if (error && !loading) {
    return <EmptyPlaceholder icon={'alert-circle'} text={'Something went wrong'} retry={fetch} />
  }

  if (loading || !transactionDetails) return <ScreenLoader />

  const {
    sessionId,
    stationName,
    energy,
    cost,
    chargingTime,
    kwhPrice,
    sessionStatus,
    paymentMethod,
    endTimeOfCharge,
  } = transactionDetails

  const time = chargingTime ? getDurationString(chargingTime, t) : null

  const date = timeStampToString(endTimeOfCharge, 'dddd, MMMM DD HH:mm')

  const vehicleName =
    vehicle?.vehicleVersion &&
    `${vehicle.vehicleVersion.vehicleManufacturerName} ${vehicle.vehicleVersion.vehicleModelName}`

  const share = async () => {
    try {
      const canOpen = await Linking.canOpenURL('mailto:')

      if (canOpen) {
        const message = [
          `${t('feedback:date')}: ${date}`,
          `${t('feedback:time')}: ${time}`,
          `${t('feedback:title')}: ${stationName}`,
          `${t('stats:cost')}: $ ${cost.toFixed(2)}`,
          `${t('feedback:delivered')}: ${energy.toFixed(2)} ${t('connector:kwh')}`,
        ]

        vehicleName && message.push(`${t('vehicle:title')}: ${vehicleName}`)

        const subject = encodeURIComponent(`${t('transaction:info')} ${sessionId}`)
        const body = encodeURIComponent(message.join('\n'))

        Linking.openURL(`mailto:?subject=${subject}&body=${body}`)
      } else {
        throw new Error()
      }
    } catch {
      showMessage({ type: 'danger', message: t('transaction:share.error') })
    }
  }

  return (
    <ScrollView contentContainerStyle={[styles.content, styles.gap]} style={styles.container}>
      <Paper label={t('feedback:id')}>
        <Typography text={sessionId.toString()} style={styles.text} />
      </Paper>
      <Paper label={t('feedback:date')}>
        <Typography text={date} style={styles.text} />
      </Paper>
      <Paper label={t('feedback:title')}>
        <Typography text={stationName} style={styles.text} />
      </Paper>
      <Paper label={t('feedback:details')} style={styles.gap}>
        <View style={styles.detail}>
          <Typography text={t('feedback:type')} style={styles.text} />
          <Typography text={t(`feedback:${paymentMethod.toLowerCase()}`)} style={styles.text} />
        </View>
        {!!kwhPrice && (
          <View style={styles.detail}>
            <Typography text={t('feedback:rate')} style={styles.text} />
            <Typography text={kwhPrice.toString()} style={styles.text} />
          </View>
        )}
        <View style={styles.detail}>
          <Typography text={t('feedback:delivered')} style={styles.text} />
          <Typography text={`${energy.toFixed(2)} ${t('connector:kwh')}`} style={styles.text} />
        </View>
        {time && (
          <View style={styles.detail}>
            <Typography text={t('feedback:time')} style={styles.text} />
            <Typography text={time} style={styles.text} />
          </View>
        )}
        <View style={styles.detail}>
          <Typography text={t('stats:cost')} style={styles.text} />
          <Typography text={`₪ ${cost.toFixed(2)}`} style={styles.text} />
        </View>
      </Paper>
      <Paper label={t('transaction:status.title')}>
        <Typography text={t(`transaction:status.${sessionStatus}`)} style={styles.text} />
      </Paper>
      {card && (
        <Paper label={t('feedback:payment')}>
          <Card card={card} />
        </Paper>
      )}
      {vehicleName && (
        <Paper label={t('vehicle:title')}>
          <Typography text={vehicleName} style={styles.text} />
          <Typography text={vehicle.vehicleVersion.year} style={styles.text} />
        </Paper>
      )}
      <Button label={t('buttons:share')} onPress={share} style={styles.button} />
    </ScrollView>
  )
}
