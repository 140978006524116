import { withStyles } from '../../../core/theme'
import i18n from '../../../core/helpers/i18n'

export const useStyles = withStyles((theme, insets, width, isWeb) => {
  const tabBarWidth = isWeb ? (i18n.language === 'ru' ? 20 : 0) + 90 : 0

  return {
    container: {
      marginLeft: tabBarWidth,
    },
    content: {
      gap: theme.spacing.m,
    },
    item: {
      width: width - tabBarWidth - 2 * theme.spacing.m,
      marginHorizontal: theme.spacing.m,
    },
    tab: {
      paddingTop: theme.spacing.m,
      paddingBottom: isWeb ? theme.spacing.m : (insets.bottom || theme.spacing.m) + 105,
    },
  }
})
