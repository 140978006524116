import i18n from '../../../core/helpers/i18n'
import { withStyles } from '../../../core/theme'

export const useStyles = withStyles((theme, insets, _, isWeb) => ({
  container: {
    flex: 1,
    backgroundColor: theme.colors.backgroundOffset,
  },
  content: {
    paddingRight: theme.spacing.m,
    paddingTop: theme.spacing.m,
    paddingLeft: (isWeb ? (i18n.language === 'ru' ? 20 : 0) + 90 : 0) + theme.spacing.m,
    paddingBottom: isWeb ? theme.spacing.m : (insets.bottom || theme.spacing.m) + 105,
  },
}))
