import { IOptions, IUseWrapper } from '../../../../core/helpers/withWrapper'
import { useTheme } from '../../../../core/theme'
import { IModalContainerProps } from './types'

export const useModalContainerWrapper: IUseWrapper<IModalContainerProps> = (
  { useStyles }: IOptions,
  { setVisible }
) => {
  const styles = useStyles()
  const theme = useTheme()

  const close = () => {
    setVisible(false)
  }

  return { theme, styles, close }
}
