import { ServerError } from '../api/setup'

export const getJoinString = (arrayOfString: (string | undefined)[]) => {
  return arrayOfString.filter(Boolean).join(', ')
}

interface GenerateErrorMapper {
  [key: number]: string
}

export function generateError(
  error: ServerError | Error | null | undefined,
  mapper: GenerateErrorMapper,
  defaultMessage?: string
) {
  if (!error || !error.response || !error.response.status) {
    return defaultMessage || null
  }

  const status = error.response.status

  for (const [key, value] of Object.entries(mapper)) {
    if (+status === +key) {
      if (value) {
        return value
      }

      return
    }
  }
}

export const getCardIcon = (cardType: string) => {
  switch (cardType) {
    case 'VISA':
      return 'cc-visa'
    case 'MATERCARD':
      return 'cc-mastercard'
    default:
      return 'credit-card'
  }
}

export const getDistance = (distance: number): string => {
  const km = distance / 1000
  return km.toFixed(2)
}
