import React from 'react'
import { TouchableWithoutFeedback, Animated } from 'react-native'
import { withWrapper } from '../../../../core/helpers/withWrapper'
import { withStyles } from '../../../../core/theme'
import { ITheme } from '../../../../core/theme/themes'
import { useCustomSwitchWrapper } from './wrapper'

const buttonWidth = 30

const useStyles = withStyles((theme) => ({
  button: {
    justifyContent: 'center',
    alignItems: 'center',
    height: buttonWidth,
    width: buttonWidth,
    backgroundColor: theme.colors.background,
    borderWidth: 2,
    borderRadius: buttonWidth / 2,
  },
  toggleStyle: {
    textAlign: 'center',
    width: buttonWidth * 1.8,
    borderRadius: buttonWidth * 2,
  },
}))

export interface ICustomSwitchProps {
  onSwitch?: (value: boolean) => void
  value?: boolean
  disabled?: boolean
}

interface ICustomSwitch extends ICustomSwitchProps {
  styles: ReturnType<typeof useStyles>
  theme: ITheme
  colorAnimInterpolation: Animated.AnimatedInterpolation
  changeToggle: () => void
  toggle: boolean
}

const CustomSwitch = ({
  disabled,
  styles,
  changeToggle,
  colorAnimInterpolation,
  toggle,
}: ICustomSwitch) => {
  return (
    <TouchableWithoutFeedback onPress={changeToggle}>
      <Animated.View
        style={[
          styles.toggleStyle,
          {
            alignItems: toggle ? 'flex-end' : 'flex-start',
            backgroundColor: colorAnimInterpolation,
          },
        ]}
      >
        <Animated.View
          style={[
            styles.button,
            {
              borderColor: colorAnimInterpolation,
            },
          ]}
        ></Animated.View>
      </Animated.View>
    </TouchableWithoutFeedback>
  )
}

export default withWrapper<ICustomSwitchProps>(CustomSwitch, useCustomSwitchWrapper, {
  useStyles,
})
