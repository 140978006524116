const palette = {
  purple: '#5A31F4',
  green: '#82af60',
  red: '#CD0E61',
  black: '#0B0B0B',
  background: '#F0F2F3',
  border: '#e4e4e4',
  white: '#fff',
  kashmirBlue: '#597393',
  pastelBlue: '#ADBCCE',
  darkBlue: '#F6F7FB',
  placeholder: '#ADBCCE',
  warning: '#F2C94C',
  blue: '#2F80ED',
  qiLightBlue: '#006486',
  qiDarkBlue: '#1c1c3b',
  qiOrange: '#fc6c3f',
  qiGreen: '#0ECD9D',
  qiPurple: '#9069FF',
  qiGray: '#BAC6D5',
  qiYellow: '#DCDC71',
}

export const theme = {
  dark: false,
  colors: {
    placeholder: palette.placeholder,
    border: palette.border,
    white: palette.white,
    background: palette.white,
    blue: palette.blue,
    orange: palette.qiOrange,
    info: palette.purple,
    backgroundOffset: palette.background,
    foreground: palette.black,
    primary: palette.qiDarkBlue,
    secondary: palette.qiLightBlue,
    success: palette.green,
    danger: palette.red,
    failure: palette.red,
    text: palette.black,
    card: palette.white,
    notification: palette.qiLightBlue,
    secondaryText: palette.kashmirBlue,
    available: palette.qiGreen,
    charging: palette.blue,
    preparing: palette.qiPurple,
    unavailable: palette.qiGray,
    suspendedEv: palette.qiYellow,
    suspendedEvSe: palette.qiOrange,
    warning: palette.warning,
    charger: palette.darkBlue,
  },
  spacing: {
    l: 24,
    m: 16,
    s: 8,
    xl: 40,
    xs: 4,
    xxl: 60,
  },
  textVariants: {
    header: {
      fontSize: 36,
      fontWeight: 'bold',
    },
    body: {
      fontSize: 16,
    },
  },
  fontSizes: {
    l: 24,
    m: 16,
    s: 8,
    xl: 40,
    xs: 4,
  },
}

export const darkTheme = {
  ...theme,
  dark: true,
  colors: {
    ...theme.colors,
    background: palette.black,
    foreground: palette.white,
    text: palette.white,
    card: palette.black,
    notification: palette.green,
    backgroundOffset: palette.black,
  },
}

export type ITheme = typeof theme
