import { Platform } from 'react-native'
import WebView from 'react-native-webview'

import { html } from './policy'

export const PrivacyScreen = () => {
  return Platform.select({
    native: <WebView source={{ html }} />,
    web: <iframe srcDoc={html} width={'100%'} height={'100%'} />,
    default: null,
  })
}
