import { useCallback, useEffect, useState } from 'react'
import { useFocusEffect } from '@react-navigation/native'

import { RequestMessageTypes, ResponseMessageTypes } from '../api/charging'
import { useWebSocket } from '../../app/providers/websocket'
import { ConnectorStatus } from '../models'

export const useConnectorStatuses = (stationId: string) => {
  const { send, response } = useWebSocket()

  const [connectorStatuses, setConnectorStatuses] = useState<Record<string, ConnectorStatus>>({})

  const getConnectorStatuses = useCallback(() => {
    const interval = setInterval(() => {
      const paylaod = {
        action: RequestMessageTypes.requestStationStatus,
        chargeStationId: stationId,
      }

      send(paylaod)
    }, 4e3)

    return () => {
      clearInterval(interval)
    }
  }, [stationId])

  useFocusEffect(getConnectorStatuses)

  useEffect(() => {
    if (response?.action === ResponseMessageTypes.stationStatus) {
      setConnectorStatuses(response.connectors)
    }
  }, [response])

  return { connectorStatuses }
}
