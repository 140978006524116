import React from 'react'
import { StyleProp, Text, TextStyle } from 'react-native'
import { withWrapper } from '../../../../core/helpers/withWrapper'
import { withStyles } from '../../../../core/theme'
import { ITheme } from '../../../../core/theme/themes'

import { useTypographyWrapper } from './wrapper'

interface TypographyProps {
  text: string
  variant?: 'primary' | 'secondary' | 'body'
  color?: keyof ITheme['colors']
  size?: keyof ITheme['fontSizes'] | number
  bold?: boolean
  style?: StyleProp<TextStyle>
  fontWeight?: TextStyle['fontWeight']
  align?: TextStyle['textAlign']
  numberOfLines?: number | undefined
}

const useStyles = withStyles((theme) => ({
  container: {
    color: theme.colors.foreground,
    fontFamily: 'Montserrat',
    fontWeight: '500',
    flexWrap: 'wrap',
  },
  body: {},
  secondary: {
    fontSize: theme.textVariants.body.fontSize,
  },
  primary: {
    fontSize: theme.textVariants.header.fontSize,
  },
}))

interface ITypography extends TypographyProps {
  styles: ReturnType<typeof useStyles>
  theme: ITheme
}

const Typography = ({
  text,
  variant = 'body',
  styles,
  color,
  theme,
  size,
  bold,
  style: customStyle,
  fontWeight,
  align,
  numberOfLines = 2,
}: ITypography) => {
  const fontSize = typeof size === 'string' ? theme.fontSizes[size] : size

  return (
    <Text
      style={[
        styles.container,
        styles[variant],
        color && { color: theme.colors[color] },
        size && { fontSize },
        bold && { fontWeight: 'bold' },
        fontWeight && { fontWeight },
        align && { textAlign: align },
        customStyle,
      ]}
      numberOfLines={numberOfLines}
    >
      {text}
    </Text>
  )
}

export default withWrapper<TypographyProps>(Typography, useTypographyWrapper, {
  useStyles,
})
