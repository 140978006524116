import { createRef, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TextInput } from 'react-native-gesture-handler'
import { IUseWrapper } from '../../../../core/helpers/withWrapper'
import { ImageGridSection } from '../../station/ImageGridSection'
import { ISearchableInputProps } from './component'

const filterHellper = (search: string, data: string[]) => {
  if (!search.length) {
    return data
  }
  const found = data.filter((countryName) =>
    countryName.toLocaleLowerCase().includes(search.toLocaleLowerCase())
  )
  return found
}

export const useSearchableInputWrapper: IUseWrapper<ISearchableInputProps> = (
  options,
  { onChange, data = [], value = '', handleBlur }
) => {
  const { t } = useTranslation()
  const { useStyles } = options!
  const styles = useStyles()

  const [text, setText] = useState(value)
  const [filteredData, setFilteredData] = useState<string[]>([])
  const [focused, setFocused] = useState(false)

  const textRef = createRef<TextInput>()

  useEffect(() => {
    setFilteredData(filterHellper(text, data))
  }, [text, data])

  const select = (selectedItem: string) => {
    fillText(selectedItem)

    closeModal()
  }

  const fillText = (item: string) => {
    setText(item)
    textRef.current?.blur()
    if (onChange) {
      onChange(item)
    }
  }

  const closeModal = () => {
    setFocused(false)

    if (handleBlur) {
      handleBlur()
    }
  }

  return {
    styles,
    t,
    filteredData,
    focused,
    setFocused,
    fillText,
    select,
    text,
    textRef,
    closeModal,
  }
}
