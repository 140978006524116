import { createSelectorHook } from '../../helpers/store'
import { RootState } from '../rootReducer'

const getAccount = () => (state: RootState) => {
  return state.account
}

const getFaq = () => (state: RootState) => {
  return state.account.questions
}

const getUser = () => (state: RootState) => {
  return state.account.user
}

const getImage = () => (state: RootState) => {
  return state.account.image
}

const getIsAuthorized = () => (state: RootState) => {
  return state.account.isAuthorized
}

export const accountSelectorHooks = {
  getAccount: createSelectorHook(getAccount),
  getFaq: createSelectorHook(getFaq),
  getUser: createSelectorHook(getUser),
  getImage: createSelectorHook(getImage),
  getIsAuthorized: createSelectorHook(getIsAuthorized),
}
