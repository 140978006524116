import { RouteProp, useRoute } from '@react-navigation/native'
import { IOptions, IUseWrapper } from '../../../../core/helpers/withWrapper'
import { RouterNames } from '../../../../core/constants'
import { RootStackParamList } from '../../../router/types'

export const useStationReportScreenWrapper: IUseWrapper = (options?: IOptions) => {
  const { useStyles } = options!
  const styles = useStyles()

  const { params } = useRoute<RouteProp<RootStackParamList, RouterNames.stationReport>>()

  return { styles, chargeStationId: params.chargerStationId }
}
