import React from 'react'
import { SafeAreaView, View } from 'react-native'
import { withWrapper } from '../../../core/helpers/withWrapper'
import { withStyles } from '../../../core/theme'
import { useCarDetailsWrapper } from './wrapper'
import { Button, InformationModal, Paper, Spacer, Switch, Typography } from '../../components'
import { renderDetails } from '../../components/carDetails'
import { TFunction } from 'i18next'
import { ICar } from '../../../core/store/vehicles'

const useStyles = withStyles((theme) => ({
  wrapper: {
    flex: 1,
    backgroundColor: theme.colors.backgroundOffset,
    height: '100%',
  },
  innerContainer: {
    width: '100%',
    height: '100%',
  },
  outerContainer: {
    backgroundColor: theme.colors.backgroundOffset,
    padding: theme.spacing.m,
    height: '100%',
  },
  headerWrapper: {
    paddingTop: theme.spacing.m,
    paddingBottom: theme.spacing.m * 2,
    borderBottomWidth: 1,
    borderBottomColor: '#ddeaf3',
    marginBottom: theme.spacing.m * 2,
  },
  detailsWrapper: {
    paddingBottom: theme.spacing.m,
    borderBottomWidth: 1,
    borderBottomColor: '#ddeaf3',
  },
}))

interface IAddCarScreenProps {}

interface ICarDetailsScreen extends IAddCarScreenProps {
  styles: ReturnType<typeof useStyles>
  isModalVisible: boolean
  selectedCar?: ICar
  isLoading: boolean
  setModalVisible: (value: boolean) => void
  deleteCar: () => void
  switchPrimary: () => void
  t: TFunction
}

const CarDetailsScreen = ({
  styles,
  isModalVisible,
  selectedCar,
  isLoading,
  setModalVisible,
  deleteCar,
  switchPrimary,
  t,
}: ICarDetailsScreen) => {
  if (!selectedCar) return null

  const { vehicleVersion, color, primary, vehicleRegistration } = selectedCar
  const { vehicleManufacturerName, vehicleModelName } = vehicleVersion

  return (
    <SafeAreaView style={styles.wrapper}>
      <View style={styles.outerContainer}>
        <Paper style={{ height: '100%' }} containerStyle={{ height: '100%' }}>
          <View style={styles.innerContainer}>
            <View style={styles.headerWrapper}>
              <Typography text={vehicleManufacturerName} size={'m'} fontWeight={'600'} />
              <Spacer top={'s'} />
              <Typography text={vehicleModelName} fontWeight={'200'} />
            </View>
            {renderDetails(
              {
                [t('cars:carDetails.regNo')]: vehicleRegistration,
                [t('cars:carDetails.color')]: t(`carColors:maps.${color}`) ?? 'Unknown',
              },
              styles
            )}
            <Spacer top={'l'} />
            <Switch label={t('cars:primary')} value={primary} onChange={switchPrimary} />
            <Spacer flex />
            <Button
              loading={isLoading}
              label={t('cars:carDetails.deleteButton')}
              color={'danger'}
              endIcon={{ name: 'chevron-right', family: 'Feather' }}
              onPress={() => setModalVisible(true)}
            />
            <InformationModal
              isVisible={isModalVisible}
              setVisible={setModalVisible}
              text={t('cars:carDetails.modal.title')}
              description={t('cars:carDetails.modal.description')}
              bottom
              withButtons={{
                left: { text: t('buttons:no'), onClick: () => setModalVisible(false) },
                right: { text: t('buttons:yes'), onClick: deleteCar },
              }}
            />
          </View>
        </Paper>
      </View>
    </SafeAreaView>
  )
}

export default withWrapper(CarDetailsScreen, useCarDetailsWrapper, {
  useStyles,
})
