import React from 'react'
import { useTranslation } from 'react-i18next'
import { ActivityIndicator, View, Text, Platform } from 'react-native'
import { DefaultWrapperProps, withWrapper } from '../../core/helpers/withWrapper'
import { withStyles } from '../../core/theme'

const useStyles = withStyles((theme) => ({
  container: {
    flex: 1,
    justifyContent: 'center',
    backgroundColor: theme.colors.backgroundOffset,
  },
  text: {
    textAlign: 'center',
    marginTop: theme.spacing.s,
    fontSize: theme.textVariants.body.fontSize,
  },
}))

interface IScreenLoaderProps {
  diableText?: boolean
}

interface IScreenLoader extends IScreenLoaderProps, DefaultWrapperProps {}

const ScreenLoader = ({ diableText, theme }: IScreenLoader) => {
  const styles = useStyles()
  const { t } = useTranslation()

  return (
    <View style={styles.container}>
      <ActivityIndicator
        size="large"
        color={Platform.OS === 'android' ? theme.colors.primary : undefined}
      />
      {/* {!diableText && <Text style={styles.text}>{t('loading:title')}</Text>} */}
    </View>
  )
}

export default withWrapper<IScreenLoaderProps>(ScreenLoader)
