import { TFunction } from 'react-i18next'
import { Pressable, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { DefaultWrapperProps, withWrapper } from '../../../core/helpers/withWrapper'
import { withStyles } from '../../../core/theme'
import { Box, Button, Typography, TextField, PhoneNumber, Checkbox } from '../../components'
import Spacer from '../../components/common/Spacer'

import { ProgressSteps, ProgressStep } from 'react-native-progress-steps'
import HeaderLanguageSwitcher from '../../components/HeaderLanguageSwitcher'
import { useRegistrationScreenWrapper } from './wrapper'
import { RouterNames } from '../../../core/constants'
import PhoneInput from 'qem-react-native-phone-number-input'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { KeyboardDismissView } from '../../components/KeyboardDismissView'

const useStyles = withStyles((theme, insets, width, isWeb) => ({
  container: {
    flex: 1,
    alignItems: 'center',
    padding: theme.spacing.l,
    backgroundColor: theme.colors.background,
  },
  view: {
    flex: 1,
  },
  link: {
    color: theme.colors.secondary,
  },
  form: {
    width: isWeb ? 400 : '100%',
    ...(isWeb ? { maxWidth: 400 } : {}),
  },
  checkboxLabel: {
    flex: 1,
    flexWrap: 'wrap',
    flexDirection: 'row',
    alignItems: 'center',
  },
}))

interface IFormik<T, B> {
  handleSubmit: () => void
  setFieldValue: (key: string, value: string) => void
  setFieldTouched: (key: string, value: boolean) => void
  values: T
  isValid: boolean
  errors: B
  touched: B
}

interface Customer {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  userName: string
}

interface Password {
  password: string
  repeatedPassword: string
  agreement: boolean
}

interface IRegistrationScreen extends DefaultWrapperProps {
  styles: ReturnType<typeof useStyles>
  t: TFunction
  openLoginScreen: () => void
  nextStep: () => void
  currentStep: number
  customer: IFormik<Customer, Customer>
  password: IFormik<Password, Password>
  isValid: boolean
  isDirty: boolean
  loading: boolean
  signUpError: string | null
  setSignUpError: (value: string | null) => void
  phoneInputRef?: React.RefObject<PhoneInput>
}

function generateField<T = Customer | Password>(
  formik: IFormik<T, T>,
  key: keyof T,
  setSingupError?: (value: string | null) => void
) {
  return {
    onChange: (text: string) => {
      formik.setFieldValue(key as string, text)

      if (setSingupError) {
        setSingupError(null)
      }
    },
    handleBlur: () => {
      if (!formik.touched[key]) {
        setTimeout(() => formik.setFieldTouched(key as string, true))
      }

      if (setSingupError) {
        setSingupError(null)
      }
    },
    value: formik.values[key],
    showMessage: formik.touched[key] ? formik.errors[key] : undefined,
    fullWidth: true,
  }
}

const RegistrationScreen = ({
  t,
  styles,
  openLoginScreen,
  currentStep,
  nextStep,
  isValid,
  isDirty,
  password,
  customer,
  loading,
  theme,
  navigation,
  signUpError,
  setSignUpError: setSingupError,
  phoneInputRef,
}: IRegistrationScreen) => (
  <SafeAreaView style={styles.view}>
    <KeyboardDismissView style={styles.view}>
      <KeyboardAwareScrollView style={styles.view}>
        <View style={styles.container}>
          <HeaderLanguageSwitcher />
          <Spacer flex vertical="m" />
          <View style={styles.form}>
            <Typography
              variant="primary"
              text={t('registration:form.title')}
              style={{ textAlign: 'center' }}
            />

            <View style={styles.view} dataSet={{ signup: 1 }}>
              <ProgressSteps
                activeStep={currentStep}
                scrollViewProps={styles.view}
                completedProgressBarColor={theme.colors.primary}
                activeStepIconBorderColor={theme.colors.primary}
                completedStepIconColor={theme.colors.primary}
                activeLabelColor={theme.colors.primary}
                disabledStepIconColor={theme.colors.placeholder}
                progressBarColor={theme.colors.placeholder}
              >
                <ProgressStep label={t('registration:form.user')} removeBtnRow scrollable={false}>
                  <TextField
                    label={t('forms:fields.firstName')}
                    {...generateField(customer, 'firstName', setSingupError)}
                    textContentType="givenName"
                  />
                  <Spacer vertical="s" />
                  <TextField
                    label={t('forms:fields.lastName')}
                    {...generateField(customer, 'lastName', setSingupError)}
                    textContentType="familyName"
                  />
                  <Spacer vertical="s" />
                  <TextField
                    label={t('forms:fields.username')}
                    {...generateField(customer, 'userName', setSingupError)}
                    textContentType="name"
                    autoCompleteType="name"
                  />
                  <Spacer vertical="s" />
                  <PhoneNumber
                    outsideRef={phoneInputRef}
                    label={t('forms:fields.phone')}
                    {...generateField(customer, 'phoneNumber', setSingupError)}
                  />
                  <Spacer vertical="s" />
                  <TextField
                    label={t('forms:fields.email')}
                    {...generateField(customer, 'email', setSingupError)}
                    textContentType="emailAddress"
                    autoCompleteType="email"
                  />
                </ProgressStep>
                <ProgressStep label={t('registration:form.security')} removeBtnRow>
                  <TextField
                    label={t('forms:fields.password')}
                    icon="lock"
                    secure
                    {...generateField(password, 'password', setSingupError)}
                    autoCompleteType="password"
                    textContentType="newPassword"
                  />
                  <Spacer vertical="s" />
                  <TextField
                    secure
                    icon="lock"
                    label={t('registration:form.confirmPassword')}
                    {...generateField(password, 'repeatedPassword', setSingupError)}
                  />
                  <Spacer vertical="s" />
                  <Checkbox
                    label={
                      <View style={styles.checkboxLabel}>
                        <Typography text={t('registration:form.agreement')} />
                        <Pressable onPress={() => navigation.navigate(RouterNames.privacy)}>
                          <Typography style={styles.link} text={t('registration:form.privacy')} />
                        </Pressable>
                        <Spacer left={'s'} />
                        <Typography text={t('profile:general.menu.and')} />
                        <Spacer right={'s'} />
                        <Pressable onPress={() => navigation.navigate(RouterNames.userGuide)}>
                          <Typography style={styles.link} text={t('registration:form.terms')} />
                        </Pressable>
                      </View>
                    }
                    {...generateField(password, 'agreement', setSingupError)}
                  />
                </ProgressStep>
              </ProgressSteps>
            </View>
          </View>

          <Spacer vertical="s" />
          {!!signUpError?.length && (
            <>
              <Typography color="danger" text={signUpError} />
              <Spacer vertical="s" />
            </>
          )}
          <View style={styles.form}>
            <Button
              label={t(`registration:form.${currentStep === 1 ? 'create' : 'button.next'}`)}
              fullWidth
              endIcon={{
                name: currentStep === 1 ? 'user-plus' : 'arrow-right',
                family: 'Feather',
              }}
              iconSize={20}
              onPress={nextStep}
              loading={loading}
              disabled={!isValid || !isDirty || loading || !!signUpError?.length}
              color={signUpError ? 'danger' : undefined}
            />
          </View>
          <Spacer vertical="m" />
          <Box>
            <Typography text={t('registration:form.haveAccount')} />
            <Spacer horizontal="xs" />
            <Button
              label={t('registration:form.button.login')}
              variant="link"
              onPress={openLoginScreen}
            />
          </Box>
        </View>
      </KeyboardAwareScrollView>
    </KeyboardDismissView>
  </SafeAreaView>
)

export default withWrapper(RegistrationScreen, useRegistrationScreenWrapper, {
  useStyles,
})
