export const PREFIX = 'ACCOUNT_'

export const LOGIN = `${PREFIX}LOGIN`
export const SIGNIN = `${PREFIX}SIGNIN`
export const LOGOUT = `${PREFIX}LOGOUT`
export const RECOVERY = `${PREFIX}RECOVERY`

export const GET_USER_PROFILE = `${PREFIX}GET_USER_PROFILE`
export const UPDATE_USER_PROFILE = `${PREFIX}UPDATE_USER_PROFILE`

export const SET_IMAGE = `${PREFIX}SET_IMAGE`

export const SET_USER_PROFILE = `${PREFIX}SET_USER_PROFILE`
export const UPDATE_USER_IMAGE = `${PREFIX}UPDATE_USER_IMAGE`
export const DEACTIVATE_USER = `${PREFIX}DEACTIVATE_USER`

export const VALIDATE_USER = `${PREFIX}VALIDATE_USER`

export const CONFIRM_REGISTRATION = `${PREFIX}CONFIRM_REGISTRATION`
export const RESET_PASSWORD = `${PREFIX}RESET_PASSWORD`

export const GET_FAQ = `${PREFIX}GET_FAQ`
export const SET_FAQ = `${PREFIX}SET_FAQ`

export const SET_IS_AUTHORIZED = `${PREFIX}SET_IS_AUTHORIZED`
export const GET_USER_DETAILS = `${PREFIX}GET_USER_DETAILS`
