import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IUseWrapper } from '../../../../core/helpers/withWrapper'
import { ISliderProps } from './component'

type OnChange = {
  fromValue: number
  toValue: number
}

export const useSliderWrapper: IUseWrapper<ISliderProps> = (
  options,
  { handleBlur, min, max, onChange, initialFromValue = min, initialToValue = max }
) => {
  const { t } = useTranslation()
  const { useStyles } = options!
  const styles = useStyles()
  const [focused, setFocused] = useState(false)
  const [fromValue, setFromValue] = useState(initialFromValue)
  const [toValue, setToValue] = useState(initialToValue)

  useEffect(() => {
    if (onChange) {
      onChange({ fromValue, toValue })
    }
  }, [fromValue, toValue])

  const onFocus = () => {
    setFocused(true)
  }

  const onBlur = () => {
    setFocused(false)

    if (handleBlur) {
      handleBlur()
    }
  }

  return {
    styles,
    t,
    focused,
    onFocus,
    onBlur,
    fromValue,
    setFromValue,
    toValue,
    setToValue,
  }
}
