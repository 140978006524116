import React from 'react'
import { DefaultWrapperProps, withWrapper } from '../../../../core/helpers/withWrapper'
import { withStyles } from '../../../../core/theme'
import { GuideList } from '../../../components'

import { useStationGuideContentWrapper } from './wrapper'

const useStyles = withStyles((theme) => ({}))

interface IStationGuideContent extends DefaultWrapperProps {
  styles: ReturnType<typeof useStyles>
}

const StationGuideContent = ({ styles }: IStationGuideContent) => (
  <GuideList
    guides={[
      {
        title: 'Header',
        image:
          'https://www.clipartmax.com/png/full/260-2603262_medelita-self-service-tugers-co-madrid.png',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Neque eget nibh vitae vel ante cursus tincidunt. Morbi vel ullamcorper a dictumst. Risus, eget aliquam feugiat aenean sapien ac sagittis. Lobortis massa sagittis, mauris mauris ultrices."',
      },
      {
        title: 'Header',
        image:
          'https://www.clipartmax.com/png/full/260-2603262_medelita-self-service-tugers-co-madrid.png',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Neque eget nibh vitae vel ante cursus tincidunt. Morbi vel ullamcorper a dictumst. Risus, eget aliquam feugiat aenean sapien ac sagittis. Lobortis massa sagittis, mauris mauris ultrices."',
      },
      {
        title: 'Header',
        image:
          'https://www.clipartmax.com/png/full/260-2603262_medelita-self-service-tugers-co-madrid.png',
        description:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Neque eget nibh vitae vel ante cursus tincidunt. Morbi vel ullamcorper a dictumst. Risus, eget aliquam feugiat aenean sapien ac sagittis. Lobortis massa sagittis, mauris mauris ultrices."',
      },
    ]}
  />
)

export default withWrapper(StationGuideContent, useStationGuideContentWrapper, { useStyles })
