import React, { useCallback } from 'react'
import { SafeAreaView, SectionList, TextInput, View } from 'react-native'
import { withWrapper } from '../../../core/helpers/withWrapper'
import { withStyles } from '../../../core/theme'
import { IVehicleData, useYearAndVersionScreenWrapper } from './wrapper'
import { ITheme } from '../../../core/theme/themes'
import {
  Button,
  CheckboxString,
  KeyboardAvoid,
  Paper,
  SelectorWithModal,
  Spacer,
  Typography,
} from '../../components'
import { TFunction } from 'react-i18next'

const useStyles = withStyles((theme) => ({
  container: {
    flex: 1,
    backgroundColor: theme.colors.backgroundOffset,
  },
  outerContainer: {
    flex: 1,
    backgroundColor: theme.colors.backgroundOffset,
    padding: theme.spacing.m,
  },
  innerContainer: {
    height: '100%',
    width: '100%',
  },
  btnContainer: {},
  textInput: {
    borderColor: '#DDEAF3',
    borderRadius: theme.spacing.s,
    borderWidth: 1,
    paddingTop: theme.spacing.m,
    paddingBottom: theme.spacing.m,
    paddingHorizontal: theme.spacing.m,
  },
}))

interface IYearAndVersionProps {}

type Item = string | { vehicleVersionId: string; vehicleVersionName: string }

type Section = { type: string; data: string[] | Item[] }

interface IYearAndVersionScreen extends IYearAndVersionProps {
  styles: ReturnType<typeof useStyles>
  theme: ITheme
  dataSections: Section[]
  colors: string[]
  isLoading: boolean
  isButtonEnabled: boolean
  vehicleData: IVehicleData
  setVehicle: (type: keyof IVehicleData) => (value: IVehicleData[typeof type]) => void
  handleListItemClick: (item: string, title: string) => void
  handleAddVehicle: () => void
  setSearch: (value: string) => void
  t: TFunction
}

interface IRenderItemProps {
  item: Item
  index: number
  section: Section
}

const YearAndVersionScreen = ({
  styles,
  theme,
  dataSections,
  colors,
  isLoading,
  isButtonEnabled,
  vehicleData,
  setVehicle,
  handleListItemClick,
  handleAddVehicle,
  setSearch,
  t,
}: IYearAndVersionScreen) => {
  const { activeYear, activeVersion } = vehicleData

  const renderItem = ({ item, index, section }: IRenderItemProps) => {
    const { type } = section
    const data = typeof item === 'string' ? item : item.vehicleVersionName
    const isChecked =
      type === t('cars:addCar.fields.year') ? data === activeYear : data === activeVersion

    return (
      <CheckboxString
        data={data}
        index={index}
        onPress={() => handleListItemClick(data, type)}
        isChecked={isChecked}
      />
    )
  }

  const handleItem = useCallback(
    ({ item, index, section }) => {
      switch (section.type) {
        case t('cars:addCar.fields.year'):
          return renderItem({ item, index, section })

        case t('cars:addCar.fields.version'):
          return activeYear !== undefined ? renderItem({ item, index, section }) : null

        case t('cars:addCar.fields.color'):
          return activeYear !== undefined ? (
            <>
              <SelectorWithModal
                colors
                data={colors}
                rightIcon={'chevron-down'}
                onSearchInput={setSearch}
                onSelectItem={setVehicle('color')}
                noItemSelectedString={t('cars:selectColor')}
              />
              <Spacer top={'m'} />
            </>
          ) : null

        case t('cars:addCar.fields.number'):
          return activeYear !== undefined ? (
            <TextInput
              maxLength={16}
              style={styles.textInput}
              onChangeText={setVehicle('number')}
            />
          ) : null

        default:
          return <View />
      }
    },
    [activeYear, activeVersion, colors]
  )

  const renderHeader = useCallback(
    ({ section: { type } }) =>
      type === t('cars:addCar.fields.year') || activeYear !== undefined ? (
        <View style={{ backgroundColor: theme.colors.white }}>
          <Typography text={type} size={'m'} bold />
          <Spacer top={'m'} />
        </View>
      ) : null,
    [activeYear, activeVersion]
  )

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.outerContainer}>
        <KeyboardAvoid>
          <Paper>
            <View style={styles.innerContainer}>
              <SectionList
                sections={dataSections}
                renderItem={handleItem}
                renderSectionHeader={renderHeader}
                keyExtractor={(item, index) =>
                  typeof item === 'string'
                    ? `${item}-${index}`
                    : `${item.vehicleVersionId}-${index}`
                }
              />
              <Spacer vertical={'s'} />
              <View style={styles.btnContainer}>
                <Button
                  bold
                  loading={isLoading}
                  label={t('buttons:add')}
                  disabled={!isButtonEnabled}
                  onPress={handleAddVehicle}
                />
              </View>
            </View>
          </Paper>
        </KeyboardAvoid>
      </View>
    </SafeAreaView>
  )
}

export default withWrapper(YearAndVersionScreen, useYearAndVersionScreenWrapper, {
  useStyles,
})
