import { useEffect, useMemo, useRef, useState } from 'react'
import { FlatList, View } from 'react-native'

import { useCharging } from '../../providers/charging'
import { Tabs } from './Tabs'
import { ActiveSessions } from './Active'
import { CompletedSessions } from './Completed'
import { useStyles } from './styles'

export const SessionsScreen = () => {
  const ref = useRef<FlatList>(null)
  const [tab, setTab] = useState(0)

  const { sessions } = useCharging()

  const styles = useStyles()

  useEffect(() => {
    ref.current?.scrollToIndex({ index: tab, animated: true })
  }, [tab])

  const tabs = [{ title: 'Active', count: sessions.size }, { title: 'Completed' }]

  const data = useMemo(
    () => [<ActiveSessions style={styles.tab} />, <CompletedSessions style={styles.tab} />],
    [styles]
  )

  const renderItem = ({ item }: { item: JSX.Element }) => {
    return <View style={styles.item}>{item}</View>
  }

  return (
    <>
      <Tabs data={tabs} selectedTab={tab} onChange={setTab} />
      <FlatList
        horizontal
        ref={ref}
        data={data}
        scrollEnabled={false}
        renderItem={renderItem}
        style={styles.container}
        contentContainerStyle={styles.content}
      />
    </>
  )
}
