import { Platform } from 'react-native'
import { api } from '../setup'
import { GET_CURRENT_DRIVER_IMAGE, GET_DRIVER_IMAGE_BY_ID, UPLOAD_DRIVER_IMAGE } from './constants'

export const driverApi = {
  getDriverImage: async () => {
    const { data } = await api.get(GET_CURRENT_DRIVER_IMAGE)
    return data
  },
  getDriverImageById: async (id: string) => {
    const { data } = await api.get(GET_DRIVER_IMAGE_BY_ID(id))
    return data
  },
  uploadDriverImage: async (uri: string) => {
    const data = new FormData()
    const name = `${Date.now()}.jpg`

    if (Platform.OS === 'web') {
      const image = await fetch(uri)
      const blob = await image.blob()

      data.append('file', blob, name)
    } else {
      data.append('file', {
        uri,
        name,
        type: 'image/jpeg',
      })
    }

    return api.post(UPLOAD_DRIVER_IMAGE, data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  },
}
