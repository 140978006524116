import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { IUseWrapper } from '../../../../core/helpers/withWrapper'

export const useSwitchWrapper: IUseWrapper<{ handleBlur?: () => void }> = (
  options,
  { handleBlur }
) => {
  const { t } = useTranslation()
  const { useStyles } = options!
  const styles = useStyles()
  const [focused, setFocused] = useState(false)

  const onFocus = () => {
    setFocused(true)
  }

  const onBlur = () => {
    setFocused(false)

    if (handleBlur) {
      handleBlur()
    }
  }

  return { styles, t, focused, onFocus, onBlur }
}
