import { TouchableOpacity, View } from 'react-native'

import { withStyles } from '../../../../core/theme'
import { withWrapper } from '../../../../core/helpers/withWrapper'
import { useSelectorListItemWrapper } from './wrapper'
import { ITheme } from '../../../../core/theme/themes'
import Spacer from '../../common/Spacer'
import Typography from '../../common/Typography'
import { Icon } from '../../icon'

interface IRenderItemProps {
  isModalVisible: boolean
  setModalVisible: (value: boolean) => void
  setSelectedString: (value: string) => void
  onSelectItem: (item: string) => void
  item: string
  selectorLeftIcon?: string
  color?: string
}

interface IRenderItem extends IRenderItemProps {
  styles: ReturnType<typeof useStyles>
  theme: ITheme
}

const useStyles = withStyles((theme) => ({
  listItem: {
    flexDirection: 'row',
    height: theme.spacing.xxl,
    alignContent: 'center',
    alignItems: 'center',
    borderBottomWidth: 2,
    borderColor: theme.colors.border,
    paddingHorizontal: theme.spacing.m,
  },
  icon: {
    marginHorizontal: theme.spacing.m,
  },
  colorView: {
    width: 20,
    height: 20,
    borderWidth: 1,
    borderRadius: 10,
    borderColor: theme.colors.placeholder,
  },
}))

const SelectorListItem = ({
  isModalVisible,
  setModalVisible,
  setSelectedString,
  onSelectItem,
  styles,
  item,
  theme,
  selectorLeftIcon,
  color,
}: IRenderItem) => (
  <TouchableOpacity
    onPress={() => {
      setSelectedString(color || item)
      onSelectItem(color || item)
      setModalVisible(!isModalVisible)
    }}
    style={styles.listItem}
  >
    {selectorLeftIcon && (
      <Icon
        family={'Feather'}
        name={'github'}
        size={15}
        color={theme.colors.primary}
        style={styles.icon}
      />
    )}
    {color && <View style={[styles.colorView, { backgroundColor: color }]} />}
    <Spacer right={'s'} />
    <Typography text={item} />
  </TouchableOpacity>
)

export default withWrapper<IRenderItemProps>(SelectorListItem, useSelectorListItemWrapper, {
  useStyles,
})
