import { TFunction } from 'react-i18next'
import { View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { withWrapper } from '../../../core/helpers/withWrapper'
import { withStyles } from '../../../core/theme'
import { Box, Button, KeyboardAvoid, Typography } from '../../components'
import Spacer from '../../components/common/Spacer'
import { TextField } from '../../components/forms'
import HeaderLanguageSwitcher from '../../components/HeaderLanguageSwitcher'
import { KeyboardDismissView } from '../../components/KeyboardDismissView'
import { useRecoveryScreenWrapper } from './wrapper'

const useStyles = withStyles((theme, insets, width, isWeb) => ({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing.l,
    backgroundColor: theme.colors.background,
  },
  content: {
    ...(isWeb ? { width: 400, maxWidth: 400 } : {}),
  },
}))

interface IRecoveryScreen {
  styles: ReturnType<typeof useStyles>
  t: TFunction
  loading: boolean
  openLoginScreen: () => void
  handleSubmit: () => void
  handleChange: (key: string) => (text: string) => void
  handleBlur: (key: string) => () => void
  values: {
    email: string
  }
  isValid: boolean
  errors: {
    email?: string
  }
  touched: {
    email?: string
  }
}

const RecoveryScreen = ({
  t,
  styles,
  openLoginScreen,
  values,
  isValid,
  handleChange,
  handleSubmit,
  handleBlur,
  errors,
  touched,
  loading,
}: IRecoveryScreen) => (
  <KeyboardAvoid>
    <SafeAreaView style={{ flex: 1 }}>
      <KeyboardDismissView style={{ flex: 1 }}>
        <View style={styles.container}>
          <HeaderLanguageSwitcher />
          <Spacer flex />
          <Typography text={t('recovery:form.title')} variant="primary" />
          <Typography
            style={{ textAlign: 'center' }}
            text={t('recovery:form.subtitle')}
            variant="secondary"
          />
          <Spacer vertical="m" />
          <View style={styles.content}>
            <TextField
              icon="inbox"
              placeholder={t('forms:placeholders.email')}
              onChange={handleChange('email')}
              handleBlur={handleBlur('email')}
              value={values.email}
              showMessage={touched.email ? errors.email : undefined}
            />
            <Spacer vertical="s" />
            <Button
              label={t('recovery:form.button.recovery')}
              endIcon={{ name: 'mail', family: 'Feather' }}
              onPress={handleSubmit}
              disabled={!isValid}
              loading={loading}
            />
          </View>
          <Spacer flex />
          <Box>
            <Typography text={t('recovery:has_account')} />
            <Spacer horizontal="xs" />
            <Button
              label={t('recovery:form.button.login')}
              variant="link"
              onPress={openLoginScreen}
            />
          </Box>
        </View>
      </KeyboardDismissView>
    </SafeAreaView>
  </KeyboardAvoid>
)

export default withWrapper(RecoveryScreen, useRecoveryScreenWrapper, {
  useStyles,
})
