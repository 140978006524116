import { StyleProp, View, ViewStyle } from 'react-native'
import ExpoCheckbox from 'expo-checkbox'

import { theme } from '../../../../core/theme/themes'
import Typography from '../Typography'
import { useStyles } from './styles'

export interface Props {
  label?: string | JSX.Element
  onChange?: (value: boolean) => void
  value?: boolean
  style?: StyleProp<ViewStyle>
}

export const Checkbox = ({ onChange, value, label, style }: Props) => {
  const styles = useStyles()

  return (
    <View style={[styles.container, style]}>
      <ExpoCheckbox
        value={value}
        onValueChange={onChange}
        style={styles.checkbox}
        color={theme.colors.primary}
      />
      {typeof label === 'string' ? <Typography text={label} /> : label}
    </View>
  )
}
