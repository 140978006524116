import { View } from 'react-native'
import { withWrapper } from '../../../../core/helpers/withWrapper'
import { withStyles } from '../../../../core/theme'
import { useInformationModalWrapper } from './wrapper'
import { ITheme } from '../../../../core/theme/themes'
import { Icon } from '../../icon'
import ModalContainer from '../ModalContainer'
import Spacer from '../Spacer'
import Typography from '../Typography'
import Button from '../Button'

const useStyles = withStyles((theme, insets, width, isWeb) => ({
  topContentWrapper: {
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  iconWrapper: {
    height: theme.spacing.m * 2,
    width: theme.spacing.m * 2,
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    backgroundColor: '#597393',
    borderRadius: theme.spacing.m,
  },
  buttonsWrapper: {
    flexDirection: 'row',
    paddingBottom: theme.spacing[isWeb ? 'l' : 'm'],
    justifyContent: 'space-between',
  },
  leftButtonWrapper: {
    width: '45%',
    marginLeft: '3%',
    marginRight: '1.5%',
  },
  rightButtonWrapper: {
    width: '45%',
    marginRight: '3%',
    marginLeft: '1.5%',
  },
}))

interface IInformationModalProps {
  icon?: string
  text: string
  isVisible: boolean
  setVisible: (value: boolean) => void
  bottom?: boolean
  withButtons?: {
    left: {
      text: string
      onClick: () => void
    }
    right: {
      text: string
      onClick: () => void
    }
  }
  description?: string
}

interface IInformationModal extends IInformationModalProps {
  styles: ReturnType<typeof useStyles>
  theme: ITheme
}

const InformationModal = ({
  styles,
  theme,
  icon,
  text = 'Whoops, something went wrong...',
  withButtons,
  description,
  ...rest
}: IInformationModal) => (
  <ModalContainer {...rest}>
    <View style={styles.topContentWrapper}>
      {icon && (
        <View style={styles.iconWrapper}>
          <Icon name={icon} color={theme.colors.white} size={24} />
        </View>
      )}
      <Spacer top={'m'} />
      <Typography text={text} bold size={'m'} align={'center'} />
      {description && (
        <>
          <Spacer top={'m'} />
          <Typography text={description} size={'m'} align={'center'} />
          <Spacer bottom={'m'} flex />
        </>
      )}
    </View>
    <Spacer top="l" />
    {withButtons && (
      <View style={styles.buttonsWrapper}>
        <View style={styles.leftButtonWrapper}>
          <Button
            variant={'outlined'}
            label={withButtons.left.text}
            onPress={withButtons.left.onClick}
          />
        </View>
        <View style={styles.rightButtonWrapper}>
          <Button
            color={'danger'}
            variant={'outlined'}
            label={withButtons.right.text}
            onPress={withButtons.right.onClick}
          />
        </View>
      </View>
    )}
  </ModalContainer>
)

export default withWrapper<IInformationModalProps>(InformationModal, useInformationModalWrapper, {
  useStyles,
})
