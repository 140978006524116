import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import { IUseWrapper, IOptions } from '../../../core/helpers/withWrapper'
import { RouterNames } from '../../../core/constants'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { accountActionHooks } from '../../../core/store/account'
import { useEffect, useState } from 'react'
import { generateError } from '../../../core/helpers/utils'
import { AxiosError } from 'axios'
import { TFunction } from 'i18next'
import { OTPTypes } from '../OTP/types'

const RecoverySchema = (t: TFunction) =>
  Yup.object().shape({
    email: Yup.string().email().required(t('forms:common.required')),
  })

export const useRecoveryScreenWrapper: IUseWrapper = (options?: IOptions) => {
  const { useStyles } = options!
  const { t } = useTranslation()
  const styles = useStyles()
  const navigation = useNavigation()
  const recovery = accountActionHooks.recovery()
  const [recoveryError, setRecoveryError] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const openLoginScreen = () => {
    navigation.navigate(RouterNames.login)
  }

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: RecoverySchema(t),
    onSubmit: (values) => {
      setRecoveryError(null)
      setLoading(true)

      recovery({
        email: values.email,
        callback: (
          error: AxiosError<{
            message?: string
          }>
        ) => {
          console.log('Error', error)
          if (error) {
            const errorMessage = generateError(error, {
              400: t('recovery:form.validation.400'),
            })

            setRecoveryError(error.response?.data.message ?? errorMessage)
          } else {
            navigation.navigate(RouterNames.otp, { type: OTPTypes.recovery })
          }

          setLoading(false)
        },
      } as any)
    },
  })

  useEffect(() => {
    if (recoveryError) {
      formik.setFieldError('email', recoveryError)
    }
  }, [recoveryError])

  return {
    styles,
    t,
    openLoginScreen,
    handleSubmit: formik.handleSubmit,
    handleChange: (field: string) => (value: string) => formik.setFieldValue(field, value),
    handleBlur: (field: string) => () => formik.setFieldTouched(field, true),
    values: formik.values,
    isValid: formik.isValid,
    errors: formik.errors,
    touched: formik.touched,
    loading,
  }
}
