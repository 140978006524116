import { useEffect, useMemo } from 'react'

import { stationsActionHooks, stationsSelectorHooks } from '../../core/store/stations'
import { useLocation } from '../providers/location'

type FilterEntries = Array<[string, string | number | boolean]>

export const StationController = () => {
  const getStations = stationsActionHooks.getStations()
  const initFilter = stationsActionHooks.initFilters()

  const filter = stationsSelectorHooks.getFilterData()
  const connectors = stationsSelectorHooks.getConnectors()

  const { location } = useLocation()

  const filterPayload = useMemo<FilterEntries>(() => {
    if (!filter) return []

    const { connectors, speed, distanceMax, ...rest } = filter

    const connectorIds = [...connectors.entries()].reduce<string[]>((acc, [key, value]) => {
      if (value) acc.push(key)
      return acc
    }, [])

    const data = Object.assign(
      { ...speed, ...rest, connectorType: connectorIds.join(',') },
      location && distanceMax && { distanceMax, ...location }
    )

    return Object.entries(data).filter(([_, value]) => ![null, undefined].includes(value))
  }, [filter, location])

  useEffect(() => {
    if (!connectors.length) return

    initFilter()
  }, [connectors])

  useEffect(() => {
    if (!filterPayload.length) return

    getStations(Object.fromEntries(filterPayload))
  }, [filterPayload])

  return null
}
