import React, { useLayoutEffect, useRef } from 'react'
import { TouchableOpacity, View } from 'react-native'
import { useTranslation } from 'react-i18next'

import { Typography } from '../../../components/common'
import { Header } from '../../../router/Header'
import { StationFilterContent } from '../content'
import { stationsActionHooks } from '../../../../core/store/stations'
import { RouterNames } from '../../../../core/constants'
import { Station } from '../../../../core/models'
import { useStyles } from './styles'

export const StationFilterScreen = ({ navigation }: ScreenProps<RouterNames.stationFilter>) => {
  const { setOptions, navigate } = navigation

  const { t } = useTranslation()

  const contentRef = useRef<StationFilterContent>(null)

  const setFilter = stationsActionHooks.setFilters()

  const styles = useStyles()

  const show = (filters: Station.Filter) => {
    setFilter(filters)
    navigate(RouterNames.private, { screen: RouterNames.home })
  }

  const reset = () => {
    contentRef.current?.reset()
  }

  const right = (
    <TouchableOpacity onPress={reset}>
      <Typography text={t('transaction:filter.resetAll')} size={14} color="secondaryText" />
    </TouchableOpacity>
  )

  useLayoutEffect(() => {
    setOptions({
      header: (props) => <Header {...props} right={right} />,
    })
  }, [])

  return (
    <View style={styles.container}>
      <StationFilterContent ref={contentRef} show={show} />
    </View>
  )
}
