import { withStyles } from '../../../core/theme'

export const useStyles = withStyles((theme, insets) => ({
  list: {
    backgroundColor: theme.colors.backgroundOffset,
  },
  content: {
    gap: theme.spacing.m,
    padding: theme.spacing.m,
    paddingBottom: insets.bottom || theme.spacing.m,
  },
  empty: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
}))
