import { withStyles } from '../../../../core/theme'

export const useStyles = withStyles((theme) => ({
  list: {
    gap: theme.spacing.m,
    paddingVertical: theme.spacing.m,
  },
  placeholder: {
    paddingVertical: theme.spacing.l,
  },
}))
