import { useTranslation } from 'react-i18next'

import { notificationsApi } from '../../../../core/api/notifications'
import { IOptions, IUseWrapper } from '../../../../core/helpers/withWrapper'
import { settingsActionHooks } from '../../../../core/store/settings'
import { ILanguagesContentProps } from './types'

export const useLanguagesContentWrapper: IUseWrapper<ILanguagesContentProps> = (
  options: IOptions,
  { onChooseLanguage }
) => {
  const { useStyles } = options!

  const { t, i18n } = useTranslation()
  const styles = useStyles()

  const setLanguage = settingsActionHooks.setLanguage()

  const changeLanguage = (language: string) => {
    setLanguage(language)
    i18n.changeLanguage(language)
    notificationsApi.language({ language })
    onChooseLanguage?.call(null)
  }

  return {
    t,
    styles,
    changeLanguage,
    language: i18n.language,
  }
}
