import { IUseWrapper } from '../../../../../core/helpers/withWrapper'
import { useTheme } from '../../../../../core/theme'

import { FilterType } from '../types'
import { IStationFilterItemProps } from './types'

export const useFilterWrapper: IUseWrapper<IStationFilterItemProps> = (
  options,
  { item: model, onPress }
) => {
  const { useStyles } = options
  const theme = useTheme()
  const styles = useStyles()

  const isCar = model.type === FilterType.car

  const title = isCar
    ? `${model.item.vehicleVersion.vehicleManufacturerName} ${model.item.vehicleVersion.vehicleModelName}`
    : model.item.name
  const subtitle = isCar ? model.item.vehicleVersion.year : model.item.address.streetAddress

  const handlePress = () => {
    onPress({ ...model, selected: !model.selected })
  }

  return { styles, theme, title, subtitle, handlePress }
}
