import { View } from 'react-native'

import { Spacer, Typography, PaperListItem } from '../common'
import { ISession } from '../../../core/api/sessions/types'
import { timeStampToString } from '../../../core/helpers/date'
import { useStyles } from './styles'

interface Props {
  session: ISession
  first?: boolean
  last?: boolean
  onPress?: () => void
}

export const Session = ({ session, onPress, ...rest }: Props) => {
  const { startTimeOfCharge, endTimeOfCharge, stationName, sessionStatus, stationAddress } = session

  const startTime = timeStampToString(startTimeOfCharge, 'HH:mm')
  const endTime = timeStampToString(endTimeOfCharge, 'HH:mm')
  const endDate = timeStampToString(endTimeOfCharge, 'DD MMM')

  const styles = useStyles()

  return (
    <PaperListItem style={styles.container} onPress={onPress} {...rest}>
      <View style={styles.left}>
        <Typography text={endDate} size={12} fontWeight={'600'} color={'secondaryText'} />
        <Spacer vertical={'s'} />
        <Typography text={`${startTime} - ${endTime}`} size={12} color={'secondaryText'} />
      </View>
      <Spacer horizontal={'xs'} />
      <View style={styles.right}>
        <View style={styles.textContainer}>
          <Typography text={stationName} size={12} fontWeight={'600'} color={'secondaryText'} />
          <Typography
            text={sessionStatus.toUpperCase()}
            color={'secondaryText'}
            size={12}
            fontWeight={'600'}
          />
        </View>
        <Spacer vertical={'xs'} />
        <Typography text={stationAddress.streetAddress} size={12} color={'secondaryText'} />
      </View>
    </PaperListItem>
  )
}
