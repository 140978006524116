import { PropsWithChildren, createContext, useContext, useState } from 'react'
import { Platform } from 'react-native'
import Geolocation from '@react-native-community/geolocation'

import { Location } from '../../core/models'

interface Context {
  request: () => void
  location: Location | null
}

const initials: Context = { request: Promise.resolve, location: null }

const LocationContext = createContext(initials)

if (Platform.OS !== 'web') {
  Geolocation.setRNConfiguration({
    skipPermissionRequests: false,
    authorizationLevel: 'whenInUse',
  })
}

export const LocationProvider = ({ children }: PropsWithChildren) => {
  const [location, setLocation] = useState<Location | null>(null)

  const request = () => {
    Geolocation.getCurrentPosition(({ coords: { longitude, latitude } }) => {
      setLocation({ longitude, latitude })
    })
  }

  const value = { location, request }

  return <LocationContext.Provider value={value}>{children}</LocationContext.Provider>
}

export const useLocation = () => useContext(LocationContext)
