import { withStyles } from '../../../../core/theme'

export const useStyles = withStyles((theme) => ({
  container: {
    paddingHorizontal: theme.spacing.m,
    paddingVertical: theme.spacing.m,
  },
  buttonText: {
    fontWeight: '400',
    marginLeft: 0,
    color: theme.colors.text,
    paddingVertical: theme.spacing.s,
  },
}))
