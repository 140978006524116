import { SafeAreaView, View } from 'react-native'

import { withWrapper } from '../../../../core/helpers/withWrapper'
import { withStyles } from '../../../../core/theme'

import { useSupportRequestScreenWrapper } from './wrapper'
import { SupportRequestContent } from '../content'
import { KeyboardDismissView } from '../../../components/KeyboardDismissView'
import { KeyboardAvoid, Paper } from '../../../components'

const useStyles = withStyles((theme, insets, width, isWeb) => ({
  container: {
    flex: 1,
    backgroundColor: theme.colors.backgroundOffset,
    paddingBottom: isWeb ? 25 : 0,
  },
  content: {
    flex: 1,
    padding: theme.spacing.m,
  },
  paper: {
    flex: 1,
  },
  paperContent: {
    flex: 1,
  },
}))

interface ISupportRequestScreen {
  styles: ReturnType<typeof useStyles>
  chargeStationId?: string
}

const Component = ({ styles, chargeStationId }: ISupportRequestScreen) => (
  <SafeAreaView style={styles.container}>
    <KeyboardDismissView>
      <KeyboardAvoid>
        <View style={styles.content}>
          <Paper style={styles.paper} containerStyle={styles.paperContent}>
            <SupportRequestContent chargeStationId={chargeStationId} />
          </Paper>
        </View>
      </KeyboardAvoid>
    </KeyboardDismissView>
  </SafeAreaView>
)

export const SupportRequestScreen = withWrapper(Component, useSupportRequestScreenWrapper, {
  useStyles,
})
