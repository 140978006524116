import { useEffect, useState } from 'react'
import { FlatList, Platform, TouchableOpacity } from 'react-native'

import { Paper, ScreenLoader, Spacer } from '../../components'
import { CommonStationCard } from '../../components/CommonStationCard'
import { Icon } from '../../components/icon'
import { StationsSortType } from '../../../core/api/stations/types'
import { RouterNames } from '../../../core/constants'
import { OrderType } from '../../../core/store/transactions'
import { stationsActionHooks, stationsSelectorHooks } from '../../../core/store/stations'
import { SortModal } from './SortModal'
import { useStyles } from './styles'
import { Station } from '../../../core/models'

export const FavoritesScreen = ({ navigation }: ScreenProps<RouterNames.favorites>) => {
  const { navigate, setOptions } = navigation

  const styles = useStyles()

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [sortBy, setSortBy] = useState<StationsSortType>('distance')
  const [orderBy, setOrderBy] = useState<OrderType>('asc')

  const [isLoading, setIsLoading] = useState(false)

  const getFavorites = stationsActionHooks.getFavoriteStations()
  const favorites = stationsSelectorHooks.getFavoriteStations()

  useEffect(() => {
    setIsLoading(true)
    getFavorites({
      callback: () => {
        setIsLoading(false)
      },
    })
  }, [])

  const sortIcon = (
    <TouchableOpacity onPress={() => setIsModalVisible(true)}>
      <Icon family={'MaterialIcons'} name="sort" size={28} />
    </TouchableOpacity>
  )

  const hasFavorites = favorites.length > 0

  // useEffect(() => {
  //   if (isLoading || !hasFavorites) return

  //   setOptions({ header: (props) => <Header {...props} right={sortIcon} /> })
  // }, [isLoading, hasFavorites])

  const onSortPress = (type: StationsSortType) => {
    const isAscending = sortBy === type && orderBy === 'asc'
    setOrderBy(isAscending ? 'desc' : 'asc')
    setSortBy(type)
  }

  const renderItem = ({ item }: { item: Station }) => {
    const onPress = () => {
      navigate(RouterNames.station, { stationId: item.id })
    }

    return (
      <TouchableOpacity onPress={onPress}>
        <Paper>
          <CommonStationCard station={item} showConnectors />
        </Paper>
      </TouchableOpacity>
    )
  }

  const spacer = () => <Spacer vertical={'s'} />

  if (isLoading) return <ScreenLoader />

  return (
    <>
      <FlatList
        data={favorites}
        renderItem={renderItem}
        keyExtractor={(item) => item.id}
        ItemSeparatorComponent={spacer}
        style={styles.container}
        contentContainerStyle={styles.content}
        showsVerticalScrollIndicator={Platform.OS === 'web'}
      />
      <SortModal
        isVisible={isModalVisible}
        sortBy={sortBy}
        orderBy={orderBy}
        setVisible={setIsModalVisible}
        onSortPress={onSortPress}
      />
    </>
  )
}
