import { withWrapper } from '../../../../core/helpers/withWrapper'
import { withStyles } from '../../../../core/theme'
import { useCheckboxStringWrapper } from './wrapper'
import { SafeAreaView } from 'react-native'
import Typography from '../Typography'
import CustomCheckbox from '../CustomCheckbox'

interface ICheckboxStringProps {
  data: string
  index: number
  onPress: () => void
  isChecked: boolean
}

interface ICheckboxStringModal extends ICheckboxStringProps {
  styles: ReturnType<typeof useStyles>
}

const useStyles = withStyles((theme) => ({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: theme.spacing.m,
  },
}))

const CheckboxString = ({ data, styles, onPress, isChecked }: ICheckboxStringModal) => {
  return (
    <SafeAreaView style={styles.container}>
      <Typography text={data} size={'m'} />
      <CustomCheckbox size={24} onClick={onPress} isChecked={isChecked} />
    </SafeAreaView>
  )
}

export default withWrapper<ICheckboxStringProps>(CheckboxString, useCheckboxStringWrapper, {
  useStyles,
})
