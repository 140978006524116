import { SafeAreaView } from 'react-native'
import { withWrapper } from '../../../../core/helpers/withWrapper'
import { withStyles } from '../../../../core/theme'
import StationGuideContent from '../content'
import { useStationGuideScreenWrapper } from './wrapper'

const useStyles = withStyles((theme) => ({
  container: {
    flex: 1,
    backgroundColor: theme.colors.backgroundOffset,
  },
}))

interface IStationGuideScreen {
  styles: ReturnType<typeof useStyles>
}

const StationGuideScreen = ({ styles }: IStationGuideScreen) => (
  <SafeAreaView style={styles.container}>
    <StationGuideContent />
  </SafeAreaView>
)

export default withWrapper(StationGuideScreen, useStationGuideScreenWrapper, { useStyles })
