import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { FlatList, TouchableOpacity, ScrollView, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { SpeedType, stationsSelectorHooks } from '../../../../core/store/stations'
import { Box, Button, Checkbox, Paper, Slider, Typography } from '../../../components'
import { OnChange } from '../../../components/forms/Slider/component'
import { ConnectorType, Station } from '../../../../core/models'
import { ConnectorImage } from '../../../components/common/ConnectorImage'
import isEqual from 'lodash.isequal'
import { Switcher } from '../../../components/common/Switcher'
import { Checkboxes, IStationFilterProps } from './types'
import { useStyles } from './styles'
import { useLocation } from '../../../providers/location'

interface SpeedOption {
  speed?: Station.FilterSpeed
  label: string
}

export interface StationFilterContent {
  reset: () => void
}

const speedList: SpeedOption[] = [
  {
    speed: SpeedType.all,
    label: 'All',
  },
  {
    speed: SpeedType.slow,
    label: 'Slow 1-22 kW',
  },
  {
    speed: SpeedType.fast,
    label: 'Fast 23-99 kW',
  },
  {
    speed: SpeedType.ultraFast,
    label: 'Ultra-Fast 100+ kW',
  },
]

const Component = (
  { show, withResetButton }: IStationFilterProps,
  ref: ForwardedRef<StationFilterContent>
) => {
  const { t } = useTranslation()

  const styles = useStyles()

  const initialFilter = stationsSelectorHooks.getInitialFilter()
  const globalFilter = stationsSelectorHooks.getFilterData()
  const connectors = stationsSelectorHooks.getConnectors()

  const { location } = useLocation()

  const [filter, setFilter] = useState(initialFilter)
  const [checkboxes, setCheckboxes] = useState<Checkboxes | null>(null)

  useEffect(() => {
    if (!globalFilter) return

    setFilter(globalFilter)
    setCheckboxes({
      anyDistance: !globalFilter?.distanceMax,
      anyPrice: !globalFilter?.maxPrice,
    })
  }, [globalFilter])

  useImperativeHandle(ref, () => ({ reset }))

  const reset = () => {
    setFilter(initialFilter)
    setCheckboxes({ anyDistance: true, anyPrice: true })
  }

  const onAvailablePress = () => {
    setFilter((filter) => ({ ...filter, available: !filter.available }))
  }

  const onConnectorPress = (connectorId: string) => {
    setFilter((filter) => {
      const isSelected = filter.connectors.get(connectorId)
      const connectors = new Map(filter.connectors).set(connectorId, !isSelected)

      return { ...filter, connectors }
    })
  }

  const onSpeedPress = (speed?: Station.FilterSpeed) => {
    setFilter((filter) => ({ ...filter, speed }))
  }

  const onDistanceChange = ({ toValue }: OnChange) => {
    setFilter((filter) => ({ ...filter, distanceMax: toValue }))
  }

  const onPriceChange = ({ fromValue, toValue }: OnChange) => {
    setFilter((filter) => ({ ...filter, maxPrice: toValue, minPrice: fromValue }))
  }

  const toggleCheckboxes = (field: keyof Checkboxes) => {
    return (value: boolean) => {
      setCheckboxes((checkboxes) => checkboxes && { ...checkboxes, [field]: value })

      if (field === 'anyDistance') {
        const { distanceMax, ...rest } = filter
        setFilter(value ? rest : filter)
      } else if (field === 'anyPrice') {
        const { minPrice, maxPrice, ...rest } = filter
        setFilter(value ? rest : filter)
      }
    }
  }

  if (!filter) return null

  const renderConnector = (item: ConnectorType) => {
    const { connectorTypeId, icon, name } = item

    const isChecked = !!filter.connectors.get(connectorTypeId)

    const onPress = () => onConnectorPress(connectorTypeId)

    return (
      <Box key={connectorTypeId} fullWidth gap={'m'} justifyContent={'flex-start'}>
        <ConnectorImage icon={icon} style={styles.connector} />
        <Switcher label={name} checked={isChecked} onCheck={onPress} style={styles.switcher} />
      </Box>
    )
  }

  const renderSpeedOption = ({ item }: { item: SpeedOption }) => {
    const { speed, label } = item

    const isSelected = isEqual(speed, filter.speed)

    return (
      <TouchableOpacity
        onPress={() => onSpeedPress(speed)}
        style={[styles.option, isSelected && styles.selected]}
      >
        <Typography text={label} color="white" />
      </TouchableOpacity>
    )
  }

  return (
    <ScrollView contentContainerStyle={styles.container}>
      <Paper>
        <Switcher
          label={t('filters:hideBusy')}
          checked={!!filter.available}
          onCheck={onAvailablePress}
        />
      </Paper>
      <Paper label={t('connector:type')}>
        <Box direction="column" my={-6}>
          {connectors.map(renderConnector)}
        </Box>
      </Paper>
      <Paper label={t('filters:speed')} style={styles.speed}>
        <FlatList
          horizontal
          data={speedList}
          renderItem={renderSpeedOption}
          showsHorizontalScrollIndicator={false}
          contentContainerStyle={[styles.list, styles.gap]}
        />
      </Paper>
      {!!location && (
        <Paper label={t('filters:distance')} style={styles.gap}>
          {!checkboxes?.anyDistance && (
            <Slider
              min={0}
              step={1}
              max={100}
              initialToValue={filter.distanceMax}
              onChange={onDistanceChange}
            />
          )}
          <Checkbox
            value={checkboxes?.anyDistance}
            label={t('filters:anyDistance')}
            onChange={toggleCheckboxes('anyDistance')}
          />
        </Paper>
      )}
      <Paper label={t('filters:price')} style={styles.gap}>
        {!checkboxes?.anyPrice && (
          <Slider
            initialFromValue={filter.minPrice}
            initialToValue={filter.maxPrice}
            min={0}
            max={100}
            step={1}
            range
            onChange={onPriceChange}
          />
        )}
        <Checkbox
          value={checkboxes?.anyPrice}
          label={t('filters:anyPrice')}
          onChange={toggleCheckboxes('anyPrice')}
        />
      </Paper>
      <View style={[styles.buttons, withResetButton && styles.row]}>
        <Button
          flex
          onPress={() => show(filter)}
          label={t('buttons:show')}
          startIcon={{ family: 'Feather', name: 'filter' }}
        />
        {withResetButton && (
          <Button
            flex
            onPress={reset}
            label={t('transaction:filter.resetAll')}
            startIcon={{ family: 'Feather', name: 'repeat' }}
          />
        )}
      </View>
    </ScrollView>
  )
}

export const StationFilterContent = forwardRef(Component)
