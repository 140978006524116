import { IOptions, IUseWrapper } from '../../../../core/helpers/withWrapper'
import { useTheme } from '../../../../core/theme'
import { useState } from 'react'

export const useSelectorWithModalWrapper: IUseWrapper = (options?: IOptions) => {
  const { useStyles } = options!
  const theme = useTheme()
  const styles = useStyles()
  const [selectedString, setSelectedString] = useState('')
  const [modalVisible, setModalVisible] = useState(false)
  const [inputValue, setInputValue] = useState('')

  return {
    styles,
    theme,
    setSelectedString,
    modalVisible,
    setModalVisible,
    inputValue,
    setInputValue,
    selectedString,
  }
}
