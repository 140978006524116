import { withStyles } from '../../../../core/theme'

export const useStyles = withStyles((theme, insets, width) => ({
  container: {
    flex: 1,
    padding: theme.spacing.m,
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: insets.bottom || theme.spacing.m,
  },
  loading: {
    justifyContent: 'center',
  },
  spinner: {
    zIndex: 1,
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
  },
  form: {
    width,
  },
}))
