import { Spacer, Typography } from '../common'
import { TextInput, View } from 'react-native'
import React from 'react'
import { useTranslation } from 'react-i18next'

interface IRatingProps {
  styles: any
  starsRate: any
  amount: any
  selectedRating: any
  setInputValue: any
  inputValue: any
}

export const Rating = ({
  styles,
  starsRate,
  amount,
  selectedRating,
  setInputValue,
  inputValue,
}: IRatingProps) => {
  const { t } = useTranslation()

  return (
    <View style={{ width: '100%' }}>
      <Typography text={t('feedback:suggestion')} bold />
      <Spacer top={'m'} />
      {starsRate(amount, selectedRating)}
      <Spacer top={'m'} />
      <Typography text={t('feedback:comment')} bold />
      <Spacer top={'m'} />
      <TextInput
        style={styles.textInput}
        multiline
        maxLength={200}
        onChangeText={(val) => setInputValue(val)}
        value={inputValue}
      />
      <Spacer top={'s'} />
      <Typography text={`${inputValue.length}/200`} fontWeight={'100'} size={'m'} align={'right'} />
    </View>
  )
}
