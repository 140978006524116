import { StyleSheet, View } from 'react-native'
import ContentLoader, { Rect, Circle } from 'react-content-loader/native'

import { theme } from '../../../../../core/theme/themes'

export const ConnectorLoader = () => (
  <View style={styles.container}>
    <View style={styles.left}>
      <ContentLoader height={40} speed={1.5} backgroundColor="#bfbfbf" foregroundColor="#e3e3e3">
        <Circle cx="20" cy="20" r="20" />
        <Rect x="110" y="23" rx="3" ry="3" width="66" height="12" />
        <Rect x="48" y="23" rx="3" ry="3" width="50" height="12" />
        <Rect x="49" y="2" rx="3" ry="3" width="50" height="12" />
      </ContentLoader>
    </View>
    <View style={styles.right}>
      <ContentLoader height={40} speed={1.5} backgroundColor="#bfbfbf" foregroundColor="#e3e3e3">
        <Rect y="16" rx="3" ry="3" width="40" height="12" />
      </ContentLoader>
    </View>
  </View>
)

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#DDEAF3',
    paddingVertical: theme.spacing.m,
    paddingHorizontal: theme.spacing.s,
    justifyContent: 'space-between',
    flexDirection: 'row',
    borderRadius: 16,
  },
  left: {
    flex: 0.9,
  },
  right: {
    flex: 0.1,
  },
})
