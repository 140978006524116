import './core/helpers/i18n'

import '@formatjs/intl-getcanonicallocales/polyfill'
import '@formatjs/intl-locale/polyfill'
import '@formatjs/intl-displaynames/polyfill'
import '@formatjs/intl-displaynames/locale-data/en'
import '@formatjs/intl-displaynames/locale-data/ru'
import '@formatjs/intl-displaynames/locale-data/ar'
import '@formatjs/intl-displaynames/locale-data/he'
import { Provider as StoreProvider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { useEffect } from 'react'
import store, { persistor } from './core/store/store'

import { Router } from './app/router'
import { Platform, StatusBar, StatusBarStyle } from 'react-native'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import FlashMessage, { Position } from 'react-native-flash-message'
import SplashScreen from 'react-native-splash-screen'

import 'dayjs/locale/en'

import { GestureHandlerRootView } from 'react-native-gesture-handler'
import { NotificationsController, DataController, StationController } from './app/controllers'
import { WebSocketProvider } from './app/providers/websocket'
import { LocationProvider } from './app/providers/location'
import { ChargingProvider } from './app/providers/charging'
import { AuthProvider } from './app/providers/auth'
import { theme } from './core/theme/themes'

export const Application = () => {
  const style = Platform.select({ web: { maxWidth: 320, borderRadius: 8 } })

  const barStyle = Platform.select<StatusBarStyle>({
    ios: 'dark-content',
    default: 'light-content',
  })

  const position = Platform.select<Position>({
    web: { top: 80, right: 10, left: undefined },
    default: 'bottom',
  })

  useEffect(() => {
    SplashScreen?.hide()
    FlashMessage.setColorTheme({
      success: theme.colors.primary,
      info: theme.colors.info,
      warning: theme.colors.warning,
      danger: theme.colors.danger,
    })
  }, [])

  return (
    <>
      <StatusBar animated backgroundColor={theme.colors.primary} barStyle={barStyle} />
      <SafeAreaProvider>
        <GestureHandlerRootView style={{ flex: 1 }}>
          <LocationProvider>
            <StoreProvider store={store}>
              <StationController />
              <PersistGate persistor={persistor}>
                <AuthProvider>
                  <WebSocketProvider>
                    <ChargingProvider>
                      <Router />
                    </ChargingProvider>
                  </WebSocketProvider>
                  <NotificationsController />
                  <DataController />
                </AuthProvider>
                <FlashMessage position={position} icon="auto" style={style} />
              </PersistGate>
            </StoreProvider>
          </LocationProvider>
        </GestureHandlerRootView>
      </SafeAreaProvider>
    </>
  )
}
