import { View } from 'react-native'
import { useTranslation } from 'react-i18next'

import { Button, Divider, Paper } from '../../components'
import { RouterNames } from '../../../core/constants'

import { useStyles } from './styles'

export const SupportScreen = ({ navigation }: ScreenProps<RouterNames.support>) => {
  const { navigate } = navigation

  const { t } = useTranslation()

  const styles = useStyles()

  return (
    <View style={styles.container}>
      <Paper style={styles.paper}>
        <Button
          label={t('support:request')}
          fullWidth
          startIcon={{ name: 'mail', family: 'Feather' }}
          endIcon={{ name: 'chevron-right', family: 'Feather' }}
          variant="menu"
          align="left"
          iconSize={24}
          onPress={() => navigate(RouterNames.supportRequest)}
        />
        <Divider vertical="l" />
        <Button
          label={t('support:feedback')}
          fullWidth
          startIcon={{ name: 'smile', family: 'Feather' }}
          endIcon={{ name: 'chevron-right', family: 'Feather' }}
          variant="menu"
          align="left"
          iconSize={24}
          onPress={() => navigate(RouterNames.sendFeedback)}
        />
        <Divider vertical="l" />
        <Button
          label={t('support:faq')}
          fullWidth
          startIcon={{ name: 'help-circle', family: 'Feather' }}
          endIcon={{ name: 'chevron-right', family: 'Feather' }}
          variant="menu"
          align="left"
          iconSize={24}
          onPress={() => navigate(RouterNames.faq)}
        />
      </Paper>
    </View>
  )
}
