import { Platform, SafeAreaView, View } from 'react-native'

import { DefaultWrapperProps, withWrapper } from '../../../core/helpers/withWrapper'
import { withStyles } from '../../../core/theme'
import { Button, Paper, Spacer } from '../../components'
import { LanguagesModal } from '../Languages'

import { useSettingsScreenWrapper } from './wrapper'
import { Switcher } from '../../components/common/Switcher'

const useStyles = withStyles((theme) => ({
  contentContainer: {
    paddingHorizontal: theme.spacing.m,
    paddingTop: theme.spacing.xs,
  },
  scroll: {
    flex: 1,
    backgroundColor: theme.colors.backgroundOffset,
  },
}))

interface Notifications {
  emailSubscription: boolean
  statusSubscription: boolean
}

interface ISettingsScreen extends DefaultWrapperProps {
  styles: ReturnType<typeof useStyles>
  loader: Notifications
  languagesShown: boolean
  showLanguages: () => void
  setLanguagesShown: (value: boolean) => void
  notifications: Notifications
  toggleNotification: (key: keyof Notifications) => () => void
}

const SettingsScreen = ({
  t,
  styles,
  i18n,
  loader,
  toggleNotification,
  languagesShown,
  showLanguages,
  setLanguagesShown,
  notifications: { emailSubscription, statusSubscription },
}: ISettingsScreen) => (
  <SafeAreaView style={styles.scroll}>
    <View style={styles.contentContainer}>
      <Spacer vertical={'s'} />
      <Paper label={t('notifications:push')}>
        <Switcher
          checked={statusSubscription}
          loading={loader.statusSubscription}
          label={t('notifications:chargingStatus')}
          onCheck={toggleNotification('statusSubscription')}
        />
      </Paper>
      <Spacer vertical={'s'} />
      <Paper label={t('notifications:email')}>
        <Switcher
          checked={emailSubscription}
          loading={loader.emailSubscription}
          label={t('notifications:summary')}
          onCheck={toggleNotification('emailSubscription')}
        />
      </Paper>
      <Spacer vertical={'s'} />
      <Paper label={t('languages:title')}>
        <Spacer vertical={'xs'} />
        <Button
          label={t(`languages:original.${i18n.language}`)}
          fullWidth
          startIcon={{ name: 'globe', family: 'Feather' }}
          endIcon={{ name: 'chevron-right', family: 'Feather' }}
          variant="menu"
          align="left"
          iconSize={28}
          onPress={showLanguages}
        />
        <Spacer vertical={'xs'} />
      </Paper>
    </View>
    {Platform.OS === 'web' && (
      <LanguagesModal isVisible={languagesShown} setVisible={setLanguagesShown} />
    )}
  </SafeAreaView>
)

export default withWrapper(SettingsScreen, useSettingsScreenWrapper, {
  useStyles,
})
