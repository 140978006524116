import { all, call, put, takeLatest } from 'redux-saga/effects'
import * as CONSTANTS from './constants'

import { Card, Rfid } from '../../models'
import { WalletAPI } from '../../api/wallet'
import { setIsLoading, setPaymentMethods } from './actions'

function* getPaymentMethodsSaga() {
  yield put(setIsLoading(true))
  try {
    const [cards, rfids]: [Card[], Rfid[]] = yield all([
      call(WalletAPI.getCards),
      call(WalletAPI.getRfids),
    ])

    yield put(setPaymentMethods({ rfids, cards }))
  } finally {
    yield put(setIsLoading(false))
  }
}

export function* paymentSagas(): Generator<any> {
  yield all([yield takeLatest(CONSTANTS.GET_PAYMENT_METHODS, getPaymentMethodsSaga)])
}
