import { api } from '../setup'
import * as CONSTANTS from './constants'
import { IVehiclesApi } from './types'

export const vehiclesApi: IVehiclesApi = {
  getManufacturers: async () => {
    const { data } = await api.get(CONSTANTS.MANUFACTURER)
    return data
  },
  getModels: async (id) => {
    const { data } = await api.get(`${CONSTANTS.MANUFACTURER}/${id}/model`)
    return data
  },
  getYears: async (id) => {
    const { data } = await api.get(`${CONSTANTS.YEARS}/${id}/year`)
    return data
  },
  getVersions: async (id, year) => {
    const { data } = await api.get(`${CONSTANTS.YEARS}/${id}/year/${year}/version`)
    return data
  },
  addVehicle: async (payload) => {
    await api.post(`${CONSTANTS.ADD_VEHICLE}`, {
      ...payload,
      color: payload.color.toLowerCase(),
    })
  },
  getCars: async () => {
    const { data } = await api.get(`${CONSTANTS.CARS}`)
    return data
  },
  deleteVehicle: async (vehicleId) => {
    await api.post(`${CONSTANTS.DELETE_VEHICLE}`, { vehicleId })
  },
  setPrimaryCar: async (payload) => {
    await api.post(CONSTANTS.SET_PRIMARY_CAR, { vehicleID: payload })
  },
  getVehicle: async (vehicleId) => {
    const { data } = await api.get(CONSTANTS.VEHICLE(vehicleId))
    return data
  },
  getVersion: async (versionId) => {
    const { data } = await api.get(CONSTANTS.VERSION(versionId))
    return data
  },
  getVehicleDetails: async (vehicleId) => {
    const { data } = await api.get(CONSTANTS.VEHICLE_DETAILS(vehicleId))
    return data
  },
}
