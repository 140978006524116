import { useEffect, useMemo, useRef, useState } from 'react'
import MapView, { Marker, PROVIDER_GOOGLE, Region } from 'react-native-maps'

import { useLocation } from '../../../providers/location'

import { Controls } from '../controls'
import { MapProps } from '../types'

import { Clusters } from './clusters'
import { useStyles } from './styles'

export const Map = ({
  position,
  stations,
  onMapPress,
  onStationsPress,
  onListView,
  disabled,
}: MapProps) => {
  const [shouldFollowUser, setShouldFollowUser] = useState(false)
  const [region, setRegion] = useState<Region | null>(null)

  const map = useRef<MapView>(null)

  const { location } = useLocation()

  const styles = useStyles()

  const initialRegion = useMemo<Region | undefined>(() => {
    const place = position ?? location
    const delta = position ? 5e-3 : 10

    if (place) {
      return { ...place, latitudeDelta: delta, longitudeDelta: delta }
    }
  }, [location, position])

  useEffect(() => {
    setRegion(region)
  }, [initialRegion])

  const onTractionPress = () => {
    if (!initialRegion) return

    setShouldFollowUser((shouldFollow) => !shouldFollow)
    map.current?.animateToRegion(initialRegion, 300)
  }

  return (
    <>
      <MapView
        ref={map}
        onPress={onMapPress}
        provider={PROVIDER_GOOGLE}
        showsUserLocation={shouldFollowUser}
        showsMyLocationButton={false}
        initialRegion={initialRegion}
        rotateEnabled={false}
        scrollEnabled={!disabled}
        zoomEnabled={!disabled}
        zoomTapEnabled={!disabled}
        style={styles.container}
        onRegionChangeComplete={setRegion}
      >
        {position && <Marker coordinate={position} />}
        {stations && <Clusters region={region} stations={stations} onPress={onStationsPress} />}
      </MapView>
      {!disabled && (
        <Controls
          crosshairEnabled={shouldFollowUser}
          onCrosshairPress={onTractionPress}
          onLayersPress={onListView}
        />
      )}
    </>
  )
}
