import React from 'react'
import { View } from 'react-native'
import { withWrapper } from '../../../../core/helpers/withWrapper'
import PhoneInput from 'qem-react-native-phone-number-input'
import { getCountry } from 'react-native-localize'

import { usePhoneNumberWrapper } from './wrapper'
import { SUPPORTED_COUNTRIES } from '../../../../core/config'
import { withStyles } from '../../../../core/theme'
import { Box, Typography } from '../../common'
import { TFunction } from 'react-i18next'
import { TranslationLanguageCode } from 'qem-react-native-country-picker-modal'
import { Icon } from '../../icon'

const useStyles = withStyles((theme) => ({
  container: {
    backgroundColor: theme.colors.background,
    borderColor: theme.colors.border,
    borderWidth: 2,
    overflow: 'hidden',
    borderRadius: theme.spacing.s,
    width: '100%',
    height: 60,
  },
  active: {
    borderColor: theme.colors.primary,
  },
  error: {
    borderColor: theme.colors.danger,
  },
  flag: {
    backgroundColor: theme.colors.background,
    marginRight: -15,
    width: 80,
  },
  text: {
    backgroundColor: theme.colors.background,
    paddingHorizontal: theme.spacing.s,
  },
  textInput: {
    color: theme.colors.foreground,
    height: 60,
    width: '100%',
  },
  codeInput: {
    color: theme.colors.foreground,
  },
}))

export interface IPhoneNumberProps {
  label?: string
  onChange?: (text: string) => void
  value?: string
  handleBlur?: () => void
  showMessage?: string
  outsideRef?: React.RefObject<PhoneInput>
}

interface IPhoneNumber extends IPhoneNumberProps {
  phoneInput: React.RefObject<PhoneInput>
  value: string
  handleChange: (value: string) => void
  handleFocus: () => void
  setFormattedValue: (value: string) => void
  darkTheme: boolean
  styles: ReturnType<typeof useStyles>
  valid: boolean
  validate: () => void
  formattedValue: string
  isShowMessage: boolean
  t: TFunction
  focused: boolean
  getCountrySelectorLanguage: () => TranslationLanguageCode
}

const PhoneNumber = ({
  darkTheme,
  phoneInput,
  label,
  setFormattedValue,
  handleChange,
  styles,
  value,
  validate,
  isShowMessage,
  handleFocus,
  valid,
  focused,
  t,
  outsideRef,
  getCountrySelectorLanguage,
}: IPhoneNumber) => (
  <View style={{ width: '100%' }}>
    {label && (
      <Box justifyContent="flex-start" alignItems="flex-start" px="m" pb="s">
        <Typography text={label} color={!valid ? 'danger' : undefined} />
      </Box>
    )}
    <PhoneInput
      ref={(node) => {
        phoneInput.current = node
        if (outsideRef) {
          outsideRef.current = node
        }
      }}
      defaultValue={value}
      defaultCode={getCountry() as any}
      layout="first"
      placeholder="(xxx) xxxx-xxxx"
      onChangeText={handleChange}
      onChangeFormattedText={setFormattedValue}
      withDarkTheme={darkTheme}
      containerStyle={[styles.container, focused && styles.active, !valid && styles.error]}
      textInputProps={{
        onBlur: validate,
        blurOnSubmit: true,
        keyboardType: 'numeric',
        onFocus: handleFocus,
      }}
      renderDropdownImage={<Icon family={'Feather'} name="chevron-down" size={10} />}
      flagButtonStyle={styles.flag}
      textContainerStyle={styles.text}
      textInputStyle={styles.textInput}
      codeTextStyle={styles.codeInput}
      countryPickerProps={{
        withAlphaFilter: false,
        countryCodes: SUPPORTED_COUNTRIES,
        translation: getCountrySelectorLanguage(),
        withEmoji: false,
        withFlag: true,
        renderFlagButton: undefined,
      }}
    />
    {(isShowMessage || !valid) && (
      <Box direction="column" px="m" pt="s" justifyContent="flex-start" alignItems="flex-start">
        <Typography text={t('forms:phonenumber.messages.error')} color="danger" />
      </Box>
    )}
  </View>
)

export default withWrapper<IPhoneNumberProps>(PhoneNumber, usePhoneNumberWrapper, { useStyles })
