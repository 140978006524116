import AsyncStorage from '@react-native-async-storage/async-storage'

export class Storage {
  static setItem = <T>(key: string, value: T) => {
    const objStr = JSON.stringify(value)

    return AsyncStorage.setItem(key, objStr)
  }

  static getItem = async <T>(key: string) => {
    const objStr = await AsyncStorage.getItem(key)

    return objStr ? (JSON.parse(objStr) as T) : null
  }

  static removeItem = (key: string) => {
    return AsyncStorage.removeItem(key)
  }
}
