import { useEffect } from 'react'
import { StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native'
import { DefaultWrapperProps, withWrapper } from '../../../../core/helpers/withWrapper'
import { withStyles } from '../../../../core/theme'
import { useSelectorWithModalWrapper } from './wrapper'
import { ITheme } from '../../../../core/theme/themes'
import Box from '../Box'
import Typography from '../Typography'
import Spacer from '../Spacer'
import { SelectorModal } from '../../selector'
import { Icon } from '../../icon'

interface ISelectorWithModalProps {
  data: string[]
  placeholder?: string
  onSelectItem: (item: string) => void
  onSearchInput: (inputString: string) => void
  leftIcon?: string
  rightIcon?: string
  noItemSelectedString: string
  autoSelect?: boolean
  label?: string
  selectorLeftIcon?: string
  customStyle?: StyleProp<ViewStyle>
  colors?: boolean
}

interface ISelectorWithModal extends ISelectorWithModalProps, DefaultWrapperProps {
  styles: ReturnType<typeof useStyles>
  theme: ITheme
  selectedString: string
  setSelectedString: (value: string) => void
  modalVisible: boolean
  setModalVisible: (value: boolean) => void
  inputValue: string
  setInputValue: (value: string) => void
  textHandler: (data: { noItemSelectedString: string }) => string
}

const useStyles = withStyles((theme) => ({
  icons: {
    marginHorizontal: theme.spacing.m,
  },
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    backgroundColor: theme.colors.backgroundOffset,
    paddingVertical: theme.spacing.m,
    paddingHorizontal: theme.spacing.m,
  },
  currentBrand: {
    height: theme.spacing.xl * 1.2,
    backgroundColor: theme.colors.background,
    borderColor: '#DDEAF3',
    borderWidth: 1,
    overflow: 'hidden',
    borderRadius: theme.spacing.s,
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: theme.spacing.xs,
    flexDirection: 'row',
  },
  colorView: {
    width: 20,
    height: 20,
    borderRadius: 10,
    borderWidth: 1,
    borderColor: theme.colors.placeholder,
  },
  selectorButton: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  btnWithoutIcons: {
    marginLeft: theme.spacing.m,
  },
}))

const SelectorWithModal = ({
  t,
  styles,
  data,
  placeholder,
  onSelectItem,
  onSearchInput,
  leftIcon,
  rightIcon,
  noItemSelectedString,
  setSelectedString,
  modalVisible,
  setModalVisible,
  inputValue,
  setInputValue,
  autoSelect,
  label,
  selectedString,
  theme,
  selectorLeftIcon,
  customStyle,
  colors,
}: ISelectorWithModal) => {
  useEffect(() => {
    autoSelect && setSelectedString(data[0])
  }, [data])

  return (
    <>
      {label && (
        <Box justifyContent="flex-start" alignItems="flex-start" px="m" pb="s">
          <Typography text={label} size="m" />
        </Box>
      )}
      <TouchableOpacity
        onPress={() => setModalVisible(true)}
        style={[styles.currentBrand, customStyle && customStyle]}
      >
        <View style={[styles.selectorButton, !leftIcon && styles.btnWithoutIcons]}>
          {leftIcon && (
            <Icon
              family={'Feather'}
              name={leftIcon}
              size={25}
              color={theme.colors.primary}
              onPress={() => setModalVisible(false)}
              style={styles.icons}
            />
          )}
          {colors && !!selectedString && (
            <>
              <View style={[styles.colorView, { backgroundColor: selectedString }]} />
              <Spacer right={'s'} />
            </>
          )}
          <Typography
            text={selectedString ? t(`carColors:maps.${selectedString}`) : noItemSelectedString}
            fontWeight={'500'}
          />
        </View>
        {rightIcon && (
          <Icon
            family={'Feather'}
            name={rightIcon}
            size={25}
            color={theme.colors.text}
            style={styles.icons}
          />
        )}
      </TouchableOpacity>
      <SelectorModal
        data={data}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        inputValue={inputValue}
        setInputValue={setInputValue}
        onSearchInput={onSearchInput}
        placeholder={placeholder}
        setSelectedString={setSelectedString}
        onSelectItem={onSelectItem}
        colors={colors}
        selectorLeftIcon={selectorLeftIcon}
      />
    </>
  )
}

export default withWrapper<ISelectorWithModalProps>(
  SelectorWithModal,
  useSelectorWithModalWrapper,
  {
    useStyles,
  }
)
