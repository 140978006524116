import { useNavigation, useNavigationState } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import { IOptions, IUseWrapper } from '../../../core/helpers/withWrapper'
import * as Yup from 'yup'
import { useMemo, useState } from 'react'
import { useFormik } from 'formik'
import { vehiclesActionHooks } from '../../../core/store/vehicles'
import { RouterNames } from '../../../core/constants'
import { showMessage } from 'react-native-flash-message'
import { TFunction } from 'i18next'

const CarSchema = (t: TFunction) =>
  Yup.object().shape({
    make: Yup.string().required(t('forms:common.required')),
    model: Yup.string().required(t('forms:common.required')),
    year: Yup.string().required(t('forms:common.required')),
    color: Yup.string().required(t('forms:common.required')),
    number: Yup.string().required(t('forms:common.required')),
  })

const CUSTOM_VEHICLE_VERSION_ID = 'ffc79933-d764-467f-b481-1af2901deca7'

export const useAddOwnCarScreenWrapper: IUseWrapper = (options?: IOptions) => {
  const { useStyles } = options!
  const { t } = useTranslation()
  const styles = useStyles()
  const { navigate } = useNavigation()
  const [search, setSearch] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const addVehicle = vehiclesActionHooks.addVehicle()

  const routes = useNavigationState((state) => state.routes)

  const car = useFormik({
    initialValues: {
      make: '',
      model: '',
      year: '',
      color: '',
      number: '',
    },
    validationSchema: CarSchema(t),
    onSubmit: (values) => {
      setIsLoading(true)
      const { make, model, year, color, number } = values
      addVehicle({
        vehicleVersionId: CUSTOM_VEHICLE_VERSION_ID,
        color,
        vehicleRegistration: number,
        userDefinedVehicleVersion: {
          year,
          vehicleModelName: model,
          vehicleManufacturerName: make,
        },
        onSuccess() {
          setIsLoading(false)
          showMessage({
            type: 'success',
            message: t('cars:addCar.message.success.title'),
            description: t('cars:addCar.message.success.description'),
          })
          const station = routes.find((r) => r.name === RouterNames.station)
          navigate(
            station ? RouterNames.station : RouterNames.cars,
            station?.params as { stationId: string }
          )
        },
        onError() {
          setIsLoading(false)
          showMessage({
            type: 'danger',
            message: t('cars:addCar.message.error.title'),
            description: t('cars:addCar.message.error.description'),
          })
        },
      })
    },
  })

  const isButtonEnabled = useMemo(() => {
    return Object.values(car.values).reduce((acc, curr) => acc && !!curr, true)
  }, [car.values])

  const colors = t('carColors:names')
    .split(',')
    .filter((i) =>
      t(`carColors:maps.${i.toLowerCase()}`).toLowerCase().includes(search.toLowerCase())
    )

  return {
    t,
    styles,
    car,
    colors,
    isLoading,
    isButtonEnabled,
    setSearch,
  }
}
