import { DefaultWrapperProps, withWrapper } from '../../../../core/helpers/withWrapper'
import { withStyles } from '../../../../core/theme'
import ModalContainer from '../../../components/common/ModalContainer'
import { TransactionFilterContent } from '../content'
import { useTransactionFilterModalWrapper } from './wrapper'

const useStyles = withStyles(() => ({
  container: {
    width: 600,
  },
}))

interface ITransactionFilterModalProps {
  isVisible: boolean
  setVisible: (value: boolean) => void
}

interface ItransactionFilterModal extends ITransactionFilterModalProps, DefaultWrapperProps {
  styles: ReturnType<typeof useStyles>
}

const Component = ({ styles, setVisible, ...rest }: ItransactionFilterModal) => (
  <ModalContainer contentStyles={styles.container} {...rest} setVisible={setVisible}>
    <TransactionFilterContent onFilter={() => setVisible(false)} />
  </ModalContainer>
)

export const TransactionFilterModal = withWrapper<ITransactionFilterModalProps>(
  Component,
  useTransactionFilterModalWrapper,
  { useStyles }
)
