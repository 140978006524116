export const GET_MANUFACTURERS = 'GET_MANUFACTURERS'
export const SET_MANUFACTURERS = 'SET_MANUFACTURERS'
export const GET_MODELS = 'GET_MODELS'
export const SET_MODELS = 'SET_MODELS'
export const GET_YEARS = 'GET_YEARS'
export const SET_YEARS = 'SET_YEARS'
export const GET_VERSIONS = 'GET_VERSIONS'
export const SET_VERSIONS = 'SET_VERSIONS'
export const ADD_VEHICLE = 'ADD_VEHICLE'
export const GET_CARS = 'GET_CARS'
export const SET_CARS = 'SET_CARS'
export const DELETE_VEHICLE = 'DELETE_VEHICLE'
export const SET_PRIMARY_CAR = 'SET_PRIMARY_CAR'
export const GET_VEHICLE_DETAILS = 'GET_VEHICLE_DETAILS'
export const SET_VEHICLE = 'SET_VEHICLE'
