import { View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { Avatar, Box, EmptyPlaceholder, Spacer, Typography } from '../../../components'
import Rating from '../../../components/Rating'
import { timeStampToString } from '../../../../core/helpers/date'
import { Review } from '../../../../core/models'
import { useStyles } from './styles'

interface Props {
  reviews: Review[]
}

export const Reviews = ({ reviews }: Props) => {
  const { t } = useTranslation()

  const styles = useStyles()

  const renderItem = (item: Review) => {
    const { reviewId, userItem, rating, createdAt, feedback } = item
    const { firstName, lastName, avatar } = userItem

    const placeholder = firstName[0] + lastName[0]

    return (
      <Box key={reviewId} flex fullWidth direction="column" alignItems="flex-start">
        <Box flex fullWidth>
          <Avatar avatar={avatar} placeholder={placeholder} size={50} />
          <Spacer horizontal={'s'} />
          <Box direction="column" flex fullWidth alignItems="flex-start">
            <Typography size="m" bold text={`${firstName} ${lastName}`} />
            <Spacer vertical={'xs'} />
            <Rating defaultValue={rating} disabled />
          </Box>
          <Typography size={14} fontWeight={'300'} text={timeStampToString(createdAt, 'DD MMM')} />
        </Box>
        <Spacer vertical={'s'} />
        <Typography text={feedback} numberOfLines={4} align="justify" />
      </Box>
    )
  }

  return (
    <>
      <Typography text={t('stations:reviews')} size={'m'} bold />
      {!!reviews.length ? (
        <View style={styles.list}>{reviews.map(renderItem)}</View>
      ) : (
        <EmptyPlaceholder disableIcon text={t('empty:reviews')} style={styles.placeholder} />
      )}
    </>
  )
}
