import { useState } from 'react'
import { Dimensions, FlatList, Platform, Pressable, View } from 'react-native'

import { DefaultWrapperProps, withWrapper } from '../../../../core/helpers/withWrapper'
import { withStyles } from '../../../../core/theme'
import ModalContainer from '../ModalContainer'
import { useRadioModalWrapper } from './wrapper'
import CustomCheckbox from '../CustomCheckbox'
import Typography from '../Typography'
import Spacer from '../Spacer'
import Button from '../Button'
import Divider from '../Divider'

const { height } = Dimensions.get('window')

const useStyles = withStyles((theme, insets, width, isWeb) => ({
  wrapper: {
    ...(isWeb ? { flex: 1 } : { maxHeight: height / 2 }),
    padding: theme.spacing.m,
  },
  item: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: theme.spacing.m,
  },
  footer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
}))

interface IRadioItem {
  id: string
  data: any
}

interface IRadioModalProps {
  addNew: () => void
  isVisible: boolean
  setVisible: (value: boolean) => void
  title: string
  checkedId?: string
  items: Array<IRadioItem>
  onCheck: (id: string) => void
  rowItem: (item: any) => JSX.Element
}

interface IRadioModal extends IRadioModalProps, DefaultWrapperProps {
  styles: ReturnType<typeof useStyles>
}

const RadioModal = ({
  t,
  addNew,
  rowItem,
  styles,
  items,
  onCheck,
  title,
  checkedId,
  ...rest
}: IRadioModal) => {
  const [selectedId, setSelectedId] = useState(checkedId)

  const onSelect = () => {
    selectedId && onCheck(selectedId)
  }

  const renderItem = ({ item }: { item: IRadioItem }) => {
    const { id, data } = item

    const isChecked = id === selectedId

    const onPress = () => {
      setSelectedId(id)
    }

    return (
      <Pressable style={styles.item} onPress={onPress}>
        {rowItem(data)}
        <CustomCheckbox size={30} isChecked={isChecked} onClick={onPress} />
      </Pressable>
    )
  }

  const keyExtractor = ({ id }: IRadioItem, index: number) => {
    return `${id}_${index}`
  }

  return (
    <ModalContainer {...rest}>
      <View style={styles.wrapper}>
        <View>
          <Typography bold text={title} size={20} />
        </View>
        <FlatList
          data={items}
          renderItem={renderItem}
          keyExtractor={keyExtractor}
          ItemSeparatorComponent={Divider}
          showsVerticalScrollIndicator={Platform.OS === 'web'}
        />
        <Spacer vertical={'s'} />
        <View style={styles.footer}>
          <Button flex label={t('buttons:addNew')} onPress={addNew} />
          <Spacer horizontal={'s'} />
          <Button flex label={t('buttons:select')} onPress={onSelect} />
        </View>
      </View>
    </ModalContainer>
  )
}

export default withWrapper<IRadioModalProps>(RadioModal, useRadioModalWrapper, {
  useStyles,
})
