import { useFormik, FormikProps } from 'formik'
import { IOptions, IUseWrapper } from '../../../core/helpers/withWrapper'
import * as Yup from 'yup'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { applicationApi } from '../../../core/api/application'

import { showMessage } from 'react-native-flash-message'
import { Keyboard } from 'react-native'

const FeedbackSchema = (t: TFunction) =>
  Yup.object().shape({
    feedback: Yup.string().max(200).required(t('forms:common.required')),
  })

interface Feedback {
  feedback: string
}

export function generateField<T = Feedback>(formik: FormikProps<T>, key: keyof T) {
  return {
    onChange: (text: string) => {
      formik.setFieldValue(key as string, text)
    },
    handleBlur: () => {
      if (!formik.touched[key]) {
        formik.setFieldTouched(key as string, true)
      }
    },
    value: formik.values[key],
    showMessage: formik.touched[key] ? formik.errors[key] : undefined,
    fullWidth: true,
  }
}

export const useSendFeedbackScreenWrapper: IUseWrapper = (options?: IOptions) => {
  const { useStyles } = options!
  const { t } = useTranslation()
  const styles = useStyles()
  const [rating, setRating] = useState(5)
  const [loading, setLoading] = useState(false)

  const feedback = useFormik({
    initialValues: {
      feedback: '',
    },
    validationSchema: FeedbackSchema(t),
    onSubmit: async (values, { resetForm }) => {
      Keyboard.dismiss()
      try {
        setLoading(true)
        const id = await applicationApi.review({
          rating: rating,
          feedback: values.feedback,
        })
        showMessage({
          message: t('feedback:message.title'),
          description: t('feedback:message.content'),
          type: 'success',
        })
        resetForm()
      } catch (e) {
        showMessage({
          message: t('feedback:error.title'),
          description: t('feedback:error.content'),
          type: 'danger',
        })
      }
      setLoading(false)
    },
  })

  const onRatingChange = (value: number) => {
    setRating(value)
  }

  const onSubmit = async () => {
    feedback.handleSubmit()
  }

  return {
    styles,
    generateField: (key: keyof Feedback) => generateField(feedback, key),
    rating,
    onRatingChange,
    onSubmit,
    loading,
  }
}
