import { useTranslation } from 'react-i18next'
import { useNavigation } from '@react-navigation/native'
import { Card as CardModel } from '../../../core/models'
import { RouterNames } from '../../../core/constants'
import { Carousel } from '../../components/carousel'
import { Card } from '../../components/card'

interface Props {
  data: CardModel[]
  loading?: boolean
}

export const Cards = ({ data, loading }: Props) => {
  const { navigate } = useNavigation()
  const { t } = useTranslation()

  const onItemPress = ({ cardId }: CardModel) => {
    navigate(RouterNames.cardInfo, { id: cardId })
  }

  const renderItem = (item: CardModel) => {
    return <Card showIsDefault showErrorMessage card={item} />
  }

  return (
    <Carousel
      data={data}
      loading={loading}
      renderItem={renderItem}
      onItemPress={onItemPress}
      label={t('card:common.title')}
    />
  )
}
