import { createAction } from '@reduxjs/toolkit'
import { createActionHook, createEmptyActionHook } from '../../helpers/store'
import * as CONSTANTS from './constants'
import {
  GetTransactions,
  SetFilter,
  SetTransactions,
  ResetTransactionFilter,
  SetError,
  GetTransaction,
  GetRecentTransactions,
  ITransaction,
  SetStats,
} from './types'

const getTransactions = createAction<GetTransactions>(CONSTANTS.GET_TRANSACTIONS)
const getRecentTransactions = createAction<GetRecentTransactions>(CONSTANTS.GET_RECENT_TRANSACTIONS)
const getTransaction = createAction<GetTransaction>(CONSTANTS.GET_TRANSACTION)
const getStats = createAction(CONSTANTS.GET_USER_STATS)

const setStats = createAction<SetStats>(CONSTANTS.SET_STATS)
const setTransaction = createAction<ITransaction>(CONSTANTS.SET_TRANSACTION)
const setTransactions = createAction<SetTransactions>(CONSTANTS.SET_TRANSACTIONS)
const setRecentTransactions = createAction<SetTransactions>(CONSTANTS.SET_RECENT_TRANSACTIONS)
const setTransactionsFilter = createAction<SetFilter>(CONSTANTS.SET_FILTER)
const setError = createAction<SetError>(CONSTANTS.SET_ERROR)

const resetTransactionsFilter = createAction<ResetTransactionFilter>(CONSTANTS.RESET_FILTER)

export const transactionsActionHooks = {
  getStats: createEmptyActionHook(getStats),
  getTransactions: createActionHook(getTransactions),
  getTransaction: createActionHook(getTransaction),
  getRecentTransactions: createActionHook(getRecentTransactions),
  setStats: createActionHook(setStats),
  setTransactions: createActionHook(setTransactions),
  setTransactionsFilter: createActionHook(setTransactionsFilter),
  resetTransactionsFilter: createActionHook(resetTransactionsFilter),
  setError: createActionHook(setError),
  setTransaction: createActionHook(setTransaction),
}

export {
  getStats,
  getTransactions,
  getTransaction,
  getRecentTransactions,
  resetTransactionsFilter,
  setStats,
  setError,
  setTransactions,
  setTransaction,
  setTransactionsFilter,
  setRecentTransactions,
}
