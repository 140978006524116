import { all, put, call, takeLatest } from 'redux-saga/effects'
import { stationsApi } from '../../api/stations'
import {
  getFavoriteStations,
  getStations,
  setConnectors,
  setFavoriteStations,
  setStation,
  setStations,
} from './actions'

import * as CONSTANTS from './constants'
import {
  AddFavoriteStation,
  CreateReviewPayload,
  GetStation,
  Payload,
  RemoveFavoriteStation,
} from './types'
import { driverApi } from '../../api/drivers'
import { ConnectorType, Review, Station, StationDetails } from '../../models'

function* getStationsSaga({ payload: { callback, ...data } }: Payload<Station.Filter>) {
  try {
    const stations: Station[] = yield call(stationsApi.getStations, data)

    yield put(setStations(stations))

    if (callback) {
      callback(null, stations)
    }
  } catch (error) {
    if (callback) {
      callback(error)
    }
  }
}

function* getFavoriteStationsSaga({ payload: { callback, ...data } }: Payload<Station.Filter>) {
  try {
    const stations: Station[] = yield call(stationsApi.getFavoriteStations, data)

    yield put(setFavoriteStations(stations))

    callback?.call(null, null)
  } catch (error) {
    callback?.call(null, error)
  }
}

function* getStationSaga({ payload: { stationId, callback } }: Payload<GetStation>) {
  try {
    const [station, price]: [StationDetails, number] = yield all([
      call(stationsApi.getStation, stationId),
      call(stationsApi.getStationPrice, stationId),
    ])

    const reviews: Review[] = yield all(
      (station.reviews || []).map(function* ({ userItem, ...rest }) {
        const avatar: string = yield call(driverApi.getDriverImageById, userItem.userId)
        return { ...rest, userItem: { ...userItem, avatar } }
      })
    )

    yield put(setStation({ ...station, price, reviews }))
    callback && callback(null, station)
  } catch (error) {
    yield put(setStation(null))
    callback && callback(error)
  }
}

function* addFavoriteStationSaga({ payload }: Payload<AddFavoriteStation>) {
  try {
    const station: Station = yield call(stationsApi.addFavoriteStation, payload)

    yield put(getStations({}))
    yield put(getFavoriteStations({}))

    if (payload.callback) {
      payload.callback(null, station)
    }
  } catch (error) {
    if (payload.callback) {
      payload.callback(error)
    }
  }
}

function* removeFavoriteStationSaga({ payload }: Payload<RemoveFavoriteStation>) {
  try {
    const station: Station = yield call(stationsApi.removeFavoriteStation, payload)

    yield put(getStations({}))
    yield put(getFavoriteStations({}))

    if (payload.callback) {
      payload.callback(null, station)
    }
  } catch (error) {
    if (payload.callback) {
      payload.callback(error)
    }
  }
}

function* createReviewSaga({ payload }: Payload<CreateReviewPayload>) {
  try {
    const station: Review = yield call(stationsApi.createReview, payload)

    if (payload.callback) {
      payload.callback(null, station)
    }
  } catch (error) {
    if (payload.callback) {
      payload.callback(error)
    }
  }
}

function* getConnectorsSaga({ payload }: Payload<{}>) {
  try {
    const connectors: ConnectorType[] = yield call(stationsApi.getConnectors)

    yield put(setConnectors(connectors))
  } catch (error) {
    if (payload.callback) {
      payload.callback(error)
    }
  }
}

export function* stationsSagas(): Generator<any> {
  yield all([
    yield takeLatest<any>(CONSTANTS.GET_STATIONS, getStationsSaga),
    yield takeLatest<any>(CONSTANTS.GET_FAVORITE_STATIONS, getFavoriteStationsSaga),
    yield takeLatest<any>(CONSTANTS.GET_STATION, getStationSaga),
    yield takeLatest<any>(CONSTANTS.ADD_FAVORITE, addFavoriteStationSaga),
    yield takeLatest<any>(CONSTANTS.REMOVE_FAVORITE, removeFavoriteStationSaga),
    yield takeLatest<any>(CONSTANTS.CREATE_REVIEW, createReviewSaga),
    yield takeLatest<any>(CONSTANTS.GET_CONNECTORS, getConnectorsSaga),
  ])
}
