import { withStyles } from '../../../../core/theme'

export const useStyles = withStyles(() => ({
  container: {
    width: 40,
    height: 48,
    alignItems: 'center',
    justifyContent: 'center',
  },
}))
