import { View } from 'react-native'

import { withWrapper } from '../../../core/helpers/withWrapper'
import { withStyles } from '../../../core/theme'
import { GuideList } from '../../components'

import { useUserGuideScreenWrapper } from './wrapper'

const useStyles = withStyles((_, insets) => ({
  container: {
    flex: 1,
    paddingBottom: insets.bottom,
  },
}))

interface IUserGuideScreen {
  styles: ReturnType<typeof useStyles>
}

const UserGuideScreen = ({ styles }: IUserGuideScreen) => (
  <View style={styles.container}>
    <GuideList
      guides={[
        {
          title: 'Header',
          image:
            'https://www.clipartmax.com/png/full/260-2603262_medelita-self-service-tugers-co-madrid.png',
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Neque eget nibh vitae vel ante cursus tincidunt. Morbi vel ullamcorper a dictumst. Risus, eget aliquam feugiat aenean sapien ac sagittis. Lobortis massa sagittis, mauris mauris ultrices."',
        },
        {
          title: 'Header',
          image:
            'https://www.clipartmax.com/png/full/260-2603262_medelita-self-service-tugers-co-madrid.png',
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Neque eget nibh vitae vel ante cursus tincidunt. Morbi vel ullamcorper a dictumst. Risus, eget aliquam feugiat aenean sapien ac sagittis. Lobortis massa sagittis, mauris mauris ultrices."',
        },
        {
          title: 'Header',
          image:
            'https://www.clipartmax.com/png/full/260-2603262_medelita-self-service-tugers-co-madrid.png',
          description:
            'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Neque eget nibh vitae vel ante cursus tincidunt. Morbi vel ullamcorper a dictumst. Risus, eget aliquam feugiat aenean sapien ac sagittis. Lobortis massa sagittis, mauris mauris ultrices."',
        },
      ]}
    />
  </View>
)

export default withWrapper(UserGuideScreen, useUserGuideScreenWrapper, {
  useStyles,
})
