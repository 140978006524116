import { RouteProp, useNavigation, useNavigationState, useRoute } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import { IOptions, IUseWrapper } from '../../../core/helpers/withWrapper'
import { useEffect, useMemo, useState } from 'react'
import { vehiclesActionHooks, vehiclesSelectorHooks } from '../../../core/store/vehicles'
import { showMessage } from 'react-native-flash-message'
import { RouterNames } from '../../../core/constants'
import { RootStackParamList } from '../../router/types'

export interface IVehicleData {
  activeYear: string
  activeVersion: string
  number: string
  color: string
}

export const useYearAndVersionScreenWrapper: IUseWrapper = (options?: IOptions) => {
  const { useStyles } = options!
  const { t } = useTranslation()
  const styles = useStyles()
  const { navigate } = useNavigation()

  const [vehicleData, setVehicleData] = useState<IVehicleData>({} as IVehicleData)

  const setVehicle = (type: keyof IVehicleData) => {
    return (value: IVehicleData[typeof type]) => setVehicleData({ ...vehicleData, [type]: value })
  }

  const [search, setSearch] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const { params } = useRoute<RouteProp<RootStackParamList, RouterNames.yearAndVersion>>()
  const routes = useNavigationState((state) => state.routes)

  const fetchYears = vehiclesActionHooks.getYears()
  const fetchVersions = vehiclesActionHooks.getVersions()
  const addVehicle = vehiclesActionHooks.addVehicle()

  const resetYears = vehiclesActionHooks.setYears()
  const resetVersions = vehiclesActionHooks.setVersions()

  useEffect(() => {
    if (params.id) {
      fetchYears({ id: params.id })
    } else {
      navigate(RouterNames.private, { screen: RouterNames.home })
    }

    return () => {
      resetYears([])
      resetVersions([])
    }
  }, [])

  const years = vehiclesSelectorHooks.getYears()
  const versions = vehiclesSelectorHooks.getVersions()

  const handleListItemClick = (item: string, title: string) => {
    if (title === t('cars:addCar.fields.year')) {
      setVehicleData({ ...vehicleData, activeYear: item })
      if (params.id) {
        fetchVersions({ id: params.id, year: item })
      }
    } else {
      setVehicleData({ ...vehicleData, activeVersion: item })
    }
  }

  const colors = t('carColors:names')
    .split(',')
    .filter((i) =>
      t(`carColors:maps.${i.toLowerCase()}`).toLowerCase().includes(search.toLowerCase())
    )

  const handleAddVehicle = () => {
    const { activeVersion, color, number } = vehicleData
    const { vehicleVersionId } = versions.find((v) => v.vehicleVersionName === activeVersion)!
    setIsLoading(true)
    addVehicle({
      color,
      vehicleVersionId,
      vehicleRegistration: number,
      onSuccess() {
        setIsLoading(false)
        showMessage({
          type: 'success',
          message: t('cars:addCar.message.success.title'),
          description: t('cars:addCar.message.success.description'),
        })
        const station = routes.find((r) => r.name === RouterNames.station)
        navigate(
          station ? RouterNames.station : RouterNames.cars,
          station?.params as { stationId: string }
        )
      },
      onError() {
        setIsLoading(false)
        showMessage({
          type: 'danger',
          message: t('cars:addCar.message.error.title'),
          description: t('cars:addCar.message.error.description'),
        })
      },
    })
  }

  const isButtonEnabled = useMemo(() => {
    const values = Object.values(vehicleData)
    return values.length < 4 ? false : values.reduce((acc, curr) => acc && !!curr, true)
  }, [vehicleData])

  const dataSections = [
    {
      type: t('cars:addCar.fields.year'),
      data: years,
    },
    {
      type: t('cars:addCar.fields.version'),
      data: versions || [],
    },
    {
      type: t('cars:addCar.fields.color'),
      data: ['color'],
    },
    {
      type: t('cars:addCar.fields.number'),
      data: ['number'],
    },
  ]

  return {
    t,
    styles,
    dataSections,
    colors,
    isLoading,
    isButtonEnabled,
    vehicleData,
    setVehicle,
    handleListItemClick,
    handleAddVehicle,
    setSearch,
    addVehicle,
  }
}
