import { useState } from 'react'
import { Platform, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { showMessage } from 'react-native-flash-message'
import { Button, Divider, InformationModal, Spacer } from '../common'
import { notificationActionHooks } from '../../../core/store/notification'
import { accountActionHooks } from '../../../core/store/account'
import { useDeviceType } from '../../../core/hooks'
import { withStyles } from '../../../core/theme'

const useStyles = withStyles((theme) => ({
  container: {
    paddingHorizontal: theme.spacing.s,
    width: '100%',
  },
}))

export const DangerZone = () => {
  const { t } = useTranslation()
  const type = useDeviceType()
  const styles = useStyles()

  const logout = accountActionHooks.logout()
  const deactivateUser = accountActionHooks.deactivateUser()
  const unsubscribe = notificationActionHooks.unsubscribe()

  const [modal, setModal] = useState({
    type: 'logOut',
    title: t('dangerZone:logOut.message'),
    description: t('dangerZone:logOut.description'),
  })
  const [isModalVisible, setModalVisible] = useState(false)

  const handleSubmit = () => {
    setModalVisible(false)
    Platform.OS !== 'web' && unsubscribe()
    setTimeout(() => {
      if (modal.type === 'logOut') {
        logout()
      } else {
        deactivateUser({
          callback: (error) => {
            if (error) {
              showMessage({ message: 'Error', description: t('forms:common.500'), type: 'danger' })
            }
          },
        })
      }
    }, 400)
  }

  const toggleModal = (key: string) => {
    return () => {
      setModalVisible(true)
      setModal({
        type: key,
        title: t(`dangerZone:${key}.message`),
        description: t(`dangerZone:${key}.description`),
      })
    }
  }

  return (
    <>
      <View style={styles.container}>
        {type === 'mobile' && <Spacer vertical={'s'} />}
        <Button
          label={t('profile:danger.menu.logout')}
          fullWidth
          startIcon={{ name: 'log-out', family: 'Feather' }}
          endIcon={{ name: 'chevron-right', family: 'Feather' }}
          variant="menu"
          align="left"
          iconSize={24}
          onPress={toggleModal('logOut')}
        />
        <Divider vertical="l" />
        <Button
          label={t('profile:danger.menu.delete')}
          fullWidth
          startIcon={{ name: 'trash', family: 'Feather' }}
          color="danger"
          endIcon={{ name: 'chevron-right', family: 'Feather' }}
          variant="menu"
          align="left"
          iconSize={24}
          onPress={toggleModal('delete')}
        />
        <Spacer vertical="s" />
      </View>
      <InformationModal
        isVisible={isModalVisible}
        setVisible={setModalVisible}
        text={modal.title}
        description={modal.description}
        bottom
        withButtons={{
          left: { text: t('buttons:no'), onClick: () => setModalVisible(false) },
          right: { text: t('buttons:yes'), onClick: handleSubmit },
        }}
      />
    </>
  )
}
