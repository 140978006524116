import { createReducer } from '@reduxjs/toolkit'

import { setEmailSubscription, setStatusSubscription } from './actions'
import { NotificationState } from './types'

const initialState: NotificationState = {
  emailSubscription: false,
  statusSubscription: false,
}

export const notificationReducer = createReducer(initialState, (builder) => {
  builder.addCase(setEmailSubscription, (state, action) => {
    return { ...state, emailSubscription: action.payload }
  })
  builder.addCase(setStatusSubscription, (state, action) => {
    return { ...state, statusSubscription: action.payload }
  })
})
