import { withStyles } from '../../../../core/theme'

export const useStyles = withStyles(() => ({
  container: {
    width: 500,
    maxWidth: '90%',
    maxHeight: undefined,
  },
  paper: {
    maxHeight: '83vh',
  },
}))
