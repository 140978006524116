import { useCallback, useState } from 'react'
import { ActivityIndicator, View } from 'react-native'
import { useFocusEffect } from '@react-navigation/native'
import { WebView } from 'react-native-webview'

import { WalletAPI } from '../../../../core/api/wallet'
import { useConfirmation } from '../useConfirmation'
import { VerifoneLogo } from '../VerifoneLogo'
import { Footer } from '../Footer'
import { useStyles } from './styles'

const getHtml = (scriptUrl?: string) => {
  if (!scriptUrl) return null

  return `
    <!DOCTYPE html>
    <html>
      <head>
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1, minimum-scale=1" />
      </head>
      <body>
        <div>
          <script defer src="${scriptUrl}"></script>
        </div>
      </body>
    </html>
  `
}

export const AddCardScreen = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [scriptUrl, setScriptUrl] = useState<string>()

  const confirm = useConfirmation()

  const styles = useStyles()

  useFocusEffect(
    useCallback(() => {
      let transactionId: string | null = null

      WalletAPI.getCheckoutForm().then(({ id, url }) => {
        transactionId = id
        setScriptUrl(url)
      })

      return () => {
        transactionId && confirm(transactionId)
      }
    }, [])
  )

  const html = getHtml(scriptUrl)

  return (
    <View style={[styles.container, isLoading && styles.loading]}>
      {!isLoading && <VerifoneLogo />}
      {isLoading && (
        <View style={styles.spinner}>
          <ActivityIndicator size={'large'} />
        </View>
      )}
      {html && (
        <WebView
          source={{ html }}
          style={styles.form}
          scrollEnabled={false}
          onLoadEnd={() => setIsLoading(false)}
        />
      )}
      {!isLoading && <Footer />}
    </View>
  )
}
