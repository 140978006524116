import { withStyles } from '../../../core/theme'

export const useStyles = withStyles((theme, insets) => ({
  container: {
    backgroundColor: theme.colors.backgroundOffset,
  },
  content: {
    paddingHorizontal: theme.spacing.m,
    paddingTop: theme.spacing.s,
    paddingBottom: insets.bottom || theme.spacing.m,
  },
  header: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    paddingVertical: theme.spacing.s,
  },
  button: {
    flex: 1,
  },
}))
