import { useEffect, useRef, useState } from 'react'
import { ActivityIndicator, View } from 'react-native'

import { WalletAPI } from '../../../../core/api/wallet'
import { useConfirmation } from '../useConfirmation'
import { VerifoneLogo } from '../VerifoneLogo'
import { Footer } from '../Footer'
import { useStyles } from './styles'

export const AddCardScreen = () => {
  const [isLoading, setIsLoading] = useState(false)

  const formRef = useRef<HTMLDivElement>(null)

  const confirm = useConfirmation()

  const styles = useStyles()

  const onIframeLoaded = ({ data }: MessageEvent<string>) => {
    if (data?.includes('init')) {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setIsLoading(true)

    let transactionId: string | null = null

    WalletAPI.getCheckoutForm().then(({ id, url }) => {
      transactionId = id

      const script = document.createElement('script')
      script.defer = true
      script.src = url
      formRef.current?.appendChild(script)
    })

    window.addEventListener('message', onIframeLoaded)

    return () => {
      window.removeEventListener('message', onIframeLoaded)

      transactionId && confirm(transactionId)
    }
  }, [])

  return (
    <View style={[styles.container, isLoading && styles.loading]}>
      {!isLoading && <VerifoneLogo />}
      {isLoading && <ActivityIndicator size={'large'} style={styles.spinner} />}
      <div ref={formRef} style={{ width: 500 }} />
      {!isLoading && <Footer />}
    </View>
  )
}
