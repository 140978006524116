import { SafeAreaView, View } from 'react-native'

import { DefaultWrapperProps, withWrapper } from '../../../core/helpers/withWrapper'
import { withStyles } from '../../../core/theme'
import { Button, KeyboardAvoid, Paper, Spacer, TextField } from '../../components'
import { KeyboardDismissView } from '../../components/KeyboardDismissView'
import Rating from '../../components/Rating'
import { useSendFeedbackScreenWrapper } from './wrapper'

const useStyles = withStyles((theme, insets, width, isWeb) => ({
  contentContainer: {
    flex: 1,
    padding: theme.spacing.m,
  },
  wrapper: {
    flex: 1,
    height: '100%',
    backgroundColor: theme.colors.backgroundOffset,
    paddingBottom: isWeb ? 25 : 0,
  },
  rating: {
    width: 240,
    justifyContent: 'space-between',
  },
}))

interface ISendFeedbackScreen extends DefaultWrapperProps {
  styles: ReturnType<typeof useStyles>
  generateField: (key: 'feedback') => any
  rating: number
  onRatingChange: (value: number) => void
  onSubmit: () => Promise<void>
  loading: boolean
}

const SendFeedbackScreen = ({
  styles,
  generateField,
  rating,
  onRatingChange,
  onSubmit,
  t,
  loading,
}: ISendFeedbackScreen) => (
  <SafeAreaView style={styles.wrapper}>
    <KeyboardDismissView>
      <KeyboardAvoid>
        <View style={styles.contentContainer}>
          <Paper style={{ height: '100%' }} containerStyle={{ height: '100%' }}>
            <Rating
              defaultValue={rating}
              size={32}
              onChange={onRatingChange}
              style={styles.rating}
            />
            <Spacer top="m" />
            <TextField
              showSoftInputOnFocus
              maxLength={200}
              label={t('feedback:comments')}
              multiline
              showCounter
              boxSize={120}
              {...generateField('feedback')}
            />
            <Spacer flex />
            <Button label={t('buttons:submit')} onPress={onSubmit} loading={loading} />
          </Paper>
        </View>
      </KeyboardAvoid>
    </KeyboardDismissView>
  </SafeAreaView>
)

export default withWrapper(SendFeedbackScreen, useSendFeedbackScreenWrapper, {
  useStyles,
})
