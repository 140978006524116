import { withStyles } from '../../../core/theme'

const SIZE = 24

export const useStyles = withStyles((theme, insets, _, isWeb) => ({
  container: {
    backgroundColor: 'transparent',
    position: 'absolute',
    left: 0,
    bottom: 0,
    ...(isWeb ? { top: 0 } : { right: 0 }),
  },
  content: {
    paddingBottom: insets.bottom || theme.spacing.m,
    justifyContent: 'center',
    paddingTop: theme.spacing.m,
    borderColor: theme.colors.background,
    borderTopColor: theme.colors.border,
    borderWidth: 1,
    paddingHorizontal: theme.spacing.m,
    backgroundColor: theme.colors.white,
    ...(isWeb
      ? {
          height: '100%',
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          borderRightColor: theme.colors.backgroundOffset,
        }
      : {
          flexDirection: 'row',
          borderTopLeftRadius: theme.spacing.m,
          borderTopRightRadius: theme.spacing.m,
        }),
  },
  item: {
    flex: 1,
    gap: theme.spacing.xs,
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    width: 50,
    height: 50,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: theme.spacing.m,
  },
  badge: {
    position: 'absolute',
    top: -SIZE / 3,
    right: -SIZE / 3,
    width: SIZE,
    height: SIZE,
    borderRadius: SIZE,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors.charging,
  },
}))
