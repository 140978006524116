import { memo, useEffect, useMemo, useRef, useState } from 'react'
import { Linking, Platform, View } from 'react-native'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import {
  NavigationContainer,
  NavigationState,
  NavigationContainerRef,
} from '@react-navigation/native'
import { guestRoutes, linking, privateRoutes, publicRoutes } from './routes'
import { useTheme } from '../../core/theme'
import { BottomTabNavigationOptions, createBottomTabNavigator } from '@react-navigation/bottom-tabs'
import { Header } from './Header'
import { TabBar } from './TabBar'
import { RootStackParamList, TabsParamsList } from './types'
import { SafeAreaProvider } from 'react-native-safe-area-context'
import { RouterNames } from '../../core/constants'
import { Storage } from '../../core/helpers/storage'
import { accountSelectorHooks } from '../../core/store/account'

type Theme = ReturnType<typeof useTheme>

const Stack = createNativeStackNavigator<RootStackParamList>()
const Tab = createBottomTabNavigator<TabsParamsList>()

const wrapComponent = (Component: React.FC, theme: Theme) => {
  const style = { flex: 1, backgroundColor: theme.colors.background }

  return (props: Record<string, any>) => (
    <View style={style}>
      <Component {...props} />
    </View>
  )
}

const wrapTabs = (
  tabs: {
    name: keyof TabsParamsList
    component: React.FC
    options: BottomTabNavigationOptions
  }[]
) => {
  return () => (
    <Tab.Navigator
      tabBar={(props) => <TabBar {...props} />}
      screenOptions={{ header: (props) => <Header {...props} /> }}
    >
      {tabs.map((tab) => (
        <Tab.Screen key={tab.name} {...tab} />
      ))}
    </Tab.Navigator>
  )
}

const Router = () => {
  const theme = useTheme()

  const [isReady, setIsReady] = useState(false)
  const [initialState, setInitialState] = useState<NavigationState>()

  const ref = useRef<NavigationContainerRef<RootStackParamList>>(null)

  const isAuthorized = accountSelectorHooks.getIsAuthorized()

  const onStateChange = (state?: NavigationState) => {
    if (Platform.OS !== 'web') return

    Storage.setItem('navigation', state)
  }

  useEffect(() => {
    setIsReady(false)
    Promise.all([Linking.getInitialURL(), Storage.getItem<NavigationState>('navigation')])
      .then(([initialUrl, initialState]) => {
        if (!initialState) return

        const path = linking.getPathFromState?.call(null, initialState).split('/')[1]
        const url = path && linking.config?.screens?.[path]

        if (!initialUrl?.includes(url)) {
          ref.current?.navigate(url)
        } else {
          setInitialState(initialState ?? undefined)
        }
      })
      .finally(() => setIsReady(true))
  }, [])

  const { initialRoute, routes } = useMemo(() => {
    let routes, initialRoute

    if (isAuthorized) {
      routes = [...privateRoutes, ...publicRoutes]
      initialRoute = RouterNames.private
    } else {
      routes = [...guestRoutes, ...publicRoutes]
      initialRoute = RouterNames.login
    }

    return { initialRoute, routes }
  }, [isAuthorized])

  const renderScreeen = ({ name, component, nestedTabs, tabs, options }: any) => {
    const getComponent = nestedTabs ? wrapTabs(tabs) : wrapComponent(component, theme)

    return <Stack.Screen key={name} name={name} component={getComponent} options={options} />
  }

  if (!isReady) return null

  return (
    <SafeAreaProvider>
      <NavigationContainer
        ref={ref}
        linking={linking}
        initialState={initialState}
        onStateChange={onStateChange}
        documentTitle={{ enabled: false }}
      >
        <Stack.Navigator
          initialRouteName={initialRoute as keyof RootStackParamList}
          screenOptions={{ header: (props) => <Header {...props} /> }}
        >
          {routes.map(renderScreeen)}
        </Stack.Navigator>
      </NavigationContainer>
    </SafeAreaProvider>
  )
}

export default memo(Router)
