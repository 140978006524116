import { View } from 'react-native'
import { DefaultWrapperProps, withWrapper } from '../../../../core/helpers/withWrapper'

import { withStyles } from '../../../../core/theme'
import { Box, Typography, Spacer, RoundProgressBar, Divider, Button } from '../../../components'
import { Spinner } from '../../../components/common/Spinner'
import { Connector } from '../../../components/connector'
import { useStationDetailsContentWrapper } from './wrapper'
import { IStationDetailsContentProps } from './types'
import { Charging } from '../../../../core/models'

const useStyles = withStyles((theme) => ({
  container: {
    alignItems: 'center',
  },
  btnContainer: {
    width: '65%',
  },
  percentage: {
    alignItems: 'center',
  },
  textsContainer: {
    width: '100%',
  },
  statContainer: {
    backgroundColor: '#F0F3FF',
    padding: theme.spacing.m,
    borderRadius: theme.spacing.s,
    width: '32%',
    height: '100%',
  },
}))

interface IStationDetailsContent extends DefaultWrapperProps, IStationDetailsContentProps {
  styles: ReturnType<typeof useStyles>
  buttonText?: string
  isCharging: boolean
  isFinished: boolean
  chargingStatus?: Charging.SessionStatus
  chargingData: Charging.SessionData
  spinnerText: string
  onButtonPress: () => void
}

const StationDetailsScreen = ({
  t,
  styles,
  theme,
  isFinished,
  isCharging,
  spinnerText,
  buttonText,
  chargingData,
  chargingStatus,
  onButtonPress,
}: IStationDetailsContent) => {
  const { station, car, connector } = chargingData

  const percent = chargingStatus?.percent || 0
  const energy = chargingStatus?.energy || 0
  const speed = chargingStatus?.speed || 0
  const cost = chargingStatus?.cost || 0

  const getStat = (label: string, value: string) => {
    return (
      <View style={styles.statContainer}>
        <Box direction="column" alignItems="center" justifyContent="center">
          <Typography text={value} bold size={18} />
          <Spacer vertical={6} />
          <Typography text={label} size={12} />
        </Box>
      </View>
    )
  }

  return (
    <View style={styles.container}>
      {chargingStatus?.percent !== null ? (
        <RoundProgressBar
          size={180}
          progress={percent}
          strokeWidth={15}
          baseColor={theme.colors.charger}
          color={theme.colors.secondaryText}
        >
          {isFinished ? (
            <Typography bold text={`${t('stations:success')}!`} size={'m'} />
          ) : (
            <View style={styles.percentage}>
              <Typography bold color={'text'} size={'l'} text={`${percent}%`} />
              <Typography color={'text'} fontWeight={'400'} text={t('stations:progress')} />
            </View>
          )}
        </RoundProgressBar>
      ) : (
        <Spinner
          size={180}
          strokeWidth={15}
          spinning={isCharging}
          color={theme.colors.charger}
          progressColor={theme.colors.secondaryText}
        >
          <Typography size={'m'} align={'left'} text={spinnerText} />
        </Spinner>
      )}
      <Spacer top={'l'} />
      <View style={styles.btnContainer}>
        <Button
          loading={!buttonText}
          label={buttonText}
          color={'primary'}
          onPress={onButtonPress}
        />
      </View>
      <Spacer top={'l'} />
      <Box direction="row" alignItems="center" justifyContent="space-between" fullWidth>
        {getStat(t('stats:speed'), `${speed} ${t('connector:kw')}`)}
        {getStat(t('stats:delivered'), `${energy.toFixed(2)} ${t('connector:kwh')}`)}
        {getStat(t('stats:cost'), `₪ ${cost.toFixed(2)}`)}
      </Box>
      <Spacer top={'l'} />
      <View style={styles.textsContainer}>
        {car && (
          <>
            <Typography
              text={`${car.vehicleVersion.vehicleManufacturerName} ${car.vehicleVersion.vehicleModelName}`}
              size={'m'}
              bold
              align={'left'}
            />
            <Typography text={car.vehicleVersion.year} size={'m'} fontWeight={'200'} />
            <Divider vertical={'l'} />
          </>
        )}
        <Typography text={station?.name} size={'m'} bold />
        <Typography text={station?.address.streetAddress} size={'m'} fontWeight={'200'} />
        <Spacer top={'xl'} />
        <Typography text={t('connector:type')} size={'m'} bold />
        <Spacer top={'m'} />
        {connector && <Connector connector={connector} price={station.price} />}
      </View>
    </View>
  )
}

export default withWrapper<IStationDetailsContentProps>(
  StationDetailsScreen,
  useStationDetailsContentWrapper,
  { useStyles }
)
