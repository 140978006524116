import { createSelectorHook } from '../../helpers/store'
import { RootState } from '../rootReducer'

const getEmailSubscription = () => (state: RootState) => {
  return state.notification.emailSubscription
}

const getStatusSubscription = () => (state: RootState) => {
  return state.notification.statusSubscription
}

export const notificationSelectorHooks = {
  getEmailSubscription: createSelectorHook(getEmailSubscription),
  getStatusSubscription: createSelectorHook(getStatusSubscription),
}
