import { useEffect, useRef, useState } from 'react'
import { Dimensions, View } from 'react-native'
import { LatLng, Marker, MarkerPressEvent, Region } from 'react-native-maps'

import SuperCluster from 'supercluster'

import { theme } from '../../../../../core/theme/themes'
import { Station } from '../../../../../core/models'
import { Typography } from '../../../common'

import { ClusterProperties, PointProperties, StationMarker, StationPoint } from './types'
import { calculateBBox, getMapZoom, mapStationToGeoJson } from './helpers'
import { useStyles } from './styles'

interface Props {
  stations: Station[]
  region?: Region | null
  onPress?: (ids: string[]) => void
}

const dimensions = Dimensions.get('screen')

export const Clusters = ({ region, stations, onPress }: Props) => {
  const [refresh, setRefresh] = useState(false)
  const [markers, setMarkers] = useState<StationMarker[]>([])

  const root = useRef<SuperCluster<PointProperties, ClusterProperties> | null>(null)

  const styles = useStyles()

  useEffect(() => {
    const cluster = new SuperCluster<PointProperties, ClusterProperties>({
      map: (props) => ({
        id: props.id,
        type: 'cluster',
        cluster_id: 0,
        cluster: true,
        point_count: 0,
        address: props.address,
      }),
    })

    const geoJsonFeatures = stations.reduce<StationPoint[]>((acc, item) => {
      const feature = mapStationToGeoJson(item)
      feature && acc.push(feature)
      return acc
    }, [])

    cluster.load(geoJsonFeatures)
    root.current = cluster
    setRefresh((refresh) => !refresh)
  }, [stations, region])

  useEffect(() => {
    if (!region) return

    const bBox = calculateBBox(region)
    const zoom = getMapZoom(bBox, dimensions)

    const markers = root.current?.getClusters(bBox, zoom)
    markers && setMarkers(markers)
  }, [refresh, region])

  const onMarkerPress = (id: string) => {
    return (e: MarkerPressEvent) => {
      e.stopPropagation()
      onPress?.call(null, [id])
    }
  }

  const onClusterPress = (id: number) => {
    return (e: MarkerPressEvent) => {
      e.stopPropagation()
      const children = root.current?.getLeaves(id, Infinity)
      const ids = children?.map(({ properties }) => properties.id)
      ids && onPress?.call(null, ids)
    }
  }

  const renderMarker = ({ geometry, properties }: StationMarker) => {
    const position: LatLng = {
      latitude: geometry.coordinates[1],
      longitude: geometry.coordinates[0],
    }

    if (!properties.cluster) {
      return (
        <Marker
          key={properties.id}
          tracksViewChanges={false}
          pinColor={theme.colors.primary}
          coordinate={position}
          onPress={onMarkerPress(properties.id)}
        />
      )
    }

    return (
      <Marker
        key={properties.cluster_id}
        coordinate={position}
        tracksViewChanges={false}
        onPress={onClusterPress(properties.cluster_id)}
      >
        <View style={styles.cluster}>
          <Typography color={'white'} size={'m'} text={properties.point_count.toString()} />
        </View>
      </Marker>
    )
  }

  return <>{markers.map(renderMarker)}</>
}
