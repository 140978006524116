import { PropsWithChildren, createContext, useContext, useMemo, useState } from 'react'
import {
  I18nManager,
  ImageStyle,
  TextStyle,
  ViewStyle,
  useColorScheme,
  useWindowDimensions,
} from 'react-native'
import { theme, darkTheme, ITheme } from './themes'
import { StyleSheet } from 'react-native'
import { EdgeInsets, useSafeAreaInsets } from 'react-native-safe-area-context'
import { useDeviceType } from '../hooks/useDeviceType'

interface IThemeContext {
  theme: typeof theme
  darkMode: boolean
  setDarkMode: (darkMode: boolean) => void
}

const ThemeContext = createContext<IThemeContext>({
  theme,
  darkMode: false,
  setDarkMode: () => {},
})

export const ThemeProvider = ({ children }: PropsWithChildren) => {
  const scheme = useColorScheme()
  const [darkMode, setDarkMode] = useState(false) //scheme === 'dark'

  const value = useMemo(() => {
    return {
      theme: darkMode ? darkTheme : theme,
      darkMode: darkMode,
      setDarkMode: (value?: boolean) => {
        setDarkMode(value !== undefined ? value : !darkMode)
      },
    }
  }, [darkMode, setDarkMode])

  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
}

type NamedStyle<T> = { [P in keyof T]: ViewStyle & TextStyle & ImageStyle }

export const useTheme = () => {
  const { theme } = useContext(ThemeContext)

  return theme
}

export const useDarkTheme = () => {
  const { darkMode } = useContext(ThemeContext)

  return darkMode
}

type StylesCallback<T> = (
  theme: ITheme,
  insets: EdgeInsets,
  width: number,
  isWeb: boolean
) => NamedStyle<T>

export const withStyles = <T,>(styles: StylesCallback<T>) => {
  return () => {
    const theme = useTheme()
    const insets = useSafeAreaInsets()
    const type = useDeviceType()
    const { width } = useWindowDimensions()

    const style = useMemo(() => {
      return styles(theme, insets, width, type === 'web')
    }, [theme, insets, styles, width, I18nManager.isRTL])

    return style
  }
}
