import { withStyles } from '../../../core/theme'

export const useStyles = withStyles((theme) => ({
  container: {
    flex: 1,
    padding: theme.spacing.m,
    backgroundColor: theme.colors.backgroundOffset,
  },
  paper: {
    paddingVertical: 2 * theme.spacing.m,
  },
}))
