import { createReducer } from '@reduxjs/toolkit'
import {
  resetTransactionsFilter,
  setTransactions,
  setTransactionsFilter,
  getTransactions,
  setError,
  setTransaction,
  getTransaction,
  getRecentTransactions,
  setRecentTransactions,
  setStats,
  getStats,
} from './actions'
import { TransactionsState } from './types'

const initialState: TransactionsState = {
  current: null,
  list: [],
  recentList: [],
  loading: false,
  error: null,
  chargerStationsId: [],
  endTime: null,
  startTime: null,
  vehiclesId: [],
  stats: null,
}

export const transactionsReducer = createReducer(initialState, (builder) => {
  builder.addCase(getTransactions, (state, action) => {
    return { ...state, loading: true, error: null }
  })
  builder.addCase(getTransaction, (state, action) => {
    return { ...state, loading: true, error: null, current: null }
  })
  builder.addCase(getRecentTransactions, (state, action) => {
    return { ...state, loading: true, error: null, current: null, recentList: [] }
  })
  builder.addCase(getStats, (state, action) => {
    return { ...state, loading: true, error: null }
  })
  builder.addCase(setStats, (state, action) => {
    return { ...state, loading: false, stats: action.payload, error: null }
  })
  builder.addCase(setTransactions, (state, action) => {
    return { ...state, list: action.payload, loading: false, error: null }
  })
  builder.addCase(
    setTransactionsFilter,
    (
      state,
      { payload: { transactionEndTime, transactionStartTime, chargerStationsId, vehiclesId } }
    ) => {
      return {
        ...state,
        endTime: transactionEndTime === -1 ? null : transactionEndTime ?? state.endTime,
        startTime: transactionStartTime === -1 ? null : transactionStartTime ?? state.startTime,
        chargerStationsId: chargerStationsId ? chargerStationsId : state.chargerStationsId,
        vehiclesId: vehiclesId ? vehiclesId : state.vehiclesId,
      }
    }
  )
  builder.addCase(resetTransactionsFilter, (state, action) => {
    return { ...state, startTime: null, endTime: null, chargerStationsId: [], vehiclesId: [] }
  })
  builder.addCase(setError, (state, action) => {
    return { ...state, loading: false, error: action.payload, current: null, list: [] }
  })
  builder.addCase(setTransaction, (state, action) => {
    return { ...state, loading: false, error: null, current: action.payload }
  })
  builder.addCase(setRecentTransactions, (state, action) => {
    return { ...state, loading: false, recentList: action.payload }
  })
})
