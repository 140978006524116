import { StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native'
import { Typography } from '../../../components'
import { useStyles } from './styles'

interface Tab {
  title: string
  count?: number
}

interface Props {
  data: Tab[]
  selectedTab: number
  onChange: (value: number) => void
  style?: StyleProp<ViewStyle>
}

export const Tabs = ({ data, selectedTab, onChange, style }: Props) => {
  const styles = useStyles()

  const renderItem = ({ title, count }: Tab, index: number) => {
    const isSelected = index === selectedTab

    const onPress = () => onChange(index)

    return (
      <TouchableOpacity
        key={index}
        onPress={onPress}
        style={[styles.item, isSelected && styles.selected]}
      >
        <Typography size={'m'} text={title} />
        {!!count && (
          <View style={styles.count}>
            <Typography bold text={count.toString()} color={'white'} />
          </View>
        )}
      </TouchableOpacity>
    )
  }

  return <View style={[styles.container, style]}>{data.map(renderItem)}</View>
}
