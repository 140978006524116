import { Charging, ConnectorStatus } from '../../models'

export const enum RequestMessageTypes {
  auth = 'authenticate',
  startCharging = 'startCharging',
  endCharging = 'endCharging',
  requestChargingStatus = 'requestStatus',
  requestStationStatus = 'requestChargeStationStatus',
  requestConnectorStatus = 'requestConnectorStatus',
}

export const enum ResponseMessageTypes {
  confirmAuth = 'confirmAuthenticate',
  failAuth = 'failAuthentication',
  confirmStartCharging = 'confirmStartCharging',
  failStartCharging = 'failStartCharging',
  confirmEndCharging = 'confirmEndCharging',
  chargingStatus = 'chargingStatus',
  stationStatus = 'chargeStationStatus',
  connectorStatus = 'connectorStatus',
}

interface Message<T> {
  action: T
}

export interface AuthRequest {
  token: string
}

export interface AuthResponse
  extends Message<ResponseMessageTypes.confirmAuth | ResponseMessageTypes.failAuth> {
  message: string
}

export interface StartChargingRequest {
  cardId: string
  vehicleId: string
  connectorId: string
  stationId: string
  reserveId: string | null
}

export interface StartChargingConfirmation
  extends Message<ResponseMessageTypes.confirmStartCharging> {
  time: number
  connectorId: string
  chargingId: string
}

export interface StartChargingFail extends Message<ResponseMessageTypes.failStartCharging> {
  message: string
}

export interface EndChargingRequest {
  chargingid: string
}

export interface EndChargingConfirmation extends Message<ResponseMessageTypes.confirmEndCharging> {
  chargingid: string
  energy: number
  cost: number
  time: number
}

export interface ChargingStatusRequest {
  chargingid: string
}

export interface StationStatusRequest {
  chargeStationId: string
}

export interface ConnectorStatusRequest {
  connectorId: string
}

export interface ChargingStatusResponse extends Message<ResponseMessageTypes.chargingStatus> {
  chargingId: string
  connectorId: string
  energy: number
  cost: number
  time: number
  status: Charging.Status
  percent: number | null
  speed: number | null
}

export interface StationStatusResponse extends Message<ResponseMessageTypes.stationStatus> {
  chargeStationId: string
  connectors: Record<string, ConnectorStatus>
}

export interface ConnectorStatusResponse extends Message<ResponseMessageTypes.connectorStatus> {
  connectorStatus: string
  connectorId: string
}

export type ResponseType =
  | AuthResponse
  | StartChargingConfirmation
  | StartChargingFail
  | EndChargingConfirmation
  | ChargingStatusResponse
  | StationStatusResponse
  | ConnectorStatusResponse
