import { withStyles } from '../../../core/theme'

export const useStyles = withStyles((theme, insets) => ({
  container: {
    flex: 1,
    paddingTop: theme.spacing.m,
    paddingHorizontal: theme.spacing.m,
    paddingBottom: insets.bottom || theme.spacing.m,
    backgroundColor: theme.colors.backgroundOffset,
  },
  content: {
    flex: 1,
    justifyContent: 'space-between',
  },
  form: {
    gap: theme.spacing.m,
  },
  checkbox: {
    marginLeft: theme.spacing.s,
  },
}))
