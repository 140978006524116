import { Platform } from 'react-native'
import { IModalContainerProps } from './types'

type ModalContainerType = { ModalContainer: (props: IModalContainerProps) => JSX.Element }

const { ModalContainer } = Platform.select<ModalContainerType>({
  native: require('./component.native'),
  default: require('./component.web'),
})

export default ModalContainer
