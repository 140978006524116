import { RefObject, useMemo } from 'react'
import { TFunction } from 'react-i18next'
import {
  FlatList,
  Platform,
  TouchableWithoutFeedback,
  useWindowDimensions,
  View,
} from 'react-native'
import { DefaultWrapperProps, withWrapper } from '../../../core/helpers/withWrapper'
import { withStyles } from '../../../core/theme'
import { Box, IconButton, Spacer } from '../../components/common'
import { Map } from '../../components/map'

import { useHomeScreenWrapper } from './wrapper'

import { MapStationCard, Search, HomeHeader } from '../../components/home'
import i18n from '../../../core/helpers/i18n'
import { useDeviceType } from '../../../core/hooks'
import { Station } from '../../../core/models'

const useStyles = withStyles((theme, insets, width, isWeb) => ({
  container: {
    flex: 1,
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    backgroundColor: theme.colors.backgroundOffset,
    position: 'relative',
    marginLeft: isWeb ? 90 + (i18n.language === 'ru' ? 20 : 0) : 0,
    marginBottom: isWeb ? 0 : 90,
  },
  cardList: {
    position: 'absolute',
    bottom: isWeb ? 40 : (insets.bottom || theme.spacing.m) + theme.spacing.m,
    right: 10,
    left: 0,
    paddingRight: 5,
    zIndex: 1,
  },
  chargingCard: {
    position: 'absolute',
    zIndex: 1,
    ...(isWeb
      ? { top: theme.spacing.m, left: 520, width: 200 }
      : { top: (insets.top || 30) * 2.7, left: theme.spacing.m, right: theme.spacing.m }),
  },
  searchButton: {
    position: 'absolute',
    zIndex: 1,
    ...(isWeb
      ? { top: theme.spacing.m, left: 440 }
      : { right: 10, bottom: theme.spacing.m * (Platform.OS === 'ios' ? 3 : 2) }),
    paddingRight: 5,
  },
  sliderContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  slide: {
    flexDirection: 'row',
    width: width - (isWeb ? 270 : 30),
    justifyContent: 'space-evenly',
  },
  slideItem: {
    flex: 0.45,
  },
  singleItem: {
    width: width - theme.spacing.m * 2,
  },
}))

interface IHomeScreen extends DefaultWrapperProps {
  styles: ReturnType<typeof useStyles>
  t: TFunction
  focus: boolean
  stations: Station[]
  handleFocus: (value: boolean) => void
  setFocus: (value: boolean) => void
  search: string
  selectedStations: Station[]
  onClusterPress: (ids: string[]) => void
  onMapPress: () => void
  mapPoints: Station[]
  listRef: RefObject<FlatList>
  goBack: () => void
  goNext: () => void
}

const HomeScreen = ({
  styles,
  focus,
  handleFocus,
  setFocus,
  stations,
  search,
  selectedStations,
  onClusterPress,
  onMapPress,
  listRef,
  goBack,
  goNext,
}: IHomeScreen) => {
  const { width } = useWindowDimensions()
  const type = useDeviceType()

  const list = useMemo(() => {
    if (type === 'mobile') return selectedStations

    return selectedStations.reduce<Station[][]>((acc, curr, i) => {
      i % 2 ? acc[Math.floor(i / 2)].push(curr) : acc.push([curr])
      return acc
    }, [])
  }, [selectedStations, type])

  const renderItem = ({ item }: { item: Station | Station[] }) => {
    return Array.isArray(item) ? (
      <View style={styles.slide}>
        {item.map((station) => (
          <View style={styles.slideItem}>
            <MapStationCard station={station} />
          </View>
        ))}
      </View>
    ) : (
      <TouchableWithoutFeedback>
        <View style={styles.singleItem}>
          <MapStationCard station={item} />
        </View>
      </TouchableWithoutFeedback>
    )
  }

  const items = (
    <FlatList
      ref={listRef}
      data={list as []}
      renderItem={renderItem}
      ListHeaderComponent={() => <Spacer horizontal={'s'} />}
      ItemSeparatorComponent={() => <Spacer horizontal={'m'} />}
      ListFooterComponent={() => <Spacer right={'s'} />}
      horizontal
      showsHorizontalScrollIndicator={false}
      disableIntervalMomentum
      decelerationRate={0.0}
      scrollEventThrottle={1}
      snapToAlignment={'center'}
      scrollEnabled={type === 'mobile'}
      snapToInterval={width + (Platform.OS === 'ios' ? 0 : 5)}
    />
  )

  return (
    <View style={{ flex: 1 }}>
      <View style={styles.container}>
        <HomeHeader handleFocus={handleFocus} />
        {!focus ? (
          <View style={styles.cardList}>
            {!!selectedStations.length && (
              <>
                {type === 'mobile' ? (
                  items
                ) : (
                  <View style={styles.sliderContainer}>
                    {selectedStations.length > 2 && (
                      <IconButton icon={'chevron-left'} size={25} rounded onPress={goBack} />
                    )}
                    {items}
                    {selectedStations.length > 2 && (
                      <IconButton icon={'chevron-right'} size={25} rounded onPress={goNext} />
                    )}
                  </View>
                )}
              </>
            )}
          </View>
        ) : (
          <View style={styles.searchButton}>
            <Box direction="column" justifyContent="flex-end" alignItems="flex-end">
              <IconButton
                icon="map"
                size={20}
                boxSize={40}
                onPress={() => setFocus(false)}
                shadow
              />
            </Box>
          </View>
        )}
        {focus && <Search search={search} />}
        <Map
          stations={stations}
          onMapPress={onMapPress}
          onStationsPress={onClusterPress}
          onListView={focus ? undefined : () => setFocus(true)}
        />
      </View>
    </View>
  )
}

export default withWrapper(HomeScreen, useHomeScreenWrapper, {
  useStyles,
})
