import { useNavigation } from '@react-navigation/native'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Platform } from 'react-native'
import { showMessage } from 'react-native-flash-message'
import { IOptions, IUseWrapper } from '../../../core/helpers/withWrapper'
import { useDeviceType } from '../../../core/hooks'
import {
  notificationActionHooks,
  notificationSelectorHooks,
} from '../../../core/store/notification'
import { RouterNames } from '../../../core/constants'

export const useSettingsScreenWrapper: IUseWrapper = ({ useStyles }: IOptions) => {
  const styles = useStyles()
  const type = useDeviceType()
  const { navigate } = useNavigation()
  const { t } = useTranslation()

  const [loader, setLoader] = useState({ emailSubscription: false, statusSubscription: false })
  const [languagesShown, setLanguagesShown] = useState(false)

  const emailSubscription = notificationSelectorHooks.getEmailSubscription()
  const statusSubscription = notificationSelectorHooks.getStatusSubscription()

  const updateEmailSubscription = notificationActionHooks.updateEmailSubscription()
  const updateStatusSubscription = notificationActionHooks.updateStatusSubscription()

  const notifications = { emailSubscription, statusSubscription }

  const toggleNotification = (key: keyof typeof notifications) => {
    return () => {
      const value = !notifications[key]
      const callback = handleResponse(key, value)

      setLoader((loader) => ({ ...loader, [key]: true }))

      switch (key) {
        case 'emailSubscription': {
          updateEmailSubscription({ emailNotification: value, callback })
          break
        }
        case 'statusSubscription': {
          updateStatusSubscription({ statusNotification: value, callback })
          break
        }
      }
    }
  }

  const handleResponse = (key: keyof typeof notifications, value: boolean) => {
    return async (error?: Error | null) => {
      setLoader((loader) => ({ ...loader, [key]: false }))

      if (error) {
        showMessage({ type: 'danger', message: t(`settings:${key}.error`) })
      } else {
        showMessage({
          type: 'success',
          message: t(`settings:common.${value ? 'subscribe' : 'unsubscribe'}`),
          description: t(`settings:${key}.message.${value ? 'subscribe' : 'unsubscribe'}`),
          style: {
            borderRadius: type === 'web' ? 10 : 0,
          },
          textStyle: {
            paddingRight: 20,
          },
        })
      }
    }
  }

  const showLanguages = () => {
    Platform.OS === 'web' ? setLanguagesShown(true) : navigate(RouterNames.languages)
  }

  return {
    styles,
    loader,
    notifications,
    showLanguages,
    languagesShown,
    setLanguagesShown,
    toggleNotification,
  }
}
