import { api } from '../setup'
import * as CONSTANTS from './constants'
import { INotificationsApi, PushNotificationsPayload } from './types'

export const notificationsApi: INotificationsApi = {
  getMontlySubscription: async () => {
    const { data } = await api.get(CONSTANTS.GET_MONTHLY_SUBSCRIPTION)
    return data
  },
  setEmailSubscription: async (payload) => {
    return api.post(CONSTANTS.SET_EMAIL_SUBSCRIPTION, payload)
  },
  setStatusSubscription: async (payload) => {
    return api.post(CONSTANTS.SET_STATUS_SUBSCRIPTION, payload)
  },
  language: (payload) => {
    return api.post(CONSTANTS.NOTIFICATION_LANGUAGE, payload)
  },
  subscribe: async (payload: PushNotificationsPayload) => {
    const { data } = await api.post(CONSTANTS.SUBSCRIBE_PUSH_NOTIFICATIONS, payload)
    return data
  },
  unsubscribe: () => {
    return api.delete(CONSTANTS.UNSUBSCRIBE_PUSH_NOTIFICATIONS)
  },
}
