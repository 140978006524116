import { createSelectorHook } from '../../helpers/store'
import { RootState } from '../rootReducer'

const getPaymentMethods = () => (state: RootState) => {
  const { rfids, cards } = state.payment
  return { rfids, cards }
}

const getIsLoading = () => (state: RootState) => state.payment.loading

export const paymentSelectorHooks = {
  getPaymentMethods: createSelectorHook(getPaymentMethods),
  getIsLoading: createSelectorHook(getIsLoading),
}
