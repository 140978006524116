import { useCallback, useState } from 'react'
import { View, Image, TouchableOpacity, StyleSheet, ActivityIndicator } from 'react-native'

import { launchImageLibraryAsync, MediaTypeOptions } from 'expo-image-picker'

import { resizeImage } from '../../../../core/helpers/imageResize'
import Typography from '../Typography'
import { Icon } from '../../icon'
import { useStyles } from './styles'

interface Props {
  avatar?: string | null
  placeholder: string
  size?: number
  editable?: boolean
  onImageChange?: (uri: string) => void
  loading?: boolean
}

export const Avatar = ({ avatar, placeholder, size = 60, onImageChange, editable }: Props) => {
  const [isLoading, setIsLoading] = useState(false)

  const placeholderText = <Typography text={placeholder} size={size / 2} color="foreground" />

  const toggleLoader = useCallback(() => {
    setIsLoading((isLoading) => !isLoading)
  }, [])

  const image = avatar && (
    <Image
      source={{ uri: avatar }}
      onLoadStart={toggleLoader}
      onLoadEnd={toggleLoader}
      resizeMode={'cover'}
      style={StyleSheet.absoluteFill}
    />
  )

  const styles = useStyles()

  const pickImage = async () => {
    const { assets, canceled } = await launchImageLibraryAsync({
      mediaTypes: MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [4, 4],
      quality: 1,
    })

    if (!canceled && onImageChange) {
      const { uri } = await resizeImage(assets[0].uri, 200)
      onImageChange(uri)
    }
  }

  return (
    <View style={[styles.container, { width: size, height: size }]}>
      {avatar ? image : placeholderText}
      {isLoading && <ActivityIndicator />}
      {editable && (
        <TouchableOpacity
          disabled={isLoading}
          onPress={pickImage}
          style={[styles.edit, isLoading && styles.disabled]}
        >
          <Icon family={'Feather'} name={'camera'} color={'white'} size={size / 10} />
        </TouchableOpacity>
      )}
    </View>
  )
}
