export const PREFIX = 'TRANSACTIONS_'

export const GET_TRANSACTIONS = `${PREFIX}GET_TRANSACTIONS`
export const GET_RECENT_TRANSACTIONS = `${PREFIX}GET_RECENT_TRANSACTIONS`
export const GET_TRANSACTION = `${PREFIX}GET_TRANSACTION`
export const GET_USER_STATS = `${PREFIX}GET_USER_STATS`
export const SET_STATS = `${PREFIX}SET_STATS`
export const SET_TRANSACTION = `${PREFIX}SET_TRANSACTION`
export const SET_TRANSACTIONS = `${PREFIX}SET_TRANSACTIONS`
export const SET_FILTER = `${PREFIX}SET_FILTER`
export const SET_ERROR = `${PREFIX}SET_ERROR`
export const SET_RECENT_TRANSACTIONS = `${PREFIX}SET_RECENT_TRANSACTIONS`
export const RESET_FILTER = `${PREFIX}RESET_FILTER`
