import { withStyles } from '../../../../core/theme'

export const useStyles = withStyles((theme) => ({
  container: {
    flex: 1,
    backgroundColor: theme.colors.backgroundOffset,
  },
  content: {
    padding: theme.spacing.m,
  },
}))
