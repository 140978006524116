import { withStyles } from '../../../../core/theme'

export const useStyles = withStyles((theme, insets) => ({
  traction: {
    position: 'absolute',
    right: theme.spacing.m,
    bottom: (insets.bottom || theme.spacing.m) + theme.spacing.m,
  },
  shiftedTraction: {
    zIndex: 2,
    marginBottom: 10,
    bottom: insets.bottom ? '38%' : '41%',
  },
}))
