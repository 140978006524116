import React from 'react'
import { useTranslation } from 'react-i18next'
import { Pressable, StyleSheet, View } from 'react-native'
import { ICar } from '../../../core/store/vehicles'
import { Button, Spacer, Typography } from '../common'

interface Props {
  car?: ICar
  onAddNew: () => void
  onPress: () => void
}

export const VehicleSection = ({ car, onAddNew, onPress }: Props) => {
  const { t } = useTranslation()

  if (!car) {
    return (
      <>
        <Typography text={t('charging:noCar')} size={'m'} />
        <Spacer vertical={'s'} />
        <Button label={t('cars:addCar.addVehicle')} onPress={onAddNew} />
      </>
    )
  }

  return (
    <>
      <Typography text={t('vehicle:title')} size={'m'} bold />
      <Spacer vertical={'s'} />
      <Pressable style={styles.wrapper} onPress={onPress}>
        <View>
          <Typography
            bold
            size={14}
            text={`${car?.vehicleVersion?.vehicleManufacturerName} ${car?.vehicleVersion?.vehicleModelName}`}
          />
          <Spacer bottom={'s'} />
          <Typography bold size={14} text={car?.vehicleVersion?.year} color={'placeholder'} />
        </View>
        <Button
          variant="menu"
          bold
          align="left"
          disableTouchableOpacity
          endIcon={{ name: 'chevron-right', family: 'Feather' }}
        />
      </Pressable>
    </>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
})
