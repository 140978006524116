import { useEffect, useState } from 'react'

import { stationsApi } from '../api/stations'

export const useStationPrice = (stationId: string, disabled = false) => {
  const [price, setPrice] = useState<number | null>(null)

  useEffect(() => {
    if (disabled) return

    stationsApi.getStationPrice(stationId).then(setPrice)
  }, [disabled, stationId])

  return price
}
