import { KeyboardAvoidingView, Platform } from 'react-native'

import { IconButton, Spacer } from '../../common'
import { useStyles } from './styles'

interface Props {
  crosshairEnabled: boolean
  onCrosshairPress: () => void
  onLayersPress?: () => void
}

export const Controls = ({ crosshairEnabled, onCrosshairPress, onLayersPress }: Props) => {
  const styles = useStyles()

  return (
    <KeyboardAvoidingView
      behavior={Platform.select({ ios: 'padding', android: 'height' })}
      style={[styles.traction]}
    >
      <IconButton
        icon="crosshair"
        size={20}
        boxSize={40}
        shadow
        color={crosshairEnabled ? 'primary' : 'text'}
        onPress={onCrosshairPress}
      />
      {onLayersPress && (
        <>
          <Spacer vertical={'s'} />
          <IconButton icon="layers" size={20} boxSize={40} onPress={onLayersPress} shadow />
        </>
      )}
    </KeyboardAvoidingView>
  )
}
