import { withWrapper } from '../../../../core/helpers/withWrapper'
import { withStyles } from '../../../../core/theme'
import ModalContainer from '../../../components/common/ModalContainer'
import { useStationFeedbackModalWrapper } from './wrapper'
import StationFeedbackContent from '../content'
import { SupportRequestModal } from '../../SupportRequest'
import { Charging } from '../../../../core/models'

const useStyles = withStyles(() => ({
  container: {
    width: '100%',
    maxHeight: '85vh',
    maxWidth: 500,
  },
  content: {
    maxHeight: '85vh',
  },
}))

interface IStationFeedbackModalProps {
  session: Charging.ActiveSession
  isVisible: boolean
  setVisible: (value: boolean) => void
}

interface IStationFeedbackModal extends IStationFeedbackModalProps {
  styles: ReturnType<typeof useStyles>
  isReportShown: boolean
  setIsReportShown: (value: boolean) => void
}

const StationFeedbackModal = ({
  styles,
  isReportShown,
  setIsReportShown,
  session,
  isVisible,
  setVisible,
}: IStationFeedbackModal) => (
  <>
    <ModalContainer isVisible={isVisible} setVisible={setVisible} contentStyles={styles.container}>
      <StationFeedbackContent
        style={styles.content}
        session={session}
        showReport={() => setIsReportShown(true)}
        closeCallback={() => setVisible(false)}
      />
    </ModalContainer>
    <SupportRequestModal
      isVisible={isReportShown}
      setVisible={setIsReportShown}
      chargeStationId={session.chargeStationId}
    />
  </>
)

export default withWrapper<IStationFeedbackModalProps>(
  StationFeedbackModal,
  useStationFeedbackModalWrapper,
  { useStyles }
)
