import React from 'react'
import { TFunction } from 'react-i18next'
import { FlatList, TouchableOpacity, View } from 'react-native'

import { Box, Divider, Spacer, Typography } from '../../../components'
import { Icon } from '../../../components/icon'
import { withWrapper } from '../../../../core/helpers/withWrapper'
import { SUPPORTED_LANGUAGES } from '../../../../core/config'
import { withStyles } from '../../../../core/theme'
import { useLanguagesContentWrapper } from './wrapper'
import { ILanguagesContentProps } from './types'

const useStyles = withStyles((theme) => ({
  item: {
    flex: 1,
    width: '100%',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    paddingHorizontal: theme.spacing.l,
    backgroundColor: theme.colors.background,
  },
  list: {
    paddingVertical: theme.spacing.s,
  },
}))

interface ILanguagesContent extends ILanguagesContentProps {
  t: TFunction
  language: string
  changeLanguage: (lang: string) => void
  styles: ReturnType<typeof useStyles>
}

const LanguagesContent = ({ styles, t, changeLanguage, language }: ILanguagesContent) => {
  const renderItem = ({ item }: { item: string }) => {
    return (
      <View style={styles.item}>
        <TouchableOpacity onPress={() => changeLanguage(item)} style={{ width: '100%' }}>
          <Box justifyContent="space-between" alignItems="center" direction="row">
            <Box justifyContent="flex-start" alignItems="flex-start" direction="column">
              <Typography text={t(`languages:original.${item}`)} size={20} />
              <Spacer vertical={2} />
              <Typography text={t(`languages:translated.${item}`)} size={14} />
            </Box>
            <Icon family={'Feather'} size={24} name={item === language ? 'disc' : 'circle'} />
          </Box>
        </TouchableOpacity>
      </View>
    )
  }

  const separator = () => <Divider vertical={16} />

  return (
    <FlatList
      style={styles.list}
      data={SUPPORTED_LANGUAGES}
      ItemSeparatorComponent={separator}
      renderItem={renderItem}
    />
  )
}

export default withWrapper<ILanguagesContentProps>(LanguagesContent, useLanguagesContentWrapper, {
  useStyles,
})
