export const html = `
  <!DOCTYPE html>
  <html>
  <head>
    <meta name="viewport" content="width=device-width, initial-scale=1, viewport-fit=cover" />
  </head>
  <body>
    <div>
      <div><strong><span style="font-size:26px"><span data-custom-class="title">PRIVACY POLICY</span></span></strong>
      </div>
      <div><br></div>
      <div><span style="color:#7f7f7f"><strong><span style="font-size:15px"><span data-custom-class="subtitle">Last
                updated September 07, 2023</span></span></strong></span></div>
      <div><br></div>
      <div><br></div>
      <div><br></div>
      <div style="line-height:1.5"><span style="color:#7f7f7f"><span style="color:#595959;font-size:15px"><span
              data-custom-class="body_text">This privacy notice for Quantex Innovations, LLC ("<strong>we</strong>,"
              "<strong>us</strong>," or "<strong>our</strong>"</span><span data-custom-class="body_text">), describes how
              and why we might collect, store, use, and/or share ("<strong>process</strong>") your information when you
              use our services ("<strong>Services</strong>"), such as when you:</span></span></span></div>
      <ul>
        <li style="line-height:1.5"><span style="font-size:15px;color:#595959"><span
              style="font-size:15px;color:#595959"><span data-custom-class="body_text">Visit our website at<a
                  href="https://web.qi-dev.quantexinnovations.com" target="_blank"
                  data-custom-class="link">https://web.qi-dev.quantexinnovations.com</a><span
                  style="font-size:15px"><span style="color:#595959"><span data-custom-class="body_text"><span
                        style="font-size:15px"><span style="color:#595959">, or any website of ours that links to this
                          privacy notice</span></span></span></span></span></span></span></span></li>
      </ul>
      <ul>
        <li style="line-height:1.5"><span style="font-size:15px;color:#595959"><span
              style="font-size:15px;color:#595959"><span data-custom-class="body_text">Download and use our mobile
                application (QEM)<span style="font-size:15px;color:#595959"><span
                    style="font-size:15px;color:#595959"><span data-custom-class="body_text"><span
                        style="font-size:15px"><span style="color:#595959"><span data-custom-class="body_text"><span
                              style="font-size:15px"><span
                                style="color:#595959">,</span></span></span></span></span></span></span></span></span><span
                data-custom-class="body_text"><span style="font-size:15px"><span style="color:#595959"><span
                      data-custom-class="body_text"><span style="font-size:15px"><span style="color:#595959">or any other
                          application of ours that links to this privacy
                          notice</span></span></span></span></span></span></span></span></li>
      </ul>
      <div style="line-height:1.5"></div>
      <ul>
        <li style="line-height:1.5"><span style="font-size:15px;color:#595959"><span
              style="font-size:15px;color:#595959"><span data-custom-class="body_text">Engage with us in other related
                ways, including any sales, marketing, or events</span></span></span></li>
      </ul>
      <div style="line-height:1.5"><span style="font-size:15px"><span style="color:#7f7f7f"><span
              data-custom-class="body_text"><strong>Questions or concerns?</strong>Reading this privacy notice will help
              you understand your privacy rights and choices. If you do not agree with our policies and practices, please
              do not use our Services. If you still have any questions or concerns, please contact us at
              support@quantexinnovations.com.</span></span></span></div>
      <div style="line-height:1.5"><br></div>
      <div style="line-height:1.5"><br></div>
      <div style="line-height:1.5"><strong><span style="font-size:15px"><span data-custom-class="heading_1">SUMMARY OF KEY
              POINTS</span></span></strong></div>
      <div style="line-height:1.5"><br></div>
      <div style="line-height:1.5"><span style="font-size:15px"><span data-custom-class="body_text"><strong><em>This
                summary provides key points from our privacy notice, but you can find out more details about any of these
                topics by clicking the link following each key point or by using our</em></strong></span></span><a
          data-custom-class="link" href="#toc"><span style="font-size:15px"><span
              data-custom-class="body_text"><strong><em>table of contents</em></strong></span></span></a><span
          style="font-size:15px"><span data-custom-class="body_text"><strong><em>below to find the section you are looking
                for.</em></strong></span></span></div>
      <div style="line-height:1.5"><br></div>
      <div style="line-height:1.5"><span style="font-size:15px"><span data-custom-class="body_text"><strong>What personal
              information do we process?</strong>When you visit, use, or navigate our Services, we may process personal
            information depending on how you interact with us and the Services, the choices you make, and the products and
            features you use. Learn more about</span></span><a data-custom-class="link" href="#personalinfo"><span
            style="font-size:15px"><span data-custom-class="body_text">personal information you disclose to
              us</span></span></a><span data-custom-class="body_text">.</span></div>
      <div style="line-height:1.5"><br></div>
      <div style="line-height:1.5"><span style="font-size:15px"><span data-custom-class="body_text"><strong>Do we process
              any sensitive personal information?</strong>We do not process sensitive personal information.</span></span>
      </div>
      <div style="line-height:1.5"><br></div>
      <div style="line-height:1.5"><span style="font-size:15px"><span data-custom-class="body_text"><strong>Do we receive
              any information from third parties?</strong>We do not receive any information from third
            parties.</span></span></div>
      <div style="line-height:1.5"><br></div>
      <div style="line-height:1.5"><span style="font-size:15px"><span data-custom-class="body_text"><strong>How do we
              process your information?</strong>We process your information to provide, improve, and administer our
            Services, communicate with you, for security and fraud prevention, and to comply with law. We may also process
            your information for other purposes with your consent. We process your information only when we have a valid
            legal reason to do so. Learn more about</span></span><a data-custom-class="link" href="#infouse"><span
            style="font-size:15px"><span data-custom-class="body_text">how we process your
              information</span></span></a><span data-custom-class="body_text">.</span></div>
      <div style="line-height:1.5"><br></div>
      <div style="line-height:1.5"><span style="font-size:15px"><span data-custom-class="body_text"><strong>In what
              situations and with which types of parties do we share personal information?</strong>We may share
            information in specific situations and with specific categories of third parties. Learn more
            about</span></span><a data-custom-class="link" href="#whoshare"><span style="font-size:15px"><span
              data-custom-class="body_text">when and with whom we share your personal information</span></span></a><span
          style="font-size:15px"><span data-custom-class="body_text">.</span></span></div>
      <div style="line-height:1.5"><br></div>
      <div style="line-height:1.5"><span style="font-size:15px"><span data-custom-class="body_text"><strong>How do we keep
              your information safe?</strong>We have organizational and technical processes and procedures in place to
            protect your personal information. However, no electronic transmission over the internet or information
            storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that hackers,
            cybercriminals, or other unauthorized third parties will not be able to defeat our security and improperly
            collect, access, steal, or modify your information. Learn more about</span></span><a data-custom-class="link"
          href="#infosafe"><span style="font-size:15px"><span data-custom-class="body_text">how we keep your information
              safe</span></span></a><span data-custom-class="body_text">.</span></div>
      <div style="line-height:1.5"><br></div>
      <div style="line-height:1.5"><span style="font-size:15px"><span data-custom-class="body_text"><strong>What are your
              rights?</strong>Depending on where you are located geographically, the applicable privacy law may mean you
            have certain rights regarding your personal information. Learn more about</span></span><a
          data-custom-class="link" href="#privacyrights"><span style="font-size:15px"><span
              data-custom-class="body_text">your privacy rights</span></span></a><span
          data-custom-class="body_text">.</span></div>
      <div style="line-height:1.5"><br></div>
      <div style="line-height:1.5"><span style="font-size:15px"><span data-custom-class="body_text"><strong>How do you
              exercise your rights?</strong>The easiest way to exercise your rights is by visiting<a
              href="https://web.qi-dev.quantexinnovations.com" target="_blank"
              data-custom-class="link">https://web.qi-dev.quantexinnovations.com</a>, or by contacting us. We will
            consider and act upon any request in accordance with applicable data protection laws.</span></span></div>
      <div style="line-height:1.5"><br></div>
      <div style="line-height:1.5"><span style="font-size:15px"><span data-custom-class="body_text">Want to learn more
            about what we do with any information we collect?</span></span><a data-custom-class="link" href="#toc"><span
            style="font-size:15px"><span data-custom-class="body_text">Review the privacy notice in
              full</span></span></a><span style="font-size:15px"><span data-custom-class="body_text">.</span></span></div>
      <div style="line-height:1.5"><br></div>
      <div style="line-height:1.5"><br></div>
      <div id="toc" style="line-height:1.5"><span style="font-size:15px"><span style="color:#7f7f7f"><span
              style="color:#000"><strong><span data-custom-class="heading_1">TABLE OF
                  CONTENTS</span></strong></span></span></span></div>
      <div style="line-height:1.5"><br></div>
      <div style="line-height:1.5"><span style="font-size:15px"><a data-custom-class="link" href="#infocollect"><span
              style="color:#595959">1. WHAT INFORMATION DO WE COLLECT?</span></a></span></div>
      <div style="line-height:1.5"><span style="font-size:15px"><a data-custom-class="link" href="#infouse"><span
              style="color:#595959">2. HOW DO WE PROCESS YOUR INFORMATION?</span></a></span></div>
      <div style="line-height:1.5"><span style="font-size:15px"><a data-custom-class="link" href="#legalbases"><span
              style="color:#595959">3.<span style="font-size:15px"><span style="color:#595959">WHAT LEGAL BASES DO WE RELY
                  ON TO PROCESS YOUR PERSONAL INFORMATION?</span></span></span></a></span></div>
      <div style="line-height:1.5"><span style="font-size:15px"><span style="color:#595959"><a data-custom-class="link"
              href="#whoshare">4. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</a></span></span></div>
      <div style="line-height:1.5"><span style="font-size:15px"><a data-custom-class="link" href="#cookies"><span
              style="color:#595959">5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</span></a></span></div>
      <div style="line-height:1.5"><span style="font-size:15px"><a data-custom-class="link" href="#intltransfers"><span
              style="color:#595959">6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</span></a></span></div>
      <div style="line-height:1.5"><span style="font-size:15px"><a data-custom-class="link" href="#inforetain"><span
              style="color:#595959">7. HOW LONG DO WE KEEP YOUR INFORMATION?</span></a></span></div>
      <div style="line-height:1.5"><span style="font-size:15px"><a data-custom-class="link" href="#infosafe"><span
              style="color:#595959">8. HOW DO WE KEEP YOUR INFORMATION SAFE?</span></a></span></div>
      <div style="line-height:1.5"><span style="font-size:15px"><a data-custom-class="link" href="#infominors"><span
              style="color:#595959">9. DO WE COLLECT INFORMATION FROM MINORS?</span></a></span></div>
      <div style="line-height:1.5"><span style="font-size:15px"><span style="color:#595959"><a data-custom-class="link"
              href="#privacyrights">10. WHAT ARE YOUR PRIVACY RIGHTS?</a></span></span></div>
      <div style="line-height:1.5"><span style="font-size:15px"><a data-custom-class="link" href="#DNT"><span
              style="color:#595959">11. CONTROLS FOR DO-NOT-TRACK FEATURES</span></a></span></div>
      <div style="line-height:1.5"><span style="font-size:15px"><a data-custom-class="link" href="#caresidents"><span
              style="color:#595959">12. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span></a></span></div>
      <div style="line-height:1.5"><a data-custom-class="link" href="#virginia"><span style="font-size:15px">13. DO
            VIRGINIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span></a></div>
      <div style="line-height:1.5"></div>
      <div style="line-height:1.5"><span style="font-size:15px"><a data-custom-class="link" href="#policyupdates"><span
              style="color:#595959">14. DO WE MAKE UPDATES TO THIS NOTICE?</span></a></span></div>
      <div style="line-height:1.5"><a data-custom-class="link" href="#contact"><span
            style="color:#595959;font-size:15px">15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span></a></div>
      <div style="line-height:1.5"><a data-custom-class="link" href="#request"><span style="color:#595959">16. HOW CAN YOU
            REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?</span></a></div>
      <div style="line-height:1.5"><br></div>
      <div style="line-height:1.5"><br></div>
      <div id="infocollect" style="line-height:1.5"><span style="color:#7f7f7f"><span
            style="color:#595959;font-size:15px"><span style="font-size:15px;color:#595959"><span
                style="font-size:15px;color:#595959"><span id="control" style="color:#000"><strong><span
                      data-custom-class="heading_1">1. WHAT INFORMATION DO WE
                      COLLECT?</span></strong></span></span></span></span></span></div>
      <div style="line-height:1.5"><br></div>
      <div id="personalinfo" style="line-height:1.5"><span data-custom-class="heading_2" style="color:#000"><span
            style="font-size:15px"><strong>Personal information you disclose to us</strong></span></span></div>
      <div>
        <div><br></div>
        <div style="line-height:1.5"><span style="color:#7f7f7f"><span style="color:#595959;font-size:15px"><span
                data-custom-class="body_text"><span style="font-size:15px;color:#595959"><span
                    style="font-size:15px;color:#595959"><span data-custom-class="body_text"><strong><em>In
                          Short:</em></strong></span></span></span></span><span data-custom-class="body_text"><span
                  style="font-size:15px;color:#595959"><span style="font-size:15px;color:#595959"><span
                      data-custom-class="body_text"><em>We collect personal information that you provide to
                        us.</em></span></span></span></span></span></span></div>
      </div>
      <div style="line-height:1.5"><br></div>
      <div style="line-height:1.5"><span style="font-size:15px;color:#595959"><span
            style="font-size:15px;color:#595959"><span data-custom-class="body_text">We collect personal information that
              you voluntarily provide to us when you register on the Services,</span><span style="font-size:15px"><span
                data-custom-class="body_text">express an interest in obtaining information about us or our products and
                Services, when you participate in activities on the Services, or otherwise when you contact
                us.</span></span></span></span></div>
      <div style="line-height:1.5"><br></div>
      <div style="line-height:1.5"></div>
      <div style="line-height:1.5"><span style="font-size:15px;color:#595959"><span
            style="font-size:15px;color:#595959"><span data-custom-class="body_text"><strong>Personal Information Provided
                by You.</strong>The personal information that we collect depends on the context of your interactions with
              us and the Services, the choices you make, and the products and features you use. The personal information
              we collect may include the following:</span></span></span></div>
      <ul>
        <li style="line-height:1.5"><span style="font-size:15px;color:#595959"><span
              style="font-size:15px;color:#595959"><span data-custom-class="body_text"><span style="font-size:15px"><span
                    data-custom-class="body_text">names</span></span></span></span></span></li>
      </ul>
      <div style="line-height:1.5"></div>
      <ul>
        <li style="line-height:1.5"><span style="font-size:15px;color:#595959"><span
              style="font-size:15px;color:#595959"><span data-custom-class="body_text"><span style="font-size:15px"><span
                    data-custom-class="body_text">phone numbers</span></span></span></span></span></li>
      </ul>
      <div style="line-height:1.5"></div>
      <ul>
        <li style="line-height:1.5"><span style="font-size:15px;color:#595959"><span
              style="font-size:15px;color:#595959"><span data-custom-class="body_text"><span style="font-size:15px"><span
                    data-custom-class="body_text">email addresses</span></span></span></span></span></li>
      </ul>
      <div style="line-height:1.5"></div>
      <ul>
        <li style="line-height:1.5"><span style="font-size:15px;color:#595959"><span
              style="font-size:15px;color:#595959"><span data-custom-class="body_text"><span style="font-size:15px"><span
                    data-custom-class="body_text">usernames</span></span></span></span></span></li>
      </ul>
      <div style="line-height:1.5"></div>
      <ul>
        <li style="line-height:1.5"><span style="font-size:15px;color:#595959"><span
              style="font-size:15px;color:#595959"><span data-custom-class="body_text"><span style="font-size:15px"><span
                    data-custom-class="body_text">passwords</span></span></span></span></span></li>
      </ul>
      <div style="line-height:1.5"></div>
      <ul>
        <li style="line-height:1.5"><span style="font-size:15px;color:#595959"><span
              style="font-size:15px;color:#595959"><span data-custom-class="body_text"><span style="font-size:15px"><span
                    data-custom-class="body_text">debit/credit card numbers</span></span></span></span></span></li>
      </ul>
      <div style="line-height:1.5"></div>
      <ul>
        <li style="line-height:1.5"><span style="font-size:15px;color:#595959"><span
              style="font-size:15px;color:#595959"><span data-custom-class="body_text"><span style="font-size:15px"><span
                    data-custom-class="body_text">vehicle license plate, make and model, and
                    color</span></span></span></span></span></li>
      </ul>
      <div style="line-height:1.5"></div>
      <div id="sensitiveinfo" style="line-height:1.5"><span style="font-size:15px"><span
            data-custom-class="body_text"><strong>Sensitive Information.</strong>We do not process sensitive
            information.</span></span></div>
      <div style="line-height:1.5"><br></div>
      <div style="line-height:1.5"></div>
      <div style="line-height:1.5"><span style="font-size:15px;color:#595959"><span
            style="font-size:15px;color:#595959"><span data-custom-class="body_text"><strong>Payment Data.</strong>We may
              collect data necessary to process your payment if you make purchases, such as your payment instrument
              number, and the security code associated with your payment instrument. All payment data is stored by
              Verifone<span style="font-size:15px;color:#595959"><span style="font-size:15px;color:#595959"><span
                    data-custom-class="body_text">. You may find their privacy notice link(s) here:<a
                      href="https://www.verifone.com/en/global/legal/privacy-policy" target="_blank"
                      data-custom-class="link">https://www.verifone.com/en/global/legal/privacy-policy</a><span
                      style="font-size:15px;color:#595959"><span style="font-size:15px;color:#595959"><span
                          data-custom-class="body_text"><span style="font-size:15px"><span
                              data-custom-class="body_text">.</span></span></span></span></span></span></span></span></span></span></span>
      </div>
      <div style="line-height:1.5"><br></div>
      <div style="line-height:1.5"><span style="font-size:15px;color:#595959"><span
            style="font-size:15px;color:#595959"><span data-custom-class="body_text"><span style="font-size:15px"><span
                  data-custom-class="body_text">__________</span></span></span></span></span></div>
      <div style="line-height:1.5"><br></div>
      <div style="line-height:1.5"></div>
      <div style="line-height:1.5"><span data-custom-class="body_text"><span style="font-size:15px"><strong>Application
              Data.</strong>If you use our application(s), we also may collect the following information if you choose to
            provide us with access or permission:</span></span></div>
      <ul>
        <li style="line-height:1.5"><span data-custom-class="body_text"><span style="font-size:15px"><em>Geolocation
                Information.</em>We may request access or permission to track location-based information from your mobile
              device, either continuously or while you are using our mobile application(s), to provide certain
              location-based services. If you wish to change our access or permissions, you may do so in your device's
              settings.</span></span></li>
      </ul>
      <div style="line-height:1.5"></div>
      <ul>
        <li style="line-height:1.5"><span style="font-size:15px"><span data-custom-class="body_text"><em>Mobile Device
                Access.</em>We may request access or permission to certain features from your mobile device, including
              your mobile device's camera, navigation applications, and other features. If you wish to change our access
              or permissions, you may do so in your device's settings.</span></span></li>
      </ul>
      <div style="line-height:1.5">
        <div style="line-height:1.5">
          <div style="line-height:1.5"><span style="font-size:15px"><span data-custom-class="body_text">This information
                is primarily needed to maintain the security and operation of our application(s), for troubleshooting, and
                for our internal analytics and reporting purposes.</span></span></div>
          <div style="line-height:1.5"><br></div>
          <div style="line-height:1.5"></div>
          <div style="line-height:1.5"><span style="font-size:15px;color:#595959"><span
                style="font-size:15px;color:#595959"><span data-custom-class="body_text">All personal information that you
                  provide to us must be true, complete, and accurate, and you must notify us of any changes to such
                  personal information.</span></span></span></div>
          <div style="line-height:1.5"><br></div>
          <div style="line-height:1.5"></div>
          <div id="infouse" style="line-height:1.5"><span style="color:#7f7f7f"><span
                style="color:#595959;font-size:15px"><span style="font-size:15px;color:#595959"><span
                    style="font-size:15px;color:#595959"><span id="control" style="color:#000"><strong><span
                          data-custom-class="heading_1">2. HOW DO WE PROCESS YOUR
                          INFORMATION?</span></strong></span></span></span></span></span></div>
          <div>
            <div style="line-height:1.5"><br></div>
            <div style="line-height:1.5"><span style="color:#7f7f7f"><span style="color:#595959;font-size:15px"><span
                    data-custom-class="body_text"><span style="font-size:15px;color:#595959"><span
                        style="font-size:15px;color:#595959"><span data-custom-class="body_text"><strong><em>In
                              Short:</em></strong><em>We process your information to provide, improve, and administer our
                            Services, communicate with you, for security and fraud prevention, and to comply with law. We
                            may also process your information for other purposes with your
                            consent.</em></span></span></span></span></span></span></div>
          </div>
          <div style="line-height:1.5"><br></div>
          <div style="line-height:1.5"><span style="font-size:15px;color:#595959"><span
                style="font-size:15px;color:#595959"><span data-custom-class="body_text"><strong>We process your personal
                    information for a variety of reasons, depending on how you interact with our Services,
                    including:</strong></span></span></span></div>
          <ul>
            <li style="line-height:1.5"><span style="font-size:15px;color:#595959"><span
                  style="font-size:15px;color:#595959"><span data-custom-class="body_text"><strong>To facilitate account
                      creation and authentication and otherwise manage user accounts.</strong>We may process your
                    information so you can create and log in to your account, as well as keep your account in working
                    order.</span></span></span></li>
          </ul>
          <div style="line-height:1.5">
            <div style="line-height:1.5"></div>
            <ul>
              <li style="line-height:1.5"><span style="font-size:15px;color:#595959"><span
                    style="font-size:15px;color:#595959"><span data-custom-class="body_text"><strong>To deliver and
                        facilitate delivery of services to the user.</strong>We may process your information to provide
                      you with the requested service.</span></span></span></li>
            </ul>
            <div style="line-height:1.5">
              <div style="line-height:1.5"></div>
              <ul>
                <li style="line-height:1.5"><span style="font-size:15px;color:#595959"><span
                      style="font-size:15px;color:#595959"><span data-custom-class="body_text"><strong>To respond to user
                          inquiries/offer support to users.</strong>We may process your information to respond to your
                        inquiries and solve any potential issues you might have with the requested
                        service.</span></span></span></li>
              </ul>
              <div style="line-height:1.5">
                <div style="line-height:1.5">
                  <div style="line-height:1.5">
                    <div style="line-height:1.5">
                      <p style="font-size:15px;line-height:1.5"></p>
                      <p style="font-size:15px;line-height:1.5"></p>
                      <p style="font-size:15px;line-height:1.5"></p>
                      <p style="font-size:15px;line-height:1.5"></p>
                      <ul>
                        <li style="line-height:1.5"><span style="font-size:15px"><span style="color:#595959"><span
                                style="color:#595959"><span data-custom-class="body_text"><strong>To request
                                    feedback.</strong>We may process your information when necessary to request feedback
                                  and to contact you about your use of our Services.</span></span></span></span></li>
                      </ul>
                      <div style="line-height:1.5">
                        <div style="line-height:1.5">
                          <div style="line-height:1.5">
                            <div style="line-height:1.5">
                              <div style="line-height:1.5">
                                <div style="line-height:1.5">
                                  <div style="line-height:1.5"></div>
                                  <ul>
                                    <li style="line-height:1.5"><span data-custom-class="body_text"><span
                                          style="font-size:15px"><strong>To protect our Services.</strong>We may process
                                          your information as part of our efforts to keep our Services safe and secure,
                                          including fraud monitoring and prevention.</span></span></li>
                                  </ul>
                                  <div style="line-height:1.5">
                                    <div style="line-height:1.5">
                                      <div style="line-height:1.5">
                                        <div style="line-height:1.5">
                                          <div style="line-height:1.5">
                                            <div style="line-height:1.5">
                                              <div style="line-height:1.5">
                                                <div style="line-height:1.5"></div>
                                                <ul>
                                                  <li style="line-height:1.5"><span data-custom-class="body_text"><span
                                                        style="font-size:15px"><strong>To save or protect an individual's
                                                          vital interest.</strong>We may process your information when
                                                        necessary to save or protect an individual’s vital interest, such
                                                        as to prevent harm.</span></span></li>
                                                </ul>
                                                <div style="line-height:1.5"></div>
                                                <ul>
                                                  <li style="line-height:1.5"><span style="font-size:15px"><span
                                                        data-custom-class="body_text"><strong>We may process your
                                                          information</strong><strong>.</strong>to authorize and pay for
                                                        charging sessions.</span></span></li>
                                                </ul>
                                                <div style="line-height:1.5"></div>
                                                <div style="line-height:1.5"><br></div>
                                                <div id="legalbases" style="line-height:1.5"><strong><span
                                                      style="font-size:15px"><span data-custom-class="heading_1">3. WHAT
                                                        LEGAL BASES DO WE RELY ON TO PROCESS YOUR
                                                        INFORMATION?</span></span></strong></div>
                                                <div style="line-height:1.5"><br></div>
                                                <div style="line-height:1.5"><em><span style="font-size:15px"><span
                                                        data-custom-class="body_text"><strong>In Short:</strong>We only
                                                        process your personal information when we believe it is necessary
                                                        and we have a valid legal reason (i.e., legal basis) to do so
                                                        under applicable law, like with your consent, to comply with laws,
                                                        to provide you with services to enter into or fulfill our
                                                        contractual obligations, to protect your rights, or to fulfill our
                                                        legitimate business interests.</span></span></em></div>
                                                <div style="line-height:1.5"><br></div>
                                                <div style="line-height:1.5"><em><span style="font-size:15px"><span
                                                        data-custom-class="body_text"><strong><u>If you are located in the
                                                            EU or UK, this section applies to
                                                            you.</u></strong></span></span></em></div>
                                                <div style="line-height:1.5"><br></div>
                                                <div style="line-height:1.5"><span style="font-size:15px"><span
                                                      data-custom-class="body_text">The General Data Protection Regulation
                                                      (GDPR) and UK GDPR require us to explain the valid legal bases we
                                                      rely on in order to process your personal information. As such, we
                                                      may rely on the following legal bases to process your personal
                                                      information:</span></span></div>
                                                <ul>
                                                  <li style="line-height:1.5"><span style="font-size:15px"><span
                                                        data-custom-class="body_text"><strong>Consent.</strong>We may
                                                        process your information if you have given us permission (i.e.,
                                                        consent) to use your personal information for a specific purpose.
                                                        You can withdraw your consent at any time. Learn more
                                                        about</span></span><a data-custom-class="link"
                                                      href="#withdrawconsent"><span style="font-size:15px"><span
                                                          data-custom-class="body_text">withdrawing your
                                                          consent</span></span></a><span
                                                      data-custom-class="body_text">.</span></li>
                                                </ul>
                                                <div style="line-height:1.5"></div>
                                                <ul>
                                                  <li style="line-height:1.5"><span data-custom-class="body_text"><span
                                                        style="font-size:15px"><strong>Performance of a
                                                          Contract.</strong>We may process your personal information when
                                                        we believe it is necessary to fulfill our contractual obligations
                                                        to you, including providing our Services or at your request prior
                                                        to entering into a contract with you.</span></span></li>
                                                </ul>
                                                <div style="line-height:1.5"></div>
                                                <ul>
                                                  <li style="line-height:1.5"><span data-custom-class="body_text"><span
                                                        style="font-size:15px"><strong>Legitimate Interests.</strong>We
                                                        may process your information when we believe it is reasonably
                                                        necessary to achieve our legitimate business interests and those
                                                        interests do not outweigh your interests and fundamental rights
                                                        and freedoms. For example, we may process your personal
                                                        information for some of the purposes described in order
                                                        to:</span></span></li>
                                                </ul>
                                                <div style="line-height:1.5">
                                                  <div style="line-height:1.5">
                                                    <div style="line-height:1.5">
                                                      <div style="line-height:1.5">
                                                        <div style="line-height:1.5"></div>
                                                        <ul style="margin-left:40px">
                                                          <li style="line-height:1.5"><span
                                                              data-custom-class="body_text"><span
                                                                style="font-size:15px">Diagnose problems and/or prevent
                                                                fraudulent activities</span></span></li>
                                                        </ul>
                                                        <div style="line-height:1.5"></div>
                                                        <ul style="margin-left:40px">
                                                          <li style="line-height:1.5"><span
                                                              data-custom-class="body_text"><span
                                                                style="font-size:15px">Understand how our users use our
                                                                products and services so we can improve user
                                                                experience</span></span></li>
                                                        </ul>
                                                        <div style="line-height:1.5"></div>
                                                        <ul>
                                                          <li style="line-height:1.5"><span
                                                              data-custom-class="body_text"><span
                                                                style="font-size:15px"><strong>Legal
                                                                  Obligations.</strong>We may process your information
                                                                where we believe it is necessary for compliance with our
                                                                legal obligations, such as to cooperate with a law
                                                                enforcement body or regulatory agency, exercise or defend
                                                                our legal rights, or disclose your information as evidence
                                                                in litigation in which we are involved.<br></span></span>
                                                          </li>
                                                        </ul>
                                                        <div style="line-height:1.5"></div>
                                                        <ul>
                                                          <li style="line-height:1.5"><span
                                                              data-custom-class="body_text"><span
                                                                style="font-size:15px"><strong>Vital Interests.</strong>We
                                                                may process your information where we believe it is
                                                                necessary to protect your vital interests or the vital
                                                                interests of a third party, such as situations involving
                                                                potential threats to the safety of any
                                                                person.</span></span></li>
                                                        </ul>
                                                        <div style="line-height:1.5"></div>
                                                        <div style="line-height:1.5"><br></div>
                                                        <div id="whoshare" style="line-height:1.5"><span
                                                            style="color:#7f7f7f"><span
                                                              style="color:#595959;font-size:15px"><span
                                                                style="font-size:15px;color:#595959"><span
                                                                  style="font-size:15px;color:#595959"><span id="control"
                                                                    style="color:#000"><strong><span
                                                                        data-custom-class="heading_1">4. WHEN AND WITH
                                                                        WHOM DO WE SHARE YOUR PERSONAL
                                                                        INFORMATION?</span></strong></span></span></span></span></span>
                                                        </div>
                                                        <div style="line-height:1.5"><br></div>
                                                        <div style="line-height:1.5"><span
                                                            style="font-size:15px;color:#595959"><span
                                                              style="font-size:15px;color:#595959"><span
                                                                data-custom-class="body_text"><strong><em>In
                                                                    Short:</em></strong><em>We may share information in
                                                                  specific situations described in this section and/or
                                                                  with the following categories of third
                                                                  parties.</em></span></span></span></div>
                                                        <div style="line-height:1.5"></div>
                                                        <div style="line-height:1.5"><br></div>
                                                        <div style="line-height:1.5"><span style="font-size:15px"><span
                                                              data-custom-class="body_text"><strong>Vendors, Consultants,
                                                                and Other Third-Party Service Providers.</strong>We may
                                                              share your data with third-party vendors, service providers,
                                                              contractors, or agents ("<strong>third parties</strong>")
                                                              who perform services for us or on our behalf and require
                                                              access to such information to do that work. The categories
                                                              of third parties we may share personal information with are
                                                              as follows:</span></span></div>
                                                        <ul>
                                                          <li style="line-height:1.5"><span
                                                              style="font-size:15px;color:#595959"><span
                                                                style="font-size:15px;color:#595959"><span
                                                                  data-custom-class="body_text">Payment
                                                                  Processors</span></span></span></li>
                                                        </ul>
                                                        <ul>
                                                          <li style="line-height:1.5"><span
                                                              style="font-size:15px;color:#595959"><span
                                                                style="font-size:15px;color:#595959"><span
                                                                  data-custom-class="body_text">Data Storage Service
                                                                  Providers</span></span></span></li>
                                                        </ul>
                                                        <ul>
                                                          <li style="line-height:1.5"><span
                                                              style="font-size:15px;color:#595959"><span
                                                                style="font-size:15px;color:#595959"><span
                                                                  data-custom-class="body_text">Cloud Computing
                                                                  Services</span></span></span></li>
                                                        </ul>
                                                        <ul>
                                                          <li style="line-height:1.5"><span
                                                              style="font-size:15px;color:#595959"><span
                                                                style="font-size:15px;color:#595959"><span
                                                                  data-custom-class="body_text">Testing
                                                                  Tools</span></span></span></li>
                                                        </ul>
                                                        <ul>
                                                          <li style="line-height:1.5"><span
                                                              style="font-size:15px;color:#595959"><span
                                                                style="font-size:15px;color:#595959"><span
                                                                  data-custom-class="body_text">User Account Registration
                                                                  & Authentication Services</span></span></span></li>
                                                        </ul>
                                                        <div style="line-height:1.5"><br></div>
                                                        <div style="line-height:1.5"><span style="font-size:15px"><span
                                                              data-custom-class="body_text">We also may need to share your
                                                              personal information in the following
                                                              situations:</span></span></div>
                                                        <ul>
                                                          <li style="line-height:1.5"><span style="font-size:15px"><span
                                                                data-custom-class="body_text"><strong>Business
                                                                  Transfers.</strong>We may share or transfer your
                                                                information in connection with, or during negotiations of,
                                                                any merger, sale of company assets, financing, or
                                                                acquisition of all or a portion of our business to another
                                                                company.</span></span></li>
                                                        </ul>
                                                        <div style="line-height:1.5"></div>
                                                        <ul>
                                                          <li style="line-height:1.5"><span style="font-size:15px"><span
                                                                data-custom-class="body_text"><strong>When we use Google
                                                                  Maps Platform APIs.</strong>We may share your
                                                                information with certain Google Maps Platform APIs (e.g.,
                                                                Google Maps API, Places API).</span></span></li>
                                                        </ul>
                                                        <div style="line-height:1.5"></div>
                                                        <ul>
                                                          <li style="line-height:1.5"><span style="font-size:15px"><span
                                                                data-custom-class="body_text"><strong>Affiliates.</strong>We
                                                                may share your information with our affiliates, in which
                                                                case we will require those affiliates to honor this
                                                                privacy notice. Affiliates include our parent company and
                                                                any subsidiaries, joint venture partners, or other
                                                                companies that we control or that are under common control
                                                                with us.</span></span></li>
                                                        </ul>
                                                        <div style="line-height:1.5"></div>
                                                        <ul>
                                                          <li style="line-height:1.5"><span
                                                              data-custom-class="body_text"><span
                                                                style="font-size:15px"><strong>Business
                                                                  Partners.</strong>We may share your information with our
                                                                business partners to offer you certain products, services,
                                                                or promotions.</span></span></li>
                                                        </ul>
                                                        <div style="line-height:1.5">
                                                          <div style="line-height:1.5">
                                                            <div style="line-height:1.5"></div>
                                                            <div style="line-height:1.5"><br></div>
                                                            <div id="cookies" style="line-height:1.5"><span
                                                                style="color:#7f7f7f"><span
                                                                  style="color:#595959;font-size:15px"><span
                                                                    style="font-size:15px;color:#595959"><span
                                                                      style="font-size:15px;color:#595959"><span
                                                                        id="control" style="color:#000"><strong><span
                                                                            data-custom-class="heading_1">5. DO WE USE
                                                                            COOKIES AND OTHER TRACKING
                                                                            TECHNOLOGIES?</span></strong></span></span></span></span></span>
                                                            </div>
                                                            <div style="line-height:1.5"><br></div>
                                                            <div style="line-height:1.5"><span
                                                                style="font-size:15px;color:#595959"><span
                                                                  style="font-size:15px;color:#595959"><span
                                                                    data-custom-class="body_text"><strong><em>In
                                                                        Short:</em></strong><em>We may use cookies and
                                                                      other tracking technologies to collect and store
                                                                      your information.</em></span></span></span></div>
                                                            <div style="line-height:1.5"><br></div>
                                                            <div style="line-height:1.5"><span
                                                                style="font-size:15px;color:#595959"><span
                                                                  style="font-size:15px;color:#595959"><span
                                                                    data-custom-class="body_text">We may use cookies and
                                                                    similar tracking technologies (like web beacons and
                                                                    pixels) to access or store information. Specific
                                                                    information about how we use such technologies and how
                                                                    you can refuse certain cookies is set out in our
                                                                    Cookie Notice<span
                                                                      style="color:#595959;font-size:15px"><span
                                                                        data-custom-class="body_text">.</span></span></span></span></span>
                                                            </div>
                                                            <div style="line-height:1.5"><br></div>
                                                            <div id="intltransfers" style="line-height:1.5"><span
                                                                style="color:#7f7f7f"><span
                                                                  style="color:#595959;font-size:15px"><span
                                                                    style="font-size:15px;color:#595959"><span
                                                                      style="font-size:15px;color:#595959"><span
                                                                        id="control" style="color:#000"><strong><span
                                                                            data-custom-class="heading_1">6. IS YOUR
                                                                            INFORMATION TRANSFERRED
                                                                            INTERNATIONALLY?</span></strong></span></span></span></span></span>
                                                            </div>
                                                            <div style="line-height:1.5"><br></div>
                                                            <div style="line-height:1.5"><span
                                                                style="font-size:15px;color:#595959"><span
                                                                  style="font-size:15px;color:#595959"><span
                                                                    data-custom-class="body_text"><strong><em>In
                                                                        Short:</em></strong><em>We may transfer, store,
                                                                      and process your information in countries other than
                                                                      your own.</em></span></span></span></div>
                                                            <div style="line-height:1.5"><br></div>
                                                            <div data-custom-class="body_text" style="line-height:1.5">
                                                              <span style="font-size:15px;color:#595959"><span
                                                                  style="font-size:15px;color:#595959"><span
                                                                    data-custom-class="body_text">Our servers are located
                                                                    in<span style="font-size:15px"><span
                                                                        style="color:#595959"><span
                                                                          data-custom-class="body_text">the<span
                                                                            style="font-size:15px;color:#595959"><span
                                                                              style="font-size:15px;color:#595959"><span
                                                                                data-custom-class="body_text"><span
                                                                                  style="font-size:15px"><span
                                                                                    style="color:#595959"><span
                                                                                      data-custom-class="body_text">United
                                                                                      States</span></span></span></span></span></span></span></span></span></span></span></span><span
                                                                style="font-size:15px;color:#595959"><span
                                                                  style="font-size:15px;color:#595959"><span
                                                                    data-custom-class="body_text">. If you are accessing
                                                                    our Services from outside</span><span
                                                                    data-custom-class="body_text"><span
                                                                      style="font-size:15px"><span
                                                                        style="color:#595959"><span
                                                                          data-custom-class="body_text">the<span
                                                                            style="font-size:15px;color:#595959"><span
                                                                              style="font-size:15px;color:#595959"><span
                                                                                data-custom-class="body_text"><span
                                                                                  style="font-size:15px"><span
                                                                                    style="color:#595959"><span
                                                                                      data-custom-class="body_text">United
                                                                                      States</span></span></span></span></span></span></span></span></span></span></span></span><span
                                                                data-custom-class="body_text">, please be aware that your
                                                                information may be transferred to, stored, and processed
                                                                by us in our facilities and by those third parties with
                                                                whom we may share your personal information (see
                                                                "</span><a data-custom-class="link" href="#whoshare"><span
                                                                  style="font-size:15px"><span style="color:#595959">WHEN
                                                                    AND WITH WHOM DO WE SHARE YOUR PERSONAL
                                                                    INFORMATION?</span></span></a><span
                                                                style="font-size:15px;color:#595959"><span
                                                                  style="font-size:15px;color:#595959"><span
                                                                    data-custom-class="body_text">" above),
                                                                    in</span></span></span><span
                                                                style="font-size:15px"><span style="color:#595959"><span
                                                                    data-custom-class="body_text"><span
                                                                      style="font-size:15px;color:#595959"><span
                                                                        style="font-size:15px;color:#595959"><span
                                                                          data-custom-class="body_text"><span
                                                                            style="font-size:15px;color:#595959"><span
                                                                              style="font-size:15px;color:#595959"><span
                                                                                data-custom-class="body_text"><span
                                                                                  style="font-size:15px"><span
                                                                                    style="color:#595959"><span
                                                                                      data-custom-class="body_text"><span
                                                                                        style="font-size:15px;color:#595959"><span
                                                                                          style="font-size:15px;color:#595959"><span
                                                                                            data-custom-class="body_text"><span
                                                                                              style="font-size:15px"><span
                                                                                                style="color:#595959"><span
                                                                                                  data-custom-class="body_text">Israel,</span></span></span></span></span></span></span></span></span></span></span></span><span
                                                                            style="font-size:15px;color:#595959"><span
                                                                              style="font-size:15px;color:#595959"><span
                                                                                data-custom-class="body_text">and other
                                                                                countries.</span></span></span></span></span></span></span></span></span>
                                                            </div>
                                                            <div style="line-height:1.5"><br></div>
                                                            <div style="line-height:1.5"><span
                                                                style="font-size:15px;color:#595959"><span
                                                                  style="font-size:15px;color:#595959"><span
                                                                    data-custom-class="body_text">If you are a resident in
                                                                    the European Economic Area (EEA), United Kingdom (UK),
                                                                    or Switzerland, then these countries may not
                                                                    necessarily have data protection laws or other similar
                                                                    laws as comprehensive as those in your country.
                                                                    However, we will take all necessary measures to
                                                                    protect your personal information in accordance with
                                                                    this privacy notice and applicable
                                                                    law.</span></span></span></div>
                                                            <div style="line-height:1.5"><br></div>
                                                            <div style="line-height:1.5"><span
                                                                style="font-size:15px;color:#595959"><span
                                                                  style="font-size:15px;color:#595959"><span
                                                                    data-custom-class="body_text">European Commission's
                                                                    Standard Contractual Clauses:</span></span></span>
                                                            </div>
                                                            <div style="line-height:1.5"><br></div>
                                                            <div style="line-height:1.5"><span
                                                                style="font-size:15px;color:#595959"><span
                                                                  style="font-size:15px;color:#595959"><span
                                                                    data-custom-class="body_text">We have implemented
                                                                    measures to protect your personal information,
                                                                    including by using the European Commission's Standard
                                                                    Contractual Clauses for transfers of personal
                                                                    information between our group companies and between us
                                                                    and our third-party providers. These clauses require
                                                                    all recipients to protect all personal information
                                                                    that they process originating from the EEA or UK in
                                                                    accordance with European data protection laws and
                                                                    regulations. Our Data Processing Agreements that
                                                                    include Standard Contractual Clauses are available
                                                                    here:<span style="font-size:15px"><span
                                                                        style="color:#595959"><span
                                                                          data-custom-class="body_text"><a
                                                                            href="https://docs.google.com/document/d/1FqPElh5LTJRp5Z56x8TghFIouhOEqhOk/edit"
                                                                            target="_blank"
                                                                            data-custom-class="link">https://docs.google.com/document/d/1FqPElh5LTJRp5Z56x8TghFIouhOEqhOk/edit</a></span></span></span>.
                                                                    We have implemented similar appropriate safeguards
                                                                    with our third-party service providers and partners
                                                                    and further details can be provided upon
                                                                    request.</span></span></span></div>
                                                            <div style="line-height:1.5"><br></div>
                                                            <div id="inforetain" style="line-height:1.5"><span
                                                                style="color:#7f7f7f"><span
                                                                  style="color:#595959;font-size:15px"><span
                                                                    style="font-size:15px;color:#595959"><span
                                                                      style="font-size:15px;color:#595959"><span
                                                                        id="control" style="color:#000"><strong><span
                                                                            data-custom-class="heading_1">7. HOW LONG DO
                                                                            WE KEEP YOUR
                                                                            INFORMATION?</span></strong></span></span></span></span></span>
                                                            </div>
                                                            <div style="line-height:1.5"><br></div>
                                                            <div style="line-height:1.5"><span
                                                                style="font-size:15px;color:#595959"><span
                                                                  style="font-size:15px;color:#595959"><span
                                                                    data-custom-class="body_text"><strong><em>In
                                                                        Short:</em></strong><em>We keep your information
                                                                      for as long as necessary to fulfill the purposes
                                                                      outlined in this privacy notice unless otherwise
                                                                      required by law.</em></span></span></span></div>
                                                            <div style="line-height:1.5"><br></div>
                                                            <div style="line-height:1.5"><span
                                                                style="font-size:15px;color:#595959"><span
                                                                  style="font-size:15px;color:#595959"><span
                                                                    data-custom-class="body_text">We will only keep your
                                                                    personal information for as long as it is necessary
                                                                    for the purposes set out in this privacy notice,
                                                                    unless a longer retention period is required or
                                                                    permitted by law (such as tax, accounting, or other
                                                                    legal requirements). No purpose in this notice will
                                                                    require us keeping your personal information for
                                                                    longer than the period of time in which users have an
                                                                    account with us.</span></span></span></div>
                                                            <div style="line-height:1.5"><br></div>
                                                            <div style="line-height:1.5"><span
                                                                style="font-size:15px;color:#595959"><span
                                                                  style="font-size:15px;color:#595959"><span
                                                                    data-custom-class="body_text">When we have no ongoing
                                                                    legitimate business need to process your personal
                                                                    information, we will either delete or anonymize such
                                                                    information, or, if this is not possible (for example,
                                                                    because your personal information has been stored in
                                                                    backup archives), then we will securely store your
                                                                    personal information and isolate it from any further
                                                                    processing until deletion is
                                                                    possible.</span></span></span></div>
                                                            <div style="line-height:1.5"><br></div>
                                                            <div id="infosafe" style="line-height:1.5"><span
                                                                style="color:#7f7f7f"><span
                                                                  style="color:#595959;font-size:15px"><span
                                                                    style="font-size:15px;color:#595959"><span
                                                                      style="font-size:15px;color:#595959"><span
                                                                        id="control" style="color:#000"><strong><span
                                                                            data-custom-class="heading_1">8. HOW DO WE
                                                                            KEEP YOUR INFORMATION
                                                                            SAFE?</span></strong></span></span></span></span></span>
                                                            </div>
                                                            <div style="line-height:1.5"><br></div>
                                                            <div style="line-height:1.5"><span
                                                                style="font-size:15px;color:#595959"><span
                                                                  style="font-size:15px;color:#595959"><span
                                                                    data-custom-class="body_text"><strong><em>In
                                                                        Short:</em></strong><em>We aim to protect your
                                                                      personal information through a system of
                                                                      organizational and technical security
                                                                      measures.</em></span></span></span></div>
                                                            <div style="line-height:1.5"><br></div>
                                                            <div style="line-height:1.5"><span
                                                                style="font-size:15px;color:#595959"><span
                                                                  style="font-size:15px;color:#595959"><span
                                                                    data-custom-class="body_text">We have implemented
                                                                    appropriate and reasonable technical and
                                                                    organizational security measures designed to protect
                                                                    the security of any personal information we process.
                                                                    However, despite our safeguards and efforts to secure
                                                                    your information, no electronic transmission over the
                                                                    Internet or information storage technology can be
                                                                    guaranteed to be 100% secure, so we cannot promise or
                                                                    guarantee that hackers, cybercriminals, or other
                                                                    unauthorized third parties will not be able to defeat
                                                                    our security and improperly collect, access, steal, or
                                                                    modify your information. Although we will do our best
                                                                    to protect your personal information, transmission of
                                                                    personal information to and from our Services is at
                                                                    your own risk. You should only access the Services
                                                                    within a secure environment.</span></span></span>
                                                            </div>
                                                            <div style="line-height:1.5"><br></div>
                                                            <div id="infominors" style="line-height:1.5"><span
                                                                style="color:#7f7f7f"><span
                                                                  style="color:#595959;font-size:15px"><span
                                                                    style="font-size:15px;color:#595959"><span
                                                                      style="font-size:15px;color:#595959"><span
                                                                        id="control" style="color:#000"><strong><span
                                                                            data-custom-class="heading_1">9. DO WE COLLECT
                                                                            INFORMATION FROM
                                                                            MINORS?</span></strong></span></span></span></span></span>
                                                            </div>
                                                            <div style="line-height:1.5"><br></div>
                                                            <div style="line-height:1.5"><span
                                                                style="font-size:15px;color:#595959"><span
                                                                  style="font-size:15px;color:#595959"><span
                                                                    data-custom-class="body_text"><strong><em>In
                                                                        Short:</em></strong><em>We do not knowingly
                                                                      collect data from or market to children under 18
                                                                      years of age.</em></span></span></span></div>
                                                            <div style="line-height:1.5"><br></div>
                                                            <div style="line-height:1.5"><span
                                                                style="font-size:15px;color:#595959"><span
                                                                  style="font-size:15px;color:#595959"><span
                                                                    data-custom-class="body_text">We do not knowingly
                                                                    solicit data from or market to children under 18 years
                                                                    of age. By using the Services, you represent that you
                                                                    are at least 18 or that you are the parent or guardian
                                                                    of such a minor and consent to such minor dependent’s
                                                                    use of the Services. If we learn that personal
                                                                    information from users less than 18 years of age has
                                                                    been collected, we will deactivate the account and
                                                                    take reasonable measures to promptly delete such data
                                                                    from our records. If you become aware of any data we
                                                                    may have collected from children under age 18, please
                                                                    contact us at<span
                                                                      style="color:#595959;font-size:15px"><span
                                                                        data-custom-class="body_text">support@quantexinnovations.com</span></span>.</span></span></span>
                                                            </div>
                                                            <div style="line-height:1.5"><br></div>
                                                            <div id="privacyrights" style="line-height:1.5"><span
                                                                style="color:#7f7f7f"><span
                                                                  style="color:#595959;font-size:15px"><span
                                                                    style="font-size:15px;color:#595959"><span
                                                                      style="font-size:15px;color:#595959"><span
                                                                        id="control" style="color:#000"><strong><span
                                                                            data-custom-class="heading_1">10. WHAT ARE
                                                                            YOUR PRIVACY
                                                                            RIGHTS?</span></strong></span></span></span></span></span>
                                                            </div>
                                                            <div style="line-height:1.5"><br></div>
                                                            <div style="line-height:1.5"><span
                                                                style="font-size:15px;color:#595959"><span
                                                                  style="font-size:15px;color:#595959"><span
                                                                    data-custom-class="body_text"><strong><em>In
                                                                        Short:</em></strong><em>In some regions, such as
                                                                      the European Economic Area (EEA), United Kingdom
                                                                      (UK), and Switzerland, you have rights that allow
                                                                      you greater access to and control over your personal
                                                                      information. You may review, change, or terminate
                                                                      your account at any time.</em></span></span></span>
                                                            </div>
                                                            <div style="line-height:1.5"><br></div>
                                                            <div style="line-height:1.5"><span
                                                                style="font-size:15px;color:#595959"><span
                                                                  style="font-size:15px;color:#595959"><span
                                                                    data-custom-class="body_text">In some regions (like
                                                                    the EEA, UK, and Switzerland), you have certain rights
                                                                    under applicable data protection laws. These may
                                                                    include the right (i) to request access and obtain a
                                                                    copy of your personal information, (ii) to request
                                                                    rectification or erasure; (iii) to restrict the
                                                                    processing of your personal information; (vi) if
                                                                    applicable, to data portability; and (vii) not to be
                                                                    subject to automated decision-making. In certain
                                                                    circumstances, you may also have the right to object
                                                                    to the processing of your personal information. You
                                                                    can make such a request by contacting us by using the
                                                                    contact details provided in the section
                                                                    "</span></span></span><a data-custom-class="link"
                                                                href="#contact"><span
                                                                  style="font-size:15px;color:#595959"><span
                                                                    style="font-size:15px;color:#595959"><span
                                                                      data-custom-class="body_text">HOW CAN YOU CONTACT US
                                                                      ABOUT THIS NOTICE?</span></span></span></a><span
                                                                style="font-size:15px;color:#595959"><span
                                                                  style="font-size:15px;color:#595959"><span
                                                                    data-custom-class="body_text">"
                                                                    below.</span></span></span></div>
                                                            <div style="line-height:1.5"><br></div>
                                                            <div style="line-height:1.5"><span
                                                                style="font-size:15px;color:#595959"><span
                                                                  style="font-size:15px;color:#595959"><span
                                                                    data-custom-class="body_text">We will consider and act
                                                                    upon any request in accordance with applicable data
                                                                    protection laws.</span></span></span></div>
                                                            <div style="line-height:1.5"></div>
                                                            <div style="line-height:1.5"><span
                                                                style="font-size:15px;color:#595959"><span
                                                                  style="font-size:15px;color:#595959"><span
                                                                    data-custom-class="body_text">If you are located in
                                                                    the EEA or UK and you believe we are unlawfully
                                                                    processing your personal information, you also have
                                                                    the right to complain to your<span
                                                                      style="font-size:15px"><span
                                                                        style="color:#595959"><span
                                                                          data-custom-class="body_text"><span
                                                                            style="color:#3030f1"><span
                                                                              data-custom-class="body_text"><a
                                                                                data-custom-class="link"
                                                                                href="https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm"
                                                                                rel="noopener noreferrer"
                                                                                target="_blank"><span
                                                                                  style="font-size:15px">Member State data
                                                                                  protection
                                                                                  authority</span></a></span></span></span></span></span>or</span></span></span><a
                                                                href="https://ico.org.uk/make-a-complaint/data-protection-complaints/data-protection-complaints/"
                                                                rel="noopener noreferrer" target="_blank"><span
                                                                  data-custom-class="link"><span
                                                                    style="font-size:15px;color:#595959"><span
                                                                      style="font-size:15px;color:#595959"><span
                                                                        data-custom-class="body_text">UK data protection
                                                                        authority</span></span></span></span></a><span
                                                                style="font-size:15px;color:#595959"><span
                                                                  style="font-size:15px;color:#595959"><span
                                                                    data-custom-class="body_text">.</span></span></span>
                                                            </div>
                                                            <div style="line-height:1.5"><br></div>
                                                            <div style="line-height:1.5"><span
                                                                style="font-size:15px;color:#595959"><span
                                                                  style="font-size:15px;color:#595959"><span
                                                                    data-custom-class="body_text">If you are located in
                                                                    Switzerland, you may contact the<span
                                                                      style="font-size:15px"><span
                                                                        style="color:#595959"><span
                                                                          data-custom-class="body_text"><span
                                                                            style="color:#3030f1"><span
                                                                              data-custom-class="body_text"><span
                                                                                style="font-size:15px"><a
                                                                                  data-custom-class="link"
                                                                                  href="https://www.edoeb.admin.ch/edoeb/en/home.html"
                                                                                  rel="noopener noreferrer"
                                                                                  target="_blank">Federal Data Protection
                                                                                  and Information
                                                                                  Commissioner</a></span></span></span></span></span></span>.</span></span></span>
                                                            </div>
                                                            <div style="line-height:1.5"><br></div>
                                                            <div id="withdrawconsent" style="line-height:1.5"><span
                                                                style="font-size:15px;color:#595959"><span
                                                                  style="font-size:15px;color:#595959"><span
                                                                    data-custom-class="body_text"><strong><u>Withdrawing
                                                                        your consent:</u></strong>If we are relying on
                                                                    your consent to process your personal information,
                                                                    which may be express and/or implied consent depending
                                                                    on the applicable law, you have the right to withdraw
                                                                    your consent at any time. You can withdraw your
                                                                    consent at any time by contacting us by using the
                                                                    contact details provided in the section
                                                                    "</span></span></span><a data-custom-class="link"
                                                                href="#contact"><span
                                                                  style="font-size:15px;color:#595959"><span
                                                                    style="font-size:15px;color:#595959"><span
                                                                      data-custom-class="body_text">HOW CAN YOU CONTACT US
                                                                      ABOUT THIS NOTICE?</span></span></span></a><span
                                                                style="font-size:15px;color:#595959"><span
                                                                  style="font-size:15px;color:#595959"><span
                                                                    data-custom-class="body_text">"
                                                                    below.</span></span></span></div>
                                                            <div style="line-height:1.5"><br></div>
                                                            <div style="line-height:1.5"><span
                                                                style="font-size:15px"><span
                                                                  data-custom-class="body_text">However, please note that
                                                                  this will not affect the lawfulness of the processing
                                                                  before its withdrawal nor, when applicable law allows,
                                                                  will it affect the processing of your personal
                                                                  information conducted in reliance on lawful processing
                                                                  grounds other than consent.</span></span></div>
                                                            <div style="line-height:1.5"><br></div>
                                                            <div style="line-height:1.5"><span
                                                                style="font-size:15px"><span
                                                                  data-custom-class="heading_2"><strong>Account
                                                                    Information</strong></span></span></div>
                                                            <div style="line-height:1.5"><br></div>
                                                            <div style="line-height:1.5"><span
                                                                data-custom-class="body_text"><span
                                                                  style="font-size:15px">If you would at any time like to
                                                                  review or change the information in your account or
                                                                  terminate your account, you can:</span></span></div>
                                                            <ul>
                                                              <li style="line-height:1.5"><span
                                                                  data-custom-class="body_text"><span
                                                                    style="font-size:15px">Log in to your account settings
                                                                    and update your user account.</span></span></li>
                                                            </ul>
                                                            <div style="line-height:1.5"></div>
                                                            <div style="line-height:1.5"><span
                                                                style="font-size:15px"><span
                                                                  data-custom-class="body_text">Upon your request to
                                                                  terminate your account, we will deactivate or delete
                                                                  your account and information from our active databases.
                                                                  However, we may retain some information in our files to
                                                                  prevent fraud, troubleshoot problems, assist with any
                                                                  investigations, enforce our legal terms and/or comply
                                                                  with applicable legal requirements.</span></span></div>
                                                            <div style="line-height:1.5"><br></div>
                                                            <div style="line-height:1.5"><span
                                                                style="font-size:15px;color:#595959"><span
                                                                  style="font-size:15px;color:#595959"><span
                                                                    data-custom-class="body_text"><strong><u>Cookies and
                                                                        similar technologies:</u></strong>Most Web
                                                                    browsers are set to accept cookies by default. If you
                                                                    prefer, you can usually choose to set your browser to
                                                                    remove cookies and to reject cookies. If you choose to
                                                                    remove cookies or reject cookies, this could affect
                                                                    certain features or services of our Services. You may
                                                                    also<span style="color:#3030f1"><span
                                                                        data-custom-class="body_text"><a
                                                                          data-custom-class="link"
                                                                          href="http://www.aboutads.info/choices/"
                                                                          rel="noopener noreferrer" target="_blank"><span
                                                                            style="font-size:15px">opt out of
                                                                            interest-based advertising by
                                                                            advertisers</span></a></span></span>on our
                                                                    Services.</span></span></span></div>
                                                            <div style="line-height:1.5"><br></div>
                                                            <div style="line-height:1.5"><span
                                                                data-custom-class="body_text"><span
                                                                  style="font-size:15px">If you have questions or comments
                                                                  about your privacy rights, you may email us at
                                                                  support@quantexinnovations.com.</span></span></div>
                                                            <div style="line-height:1.5"><br></div>
                                                            <div id="DNT" style="line-height:1.5"><span
                                                                style="color:#7f7f7f"><span
                                                                  style="color:#595959;font-size:15px"><span
                                                                    style="font-size:15px;color:#595959"><span
                                                                      style="font-size:15px;color:#595959"><span
                                                                        id="control" style="color:#000"><strong><span
                                                                            data-custom-class="heading_1">11. CONTROLS FOR
                                                                            DO-NOT-TRACK
                                                                            FEATURES</span></strong></span></span></span></span></span>
                                                            </div>
                                                            <div style="line-height:1.5"><br></div>
                                                            <div style="line-height:1.5"><span
                                                                style="font-size:15px;color:#595959"><span
                                                                  style="font-size:15px;color:#595959"><span
                                                                    data-custom-class="body_text">Most web browsers and
                                                                    some mobile operating systems and mobile applications
                                                                    include a Do-Not-Track ("DNT") feature or setting you
                                                                    can activate to signal your privacy preference not to
                                                                    have data about your online browsing activities
                                                                    monitored and collected. At this stage no uniform
                                                                    technology standard for recognizing and implementing
                                                                    DNT signals has been finalized. As such, we do not
                                                                    currently respond to DNT browser signals or any other
                                                                    mechanism that automatically communicates your choice
                                                                    not to be tracked online. If a standard for online
                                                                    tracking is adopted that we must follow in the future,
                                                                    we will inform you about that practice in a revised
                                                                    version of this privacy notice.</span></span></span>
                                                            </div>
                                                            <div style="line-height:1.5"><br></div>
                                                            <div id="caresidents" style="line-height:1.5"><span
                                                                style="color:#7f7f7f"><span
                                                                  style="color:#595959;font-size:15px"><span
                                                                    style="font-size:15px;color:#595959"><span
                                                                      style="font-size:15px;color:#595959"><span
                                                                        id="control" style="color:#000"><strong><span
                                                                            data-custom-class="heading_1">12. DO
                                                                            CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY
                                                                            RIGHTS?</span></strong></span></span></span></span></span>
                                                            </div>
                                                            <div style="line-height:1.5"><br></div>
                                                            <div style="line-height:1.5"><span
                                                                style="font-size:15px;color:#595959"><span
                                                                  style="font-size:15px;color:#595959"><span
                                                                    data-custom-class="body_text"><strong><em>In
                                                                        Short:</em></strong><em>Yes, if you are a resident
                                                                      of California, you are granted specific rights
                                                                      regarding access to your personal
                                                                      information.</em></span></span></span></div>
                                                            <div style="line-height:1.5"><br></div>
                                                            <div style="line-height:1.5"><span
                                                                style="font-size:15px;color:#595959"><span
                                                                  style="font-size:15px;color:#595959"><span
                                                                    data-custom-class="body_text">California Civil Code
                                                                    Section 1798.83, also known as the "Shine The Light"
                                                                    law, permits our users who are California residents to
                                                                    request and obtain from us, once a year and free of
                                                                    charge, information about categories of personal
                                                                    information (if any) we disclosed to third parties for
                                                                    direct marketing purposes and the names and addresses
                                                                    of all third parties with which we shared personal
                                                                    information in the immediately preceding calendar
                                                                    year. If you are a California resident and would like
                                                                    to make such a request, please submit your request in
                                                                    writing to us using the contact information provided
                                                                    below.</span></span></span></div>
                                                            <div style="line-height:1.5"><br></div>
                                                            <div style="line-height:1.5"><span
                                                                style="font-size:15px;color:#595959"><span
                                                                  style="font-size:15px;color:#595959"><span
                                                                    data-custom-class="body_text">If you are under 18
                                                                    years of age, reside in California, and have a
                                                                    registered account with Services, you have the right
                                                                    to request removal of unwanted data that you publicly
                                                                    post on the Services. To request removal of such data,
                                                                    please contact us using the contact information
                                                                    provided below and include the email address
                                                                    associated with your account and a statement that you
                                                                    reside in California. We will make sure the data is
                                                                    not publicly displayed on the Services, but please be
                                                                    aware that the data may not be completely or
                                                                    comprehensively removed from all our systems (e.g.,
                                                                    backups, etc.).</span></span></span></div>
                                                            <div style="line-height:1.5"><br></div>
                                                            <div style="line-height:1.5"><span
                                                                data-custom-class="heading_2" style="color:#000"><span
                                                                  style="font-size:15px"><strong>CCPA Privacy
                                                                    Notice</strong></span></span></div>
                                                            <div>
                                                              <div><br></div>
                                                              <div style="line-height:1.5"><span
                                                                  style="color:#7f7f7f"><span
                                                                    style="color:#595959;font-size:15px"><span
                                                                      data-custom-class="body_text"><span
                                                                        style="font-size:15px;color:#595959"><span
                                                                          style="font-size:15px;color:#595959"><span
                                                                            data-custom-class="body_text">The California
                                                                            Code of Regulations defines a "resident"
                                                                            as:</span></span></span></span></span></span>
                                                              </div>
                                                            </div>
                                                            <div style="line-height:1.5"><br></div>
                                                            <div style="line-height:1.5;margin-left:20px"><span
                                                                style="font-size:15px;color:#595959"><span
                                                                  style="font-size:15px;color:#595959"><span
                                                                    data-custom-class="body_text">(1) every individual who
                                                                    is in the State of California for other than a
                                                                    temporary or transitory purpose
                                                                    and</span></span></span></div>
                                                            <div style="line-height:1.5;margin-left:20px"><span
                                                                style="font-size:15px;color:#595959"><span
                                                                  style="font-size:15px;color:#595959"><span
                                                                    data-custom-class="body_text">(2) every individual who
                                                                    is domiciled in the State of California who is outside
                                                                    the State of California for a temporary or transitory
                                                                    purpose</span></span></span></div>
                                                            <div style="line-height:1.5"><br></div>
                                                            <div style="line-height:1.5"><span
                                                                style="font-size:15px;color:#595959"><span
                                                                  style="font-size:15px;color:#595959"><span
                                                                    data-custom-class="body_text">All other individuals
                                                                    are defined as "non-residents."</span></span></span>
                                                            </div>
                                                            <div style="line-height:1.5"><br></div>
                                                            <div style="line-height:1.5"><span
                                                                style="font-size:15px;color:#595959"><span
                                                                  style="font-size:15px;color:#595959"><span
                                                                    data-custom-class="body_text">If this definition of
                                                                    "resident" applies to you, we must adhere to certain
                                                                    rights and obligations regarding your personal
                                                                    information.</span></span></span></div>
                                                            <div style="line-height:1.5"><br></div>
                                                            <div style="line-height:1.5"><span
                                                                style="font-size:15px;color:#595959"><span
                                                                  style="font-size:15px;color:#595959"><span
                                                                    data-custom-class="body_text"><strong>What categories
                                                                      of personal information do we
                                                                      collect?</strong></span></span></span></div>
                                                            <div style="line-height:1.5"><br></div>
                                                            <div style="line-height:1.5"><span
                                                                style="font-size:15px;color:#595959"><span
                                                                  style="font-size:15px;color:#595959"><span
                                                                    data-custom-class="body_text">We have collected the
                                                                    following categories of personal information in the
                                                                    past twelve (12) months:</span></span></span></div>
                                                            <div style="line-height:1.5"><span
                                                                style="font-size:15px;color:#595959"><br></span></div>
                                                            <table style="width:100%">
                                                              <tbody>
                                                                <tr>
                                                                  <td
                                                                    style="width:33.8274%;border-left:1px solid #000;border-right:1px solid #000;border-top:1px solid #000">
                                                                    <span style="font-size:15px;color:#595959"><span
                                                                        style="font-size:15px;color:#595959"><span
                                                                          data-custom-class="body_text"><strong>Category</strong></span></span></span>
                                                                  </td>
                                                                  <td
                                                                    style="width:51.4385%;border-top:1px solid #000;border-right:1px solid #000">
                                                                    <span style="font-size:15px;color:#595959"><span
                                                                        style="font-size:15px;color:#595959"><span
                                                                          data-custom-class="body_text"><strong>Examples</strong></span></span></span>
                                                                  </td>
                                                                  <td
                                                                    style="width:14.9084%;border-right:1px solid #000;border-top:1px solid #000;text-align:center">
                                                                    <span style="font-size:15px;color:#595959"><span
                                                                        style="font-size:15px;color:#595959"><span
                                                                          data-custom-class="body_text"><strong>Collected</strong></span></span></span>
                                                                  </td>
                                                                </tr>
                                                                <tr>
                                                                  <td
                                                                    style="width:33.8274%;border-left:1px solid #000;border-right:1px solid #000;border-top:1px solid #000">
                                                                    <div style="line-height:1.5"><span
                                                                        style="font-size:15px;color:#595959"><span
                                                                          style="font-size:15px;color:#595959"><span
                                                                            data-custom-class="body_text">A.
                                                                            Identifiers</span></span></span></div>
                                                                  </td>
                                                                  <td
                                                                    style="width:51.4385%;border-top:1px solid #000;border-right:1px solid #000">
                                                                    <div style="line-height:1.5"><span
                                                                        style="font-size:15px;color:#595959"><span
                                                                          style="font-size:15px;color:#595959"><span
                                                                            data-custom-class="body_text">Contact details,
                                                                            such as real name, alias, postal address,
                                                                            telephone or mobile contact number, unique
                                                                            personal identifier, online identifier,
                                                                            Internet Protocol address, email address, and
                                                                            account name</span></span></span></div>
                                                                  </td>
                                                                  <td
                                                                    style="width:14.9084%;text-align:center;vertical-align:middle;border-right:1px solid #000;border-top:1px solid #000">
                                                                    <div style="line-height:1.5"><br></div>
                                                                    <div data-custom-class="body_text"
                                                                      style="line-height:1.5"><span
                                                                        data-custom-class="body_text">NO</span></div>
                                                                    <div style="line-height:1.5"><br></div>
                                                                  </td>
                                                                </tr>
                                                                <tr>
                                                                  <td
                                                                    style="width:33.8274%;border-left:1px solid #000;border-right:1px solid #000;border-top:1px solid #000">
                                                                    <div style="line-height:1.5"><span
                                                                        style="font-size:15px;color:#595959"><span
                                                                          style="font-size:15px;color:#595959"><span
                                                                            data-custom-class="body_text">B. Personal
                                                                            information categories listed in the
                                                                            California Customer Records
                                                                            statute</span></span></span></div>
                                                                  </td>
                                                                  <td
                                                                    style="width:51.4385%;border-right:1px solid #000;border-top:1px solid #000">
                                                                    <div style="line-height:1.5"><span
                                                                        style="font-size:15px;color:#595959"><span
                                                                          style="font-size:15px;color:#595959"><span
                                                                            data-custom-class="body_text">Name, contact
                                                                            information, education, employment, employment
                                                                            history, and financial
                                                                            information</span></span></span></div>
                                                                  </td>
                                                                  <td
                                                                    style="width:14.9084%;text-align:center;border-right:1px solid #000;border-top:1px solid #000">
                                                                    <div style="line-height:1.5"><br></div>
                                                                    <div style="line-height:1.5"><span
                                                                        style="font-size:15px;color:#595959"><span
                                                                          style="font-size:15px;color:#595959"><span
                                                                            data-custom-class="body_text">NO</span></span></span>
                                                                    </div>
                                                                    <div style="line-height:1.5"><br></div>
                                                                  </td>
                                                                </tr>
                                                                <tr>
                                                                  <td
                                                                    style="width:33.8274%;border-left:1px solid #000;border-right:1px solid #000;border-top:1px solid #000">
                                                                    <div style="line-height:1.5"><span
                                                                        style="font-size:15px;color:#595959"><span
                                                                          style="font-size:15px;color:#595959"><span
                                                                            data-custom-class="body_text">C. Protected
                                                                            classification characteristics under
                                                                            California or federal law</span></span></span>
                                                                    </div>
                                                                  </td>
                                                                  <td
                                                                    style="width:51.4385%;border-right:1px solid #000;border-top:1px solid #000">
                                                                    <div style="line-height:1.5"><span
                                                                        style="font-size:15px;color:#595959"><span
                                                                          style="font-size:15px;color:#595959"><span
                                                                            data-custom-class="body_text">Gender and date
                                                                            of birth</span></span></span></div>
                                                                  </td>
                                                                  <td
                                                                    style="width:14.9084%;text-align:center;border-right:1px solid #000;border-top:1px solid #000">
                                                                    <div style="line-height:1.5"><br></div>
                                                                    <div data-custom-class="body_text"
                                                                      style="line-height:1.5"><span
                                                                        data-custom-class="body_text">NO</span></div>
                                                                    <div style="line-height:1.5"><br></div>
                                                                  </td>
                                                                </tr>
                                                                <tr>
                                                                  <td
                                                                    style="width:33.8274%;border-left:1px solid #000;border-right:1px solid #000;border-top:1px solid #000">
                                                                    <div style="line-height:1.5"><span
                                                                        style="font-size:15px;color:#595959"><span
                                                                          style="font-size:15px;color:#595959"><span
                                                                            data-custom-class="body_text">D. Commercial
                                                                            information</span></span></span></div>
                                                                  </td>
                                                                  <td
                                                                    style="width:51.4385%;border-right:1px solid #000;border-top:1px solid #000">
                                                                    <div style="line-height:1.5"><span
                                                                        style="font-size:15px;color:#595959"><span
                                                                          style="font-size:15px;color:#595959"><span
                                                                            data-custom-class="body_text">Transaction
                                                                            information, purchase history, financial
                                                                            details, and payment
                                                                            information</span></span></span></div>
                                                                  </td>
                                                                  <td
                                                                    style="width:14.9084%;text-align:center;border-right:1px solid #000;border-top:1px solid #000">
                                                                    <div style="line-height:1.5"><br></div>
                                                                    <div data-custom-class="body_text"
                                                                      style="line-height:1.5"><span
                                                                        data-custom-class="body_text">NO</span></div>
                                                                    <div style="line-height:1.5"><br></div>
                                                                  </td>
                                                                </tr>
                                                                <tr>
                                                                  <td
                                                                    style="width:33.8274%;border-left:1px solid #000;border-right:1px solid #000;border-top:1px solid #000">
                                                                    <div style="line-height:1.5"><span
                                                                        style="font-size:15px;color:#595959"><span
                                                                          style="font-size:15px;color:#595959"><span
                                                                            data-custom-class="body_text">E. Biometric
                                                                            information</span></span></span></div>
                                                                  </td>
                                                                  <td
                                                                    style="width:51.4385%;border-right:1px solid #000;border-top:1px solid #000">
                                                                    <div style="line-height:1.5"><span
                                                                        style="font-size:15px;color:#595959"><span
                                                                          style="font-size:15px;color:#595959"><span
                                                                            data-custom-class="body_text">Fingerprints and
                                                                            voiceprints</span></span></span></div>
                                                                  </td>
                                                                  <td
                                                                    style="width:14.9084%;text-align:center;border-right:1px solid #000;border-top:1px solid #000">
                                                                    <div style="line-height:1.5"><br></div>
                                                                    <div data-custom-class="body_text"
                                                                      style="line-height:1.5"><span
                                                                        data-custom-class="body_text">NO</span></div>
                                                                    <div style="line-height:1.5"><br></div>
                                                                  </td>
                                                                </tr>
                                                                <tr>
                                                                  <td
                                                                    style="width:33.8274%;border-left:1px solid #000;border-right:1px solid #000;border-top:1px solid #000">
                                                                    <div style="line-height:1.5"><span
                                                                        style="font-size:15px;color:#595959"><span
                                                                          style="font-size:15px;color:#595959"><span
                                                                            data-custom-class="body_text">F. Internet or
                                                                            other similar network
                                                                            activity</span></span></span></div>
                                                                  </td>
                                                                  <td
                                                                    style="width:51.4385%;border-right:1px solid #000;border-top:1px solid #000">
                                                                    <div style="line-height:1.5"><span
                                                                        style="font-size:15px;color:#595959"><span
                                                                          style="font-size:15px;color:#595959"><span
                                                                            data-custom-class="body_text">Browsing
                                                                            history, search history, online behavior,
                                                                            interest data, and interactions with our and
                                                                            other websites, applications, systems, and
                                                                            advertisements</span></span></span></div>
                                                                  </td>
                                                                  <td
                                                                    style="width:14.9084%;text-align:center;border-right:1px solid #000;border-top:1px solid #000">
                                                                    <div style="line-height:1.5"><br></div>
                                                                    <div data-custom-class="body_text"
                                                                      style="line-height:1.5"><span
                                                                        data-custom-class="body_text">NO</span></div>
                                                                    <div style="line-height:1.5"><br></div>
                                                                  </td>
                                                                </tr>
                                                                <tr>
                                                                  <td
                                                                    style="width:33.8274%;border-left:1px solid #000;border-right:1px solid #000;border-top:1px solid #000">
                                                                    <div style="line-height:1.5"><span
                                                                        style="font-size:15px;color:#595959"><span
                                                                          style="font-size:15px;color:#595959"><span
                                                                            data-custom-class="body_text">G. Geolocation
                                                                            data</span></span></span></div>
                                                                  </td>
                                                                  <td
                                                                    style="width:51.4385%;border-right:1px solid #000;border-top:1px solid #000">
                                                                    <div style="line-height:1.5"><span
                                                                        style="font-size:15px;color:#595959"><span
                                                                          style="font-size:15px;color:#595959"><span
                                                                            data-custom-class="body_text">Device
                                                                            location</span></span></span></div>
                                                                  </td>
                                                                  <td
                                                                    style="width:14.9084%;text-align:center;border-right:1px solid #000;border-top:1px solid #000">
                                                                    <div style="line-height:1.5"><br></div>
                                                                    <div data-custom-class="body_text"
                                                                      style="line-height:1.5"><span
                                                                        data-custom-class="body_text">NO</span></div>
                                                                    <div style="line-height:1.5"><br></div>
                                                                  </td>
                                                                </tr>
                                                                <tr>
                                                                  <td
                                                                    style="width:33.8274%;border-left:1px solid #000;border-right:1px solid #000;border-top:1px solid #000">
                                                                    <div style="line-height:1.5"><span
                                                                        style="font-size:15px;color:#595959"><span
                                                                          style="font-size:15px;color:#595959"><span
                                                                            data-custom-class="body_text">H. Audio,
                                                                            electronic, visual, thermal, olfactory, or
                                                                            similar information</span></span></span></div>
                                                                  </td>
                                                                  <td
                                                                    style="width:51.4385%;border-right:1px solid #000;border-top:1px solid #000">
                                                                    <div style="line-height:1.5"><span
                                                                        style="font-size:15px;color:#595959"><span
                                                                          style="font-size:15px;color:#595959"><span
                                                                            data-custom-class="body_text">Images and
                                                                            audio, video or call recordings created in
                                                                            connection with our business
                                                                            activities</span></span></span></div>
                                                                  </td>
                                                                  <td
                                                                    style="width:14.9084%;text-align:center;border-right:1px solid #000;border-top:1px solid #000">
                                                                    <div style="line-height:1.5"><br></div>
                                                                    <div data-custom-class="body_text"
                                                                      style="line-height:1.5"><span
                                                                        data-custom-class="body_text">NO</span></div>
                                                                    <div style="line-height:1.5"><br></div>
                                                                  </td>
                                                                </tr>
                                                                <tr>
                                                                  <td
                                                                    style="width:33.8274%;border-left:1px solid #000;border-right:1px solid #000;border-top:1px solid #000">
                                                                    <div style="line-height:1.5"><span
                                                                        style="font-size:15px;color:#595959"><span
                                                                          style="font-size:15px;color:#595959"><span
                                                                            data-custom-class="body_text">I. Professional
                                                                            or employment-related
                                                                            information</span></span></span></div>
                                                                  </td>
                                                                  <td
                                                                    style="width:51.4385%;border-right:1px solid #000;border-top:1px solid #000">
                                                                    <div style="line-height:1.5"><span
                                                                        style="font-size:15px;color:#595959"><span
                                                                          style="font-size:15px;color:#595959"><span
                                                                            data-custom-class="body_text">Business contact
                                                                            details in order to provide you our Services
                                                                            at a business level or job title, work
                                                                            history, and professional qualifications if
                                                                            you apply for a job with
                                                                            us</span></span></span></div>
                                                                  </td>
                                                                  <td
                                                                    style="width:14.9084%;text-align:center;border-right:1px solid #000;border-top:1px solid #000">
                                                                    <div style="line-height:1.5"><br></div>
                                                                    <div data-custom-class="body_text"
                                                                      style="line-height:1.5"><span
                                                                        data-custom-class="body_text">NO</span></div>
                                                                    <div style="line-height:1.5"><br></div>
                                                                  </td>
                                                                </tr>
                                                                <tr>
                                                                  <td
                                                                    style="border-left:1px solid #000;border-right:1px solid #000;border-top:1px solid #000;width:33.8274%">
                                                                    <div style="line-height:1.5"><span
                                                                        style="font-size:15px;color:#595959"><span
                                                                          style="font-size:15px;color:#595959"><span
                                                                            data-custom-class="body_text">J. Education
                                                                            Information</span></span></span></div>
                                                                  </td>
                                                                  <td
                                                                    style="border-right:1px solid #000;border-top:1px solid #000;width:51.4385%">
                                                                    <div style="line-height:1.5"><span
                                                                        style="font-size:15px;color:#595959"><span
                                                                          style="font-size:15px;color:#595959"><span
                                                                            data-custom-class="body_text">Student records
                                                                            and directory information</span></span></span>
                                                                    </div>
                                                                  </td>
                                                                  <td
                                                                    style="text-align:center;border-right:1px solid #000;border-top:1px solid #000;width:14.9084%">
                                                                    <div style="line-height:1.5"><br></div>
                                                                    <div data-custom-class="body_text"
                                                                      style="line-height:1.5"><span
                                                                        data-custom-class="body_text">NO</span></div>
                                                                    <div style="line-height:1.5"><br></div>
                                                                  </td>
                                                                </tr>
                                                                <tr>
                                                                  <td
                                                                    style="border-width:1px;border-color:#000;border-style:solid;width:33.8274%">
                                                                    <div style="line-height:1.5"><span
                                                                        style="font-size:15px;color:#595959"><span
                                                                          style="font-size:15px;color:#595959"><span
                                                                            data-custom-class="body_text">K. Inferences
                                                                            drawn from other personal
                                                                            information</span></span></span></div>
                                                                  </td>
                                                                  <td
                                                                    style="border-bottom:1px solid #000;border-top:1px solid #000;border-right:1px solid #000;width:51.4385%">
                                                                    <div style="line-height:1.5"><span
                                                                        style="font-size:15px;color:#595959"><span
                                                                          style="font-size:15px;color:#595959"><span
                                                                            data-custom-class="body_text">Inferences drawn
                                                                            from any of the collected personal information
                                                                            listed above to create a profile or summary
                                                                            about, for example, an individual’s
                                                                            preferences and
                                                                            characteristics</span></span></span></div>
                                                                  </td>
                                                                  <td
                                                                    style="text-align:center;border-right:1px solid #000;border-bottom:1px solid #000;border-top:1px solid #000;width:14.9084%">
                                                                    <div style="line-height:1.5"><br></div>
                                                                    <div data-custom-class="body_text"
                                                                      style="line-height:1.5"><span
                                                                        data-custom-class="body_text">NO</span></div>
                                                                    <div style="line-height:1.5"><br></div>
                                                                  </td>
                                                                </tr>
                                                                <tr>
                                                                  <td
                                                                    style="border-left:1px solid #000;border-right:1px solid #000;border-bottom:1px solid #000">
                                                                    <span data-custom-class="body_text">L. Sensitive
                                                                      Personal Information</span>
                                                                  </td>
                                                                  <td
                                                                    style="border-right:1px solid #000;border-bottom:1px solid #000;line-height:1.5">
                                                                  </td>
                                                                  <td
                                                                    style="border-right:1px solid #000;border-bottom:1px solid #000">
                                                                    <div data-empty="true" style="text-align:center"><br>
                                                                    </div>
                                                                    <div data-custom-class="body_text" data-empty="true"
                                                                      style="text-align:center;line-height:1.5"><span
                                                                        data-custom-class="body_text">NO</span></div>
                                                                    <div data-empty="true" style="text-align:center"><br>
                                                                    </div>
                                                                  </td>
                                                                </tr>
                                                              </tbody>
                                                            </table>
                                                            <div style="line-height:1.5"></div>
                                                            <div style="line-height:1.5"><br></div>
                                                            <div style="line-height:1.5">
                                                              <div style="line-height:1.5"></div>
                                                              <div style="line-height:1.5"><span
                                                                  style="font-size:15px;color:#595959"><span
                                                                    style="font-size:15px;color:#595959"><span
                                                                      data-custom-class="body_text">We may also collect
                                                                      other personal information outside of these
                                                                      categories through instances where you interact with
                                                                      us in person, online, or by phone or mail in the
                                                                      context of:</span></span></span></div>
                                                              <ul>
                                                                <li style="line-height:1.5"><span
                                                                    style="font-size:15px;color:#595959"><span
                                                                      style="font-size:15px;color:#595959"><span
                                                                        data-custom-class="body_text">Receiving help
                                                                        through our customer support
                                                                        channels;</span></span></span></li>
                                                              </ul>
                                                              <ul>
                                                                <li style="line-height:1.5"><span
                                                                    style="font-size:15px;color:#595959"><span
                                                                      style="font-size:15px;color:#595959"><span
                                                                        data-custom-class="body_text">Participation in
                                                                        customer surveys or contests;
                                                                        and</span></span></span></li>
                                                              </ul>
                                                              <ul>
                                                                <li style="line-height:1.5"><span
                                                                    style="font-size:15px;color:#595959"><span
                                                                      style="font-size:15px;color:#595959"><span
                                                                        data-custom-class="body_text">Facilitation in the
                                                                        delivery of our Services and to respond to your
                                                                        inquiries.</span></span></span></li>
                                                              </ul>
                                                              <div style="line-height:1.5"><span
                                                                  style="font-size:15px;color:#595959"><span
                                                                    style="font-size:15px;color:#595959"><span
                                                                      data-custom-class="body_text"><strong>How do we use
                                                                        and share your personal
                                                                        information?</strong></span></span></span></div>
                                                              <div style="line-height:1.5"><br></div>
                                                              <div style="line-height:1.5"><span
                                                                  style="font-size:15px;color:#595959"><span
                                                                    style="font-size:15px;color:#595959"><span
                                                                      data-custom-class="body_text"><span
                                                                        style="font-size:15px;color:#595959"><span
                                                                          style="font-size:15px;color:#595959"><span
                                                                            data-custom-class="body_text">More information
                                                                            about our data collection and sharing
                                                                            practices can be found in this privacy
                                                                            notice.</span></span></span></span></span></span>
                                                              </div>
                                                              <div style="line-height:1.5"><br></div>
                                                              <div style="line-height:1.5"><span
                                                                  style="font-size:15px;color:#595959"><span
                                                                    style="font-size:15px;color:#595959"><span
                                                                      data-custom-class="body_text">You may contact us by
                                                                      email at</span><span
                                                                      style="color:#595959;font-size:15px"><span
                                                                        data-custom-class="body_text">support@quantexinnovations.com,</span></span></span></span><span
                                                                  data-custom-class="body_text">or by referring to the
                                                                  contact details at the bottom of this document.</span>
                                                              </div>
                                                              <div style="line-height:1.5"><br></div>
                                                              <div style="line-height:1.5"><span
                                                                  style="font-size:15px;color:#595959"><span
                                                                    style="font-size:15px;color:#595959"><span
                                                                      data-custom-class="body_text">If you are using an
                                                                      authorized agent to exercise your right to opt out
                                                                      we may deny a request if the authorized agent does
                                                                      not submit proof that they have been validly
                                                                      authorized to act on your
                                                                      behalf.</span></span></span></div>
                                                              <div style="line-height:1.5"><br></div>
                                                              <div style="line-height:1.5"><span
                                                                  style="font-size:15px;color:#595959"><span
                                                                    style="font-size:15px;color:#595959"><span
                                                                      data-custom-class="body_text"><strong>Will your
                                                                        information be shared with anyone
                                                                        else?</strong></span></span></span></div>
                                                              <div style="line-height:1.5"><br></div>
                                                              <div style="line-height:1.5"><span
                                                                  style="font-size:15px;color:#595959"><span
                                                                    style="font-size:15px;color:#595959"><span
                                                                      data-custom-class="body_text">We may disclose your
                                                                      personal information with our service providers
                                                                      pursuant to a written contract between us and each
                                                                      service provider. Each service provider is a
                                                                      for-profit entity that processes the information on
                                                                      our behalf, following the same strict privacy
                                                                      protection obligations mandated by the
                                                                      CCPA.</span></span></span></div>
                                                              <div style="line-height:1.5"><br></div>
                                                              <div style="line-height:1.5"><span
                                                                  style="font-size:15px;color:#595959"><span
                                                                    style="font-size:15px;color:#595959"><span
                                                                      data-custom-class="body_text">We may use your
                                                                      personal information for our own business purposes,
                                                                      such as for undertaking internal research for
                                                                      technological development and demonstration. This is
                                                                      not considered to be "selling" of your personal
                                                                      information.</span></span></span></div>
                                                              <div style="line-height:1.5"><br></div>
                                                              <div style="line-height:1.5"><span
                                                                  data-custom-class="body_text"><span
                                                                    style="font-size:15px">We have not sold or shared any
                                                                    personal information to third parties for a business
                                                                    or commercial purpose in the preceding twelve (12)
                                                                    months.</span></span><span
                                                                  style="font-size:15px"><span style="color:#595959"><span
                                                                      style="color:#595959"><span
                                                                        data-custom-class="body_text">We have disclosed
                                                                        the following categories of personal information
                                                                        to third parties for a business or commercial
                                                                        purpose in the preceding twelve (12)
                                                                        months:</span></span></span></span>
                                                                <p style="font-size:15px"></p>
                                                                <p style="font-size:15px;line-height:1.5"></p>
                                                                <p style="font-size:15px;line-height:1.5"></p>
                                                                <p style="font-size:15px"></p>
                                                                <p style="font-size:15px"></p>
                                                                <p style="font-size:15px"></p>
                                                                <p style="font-size:15px"></p>
                                                                <p style="font-size:15px"></p>
                                                                <p style="font-size:15px"></p>
                                                                <p style="font-size:15px"></p>
                                                                <div style="line-height:1.5">
                                                                  <p style="font-size:15px"></p>
                                                                  <p style="font-size:15px"></p>
                                                                  <p style="font-size:15px"></p>
                                                                  <p style="font-size:15px"></p>
                                                                  <div>
                                                                    <div>
                                                                      <div>
                                                                        <div>
                                                                          <div>
                                                                            <div>
                                                                              <div style="line-height:1.5"><span
                                                                                  style="font-size:15px;color:#595959"><span
                                                                                    style="font-size:15px;color:#595959"><span
                                                                                      data-custom-class="body_text">The
                                                                                      categories of third parties to whom
                                                                                      we disclosed personal information
                                                                                      for a business or commercial purpose
                                                                                      can be found under "<span
                                                                                        style="font-size:15px"><span
                                                                                          style="color:#595959"><a
                                                                                            data-custom-class="link"
                                                                                            href="#whoshare">WHEN AND WITH
                                                                                            WHOM DO WE SHARE YOUR PERSONAL
                                                                                            INFORMATION?</a><span
                                                                                            style="font-size:15px"><span
                                                                                              style="color:#595959"><span
                                                                                                style="font-size:15px"><span
                                                                                                  style="color:#595959"><span
                                                                                                    style="font-size:15px"><span
                                                                                                      style="color:#595959"><span
                                                                                                        data-custom-class="body_text"><span
                                                                                                          style="color:#595959">"</span></span></span></span></span></span></span></span></span></span>.</span></span></span>
                                                                              </div>
                                                                              <div style="line-height:1.5"><br></div>
                                                                              <div style="line-height:1.5"><span
                                                                                  style="font-size:15px;color:#595959"><span
                                                                                    style="font-size:15px;color:#595959"><span
                                                                                      data-custom-class="body_text"><strong>Your
                                                                                        rights with respect to your
                                                                                        personal
                                                                                        data</strong></span></span></span>
                                                                              </div>
                                                                              <div style="line-height:1.5"><br></div>
                                                                              <div style="line-height:1.5"><span
                                                                                  style="font-size:15px;color:#595959"><span
                                                                                    style="font-size:15px;color:#595959"><span
                                                                                      data-custom-class="body_text"><u>Right
                                                                                        to request deletion of the data —
                                                                                        Request to
                                                                                        delete</u></span></span></span>
                                                                              </div>
                                                                              <div style="line-height:1.5"><br></div>
                                                                              <div style="line-height:1.5"><span
                                                                                  style="font-size:15px;color:#595959"><span
                                                                                    style="font-size:15px;color:#595959"><span
                                                                                      data-custom-class="body_text">You
                                                                                      can ask for the deletion of your
                                                                                      personal information. If you ask us
                                                                                      to delete your personal information,
                                                                                      we will respect your request and
                                                                                      delete your personal information,
                                                                                      subject to certain exceptions
                                                                                      provided by law, such as (but not
                                                                                      limited to) the exercise by another
                                                                                      consumer of his or her right to free
                                                                                      speech, our compliance requirements
                                                                                      resulting from a legal obligation,
                                                                                      or any processing that may be
                                                                                      required to protect against illegal
                                                                                      activities.</span></span></span>
                                                                              </div>
                                                                              <div style="line-height:1.5"><br></div>
                                                                              <div style="line-height:1.5"><span
                                                                                  style="font-size:15px;color:#595959"><span
                                                                                    style="font-size:15px;color:#595959"><span
                                                                                      data-custom-class="body_text"><u>Right
                                                                                        to be informed — Request to
                                                                                        know</u></span></span></span>
                                                                              </div>
                                                                              <div style="line-height:1.5"><br></div>
                                                                              <div style="line-height:1.5"><span
                                                                                  style="font-size:15px;color:#595959"><span
                                                                                    style="font-size:15px;color:#595959"><span
                                                                                      data-custom-class="body_text">Depending
                                                                                      on the circumstances, you have a
                                                                                      right to know:</span></span></span>
                                                                              </div>
                                                                              <ul>
                                                                                <li style="line-height:1.5"><span
                                                                                    style="font-size:15px;color:#595959"><span
                                                                                      style="font-size:15px;color:#595959"><span
                                                                                        data-custom-class="body_text">whether
                                                                                        we collect and use your personal
                                                                                        information;</span></span></span>
                                                                                </li>
                                                                              </ul>
                                                                              <ul>
                                                                                <li style="line-height:1.5"><span
                                                                                    style="font-size:15px;color:#595959"><span
                                                                                      style="font-size:15px;color:#595959"><span
                                                                                        data-custom-class="body_text">the
                                                                                        categories of personal information
                                                                                        that we
                                                                                        collect;</span></span></span></li>
                                                                              </ul>
                                                                              <ul>
                                                                                <li style="line-height:1.5"><span
                                                                                    style="font-size:15px;color:#595959"><span
                                                                                      style="font-size:15px;color:#595959"><span
                                                                                        data-custom-class="body_text">the
                                                                                        purposes for which the collected
                                                                                        personal information is
                                                                                        used;</span></span></span></li>
                                                                              </ul>
                                                                              <ul>
                                                                                <li style="line-height:1.5"><span
                                                                                    style="font-size:15px;color:#595959"><span
                                                                                      style="font-size:15px;color:#595959"><span
                                                                                        data-custom-class="body_text">whether
                                                                                        we sell or share personal
                                                                                        information to third
                                                                                        parties;</span></span></span></li>
                                                                              </ul>
                                                                              <ul>
                                                                                <li style="line-height:1.5"><span
                                                                                    style="font-size:15px;color:#595959"><span
                                                                                      style="font-size:15px;color:#595959"><span
                                                                                        data-custom-class="body_text">the
                                                                                        categories of personal information
                                                                                        that we sold, shared, or disclosed
                                                                                        for a business
                                                                                        purpose;</span></span></span></li>
                                                                              </ul>
                                                                              <ul>
                                                                                <li style="line-height:1.5"><span
                                                                                    style="font-size:15px;color:#595959"><span
                                                                                      style="font-size:15px;color:#595959"><span
                                                                                        data-custom-class="body_text">the
                                                                                        categories of third parties to
                                                                                        whom the personal information was
                                                                                        sold, shared, or disclosed for a
                                                                                        business
                                                                                        purpose;</span></span></span></li>
                                                                              </ul>
                                                                              <ul>
                                                                                <li style="line-height:1.5"><span
                                                                                    style="font-size:15px;color:#595959"><span
                                                                                      style="font-size:15px;color:#595959"><span
                                                                                        data-custom-class="body_text">the
                                                                                        business or commercial purpose for
                                                                                        collecting, selling, or sharing
                                                                                        personal information;
                                                                                        and</span></span></span></li>
                                                                              </ul>
                                                                              <div style="line-height:1.5"></div>
                                                                              <ul>
                                                                                <li style="line-height:1.5"><span
                                                                                    data-custom-class="body_text">the
                                                                                    specific pieces of personal
                                                                                    information we collected about
                                                                                    you.</span></li>
                                                                              </ul>
                                                                              <div style="line-height:1.5"><span
                                                                                  style="font-size:15px;color:#595959"><span
                                                                                    style="font-size:15px;color:#595959"><span
                                                                                      data-custom-class="body_text">In
                                                                                      accordance with applicable law, we
                                                                                      are not obligated to provide or
                                                                                      delete consumer information that is
                                                                                      de-identified in response to a
                                                                                      consumer request or to re-identify
                                                                                      individual data to verify a consumer
                                                                                      request.</span></span></span></div>
                                                                              <div style="line-height:1.5"><br></div>
                                                                              <div style="line-height:1.5"><span
                                                                                  style="font-size:15px;color:#595959"><span
                                                                                    style="font-size:15px;color:#595959"><span
                                                                                      data-custom-class="body_text"><u>Right
                                                                                        to Non-Discrimination for the
                                                                                        Exercise of a Consumer’s Privacy
                                                                                        Rights</u></span></span></span>
                                                                              </div>
                                                                              <div style="line-height:1.5"><br></div>
                                                                              <div style="line-height:1.5"><span
                                                                                  style="font-size:15px;color:#595959"><span
                                                                                    style="font-size:15px;color:#595959"><span
                                                                                      data-custom-class="body_text">We
                                                                                      will not discriminate against you if
                                                                                      you exercise your privacy
                                                                                      rights.</span></span></span></div>
                                                                              <div style="line-height:1.5"><br></div>
                                                                              <div style="line-height:1.5"><u><span
                                                                                    data-custom-class="body_text">Right to
                                                                                    Limit Use and Disclosure of Sensitive
                                                                                    Personal Information</span></u></div>
                                                                              <div style="line-height:1.5"></div>
                                                                              <div style="line-height:1.5"><br></div>
                                                                              <div style="line-height:1.5"><span
                                                                                  data-custom-class="body_text">We do not
                                                                                  process consumer's sensitive personal
                                                                                  information.</span></div>
                                                                              <div style="line-height:1.5"><br></div>
                                                                              <div style="line-height:1.5"></div>
                                                                              <div style="line-height:1.5"><span
                                                                                  style="font-size:15px;color:#595959"><span
                                                                                    style="font-size:15px;color:#595959"><span
                                                                                      data-custom-class="body_text"><u>Verification
                                                                                        process</u></span></span></span>
                                                                              </div>
                                                                              <div style="line-height:1.5"><br></div>
                                                                              <div style="line-height:1.5"><span
                                                                                  style="font-size:15px;color:#595959"><span
                                                                                    style="font-size:15px;color:#595959"><span
                                                                                      data-custom-class="body_text">Upon
                                                                                      receiving your request, we will need
                                                                                      to verify your identity to determine
                                                                                      you are the same person about whom
                                                                                      we have the information in our
                                                                                      system. These verification efforts
                                                                                      require us to ask you to provide
                                                                                      information so that we can match it
                                                                                      with information you have previously
                                                                                      provided us. For instance, depending
                                                                                      on the type of request you submit,
                                                                                      we may ask you to provide certain
                                                                                      information so that we can match the
                                                                                      information you provide with the
                                                                                      information we already have on file,
                                                                                      or we may contact you through a
                                                                                      communication method (e.g., phone or
                                                                                      email) that you have previously
                                                                                      provided to us. We may also use
                                                                                      other verification methods as the
                                                                                      circumstances
                                                                                      dictate.</span></span></span></div>
                                                                              <div style="line-height:1.5"><br></div>
                                                                              <div style="line-height:1.5"><span
                                                                                  style="font-size:15px;color:#595959"><span
                                                                                    style="font-size:15px;color:#595959"><span
                                                                                      data-custom-class="body_text">We
                                                                                      will only use personal information
                                                                                      provided in your request to verify
                                                                                      your identity or authority to make
                                                                                      the request. To the extent possible,
                                                                                      we will avoid requesting additional
                                                                                      information from you for the
                                                                                      purposes of verification. However,
                                                                                      if we cannot verify your identity
                                                                                      from the information already
                                                                                      maintained by us, we may request
                                                                                      that you provide additional
                                                                                      information for the purposes of
                                                                                      verifying your identity and for
                                                                                      security or fraud-prevention
                                                                                      purposes. We will delete such
                                                                                      additionally provided information as
                                                                                      soon as we finish verifying
                                                                                      you.</span></span></span></div>
                                                                              <div style="line-height:1.5"><br></div>
                                                                              <div style="line-height:1.5"><span
                                                                                  style="font-size:15px;color:#595959"><span
                                                                                    style="font-size:15px;color:#595959"><span
                                                                                      data-custom-class="body_text"><u>Other
                                                                                        privacy
                                                                                        rights</u></span></span></span>
                                                                              </div>
                                                                              <ul>
                                                                                <li style="line-height:1.5"><span
                                                                                    style="font-size:15px;color:#595959"><span
                                                                                      style="font-size:15px;color:#595959"><span
                                                                                        data-custom-class="body_text">You
                                                                                        may object to the processing of
                                                                                        your personal
                                                                                        information.</span></span></span>
                                                                                </li>
                                                                              </ul>
                                                                              <ul>
                                                                                <li style="line-height:1.5"><span
                                                                                    style="font-size:15px;color:#595959"><span
                                                                                      style="font-size:15px;color:#595959"><span
                                                                                        data-custom-class="body_text">You
                                                                                        may request correction of your
                                                                                        personal data if it is incorrect
                                                                                        or no longer relevant, or ask to
                                                                                        restrict the processing of the
                                                                                        information.</span></span></span>
                                                                                </li>
                                                                              </ul>
                                                                              <ul>
                                                                                <li style="line-height:1.5"><span
                                                                                    style="font-size:15px;color:#595959"><span
                                                                                      style="font-size:15px;color:#595959"><span
                                                                                        data-custom-class="body_text">You
                                                                                        can designate an authorized agent
                                                                                        to make a request under the CCPA
                                                                                        on your behalf. We may deny a
                                                                                        request from an authorized agent
                                                                                        that does not submit proof that
                                                                                        they have been validly authorized
                                                                                        to act on your behalf in
                                                                                        accordance with the
                                                                                        CCPA.</span></span></span></li>
                                                                              </ul>
                                                                              <ul>
                                                                                <li style="line-height:1.5"><span
                                                                                    style="font-size:15px;color:#595959"><span
                                                                                      style="font-size:15px;color:#595959"><span
                                                                                        data-custom-class="body_text">You
                                                                                        may request to opt out from future
                                                                                        selling or sharing of your
                                                                                        personal information to third
                                                                                        parties. Upon receiving an opt-out
                                                                                        request, we will act upon the
                                                                                        request as soon as feasibly
                                                                                        possible, but no later than
                                                                                        fifteen (15) days from the date of
                                                                                        the request
                                                                                        submission.</span></span></span>
                                                                                </li>
                                                                              </ul>
                                                                              <div style="line-height:1.5"><span
                                                                                  style="font-size:15px;color:#595959"><span
                                                                                    style="font-size:15px;color:#595959"><span
                                                                                      data-custom-class="body_text">To
                                                                                      exercise these rights, you can
                                                                                      contact us</span><span
                                                                                      style="color:#595959;font-size:15px"><span
                                                                                        data-custom-class="body_text"><span
                                                                                          style="color:#595959;font-size:15px"><span
                                                                                            data-custom-class="body_text">by
                                                                                            email at
                                                                                            support@quantexinnovations.com,</span></span></span></span></span></span><span
                                                                                  data-custom-class="body_text">or by
                                                                                  referring to the contact details at the
                                                                                  bottom of this document. If you have a
                                                                                  complaint about how we handle your data,
                                                                                  we would like to hear from you.</span>
                                                                              </div>
                                                                              <div style="line-height:1.5"><span
                                                                                  style="font-size:15px"><br></span></div>
                                                                              <div style="line-height:1.5"><span
                                                                                  id="virginia"
                                                                                  style="font-size:15px"><strong><span
                                                                                      data-custom-class="heading_1">13. DO
                                                                                      VIRGINIA RESIDENTS HAVE SPECIFIC
                                                                                      PRIVACY
                                                                                      RIGHTS?</span></strong></span></div>
                                                                              <div style="line-height:1.5"><span
                                                                                  style="font-size:15px"><br></span></div>
                                                                              <div style="line-height:1.5"><span
                                                                                  style="font-size:15px"><em><strong><span
                                                                                        data-custom-class="body_text">In
                                                                                        Short:</span></strong><span
                                                                                      data-custom-class="body_text">Yes,
                                                                                      if you are a resident of Virginia,
                                                                                      you may be granted specific rights
                                                                                      regarding access to and use of your
                                                                                      personal
                                                                                      information.</span></em></span>
                                                                              </div>
                                                                              <div style="line-height:1.5"><span
                                                                                  style="font-size:15px"><br></span></div>
                                                                              <div style="line-height:1.5"><span
                                                                                  style="font-size:15px"><strong><span
                                                                                      data-custom-class="heading_2">Virginia
                                                                                      CDPA Privacy
                                                                                      Notice</span></strong></span></div>
                                                                              <div style="line-height:1.5"><span
                                                                                  style="font-size:15px"><br></span></div>
                                                                              <div style="line-height:1.5"><span
                                                                                  style="font-size:15px"><span
                                                                                    data-custom-class="body_text">Under
                                                                                    the Virginia Consumer Data Protection
                                                                                    Act (CDPA):</span></span></div>
                                                                              <div style="line-height:1.5"><span
                                                                                  style="font-size:15px"><br></span></div>
                                                                              <div style="line-height:1.5"><span
                                                                                  style="font-size:15px"><span
                                                                                    data-custom-class="body_text">"Consumer"
                                                                                    means a natural person who is a
                                                                                    resident of the Commonwealth acting
                                                                                    only in an individual or household
                                                                                    context. It does not include a natural
                                                                                    person acting in a commercial or
                                                                                    employment context.</span></span>
                                                                              </div>
                                                                              <div style="line-height:1.5"><span
                                                                                  style="font-size:15px"><br></span></div>
                                                                              <div style="line-height:1.5"><span
                                                                                  style="font-size:15px"><span
                                                                                    data-custom-class="body_text">"Personal
                                                                                    data" means any information that is
                                                                                    linked or reasonably linkable to an
                                                                                    identified or identifiable natural
                                                                                    person. "Personal data" does not
                                                                                    include de-identified data or publicly
                                                                                    available information.</span></span>
                                                                              </div>
                                                                              <div style="line-height:1.5"><span
                                                                                  style="font-size:15px"><br></span></div>
                                                                              <div style="line-height:1.5"><span
                                                                                  style="font-size:15px"><span
                                                                                    data-custom-class="body_text">"Sale of
                                                                                    personal data" means the exchange of
                                                                                    personal data for monetary
                                                                                    consideration.</span></span></div>
                                                                              <div style="line-height:1.5"><span
                                                                                  style="font-size:15px"><br></span></div>
                                                                              <div style="line-height:1.5"><span
                                                                                  style="font-size:15px"><span
                                                                                    data-custom-class="body_text">If this
                                                                                    definition "consumer" applies to you,
                                                                                    we must adhere to certain rights and
                                                                                    obligations regarding your personal
                                                                                    data.</span></span></div>
                                                                              <div style="line-height:1.5"><br></div>
                                                                              <div style="line-height:1.5"><span
                                                                                  style="font-size:15px"><span
                                                                                    data-custom-class="body_text">The
                                                                                    information we collect, use, and
                                                                                    disclose about you will vary depending
                                                                                    on how you interact with us and our
                                                                                    Services. To find out more, please
                                                                                    visit the following
                                                                                    links:</span></span></div>
                                                                              <ul>
                                                                                <li style="line-height:1.5"><span
                                                                                    style="font-size:15px"><a
                                                                                      data-custom-class="link"
                                                                                      href="#infocollect">Personal data we
                                                                                      collect</a></span></li>
                                                                              </ul>
                                                                              <div style="line-height:1.5"></div>
                                                                              <ul>
                                                                                <li style="line-height:1.5"><span
                                                                                    style="font-size:15px"><a
                                                                                      data-custom-class="link"
                                                                                      href="#infouse">How we use your
                                                                                      personal data</a></span></li>
                                                                              </ul>
                                                                              <div style="line-height:1.5"></div>
                                                                              <ul>
                                                                                <li style="line-height:1.5"><span
                                                                                    style="font-size:15px"><a
                                                                                      data-custom-class="link"
                                                                                      href="#whoshare">When and with whom
                                                                                      we share your personal
                                                                                      data</a></span></li>
                                                                              </ul>
                                                                              <div style="line-height:1.5"><span
                                                                                  style="font-size:15px"><u><span
                                                                                      data-custom-class="body_text">Your
                                                                                      rights with respect to your personal
                                                                                      data</span></u></span></div>
                                                                              <div style="line-height:1.5"></div>
                                                                              <ul>
                                                                                <li style="line-height:1.5"><span
                                                                                    style="font-size:15px"><span
                                                                                      data-custom-class="body_text">Right
                                                                                      to be informed whether or not we are
                                                                                      processing your personal
                                                                                      data</span></span></li>
                                                                              </ul>
                                                                              <div style="line-height:1.5"></div>
                                                                              <ul>
                                                                                <li style="line-height:1.5"><span
                                                                                    style="font-size:15px"><span
                                                                                      data-custom-class="body_text">Right
                                                                                      to access your personal
                                                                                      data</span></span></li>
                                                                              </ul>
                                                                              <div style="line-height:1.5"></div>
                                                                              <ul>
                                                                                <li style="line-height:1.5"><span
                                                                                    style="font-size:15px"><span
                                                                                      data-custom-class="body_text">Right
                                                                                      to correct inaccuracies in your
                                                                                      personal data</span></span></li>
                                                                              </ul>
                                                                              <div style="line-height:1.5"></div>
                                                                              <ul>
                                                                                <li style="line-height:1.5"><span
                                                                                    style="font-size:15px"><span
                                                                                      data-custom-class="body_text">Right
                                                                                      to request deletion of your personal
                                                                                      data</span></span></li>
                                                                              </ul>
                                                                              <div style="line-height:1.5"></div>
                                                                              <ul>
                                                                                <li style="line-height:1.5"><span
                                                                                    style="font-size:15px"><span
                                                                                      data-custom-class="body_text">Right
                                                                                      to obtain a copy of the personal
                                                                                      data you previously shared with
                                                                                      us</span></span></li>
                                                                              </ul>
                                                                              <div style="line-height:1.5"></div>
                                                                              <ul>
                                                                                <li style="line-height:1.5"><span
                                                                                    style="font-size:15px"><span
                                                                                      data-custom-class="body_text">Right
                                                                                      to opt out of the processing of your
                                                                                      personal data if it is used for
                                                                                      targeted advertising, the sale of
                                                                                      personal data, or profiling in
                                                                                      furtherance of decisions that
                                                                                      produce legal or similarly
                                                                                      significant effects
                                                                                      ("profiling")</span></span></li>
                                                                              </ul>
                                                                              <div data-custom-class="body_text"
                                                                                style="line-height:1.5"><span
                                                                                  data-custom-class="body_text"
                                                                                  style="font-size:15px">We have not sold
                                                                                  any personal data to third parties for
                                                                                  business or commercial purposes. We will
                                                                                  not sell personal data in the future
                                                                                  belonging to website visitors, users,
                                                                                  and other consumers.</span></div>
                                                                              <div style="line-height:1.5"><br></div>
                                                                              <div style="line-height:1.5"><u><span
                                                                                    data-custom-class="body_text"
                                                                                    style="font-size:15px">Exercise your
                                                                                    rights provided under the Virginia
                                                                                    CDPA</span></u></div>
                                                                              <div style="line-height:1.5"><br></div>
                                                                              <div style="line-height:1.5"><span
                                                                                  data-custom-class="body_text"
                                                                                  style="font-size:15px">More information
                                                                                  about our data collection and sharing
                                                                                  practices can be found in this privacy
                                                                                  notice.</span></div>
                                                                              <div style="line-height:1.5"><br></div>
                                                                              <div style="line-height:1.5"><span
                                                                                  style="font-size:15px"><span
                                                                                    data-custom-class="body_text">You may
                                                                                    contact us by email at
                                                                                    support@quantexinnovations.com, by
                                                                                    visiting<a
                                                                                      href="https://web.qi-dev.quantexinnovations.com"
                                                                                      target="_blank"
                                                                                      data-custom-class="link">https://web.qi-dev.quantexinnovations.com</a>,
                                                                                    or by referring to the contact details
                                                                                    at the bottom of this
                                                                                    document.</span></span></div>
                                                                              <div style="line-height:1.5"><br></div>
                                                                              <div style="line-height:1.5"><span
                                                                                  style="font-size:15px"><span
                                                                                    data-custom-class="body_text">If you
                                                                                    are using an authorized agent to
                                                                                    exercise your rights, we may deny a
                                                                                    request if the authorized agent does
                                                                                    not submit proof that they have been
                                                                                    validly authorized to act on your
                                                                                    behalf.</span></span></div>
                                                                              <div style="line-height:1.5"><br></div>
                                                                              <div style="line-height:1.5"><u><span
                                                                                    style="font-size:15px"><span
                                                                                      data-custom-class="body_text">Verification
                                                                                      process</span></span></u></div>
                                                                              <div style="line-height:1.5"><br></div>
                                                                              <div style="line-height:1.5"><span
                                                                                  style="font-size:15px"><span
                                                                                    data-custom-class="body_text">We may
                                                                                    request that you provide additional
                                                                                    information reasonably necessary to
                                                                                    verify you and your consumer's
                                                                                    request. If you submit the request
                                                                                    through an authorized agent, we may
                                                                                    need to collect additional information
                                                                                    to verify your identity before
                                                                                    processing your request.</span></span>
                                                                              </div>
                                                                              <div style="line-height:1.5"><br></div>
                                                                              <div style="line-height:1.5"><span
                                                                                  style="font-size:15px"><span
                                                                                    data-custom-class="body_text">Upon
                                                                                    receiving your request, we will
                                                                                    respond without undue delay, but in
                                                                                    all cases, within forty-five (45) days
                                                                                    of receipt. The response period may be
                                                                                    extended once by forty-five (45)
                                                                                    additional days when reasonably
                                                                                    necessary. We will inform you of any
                                                                                    such extension within the initial
                                                                                    45-day response period, together with
                                                                                    the reason for the
                                                                                    extension.</span></span></div>
                                                                              <div style="line-height:1.5"><br></div>
                                                                              <div style="line-height:1.5"><span
                                                                                  style="font-size:15px"><u><span
                                                                                      data-custom-class="body_text">Right
                                                                                      to appeal</span></u></span></div>
                                                                              <div style="line-height:1.5"><span
                                                                                  style="font-size:15px"><br></span></div>
                                                                              <div style="line-height:1.5"><span
                                                                                  style="font-size:15px"><span
                                                                                    data-custom-class="body_text">If we
                                                                                    decline to take action regarding your
                                                                                    request, we will inform you of our
                                                                                    decision and reasoning behind
                                                                                    it.</span><span
                                                                                    data-custom-class="body_text">If you
                                                                                    wish to appeal our decision, please
                                                                                    email us at
                                                                                    support@quantexinnovations.com. Within
                                                                                    sixty (60) days of receipt of an
                                                                                    appeal, we will inform you in writing
                                                                                    of any action taken or not taken in
                                                                                    response to the appeal, including a
                                                                                    written explanation of the reasons for
                                                                                    the decisions. If your appeal if
                                                                                    denied, you may contact
                                                                                    the</span></span><a
                                                                                  href="https://www.oag.state.va.us/consumer-protection/index.php/file-a-complaint"
                                                                                  rel="noopener noreferrer"
                                                                                  target="_blank"><span
                                                                                    style="font-size:15px"><span
                                                                                      data-custom-class="link">Attorney
                                                                                      General to submit a
                                                                                      complaint</span></span></a><span
                                                                                  style="font-size:15px"><span
                                                                                    data-custom-class="body_text">.</span></span>
                                                                              </div>
                                                                              <div style="line-height:1.5"></div>
                                                                              <div style="line-height:1.5"><br></div>
                                                                              <div id="policyupdates"
                                                                                style="line-height:1.5"><span
                                                                                  style="color:#7f7f7f"><span
                                                                                    style="color:#595959;font-size:15px"><span
                                                                                      style="font-size:15px;color:#595959"><span
                                                                                        style="font-size:15px;color:#595959"><span
                                                                                          id="control"
                                                                                          style="color:#000"><strong><span
                                                                                              data-custom-class="heading_1">14.
                                                                                              DO WE MAKE UPDATES TO THIS
                                                                                              NOTICE?</span></strong></span></span></span></span></span>
                                                                              </div>
                                                                              <div style="line-height:1.5"><br></div>
                                                                              <div style="line-height:1.5"><span
                                                                                  style="font-size:15px;color:#595959"><span
                                                                                    style="font-size:15px;color:#595959"><span
                                                                                      data-custom-class="body_text"><em><strong>In
                                                                                          Short:</strong>Yes, we will
                                                                                        update this notice as necessary to
                                                                                        stay compliant with relevant
                                                                                        laws.</em></span></span></span>
                                                                              </div>
                                                                              <div style="line-height:1.5"><br></div>
                                                                              <div style="line-height:1.5"><span
                                                                                  style="font-size:15px;color:#595959"><span
                                                                                    style="font-size:15px;color:#595959"><span
                                                                                      data-custom-class="body_text">We may
                                                                                      update this privacy notice from time
                                                                                      to time. The updated version will be
                                                                                      indicated by an updated "Revised"
                                                                                      date and the updated version will be
                                                                                      effective as soon as it is
                                                                                      accessible. If we make material
                                                                                      changes to this privacy notice, we
                                                                                      may notify you either by prominently
                                                                                      posting a notice of such changes or
                                                                                      by directly sending you a
                                                                                      notification. We encourage you to
                                                                                      review this privacy notice
                                                                                      frequently to be informed of how we
                                                                                      are protecting your
                                                                                      information.</span></span></span>
                                                                              </div>
                                                                              <div style="line-height:1.5"><br></div>
                                                                              <div id="contact" style="line-height:1.5">
                                                                                <span style="color:#7f7f7f"><span
                                                                                    style="color:#595959;font-size:15px"><span
                                                                                      style="font-size:15px;color:#595959"><span
                                                                                        style="font-size:15px;color:#595959"><span
                                                                                          id="control"
                                                                                          style="color:#000"><strong><span
                                                                                              data-custom-class="heading_1">15.
                                                                                              HOW CAN YOU CONTACT US ABOUT
                                                                                              THIS
                                                                                              NOTICE?</span></strong></span></span></span></span></span>
                                                                              </div>
                                                                              <div style="line-height:1.5"><br></div>
                                                                              <div style="line-height:1.5"><span
                                                                                  style="font-size:15px;color:#595959"><span
                                                                                    style="font-size:15px;color:#595959"><span
                                                                                      data-custom-class="body_text">If you
                                                                                      have questions or comments about
                                                                                      this notice, you may<span
                                                                                        style="color:#595959;font-size:15px"><span
                                                                                          data-custom-class="body_text">email
                                                                                          us at
                                                                                          support@quantexinnovations.com</span></span><span
                                                                                        style="font-size:15px;color:#595959"><span
                                                                                          style="font-size:15px;color:#595959"><span
                                                                                            data-custom-class="body_text">or
                                                                                            contact us by post
                                                                                            at:</span></span></span></span></span></span>
                                                                              </div>
                                                                              <div style="line-height:1.5"><br></div>
                                                                              <div style="line-height:1.5"><span
                                                                                  style="font-size:15px;color:#595959"><span
                                                                                    style="font-size:15px;color:#595959"><span
                                                                                      data-custom-class="body_text"><span
                                                                                        style="font-size:15px"><span
                                                                                          style="color:#595959"><span
                                                                                            style="color:#595959"><span
                                                                                              data-custom-class="body_text">Quantex
                                                                                              Innovations,
                                                                                              LLC</span></span></span></span></span></span></span>
                                                                              </div>
                                                                              <div style="line-height:1.5"><span
                                                                                  style="font-size:15px"><span
                                                                                    data-custom-class="body_text">Quantex
                                                                                    Innovations c/o Path
                                                                                    Stone</span></span></div>
                                                                              <div style="line-height:1.5"><span
                                                                                  data-custom-class="body_text"
                                                                                  style="font-size:15px">201 N Union St,
                                                                                  Suite 300</span></div>
                                                                              <div style="line-height:1.5"><span
                                                                                  style="font-size:15px"><span
                                                                                    data-custom-class="body_text">Alexandria<span
                                                                                      style="color:#595959"><span
                                                                                        style="font-size:15px">, VA
                                                                                        22314</span></span></span></span>
                                                                              </div>
                                                                              <div style="line-height:1.5"><span
                                                                                  data-custom-class="body_text"
                                                                                  style="font-size:15px">United
                                                                                  States</span></div>
                                                                              <div style="line-height:1.5"><br></div>
                                                                              <div id="request" style="line-height:1.5">
                                                                                <span style="color:#7f7f7f"><span
                                                                                    style="color:#595959;font-size:15px"><span
                                                                                      style="font-size:15px;color:#595959"><span
                                                                                        style="font-size:15px;color:#595959"><span
                                                                                          id="control"
                                                                                          style="color:#000"><strong><span
                                                                                              data-custom-class="heading_1">16.
                                                                                              HOW CAN YOU REVIEW, UPDATE,
                                                                                              OR DELETE THE DATA WE
                                                                                              COLLECT FROM
                                                                                              YOU?</span></strong></span></span></span></span></span>
                                                                              </div>
                                                                              <div style="line-height:1.5"><br></div>
                                                                              <div style="line-height:1.5"><span
                                                                                  style="font-size:15px;color:#595959"><span
                                                                                    style="font-size:15px;color:#595959"><span
                                                                                      data-custom-class="body_text">Based
                                                                                      on the applicable laws of your
                                                                                      country, you may have the right to
                                                                                      request access to the personal
                                                                                      information we collect from you,
                                                                                      change that information, or delete
                                                                                      it. To request to review, update, or
                                                                                      delete your personal information,
                                                                                      please</span><span
                                                                                      data-custom-class="body_text">visit:<a
                                                                                        href="https://web.qi-dev.quantexinnovations.com"
                                                                                        target="_blank"
                                                                                        data-custom-class="link">https://web.qi-dev.quantexinnovations.com</a></span></span><span
                                                                                    data-custom-class="body_text">.</span></span>
                                                                              </div>
                                                                              <style>
                                                                                body {
                                                                                  padding-left: 8px;
                                                                                  padding-right: 8px;
                                                                                  padding-bottom: env(safe-area-inset-bottom);
                                                                                }

                                                                                ul {
                                                                                  list-style-type: square
                                                                                }

                                                                                ul>li>ul {
                                                                                  list-style-type: circle
                                                                                }

                                                                                ul>li>ul>li>ul {
                                                                                  list-style-type: square
                                                                                }

                                                                                ol li {
                                                                                  font-family: Arial
                                                                                }

                                                                                [data-custom-class=body],
                                                                                [data-custom-class=body] * {
                                                                                  background: 0 0 !important
                                                                                }

                                                                                [data-custom-class=title],
                                                                                [data-custom-class=title] * {
                                                                                  font-family: Arial !important;
                                                                                  font-size: 26px !important;
                                                                                  color: #000 !important
                                                                                }

                                                                                [data-custom-class=subtitle],
                                                                                [data-custom-class=subtitle] * {
                                                                                  font-family: Arial !important;
                                                                                  color: #595959 !important;
                                                                                  font-size: 14px !important
                                                                                }

                                                                                [data-custom-class=heading_1],
                                                                                [data-custom-class=heading_1] * {
                                                                                  font-family: Arial !important;
                                                                                  font-size: 19px !important;
                                                                                  color: #000 !important
                                                                                }

                                                                                [data-custom-class=heading_2],
                                                                                [data-custom-class=heading_2] * {
                                                                                  font-family: Arial !important;
                                                                                  font-size: 17px !important;
                                                                                  color: #000 !important
                                                                                }

                                                                                [data-custom-class=body_text],
                                                                                [data-custom-class=body_text] * {
                                                                                  color: #595959 !important;
                                                                                  font-size: 14px !important;
                                                                                  font-family: Arial !important
                                                                                }

                                                                                [data-custom-class=link],
                                                                                [data-custom-class=link] * {
                                                                                  color: #3030f1 !important;
                                                                                  font-size: 14px !important;
                                                                                  font-family: Arial !important;
                                                                                  word-break: break-word !important
                                                                                }
                                                                              </style>
                                                                            </div>
                                                                          </div>
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </body>
  </html>
`
