import { ScrollView, TouchableOpacity, View } from 'react-native'
import { getJoinString } from '../../../../core/helpers/utils'
import { DefaultWrapperProps, withWrapper } from '../../../../core/helpers/withWrapper'
import { ICar } from '../../../../core/store/vehicles'
import { withStyles } from '../../../../core/theme'
import { Button, Divider, Paper, Spacer, Typography } from '../../../components'
import { Icon } from '../../../components/icon'
import { PaymentsSection } from '../../../components/station/PaymentsSection'
import { ChargeDetails, Operator, Rating } from '../../../components/stationFeedback'
import { IStationFeedbackContentProps } from './types'
import { useStationFeedbackContentWrapper } from './wrapper'
import { Card, Charging, Station } from '../../../../core/models'

const useStyles = withStyles((theme) => ({
  container: {
    paddingTop: theme.spacing.s,
  },
  textInput: {
    minHeight: theme.spacing.xxl * 2,
    borderColor: '#DDEAF3',
    borderRadius: theme.spacing.s,
    borderWidth: 1,
    paddingTop: theme.spacing.m,
    paddingBottom: theme.spacing.m,
    paddingHorizontal: theme.spacing.m,
  },
  stars: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing.m,
  },
  paymentContainer: {
    flexDirection: 'row',
  },
  paymentIconWrapper: {
    height: theme.spacing.xl,
    width: theme.spacing.xl,
    borderRadius: theme.spacing.s,
    backgroundColor: '#F6F8FA',
  },
  paymentTextsWrapper: {
    justifyContent: 'space-between',
    paddingLeft: 12,
  },
  reportWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
}))

interface Feedback {
  stars: number
  comment: string
}

interface IStationFeedbackContent extends IStationFeedbackContentProps, DefaultWrapperProps {
  styles: ReturnType<typeof useStyles>
  result: Charging.Result
  car?: ICar
  card?: Card
  station?: Station
  feedback: Feedback
  onFeedbackChange: (field: keyof Feedback) => (value: Feedback[typeof field]) => void
  sendFeedback: () => void
}

const StationFeedbackContent = ({
  t,
  style,
  styles,
  feedback,
  car,
  card,
  station,
  result,
  onFeedbackChange,
  sendFeedback,
  showReport,
}: IStationFeedbackContent) => {
  const starsRate = (amount: number, selectedRating: number) => (
    <View style={styles.stars}>
      {Array.from({ length: amount }, (_, index) => {
        const onPress = () => onFeedbackChange('stars')(index)

        return (
          <TouchableOpacity key={index} onPress={onPress}>
            <Icon
              size={28}
              color={'#F2C94C'}
              family={'FontAwesome'}
              name={index <= selectedRating ? 'star' : 'star-o'}
            />
          </TouchableOpacity>
        )
      })}
    </View>
  )

  return (
    <Paper style={style}>
      <ScrollView contentContainerStyle={styles.container} showsVerticalScrollIndicator={false}>
        <Rating
          styles={styles}
          starsRate={starsRate}
          amount={5}
          selectedRating={feedback.stars}
          inputValue={feedback.comment}
          setInputValue={onFeedbackChange('comment')}
        />
        {station && (
          <>
            <Divider vertical={'m'} />
            <Typography text={t('feedback:title')} bold />
            <Spacer top={'m'} />
            <Typography text={station.name} fontWeight={'300'} />
            <Spacer vertical={'xs'} />
            <Typography
              text={getJoinString([
                station.address.streetAddress,
                station.address.locality,
                station.address.postCode,
              ])}
              fontWeight={'300'}
            />
            <Divider vertical={'m'} />
          </>
        )}
        <ChargeDetails details={result} />
        {card && (
          <>
            <Divider vertical={'m'} />
            <PaymentsSection card={card} />
          </>
        )}
        {car && (
          <>
            <Divider vertical={'m'} />
            <Typography text={t('vehicle:title')} bold />
            <Spacer top={'m'} />
            <Typography
              text={getJoinString([
                car.vehicleVersion.vehicleManufacturerName,
                car.vehicleVersion.vehicleModelName || car.vehicleVersion.vehicleVersionName,
              ])}
              fontWeight={'300'}
            />
            <Spacer vertical={'xs'} />
            <Typography text={car.vehicleVersion.year} fontWeight={'300'} />
          </>
        )}
        <Divider vertical={'m'} />
        <TouchableOpacity style={styles.reportWrapper} onPress={showReport}>
          <Typography text={t('feedback:report')} bold />
          <Icon family={'Feather'} name={'chevron-right'} size={20} />
        </TouchableOpacity>
        <Operator />
        <Spacer top={'l'} />
        <Button fullWidth label={t('buttons:done')} onPress={sendFeedback} />
      </ScrollView>
    </Paper>
  )
}

export default withWrapper<IStationFeedbackContentProps>(
  StationFeedbackContent,
  useStationFeedbackContentWrapper,
  {
    useStyles,
  }
)
