import { IUseWrapper, IOptions } from '../../../../core/helpers/withWrapper'
import { useTheme } from '../../../../core/theme'

export const useButtonWrapper: IUseWrapper = (options?: IOptions) => {
  const { useStyles } = options!
  const theme = useTheme()
  const styles = useStyles()

  return { styles, theme }
}
