import { useCallback, useLayoutEffect, useState } from 'react'
import { Platform, TouchableOpacity } from 'react-native'
import { useFocusEffect } from '@react-navigation/native'

import { Box, Spacer } from '../../components'
import { Icon } from '../../components/icon'
import { RouterNames } from '../../../core/constants'
import { Header } from '../../router/Header'
import {
  ITransaction,
  OrderType,
  transactionsActionHooks,
  transactionsSelectorHooks,
} from '../../../core/store/transactions'
import { TransactionFilterModal } from '../TransactionFilter'
import { SortModal } from './SortModal'
import { TransactionList } from './TransactionList'

const TRANSACTIONS_COUNT = 20

export const TransactionHistoryScreen = ({
  navigation,
}: ScreenProps<RouterNames.transactionHistory>) => {
  const { navigate, setOptions } = navigation

  const [sortVisible, setSortVisible] = useState(false)
  const [filterVisible, setFilterVisible] = useState(false)

  const [isRefreshing, setIsRefreshing] = useState(false)

  const [sortField, setSortField] = useState<keyof ITransaction>('endTimeOfCharge')
  const [page, setPage] = useState(1)

  const [order, setOrder] = useState<OrderType>('desc')

  const transactions = transactionsSelectorHooks.getTransactions()
  const isLoading = transactionsSelectorHooks.getTransactionLoading()
  const { chargerStationsId, transactionEndTime, transactionStartTime, vehiclesId } =
    transactionsSelectorHooks.getTransactionFilter()

  const getTransactions = transactionsActionHooks.getTransactions()

  useLayoutEffect(() => {
    setOptions({
      header: (props) => (
        <Header
          {...props}
          right={
            <Box direction="row">
              <TouchableOpacity onPress={toggleFilters}>
                <Icon family={'MaterialCommunityIcons'} name="filter-outline" size={28} />
              </TouchableOpacity>
              <Spacer horizontal={'s'} />
              <TouchableOpacity onPress={toggleSort}>
                <Icon family={'MaterialIcons'} name="sort" size={28} />
              </TouchableOpacity>
            </Box>
          }
        />
      ),
    })
  }, [])

  useFocusEffect(
    useCallback(() => {
      getTransactions({
        size: TRANSACTIONS_COUNT,
        page,
        sortBy: sortField,
        orderBy: order,
        endTimeOfCharge: transactionEndTime || undefined,
        startTimeOfCharge: transactionStartTime || undefined,
        chargeStationId: chargerStationsId,
        vehicleId: vehiclesId,
      })
    }, [
      page,
      isRefreshing,
      sortField,
      order,
      transactionEndTime,
      transactionStartTime,
      chargerStationsId,
      vehiclesId,
    ])
  )

  const onSortPress = (field: keyof ITransaction) => {
    setOrder((order) => (sortField === field && order === 'asc' ? 'desc' : 'asc'))
    setSortField(field)
    setPage(0)
  }

  const onRefresh = () => {
    setPage(0)
    setIsRefreshing((isRefreshing) => !isRefreshing)
  }

  const onEndReached = () => {
    const hasMore = transactions.length % TRANSACTIONS_COUNT === 0
    hasMore && !isLoading && setPage((page) => page + 1)
  }

  const toggleSort = () => {
    setSortVisible((isVisible) => !isVisible)
  }

  const toggleFilters = () => {
    Platform.OS === 'web'
      ? setFilterVisible((isVisible) => !isVisible)
      : navigate(RouterNames.transactionFilter)
  }

  return (
    <>
      <TransactionList
        transactions={transactions}
        isLoading={isLoading}
        isRefreshing={isRefreshing}
        onRefresh={onRefresh}
        onEndReached={onEndReached}
      />
      <SortModal
        order={order}
        isVisible={sortVisible}
        sortField={sortField}
        onClose={toggleSort}
        onSortPress={onSortPress}
      />
      {Platform.OS === 'web' && (
        <TransactionFilterModal isVisible={filterVisible} setVisible={setFilterVisible} />
      )}
    </>
  )
}
