import { PropsWithChildren, createContext, useContext, useEffect, useRef, useState } from 'react'

import {
  processData,
  RequestMessageTypes,
  ResponseMessageTypes,
  ResponseType,
} from '../../core/api/charging'
import { useAuth } from './auth'
import { WS_URL } from '@env'

interface WebSocketData {
  isAuthorized: boolean
  send: <T extends { action: RequestMessageTypes }>(payload: T) => void
  response: ResponseType | null
  disconnect: () => void
}

const WebSocketContext = createContext({} as WebSocketData)

export const WebSocketProvider = ({ children }: PropsWithChildren<{}>) => {
  const wsRef = useRef<WebSocket | null>(null)

  const [isAuthorized, setIsAuthorized] = useState(false)
  const [response, setResponse] = useState<ResponseType | null>(null)

  const { token } = useAuth()

  const init = async () => {
    if (!token) return

    wsRef.current = new WebSocket(WS_URL)

    wsRef.current.onopen = () => {
      const payload = { action: RequestMessageTypes.auth, token }
      send(payload)
    }

    wsRef.current.onmessage = ({ data }) => {
      const response = processData(data)
      setResponse(response)
    }

    wsRef.current.onclose = init
  }

  useEffect(() => {
    init()

    return disconnect
  }, [token])

  useEffect(() => {
    if (response?.action === ResponseMessageTypes.confirmAuth) {
      setIsAuthorized(true)
    }
  }, [response])

  const send = <T extends { action: RequestMessageTypes }>(data: T) => {
    if (wsRef.current?.readyState === wsRef.current?.OPEN) {
      wsRef.current?.send(JSON.stringify(data))
    }
  }

  const disconnect = () => {
    if (wsRef.current) {
      wsRef.current.onclose = null
      wsRef.current.close()
      setIsAuthorized(false)
    }
  }

  const value = { isAuthorized, send, disconnect, response }

  return <WebSocketContext.Provider value={value}>{children}</WebSocketContext.Provider>
}

export const useWebSocket = () => useContext(WebSocketContext)
