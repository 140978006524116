import React, { PropsWithChildren } from 'react'
import { FlexAlignType, FlexStyle, StyleProp, StyleSheet, View, ViewStyle } from 'react-native'
import { withWrapper } from '../../../../core/helpers/withWrapper'
import { ITheme } from '../../../../core/theme/themes'

import { useBoxWrapper } from './wrapper'

type Spacing = keyof ITheme['spacing']

interface IBoxProps extends PropsWithChildren<{}> {
  direction?: 'column' | 'row'
  gap?: Spacing
  px?: Spacing
  py?: Spacing
  pt?: Spacing
  pb?: Spacing
  pr?: Spacing
  pl?: Spacing
  mx?: Spacing
  my?: Spacing | number
  mt?: Spacing
  mb?: Spacing
  mr?: Spacing
  ml?: Spacing
  alignSelf?: FlexStyle['alignSelf']
  alignItems?: FlexStyle['alignItems']
  justifyContent?: FlexStyle['justifyContent']
  flex?: boolean
  fullWidth?: boolean
  zIndex?: number
  style?: StyleProp<ViewStyle>
}

interface IBox extends IBoxProps {
  theme: ITheme
}

const Box = ({
  children,
  gap,
  direction = 'row',
  alignSelf,
  alignItems = 'center',
  justifyContent = 'center',
  px,
  py,
  mx,
  my,
  theme,
  pb,
  pt,
  pr,
  pl,
  mb,
  mt,
  mr,
  ml,
  flex,
  fullWidth,
  zIndex,
  style,
}: IBox) => {
  return (
    <View
      style={StyleSheet.compose(
        {
          flex: flex ? 1 : undefined,
          alignItems,
          alignSelf,
          justifyContent,
          flexDirection: direction,
          gap: gap && theme.spacing[gap],
          paddingHorizontal: px && theme.spacing[px],
          paddingVertical: py && theme.spacing[py],
          paddingBottom: pb && theme.spacing[pb],
          paddingTop: pt && theme.spacing[pt],
          paddingLeft: pl && theme.spacing[pl],
          paddingRight: pr && theme.spacing[pr],
          marginHorizontal: mx && theme.spacing[mx],
          marginVertical: my ? (typeof my === 'string' ? theme.spacing[my] : my) : undefined,
          marginBottom: mb && theme.spacing[mb],
          marginTop: mt && theme.spacing[mt],
          marginLeft: ml && theme.spacing[ml],
          marginRight: mr && theme.spacing[mr],
          width: fullWidth ? '100%' : undefined,
          zIndex,
        },
        style
      )}
    >
      {children}
    </View>
  )
}

export default withWrapper<IBoxProps>(Box, useBoxWrapper)
