import { withStyles } from '../../../../core/theme'

export const useStyles = withStyles((theme) => ({
  gap: {
    gap: theme.spacing.m,
  },
  tabs: {
    flexGrow: 0,
  },
  tab: {
    padding: theme.spacing.xs,
  },
  selected: {
    borderWidth: 1,
    borderRadius: 8,
    borderColor: theme.colors.primary,
  },
}))
