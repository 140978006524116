import { useState } from 'react'
import { Platform, TouchableOpacity, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useNavigation } from '@react-navigation/native'
import { SupportRequestModal } from '../../SupportRequest'
import { Typography } from '../../../components/common'
import { Icon } from '../../../components/icon'
import { RouterNames } from '../../../../core/constants'
import { useStyles } from './styles'

interface MenuSectionProps {
  chargeStationId: string
}

export const Report = ({ chargeStationId }: MenuSectionProps) => {
  const { t } = useTranslation()
  const { navigate } = useNavigation()

  const [isModalVisible, setIsModalVisible] = useState(false)

  const styles = useStyles()

  const onPress = () => {
    if (Platform.OS === 'web') {
      setIsModalVisible(true)
    } else {
      navigate(RouterNames.supportRequest, { chargeStationId })
    }
  }

  return (
    <>
      <TouchableOpacity onPress={onPress} style={styles.container}>
        <View style={styles.left}>
          <Icon family={'Feather'} name={'mail'} size={18} />
          <Typography bold text={t('stations:menu.report')} />
        </View>
        <Icon family={'Feather'} name={'chevron-right'} size={18} />
      </TouchableOpacity>
      <SupportRequestModal
        isVisible={isModalVisible}
        setVisible={setIsModalVisible}
        chargeStationId={chargeStationId}
      />
    </>
  )
}
