import { createAction } from '@reduxjs/toolkit'
import { createActionHook, createEmptyActionHook } from '../../helpers/store'
import * as CONSTANTS from './constants'
import {
  Account,
  Callback,
  ConfirmRegistrationUserPayload,
  IGetFaq,
  IQuestion,
  LoginAction,
  ResetPasswordPayload,
  SignupAction,
  UpdateAction,
  UploadImage,
  ValidateUserPayload,
} from './types'

const login = createAction<LoginAction>(CONSTANTS.LOGIN)
const logout = createAction(CONSTANTS.LOGOUT)
const signin = createAction<SignupAction>(CONSTANTS.SIGNIN)

const getAccountProfile = createAction<LoginAction>(CONSTANTS.GET_USER_PROFILE)
const updateAccountProfile = createAction<UpdateAction>(CONSTANTS.UPDATE_USER_PROFILE)
const setAccountProfile = createAction<Account>(CONSTANTS.SET_USER_PROFILE)
const recovery = createAction(CONSTANTS.RECOVERY)
const updateUserImage = createAction<UploadImage>(CONSTANTS.UPDATE_USER_IMAGE)
const deactivateUser = createAction<Callback>(CONSTANTS.DEACTIVATE_USER)

const validateUser = createAction<ValidateUserPayload>(CONSTANTS.VALIDATE_USER)

const confirmRegistration = createAction<ConfirmRegistrationUserPayload>(
  CONSTANTS.CONFIRM_REGISTRATION
)

const resetPassword = createAction<ResetPasswordPayload>(CONSTANTS.RESET_PASSWORD)

const setImage = createAction<string | null>(CONSTANTS.SET_IMAGE)

const getFaq = createAction<IGetFaq>(CONSTANTS.GET_FAQ)
const setFaq = createAction<IQuestion[]>(CONSTANTS.SET_FAQ)

const setIsAuthorized = createAction<boolean>(CONSTANTS.SET_IS_AUTHORIZED)

const getUserDetails = createAction(CONSTANTS.GET_USER_DETAILS)

export const accountActionHooks = {
  getAccountProfile: createActionHook(getAccountProfile),
  login: createActionHook<LoginAction>(login),
  logout: createEmptyActionHook(logout),
  setAccountProfile: createActionHook(setAccountProfile),
  signin: createActionHook<SignupAction>(signin),
  updateAccountProfile: createActionHook(updateAccountProfile),
  updateUserImage: createActionHook(updateUserImage),
  recovery: createActionHook(recovery),
  deactivateUser: createActionHook(deactivateUser),
  validateUser: createActionHook<ValidateUserPayload>(validateUser),
  confirmRegistration: createActionHook<ConfirmRegistrationUserPayload>(confirmRegistration),
  resetPassword: createActionHook<ResetPasswordPayload>(resetPassword),
  getFaq: createActionHook<IGetFaq>(getFaq),
  setIsAuthorized: createActionHook<boolean>(setIsAuthorized),
  getUserDetails: createEmptyActionHook(getUserDetails),
  setImage: createActionHook(setImage),
}

export {
  getAccountProfile,
  login,
  logout,
  setAccountProfile,
  signin,
  updateAccountProfile,
  recovery,
  validateUser,
  resetPassword,
  getFaq,
  setFaq,
  setImage,
  setIsAuthorized,
  getUserDetails,
}
