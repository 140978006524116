import { api } from '../setup'
import * as CONSTANTS from './constants'
import { ISupportApi } from './types'

export const supportApi: ISupportApi = {
  createTicket: async (body) => {
    const { data } = await api.post(CONSTANTS.CREATE_TICKET, body)

    return data
  },
  types: async () => {
    const { data } = await api.get(CONSTANTS.TYPES)

    return data
  },
}
