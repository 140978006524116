import { useEffect, useState } from 'react'
import { IOptions, IUseWrapper } from '../../../../core/helpers/withWrapper'
import { ISpinnerProps } from './types'

export const useSpinnerWrapper: IUseWrapper<ISpinnerProps> = (
  { useStyles }: IOptions,
  { spinning = true }
) => {
  const styles = useStyles()
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    if (spinning) {
      const interval = setInterval(() => {
        setProgress((progress) => progress + 1)
      }, 10)

      return () => clearInterval(interval)
    } else {
      setProgress(0)
    }
  }, [spinning])

  return { styles, progress }
}
