export const PREFIX = 'NOTIFICATION_'

export const GET_MONTHLY_SUBSCRIPTION = `${PREFIX}GET_MONTHLY_SUBSCRIPTION`

export const SET_EMAIL_SUBSCRIPTION = `${PREFIX}SET_EMAIL_SUBSCRIPTION`
export const UPDATE_EMAIL_SUBSCRIPTION = `${PREFIX}UPDATE_EMAIL_SUBSCRIPTION`

export const SET_STATUS_SUBSCRIPTION = `${PREFIX}SET_STATUS_SUBSCRIPTION`
export const UPDATE_STATUS_SUBSCRIPTION = `${PREFIX}UPDATE_STATUS_SUBSCRIPTION`

export const SUBSCRIBE_PUSH_NOTIFICATIONS = `${PREFIX}SUBSCRIBE_PUSH_NOTIFICATIONS`
export const UNSUBSCRIBE_PUSH_NOTIFICATIONS = `${PREFIX}UNSUBSCRIBE_PUSH_NOTIFICATIONS`
