import { withStyles } from '../../../../core/theme'

export const useStyles = withStyles((theme) => ({
  modal: {
    padding: theme.spacing.m,
  },
  text: {
    marginLeft: 0,
    color: theme.colors.text,
    fontWeight: '400',
    paddingVertical: theme.spacing.s,
  },
}))
