import { useTranslation } from 'react-i18next'
import { IUseWrapper, IOptions } from '../../../core/helpers/withWrapper'
import { RouterNames } from '../../../core/constants'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useEffect, useState } from 'react'

import { TFunction } from 'i18next'
import { accountActionHooks } from '../../../core/store/account'
import { OTPTypes } from './types'
import { showMessage } from 'react-native-flash-message'
import axios from 'axios'
import { RouteProp, useNavigation, useRoute, CommonActions } from '@react-navigation/native'
import { RootStackParamList } from '../../router/types'
import { ServerError } from '../../../core/api/setup'

const OTPSchema = (t: TFunction) =>
  Yup.object().shape({
    otp: Yup.number().min(6).required(t('forms:common.required')),
  })

const PasswordSchema = (t: TFunction) =>
  Yup.object().shape({
    otp: Yup.number().min(6).required(t('forms:common.required')),
    password: Yup.string()
      .required(t('forms:common.required'))
      .min(8, t('registration:form.validation.password.main'))
      .minLowercase(1, t('registration:form.validation.password.minLowerCase'))
      .minUppercase(1, t('registration:form.validation.password.minUpperCase'))
      .minNumbers(1, t('registration:form.validation.password.minNumber'))
      .minSymbols(1, t('registration:form.validation.password.minSymbols')),
    repeatedPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], t('forms:common.passwordMatch'))
      .required(t('forms:common.required')),
  })

export const useOTPScreenWrapper: IUseWrapper = (options: IOptions) => {
  const { useStyles } = options!
  const { params } = useRoute<RouteProp<RootStackParamList, RouterNames.otp>>()
  const { type, code } = params

  const { navigate, dispatch } = useNavigation()
  const { t } = useTranslation()

  const styles = useStyles()

  const confirmRegistration = accountActionHooks.confirmRegistration()
  const resetPassword = accountActionHooks.resetPassword()

  const [otpError, setOTPError] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)

  const openLoginScreen = () => {
    navigate(RouterNames.login)
  }

  const formik = useFormik({
    initialValues: {
      otp: code || '',
      password: '',
      repeatedPassword: '',
    },
    validationSchema: type === OTPTypes.recovery ? PasswordSchema(t) : OTPSchema(t),
    onSubmit: (values) => {
      setOTPError(null)
      setLoading(true)

      if ([OTPTypes.registration, OTPTypes.confirm].includes(type)) {
        confirmRegistration({
          otp: values.otp,
          callback: (error) => {
            setLoading(false)

            if (!error) {
              dispatch(CommonActions.reset({ index: 0, routes: [{ name: RouterNames.login }] }))

              showMessage({
                type: 'success',
                message: t('otp:form.success.registration.title'),
                description: t('otp:form.success.registration.description'),
              })
            } else {
              const message = axios.isAxiosError(error)
                ? t(`otp:errors.${error.response?.data.message}`)
                : t('forms:common.otp')

              setOTPError(message)
            }
          },
        })
      }

      if (type === OTPTypes.recovery) {
        resetPassword({
          otp: values.otp,
          newPassword: values.password,
          confirmPassword: values.repeatedPassword,
          callback: (error) => {
            setLoading(false)
            if (!error) {
              navigate(RouterNames.login)
              showMessage({
                type: 'success',
                message: t('otp:form.success.recovery.title'),
                description: t('otp:form.success.recovery.description'),
              })
            } else {
              const message = axios.isAxiosError(error)
                ? t(`otp:errors.${(error as ServerError).response?.data.message}`)
                : t('forms:common.otp')
              setOTPError(message)
            }
          },
        })
      }
    },
  })

  useEffect(() => {
    if (otpError) {
      formik.setFieldError('otp', otpError)
    }
  }, [otpError])

  return {
    styles,
    t,
    openLoginScreen,
    handleSubmit: formik.handleSubmit,
    handleChange: (field: string) => (value: string) => formik.setFieldValue(field, value),
    handleBlur: (field: string) => () => formik.setFieldTouched(field, true),
    values: formik.values,
    isValid: formik.isValid,
    errors: formik.errors,
    touched: formik.touched,
    loading,
    otpError,
    setOTPError,
    password: formik,
    type,
  }
}
