import { ConnectorStatus } from '../../../core/models'
import { withStyles } from '../../../core/theme'

export const useStyles = withStyles((theme) => ({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#DDEAF3',
    paddingHorizontal: theme.spacing.s,
    paddingVertical: theme.spacing.m,
    borderColor: theme.colors.border,
    borderWidth: 2,
    borderRadius: theme.spacing.m,
  },
  active: {
    borderColor: theme.colors.primary,
  },
  body: {
    flex: 1,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  right: {
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  status: {
    overflow: 'hidden',
    textAlign: 'center',
    padding: theme.spacing.xs,
    borderRadius: theme.spacing.s,
  },
  [ConnectorStatus.Available]: {
    backgroundColor: theme.colors.available,
    color: theme.colors.white,
  },
  [ConnectorStatus.Charging]: {
    backgroundColor: theme.colors.charging,
    color: theme.colors.background,
  },
  [ConnectorStatus.Preparing]: {
    backgroundColor: theme.colors.preparing,
    color: theme.colors.white,
  },
  [ConnectorStatus.Finishing]: {
    backgroundColor: theme.colors.preparing,
    color: theme.colors.white,
  },
  [ConnectorStatus.Unavailable]: {
    backgroundColor: theme.colors.unavailable,
    color: theme.colors.white,
  },
  [ConnectorStatus.SuspendedEv]: {
    backgroundColor: theme.colors.suspendedEv,
    color: theme.colors.white,
  },
  [ConnectorStatus.SuspendedEvSe]: {
    backgroundColor: theme.colors.suspendedEvSe,
    color: theme.colors.white,
  },
  [ConnectorStatus.Faulted]: {
    backgroundColor: theme.colors.failure,
    color: theme.colors.white,
  },
}))
