import { useNavigation } from '@react-navigation/native'
import { IOptions, IUseWrapper } from '../../../../core/helpers/withWrapper'

export const useTransactionFilterScreeWrapper: IUseWrapper = ({ useStyles }: IOptions) => {
  const styles = useStyles()

  const { goBack } = useNavigation()

  return { styles, onFilter: goBack }
}
