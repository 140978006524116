import { createAction } from '@reduxjs/toolkit'

import { createActionHook } from '../../helpers/store'
import * as CONSTANTS from './constants'

const setLanguage = createAction<string>(CONSTANTS.SET_LANGUAGE)

export const settingsActionHooks = {
  setLanguage: createActionHook(setLanguage),
}

export { setLanguage }
