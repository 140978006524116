import { createSelectorHook } from '../../helpers/store'
import { RootState } from '../rootReducer'
import { ICar } from './types'

const getManufacturers = () => (state: RootState) => {
  return state.vehicles.manufacturers
}

const getModels = () => (state: RootState) => {
  return state.vehicles.models
}

const getYears = () => (state: RootState) => {
  return state.vehicles.years
}

const getVersions = () => (state: RootState) => {
  return state.vehicles.versions
}

const getCars = () => (state: RootState) => {
  return state.vehicles.cars
}

const getCarById = (carId: string) => (state: RootState) => {
  return state.vehicles.cars.find(({ vehicleId }) => vehicleId === carId)
}

const getPrimary = () => (state: RootState) => {
  return state.vehicles.cars.find(({ primary }) => primary)
}

const getVehicle = () => (state: RootState) => {
  return state.vehicles.vehicle
}

export const vehiclesSelectorHooks = {
  getVehicles: createSelectorHook<RootState['vehicles']['manufacturers']>(getManufacturers),
  getModels: createSelectorHook<RootState['vehicles']['models']>(getModels),
  getYears: createSelectorHook<RootState['vehicles']['years']>(getYears),
  getVersions: createSelectorHook<RootState['vehicles']['versions']>(getVersions),
  getCars: createSelectorHook<RootState['vehicles']['cars']>(getCars),
  getCarById: createSelectorHook<ICar | undefined>(getCarById),
  getPrimary: createSelectorHook<ICar | undefined>(getPrimary),
  getVehicle: createSelectorHook(getVehicle),
}
