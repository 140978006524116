import { useEffect } from 'react'
import { stationsActionHooks, stationsSelectorHooks } from '../../core/store/stations'
import { vehiclesActionHooks } from '../../core/store/vehicles'
import { accountSelectorHooks } from '../../core/store/account'
import { paymentActionHooks } from '../../core/store/payment'

export const DataController = () => {
  const isAuthorized = accountSelectorHooks.getIsAuthorized()
  const connectors = stationsSelectorHooks.getConnectors()

  const getPaymentMethods = paymentActionHooks.getPaymentMethods()
  const getConnectors = stationsActionHooks.getConnectors()
  const getCars = vehiclesActionHooks.getCars()

  useEffect(() => {
    if (!isAuthorized) return

    getCars({})
    getPaymentMethods()
  }, [isAuthorized])

  const hasConnectors = connectors.length > 0

  useEffect(() => {
    if (!isAuthorized || hasConnectors) return

    getConnectors()
  }, [hasConnectors, isAuthorized])

  return null
}
