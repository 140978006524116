import { api } from '../setup'
import * as CONSTANTS from './constants'
import { ISessionsApi } from './types'

export const sessionsApi: ISessionsApi = {
  sessions: async (params) => {
    const { data } = await api.get(CONSTANTS.MANUFACTURER, {
      params: { orderBy: 'desc', sortBy: 'endTimeOfCharge', ...params },
    })
    return data
  },
  getActive: async () => {
    const { data } = await api.get(CONSTANTS.ACTIVE)
    return data
  },
}
