import { IOptions, IUseWrapper } from '../../../../core/helpers/withWrapper'
import { ILanguagesModalProps } from './types'

export const useLanguagesModalWrapper: IUseWrapper<ILanguagesModalProps> = (
  options: IOptions,
  { setVisible }
) => {
  const { useStyles } = options!
  const styles = useStyles()

  const onChooseLanguage = () => {
    setVisible(false)
  }

  return { styles, onChooseLanguage }
}
