import { ActivityIndicator, View } from 'react-native'
import { useTranslation } from 'react-i18next'

import { Connector as ConnectorType } from '../../../core/models'
import { theme } from '../../../core/theme/themes'
import { ConnectorImage } from '../common/ConnectorImage'
import { Spacer, Typography } from '../common'
import { useStyles } from './styles'

interface Props {
  connector: ConnectorType
  showStatus?: boolean
  isActive?: boolean
  price?: number
}

export const Connector = ({ isActive, showStatus, connector, price }: Props) => {
  const { connectorTypeName, connectorStatus, connectorNumber, powerRating } = connector

  const styles = useStyles()
  const { t } = useTranslation()

  return (
    <View style={[styles.container, isActive && styles.active]}>
      <ConnectorImage icon={connector.icon} />
      <Spacer horizontal={'s'} />
      <View style={styles.body}>
        <View style={styles.row}>
          <View>
            <Typography bold text={`${t('connector:title')} #${connectorNumber}`} />
            <Typography
              size={'m'}
              fontWeight={'300'}
              text={`${powerRating?.toString()} ${t('connector:kwh')}`}
            />
            <Typography text={connectorTypeName} size={'m'} fontWeight={'300'} />
          </View>
          <View style={styles.right}>
            <Typography bold text={price ? `₪ ${price}` : ''} />
            {showStatus &&
              (connectorStatus ? (
                <Typography
                  text={t(`connector:status.${connectorStatus}`)}
                  size={'m'}
                  fontWeight={'300'}
                  style={[styles.status, styles[connectorStatus]]}
                />
              ) : (
                <ActivityIndicator size={20} color={theme.colors.primary} />
              ))}
          </View>
        </View>
      </View>
    </View>
  )
}
