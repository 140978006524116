import { withStyles } from '../../../../core/theme'

export const useStyles = withStyles((theme) => ({
  container: {
    gap: theme.spacing.s,
  },
  input: {
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 60,
    padding: theme.spacing.m,
    backgroundColor: theme.colors.background,
    borderWidth: 2,
    borderRadius: 8,
    borderColor: theme.colors.border,
  },
  chevron: {
    marginLeft: 'auto',
  },
  wrapper: {
    flex: 1,
  },
  dropdown: {
    padding: 0,
    maxHeight: 200,
    borderRadius: 8,
    backgroundColor: theme.colors.background,
  },
  item: {
    paddingVertical: theme.spacing.s,
    paddingHorizontal: theme.spacing.m,
  },
  header: {
    alignItems: 'flex-end',
    padding: theme.spacing.m,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    backgroundColor: theme.colors.background,
  },
  selected: {
    backgroundColor: `${theme.colors.primary}50`,
  },
}))
