import { View } from 'react-native'
import { Typography } from '../common'
import React from 'react'

export const renderDetails = (carDetails: any, styles: any) => (
  <View style={styles.detailsWrapper}>
    {Object.keys(carDetails).map((item, index) => (
      <View style={{ flexDirection: 'row', paddingBottom: 12 }} key={index}>
        <View style={{ width: '25%' }}>
          <Typography text={item} />
        </View>
        <Typography text={carDetails[item]} fontWeight={'200'} />
      </View>
    ))}
  </View>
)
