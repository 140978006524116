import { useEffect, useRef, useState } from 'react'
import { View, Text } from 'react-native'
import { IOptions, IUseWrapper } from '../../../../core/helpers/withWrapper'
import { useTheme } from '../../../../core/theme'
import { IProgressButtonProps } from './types'

export const useProgressButtonWrapper: IUseWrapper<IProgressButtonProps> = (
  { useStyles }: IOptions,
  { progress, text, color }
) => {
  const styles = useStyles()
  const theme = useTheme()

  const viewRef = useRef<View>(null)
  const textRef = useRef<Text>(null)

  const [colorIndex, setColorIndex] = useState(0)

  useEffect(() => {
    viewRef.current?.measureInWindow((fillX, _, fillWidth) => {
      textRef.current?.measureInWindow((textX, _, textWidth) => {
        const index = Math.floor(((fillX + fillWidth - textX) / textWidth) * text?.length)
        setColorIndex(index)
      })
    })
  }, [viewRef, textRef, progress])

  const buttonStyle = {
    width: `${progress}%`,
    backgroundColor: color?.button || theme.colors.primary,
  }

  const getTextColor = (i: number) => {
    return { color: i <= colorIndex ? theme.colors.white : color?.text || theme.colors.primary }
  }

  return { styles, viewRef, textRef, buttonStyle, getTextColor }
}
