import { useTranslation } from 'react-i18next'

import { Box, Typography } from '../common'
import { Charging } from '../../../core/models'

interface Props {
  details?: Charging.SessionStatus
}

export const ChargeDetails = ({ details }: Props) => {
  const { t } = useTranslation()

  if (!details) return null

  const { energy, time, cost } = details

  return (
    <Box alignItems={'flex-start'} direction={'column'} gap={'m'}>
      <Typography bold text={t('feedback:details')} />
      <Box fullWidth justifyContent={'space-between'}>
        <Typography text={t('feedback:delivered')} fontWeight={'300'} />
        <Typography text={`${energy.toFixed(2)} ${t('connector:kwh')}`} fontWeight={'300'} />
      </Box>
      <Box fullWidth justifyContent={'space-between'}>
        <Typography text={t('feedback:time')} fontWeight={'300'} />
        {/* TODO: Time needs to be formated */}
        <Typography text={`${time} sec`} fontWeight={'300'} />
      </Box>
      <Box fullWidth justifyContent={'space-between'}>
        <Typography text={t('stats:cost')} fontWeight={'300'} />
        <Typography text={`₪ ${cost.toFixed(2)}`} fontWeight={'300'} />
      </Box>
    </Box>
  )
}
