import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { IOptions, IUseWrapper } from '../../../core/helpers/withWrapper'
import { vehiclesActionHooks, vehiclesSelectorHooks } from '../../../core/store/vehicles'
import { RouterNames } from '../../../core/constants'
import { IListItem } from '../../components/common/ClickableList/component'
import { RootStackParamList } from '../../router/types'
import { useEffect, useState } from 'react'

export const useSelectModelScreenWrapper: IUseWrapper = (options?: IOptions) => {
  const { useStyles } = options!
  const styles = useStyles()

  const { navigate } = useNavigation()
  const { params } = useRoute<RouteProp<RootStackParamList, RouterNames.selectModel>>()

  const [isLoading, setIsLoading] = useState(true)

  const fetchModels = vehiclesActionHooks.getModels()
  const models = vehiclesSelectorHooks.getModels()

  useEffect(() => {
    if (params.id) {
      fetchModels({
        id: params.id,
        onSuccess() {
          setIsLoading(false)
        },
        onError(error) {
          setIsLoading(false)
          console.log(error)
        },
      })
    } else {
      navigate(RouterNames.private, { screen: RouterNames.home })
    }
  }, [])

  const handleClick = (item: IListItem) =>
    navigate(RouterNames.yearAndVersion, {
      model: item.firstString,
      pageTitle: item.firstString,
      id: item.id!,
    })

  return {
    styles,
    models,
    isLoading,
    handleClick,
  }
}
