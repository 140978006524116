import React, { PropsWithChildren } from 'react'
import { TouchableOpacity, View } from 'react-native'
import { withWrapper } from '../../../../core/helpers/withWrapper'
import { withStyles } from '../../../../core/theme'
import { ITheme } from '../../../../core/theme/themes'

import { useIconButtonWrapper } from './wrapper'
import { Icon } from '../../icon'

const useStyles = withStyles((theme) => ({
  container: {
    borderRadius: theme.spacing.m,
    backgroundColor: theme.colors.background,
    padding: theme.spacing.m,
    overflow: 'hidden',
    minWidth: 40,
    minHeight: 40,
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: theme.spacing.m,
    borderWidth: 2,
    borderColor: theme.colors.border,
  },
  shadow: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.18,
    shadowRadius: 1.0,
    elevation: 1,
  },
  badge: {
    width: 10,
    height: 10,
    backgroundColor: theme.colors.notification,
    borderRadius: 10,
    position: 'absolute',
    top: 5,
    right: 10,
  },
}))

interface IIconButtonProps extends PropsWithChildren<{}> {
  icon: string
  size?: number
  boxSize?: number
  disableBorder?: boolean
  transparent?: boolean
  padding?: number
  rounded?: boolean
  shadow?: boolean
  badge?: boolean
  color?: keyof ITheme['colors']
  onPress?: () => void
}

interface IIconButton extends IIconButtonProps {
  theme: ITheme
  styles: ReturnType<typeof useStyles>
}

const IconButton = ({
  size = 18,
  styles,
  icon,
  boxSize,
  onPress,
  disableBorder = true,
  transparent,
  rounded,
  padding,
  theme,
  color,
  shadow,
  badge,
}: IIconButton) => {
  return (
    <TouchableOpacity
      style={[
        styles.container,
        !!boxSize && { minWidth: boxSize, minHeight: boxSize },
        disableBorder && { borderWidth: 0 },
        transparent && { backgroundColor: 'transparent' },
        rounded && { borderRadius: 100 },
        !!padding && { padding },
        shadow && styles.shadow,
      ]}
      onPress={onPress}
    >
      <Icon
        family={'Feather'}
        name={icon}
        size={size}
        style={[!!color && { color: theme.colors[color] }]}
      />
      {badge && <View style={styles.badge} />}
    </TouchableOpacity>
  )
}

export default withWrapper<IIconButtonProps>(IconButton, useIconButtonWrapper, {
  useStyles,
})
