import { withStyles } from '../../../../core/theme'

export const useStyles = withStyles((theme) => ({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: theme.colors.background,
    paddingVertical: theme.spacing.m,
    paddingHorizontal: theme.spacing.m,
    shadowColor: theme.colors.white,
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.18,
    shadowRadius: 1.0,
    elevation: 1,
  },
  first: {
    borderTopRightRadius: theme.spacing.m,
    borderTopLeftRadius: theme.spacing.m,
  },
  last: {
    borderBottomRightRadius: theme.spacing.m,
    borderBottomLeftRadius: theme.spacing.m,
  },
  AloneStyle: {
    borderRadius: theme.spacing.m,
  },
}))
