import { ICar } from '../store/vehicles'
import { StationDetails } from './station'
import { Connector } from './connector'
import { Card } from './card'

export namespace Charging {
  export const enum Status {
    Charging = 'charging',
    Finished = 'finished',
  }

  export interface SessionData {
    card?: Card
    car?: ICar
    station: StationDetails
    connector: Connector
  }

  export interface SessionStatus {
    chargingId: string
    connectorId: string
    cost: number
    time: number
    energy: number
    status: Status
    speed: number | null
    percent: number | null
  }

  export interface StartChargingPayload {
    cardid?: string
    deferPayment: boolean
    vehicleid?: string
    connectorid: string
    stationid: string
  }

  export interface ActiveSession {
    cardId?: string
    chargeStationId: string
    chargingId: string
    connectorId: string
    vehicleId?: string
  }

  export interface Result extends SessionStatus {
    chargeStationId: string
    vehicleId?: string
    cardId?: string
  }
}
