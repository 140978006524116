import React from 'react'

import ModalContainer from '../../../components/common/ModalContainer'
import { stationsActionHooks } from '../../../../core/store/stations'
import { Station } from '../../../../core/models'
import { StationFilterContent } from '../content'
import { useStyles } from './styles'

interface Props {
  isVisible: boolean
  setVisible: (value: boolean) => void
}

export const StationFilterModal = ({ isVisible, setVisible }: Props) => {
  const styles = useStyles()

  const setFilters = stationsActionHooks.setFilters()

  const getFilteredStations = (filters: Station.Filter) => {
    setFilters(filters)
    setVisible(false)
  }

  return (
    <ModalContainer isVisible={isVisible} setVisible={setVisible} contentStyles={styles.content}>
      <StationFilterContent withResetButton show={getFilteredStations} />
    </ModalContainer>
  )
}
