import axios, { AxiosError } from 'axios'
import { Storage } from '../helpers/storage'
import { isTokenExpired } from '../helpers/token'
import { API_URL } from '@env'

export const api = axios.create({ baseURL: API_URL })

export type ServerError = AxiosError<{
  timestamp: number
  path: string
  status: string
  error: string
  message: string
  requestId: string
}>

export const setupApi = (logout: () => void) => {
  api.interceptors.request.use(async (config) => {
    if (config.headers) {
      const token = await Storage.getItem<string>('token')

      if (token && !isTokenExpired(token)) {
        config.headers['Authorization'] = `Bearer ${token}`
      } else {
        logout()
      }
    }
    return config
  }, Promise.reject)

  api.interceptors.response.use(
    (response) => response,
    (error) => {
      if (error.response?.status === 401) {
        logout()
      } else {
        return Promise.reject(error)
      }
    }
  )
}
