import { createReducer } from '@reduxjs/toolkit'
import { logout, setAccountProfile, setFaq, setImage, setIsAuthorized } from './actions'
import { AccountState } from './types'

const initialState: AccountState = {
  questions: [],
  user: null,
  image: null,
  isAuthorized: false,
}

export const accountReducer = createReducer(initialState, (builder) => {
  builder.addCase(setAccountProfile, (state, { payload }) => {
    return { ...state, user: payload }
  })
  builder.addCase(setImage, (state, { payload }) => {
    return { ...state, image: payload }
  })
  builder.addCase(logout, () => {
    return initialState
  })
  builder.addCase(setFaq, (state, action) => {
    return { ...state, questions: action.payload }
  })
  builder.addCase(setIsAuthorized, (state, action) => {
    return { ...state, isAuthorized: action.payload }
  })
})
