import { useEffect, useState } from 'react'
import { StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native'
import { DefaultWrapperProps, IOptions, withWrapper } from '../../core/helpers/withWrapper'
import { withStyles } from '../../core/theme'
import { Icon } from './icon'

const useStyles = withStyles((theme) => ({
  container: {
    flexDirection: 'row',
  },
}))

interface IRatingProps {
  size?: number
  defaultValue?: number
  disabled?: boolean
  onChange?: (value: number) => void
  style?: StyleProp<ViewStyle>
}

interface IRating extends DefaultWrapperProps, IRatingProps {
  styles: ReturnType<typeof useStyles>
  value: number
  setValue: (value: number) => void
}

const Component = ({ setValue, value, size = 14, disabled, styles, style }: IRating) => {
  const renderStar = (_: unknown, index: number) => {
    const onPress = () => {
      !disabled && setValue(index)
    }

    return (
      <TouchableOpacity key={index} disabled={disabled} onPress={onPress}>
        <Icon
          size={size}
          color={'#F2C94C'}
          family={'FontAwesome'}
          name={index < value ? 'star' : 'star-o'}
        />
      </TouchableOpacity>
    )
  }

  return <View style={[styles.container, style]}>{Array.from({ length: 5 }, renderStar)}</View>
}

const useRatingsWrapper = ({ useStyles }: IOptions, { defaultValue, onChange }: IRatingProps) => {
  const styles = useStyles()
  const [value, setValue] = useState(defaultValue || 0)

  useEffect(() => {
    if (onChange) {
      onChange(value)
    }
  }, [value])

  return { styles, value, setValue }
}

export default withWrapper<IRatingProps>(Component, useRatingsWrapper, {
  useStyles,
})
