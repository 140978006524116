import { PropsWithChildren } from 'react'
import { View } from 'react-native'

import { withWrapper } from '../../../../core/helpers/withWrapper'
import { withStyles } from '../../../../core/theme'
import { useRoundProgressBarWrapper } from './wrapper'
import Svg, { Circle } from 'react-native-svg'

const useStyles = withStyles(() => ({
  container: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    position: 'absolute',
  },
}))

interface IRoundProgressBarProps extends PropsWithChildren<{}> {
  size: number
  progress: number
  strokeWidth: number
  baseColor: string
  color: string
}

interface IRoundProgressBar extends IRoundProgressBarProps {
  styles: ReturnType<typeof useStyles>
}

const Component = ({
  styles,
  size,
  strokeWidth,
  baseColor,
  color,
  progress,
  children,
}: IRoundProgressBar) => {
  const radius = size / 2 - strokeWidth
  const circum = radius * 2 * Math.PI

  return (
    <View style={styles.container}>
      <Svg width={size} height={size}>
        <Circle
          stroke={baseColor}
          fill="none"
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeWidth={strokeWidth}
        />
        <Circle
          stroke={color}
          fill="none"
          cx={size / 2}
          cy={size / 2}
          r={radius}
          strokeDasharray={`${circum} ${circum}`}
          strokeDashoffset={circum * (1 - progress / 100)}
          strokeLinecap="round"
          transform={`rotate(-90, ${size / 2}, ${size / 2})`}
          strokeWidth={strokeWidth}
        />
      </Svg>
      <View style={styles.content}>{children}</View>
    </View>
  )
}

export const RoundProgressBar = withWrapper<IRoundProgressBarProps>(
  Component,
  useRoundProgressBarWrapper,
  { useStyles }
)
