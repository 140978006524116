import { StyleProp, View, ViewStyle } from 'react-native'
import { DefaultWrapperProps, withWrapper } from '../../../../core/helpers/withWrapper'
import { withStyles } from '../../../../core/theme'
import { useEmptyPlaceholderWrapper } from './wrapper'
import { ITheme } from '../../../../core/theme/themes'
import IconButton from '../IconButton'
import Button from '../Button'
import { Icon } from '../../icon'
import Spacer from '../Spacer'
import Typography from '../Typography'

const useStyles = withStyles((theme, insets) => ({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  goBack: {
    position: 'absolute',
    left: theme.spacing.s,
    top: insets.top + 20,
  },
}))

interface IEmptyPlaceholderProps {
  icon?: string
  text?: string
  retry?: () => void
  goBack?: boolean
  disableIcon?: boolean
  style?: StyleProp<ViewStyle>
}

interface IEmptyPlaceholder extends IEmptyPlaceholderProps, DefaultWrapperProps {
  styles: ReturnType<typeof useStyles>
  theme: ITheme
}

const EmptyPlaceholder = ({
  t,
  styles,
  icon = 'x',
  text = 'Whoops, something went wrong...',
  retry,
  navigation,
  goBack,
  disableIcon,
  style,
}: IEmptyPlaceholder) => (
  <View style={[styles.container, style]}>
    {goBack && (
      <View style={styles.goBack}>
        <IconButton icon="arrow-left" onPress={navigation.goBack} padding={2} size={28} />
      </View>
    )}
    {!disableIcon && <Icon family={'Feather'} name={icon} size={48} color={'black'} />}

    <Spacer horizontal={'m'} top={'m'} />
    <Typography text={text} />
    {retry && (
      <>
        <Spacer top={'m'} />
        <Button label={t('buttons:retry')} onPress={retry} variant="link" />
      </>
    )}
  </View>
)

export default withWrapper<IEmptyPlaceholderProps>(EmptyPlaceholder, useEmptyPlaceholderWrapper, {
  useStyles,
})
