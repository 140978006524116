import { ActivityIndicator, TouchableOpacity, View } from 'react-native'
import { useTranslation } from 'react-i18next'

import { useTheme } from '../../../core/theme'
import { Card } from '../card'
import { Button, Spacer, Typography } from '../common'
import { Card as CardType } from '../../../core/models'

interface Props {
  onPress?: () => void
  card?: CardType
  loading?: boolean
  showArrowRight?: boolean
}

export const PaymentsSection = ({ onPress, card, showArrowRight, loading = false }: Props) => {
  const { t } = useTranslation()
  const theme = useTheme()

  const renderContent = () => {
    if (card) {
      return (
        <TouchableOpacity onPress={onPress}>
          <Card showArrowRight={showArrowRight} card={card} />
        </TouchableOpacity>
      )
    }

    return (
      <View>
        <Typography text={t('charging:noCard')} />
        <Spacer vertical={'s'} />
        <Button label={t('card:select')} onPress={onPress} />
      </View>
    )
  }

  return (
    <>
      <Typography text={t('stations:payment')} size={'m'} bold />
      <Spacer vertical={'s'} />
      {loading ? <ActivityIndicator size="large" color={theme.colors.primary} /> : renderContent()}
    </>
  )
}
