import { useEffect, useMemo, useRef, useState } from 'react'
import {
  View,
  Pressable,
  Modal,
  FlatList,
  TouchableOpacity,
  TouchableWithoutFeedback,
} from 'react-native'

import Typography from '../Typography'
import { Icon } from '../../icon'
import Paper from '../Paper'

import { useStyles } from './styles'

interface Option {
  value: string
}

interface Props<T> {
  value?: string
  label?: string
  placeholder?: string
  options: Array<T & Option>
  onChange: (id: string) => void
  renderOption: (option: T & Option) => JSX.Element
}

const SIZE = 51

export const Selector = <T,>({
  onChange,
  renderOption,
  label,
  value,
  options,
  placeholder,
}: Props<T>) => {
  const styles = useStyles()

  const [isOpen, setIsOpen] = useState(false)
  const [position, setPosition] = useState({ top: 0, left: 0, right: 0, width: 0 })

  const listRef = useRef<FlatList>(null)
  const viewRef = useRef<View>(null)

  const valueIndex = useMemo(() => {
    return options.findIndex((x) => x.value === value)
  }, [options, value])

  useEffect(() => {
    if (isOpen && valueIndex !== -1) {
      const minIndex = Math.max(0, valueIndex - 1)
      const index = valueIndex === options.length - 1 ? valueIndex : minIndex

      const timeout = setTimeout(() => listRef.current?.scrollToIndex({ index }))

      return () => clearTimeout(timeout)
    }
  }, [isOpen, valueIndex])

  const open = () => {
    viewRef.current?.measureInWindow((left, top, width) => {
      setPosition({ top, left, right: left + width, width })
      setIsOpen(true)
    })
  }

  const close = () => {
    setIsOpen(false)
  }

  const renderItem = ({ item, index }: { item: T & Option; index: number }) => {
    const isSelected = valueIndex === index

    const onPress = () => {
      onChange(item.value)
      setIsOpen(false)
    }

    return (
      <TouchableOpacity onPress={onPress} style={[styles.item, isSelected && styles.selected]}>
        {renderOption(item)}
      </TouchableOpacity>
    )
  }

  const getItemLayout = (_: Array<Option> | null | undefined, index: number) => {
    return { index, length: SIZE, offset: SIZE * index }
  }

  const renderContent = () => {
    const selectedOption = options[valueIndex]

    if (selectedOption) {
      return renderOption(selectedOption)
    }

    if (placeholder) {
      return <Typography text={placeholder} color={'placeholder'} />
    }

    return null
  }

  return (
    <>
      <View style={styles.container}>
        {label && <Typography text={label} size={'s'} fontWeight={'700'} />}
        <Pressable onPress={open}>
          <View ref={viewRef} style={styles.input}>
            {renderContent()}
            <Icon family={'Feather'} name={'chevron-down'} size={24} style={styles.chevron} />
          </View>
        </Pressable>
      </View>
      <Modal visible={isOpen} transparent>
        <TouchableWithoutFeedback style={styles.wrapper} onPress={close}>
          <View style={styles.wrapper}>
            <Paper style={[position, styles.dropdown]}>
              <Pressable style={styles.header} onPress={close}>
                <Icon family={'Feather'} name={'chevron-up'} size={24} />
              </Pressable>
              <FlatList
                ref={listRef}
                data={options}
                renderItem={renderItem}
                getItemLayout={getItemLayout}
              />
            </Paper>
          </View>
        </TouchableWithoutFeedback>
      </Modal>
    </>
  )
}
