import { AxiosError } from 'axios'
import { ConnectorType, Station, StationDetails } from '../../models'

export interface StationsState {
  current: StationDetails | null
  list: Station[]
  favorites: Station[]
  search: string
  filters: Station.Filter | null
  connectors: ConnectorType[]
  initialFilter: Station.Filter
}

export const SpeedType: Record<string, Station.FilterSpeed | undefined> = {
  slow: { powerRateMin: 1, powerRateMax: 22 },
  fast: { powerRateMin: 23, powerRateMax: 99 },
  ultraFast: { powerRateMin: 100 },
  all: undefined,
}

export interface Payload<T, V = any> {
  payload: T & { callback?: (error: unknown | null, value?: V) => void }
}

export interface CreateReviewPayload {
  rating: number
  feedback: string
  chargeStationId: string
}

export interface AddFavoriteStation extends ICallback<AxiosError> {
  chargeStationId: string
}

export interface RemoveFavoriteStation extends AddFavoriteStation {}

export interface ICallback<T = unknown, V = any> {
  callback?: (error: T | null, value?: V) => void
}

export interface GetFavoriteStations extends ICallback<null, boolean> {}

export type SetStations = Station[]

export interface GetStation extends ICallback<AxiosError> {
  stationId: string
}

export interface ImagesId {
  imagesId: string[]
}
