import { useEffect, useState } from 'react'
import { useStatuses } from './useStatuses'
import { useWebSocket } from '../../app/providers/websocket'
import { ResponseMessageTypes } from '../api/charging'
import { Charging } from '../models'

type ButtonText = 'plugIn' | 'start' | 'starting' | 'stop' | 'finishing' | 'feedback'

export const useChargingButton = (connectorId: string) => {
  const { response } = useWebSocket()
  const { chargingStatus } = useStatuses(connectorId)

  const [text, setText] = useState<ButtonText | null>(null)

  useEffect(() => {
    switch (response?.action) {
      case ResponseMessageTypes.confirmStartCharging: {
        setText('stop')
        break
      }
      case ResponseMessageTypes.failStartCharging: {
        setText('start')
        break
      }
      case ResponseMessageTypes.confirmEndCharging: {
        setText('feedback')
        break
      }
    }
  }, [response])

  useEffect(() => {
    switch (chargingStatus?.status) {
      case Charging.Status.Charging: {
        setText('stop')
        break
      }
      case Charging.Status.Finished: {
        setText('feedback')
        break
      }
    }
  }, [chargingStatus])

  return { text, setText }
}
