import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import { IUseWrapper, IOptions } from '../../../core/helpers/withWrapper'
import { RouterNames } from '../../../core/constants'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { accountActionHooks } from '../../../core/store/account'
import { useEffect, useState } from 'react'
import { generateError } from '../../../core/helpers/utils'
import { AxiosError } from 'axios'
import { TFunction } from 'i18next'

const LoginSchema = (t: TFunction) =>
  Yup.object().shape({
    userName: Yup.string().trim().required(t('forms:common.required')),
    password: Yup.string().required(t('forms:common.required')),
  })

export const useLoginScreenWrapper: IUseWrapper = (options?: IOptions) => {
  const { useStyles } = options!
  const { t } = useTranslation()
  const styles = useStyles()
  const navigation = useNavigation()
  const login = accountActionHooks.login()
  const [loginError, setLoginError] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const openRegistrationScreen = () => {
    navigation.navigate(RouterNames.registration)
  }
  const openRecoveryScreen = () => {
    navigation.navigate(RouterNames.recovery)
  }

  const formik = useFormik({
    initialValues: {
      userName: '',
      password: '',
    },
    validationSchema: LoginSchema(t),
    onSubmit: (values) => {
      setLoginError(null)
      setLoading(true)

      login({
        userName: values.userName.trim(),
        password: values.password,
        callback: (error: AxiosError<{ message: string }>) => {
          if (error.response) {
            const errorMessage = generateError(error, {
              400: t(`login:form.validation.${error.response.data.message}`),
              500: t('forms:common.500'),
            })

            setLoginError(errorMessage)
          }

          setLoading(false)
        },
      })
    },
  })

  useEffect(() => {
    if (loginError) {
      formik.setFieldError('password', loginError)
    }
  }, [loginError])

  return {
    styles,
    t,
    openRegistrationScreen,
    openRecoveryScreen,
    handleSubmit: formik.handleSubmit,
    handleChange: (field: string) => (value: string) => formik.setFieldValue(field, value),
    handleBlur: (field: string) => () => formik.setFieldTouched(field, true),
    values: formik.values,
    isValid: formik.isValid,
    errors: formik.errors,
    touched: formik.touched,
    loading,
  }
}
