import { Platform, StyleProp, View, ViewStyle } from 'react-native'
import { SvgUri } from 'react-native-svg'

import { SYSTEM_ASSETS_URL } from '@env'

import { useStyles } from './styles'

interface Props {
  icon: string
  style?: StyleProp<ViewStyle>
}

export const ConnectorImage = ({ icon, style }: Props) => {
  const url = SYSTEM_ASSETS_URL + icon

  const styles = useStyles()

  return (
    <View style={[styles.container, style]}>
      {Platform.OS === 'web' ? <img src={url} /> : <SvgUri uri={url} />}
    </View>
  )
}
