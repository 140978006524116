import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Country, TranslationLanguageCode } from 'qem-react-native-country-picker-modal'
import PhoneInput from 'qem-react-native-phone-number-input'
import { IUseWrapper } from '../../../../core/helpers/withWrapper'
import { settingsSelectorHooks } from '../../../../core/store/settings'
import { useDarkTheme } from '../../../../core/theme'
import { ICountrySelectorProps } from './component'
import { getLanguage } from '../../../../core/helpers/getLanguage'

export const useCountrySelectorWrapper: IUseWrapper<ICountrySelectorProps> = (
  options,
  { onChange }
) => {
  const { t } = useTranslation()
  const [value, setValue] = useState('')
  const [formattedValue, setFormattedValue] = useState('')
  const [valid, setValid] = useState(true)
  const [open, setOpen] = useState(false)
  const [showMessage, setShowMessage] = useState(false)
  const phoneInput = useRef<PhoneInput>(null)
  const { useStyles } = options!
  const styles = useStyles()
  const darkTheme = useDarkTheme()
  const language = settingsSelectorHooks.getLanguage()

  const handleChange = (country: Country) => {
    setValue(country.cca2)

    if (onChange) {
      onChange(country.cca2)
    }
  }

  const handleFocus = () => {
    setValid(true)
    setShowMessage(false)
  }

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const getCountrySelectorLanguage = (): TranslationLanguageCode =>
    getLanguage(language.toUpperCase())

  return {
    darkTheme,
    formattedValue,
    handleChange,
    handleFocus,
    phoneInput,
    setFormattedValue,
    showMessage,
    styles,
    t,
    valid,
    open,
    handleClose,
    handleOpen,
    value,
    getCountrySelectorLanguage,
  }
}
