import { TFunction } from 'react-i18next'
import { ScrollView, View } from 'react-native'
import i18n from '../../../core/helpers/i18n'

import { withWrapper } from '../../../core/helpers/withWrapper'
import { useDeviceType } from '../../../core/hooks'
import { withStyles } from '../../../core/theme'
import { Paper, Spacer } from '../../components'
import { DangerZone, GeneralMenu, UserInfo } from '../../components/profile'

import { useProfileScreenWrapper } from './wrapper'
import { IStats } from '../../../core/store/transactions'
import { Account } from '../../../core/store/account'

const useStyles = withStyles((theme, insets, _, isWeb) => ({
  container: {
    flex: 1,
    backgroundColor: theme.colors.backgroundOffset,
  },
  content: {
    flexDirection: isWeb ? 'row' : 'column',
    paddingRight: theme.spacing.m,
    paddingTop: insets.top || theme.spacing.m,
    paddingBottom: isWeb ? 0 : (insets.bottom || theme.spacing.m) + 105,
    paddingLeft: (isWeb ? (i18n.language === 'ru' ? 20 : 0) + 90 : 0) + theme.spacing.m,
  },
  part: {
    flex: 1,
  },
}))

interface IProfileScreen {
  styles: ReturnType<typeof useStyles>
  t: TFunction
  image: string | null
  user: Account | null
  stats: IStats | null
  isLoading: boolean
}

const ProfileScreen = ({ stats, image, user, isLoading, styles }: IProfileScreen) => {
  const type = useDeviceType()

  const userInfo = user ? (
    <UserInfo info={user} image={image} stats={stats} isLoading={isLoading} />
  ) : null

  const getUserSection = () => {
    if (!user) return null

    switch (type) {
      case 'web': {
        return <Paper>{userInfo}</Paper>
      }
      case 'mobile': {
        return (
          <>
            <Paper>
              {userInfo}
              <Spacer vertical={'s'} />
            </Paper>
            <Spacer vertical={'s'} />
          </>
        )
      }
    }
  }

  return (
    <ScrollView
      style={styles.container}
      contentContainerStyle={styles.content}
      showsVerticalScrollIndicator={false}
    >
      {type === 'web' ? (
        <>
          <View style={styles.part}>{getUserSection()}</View>
          <Spacer horizontal={'s'} />
          <View style={styles.part}>
            <Paper>
              <GeneralMenu />
              <DangerZone />
            </Paper>
          </View>
        </>
      ) : (
        <>
          {getUserSection()}
          <Paper>
            <GeneralMenu />
            <Spacer vertical={'s'} />
          </Paper>
          <Spacer vertical={'s'} />
          <Paper>
            <DangerZone />
          </Paper>
        </>
      )}
    </ScrollView>
  )
}

export default withWrapper(ProfileScreen, useProfileScreenWrapper, {
  useStyles,
})
