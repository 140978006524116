import React, { PropsWithChildren } from 'react'
import { Platform, StyleProp, View, ViewStyle } from 'react-native'
import { withWrapper } from '../../../../core/helpers/withWrapper'
import { withStyles } from '../../../../core/theme'
import { ITheme } from '../../../../core/theme/themes'
import Box from '../Box'
import Typography from '../Typography'

import { usePaperWrapper } from './wrapper'

const useStyles = withStyles((theme) => ({
  content: {
    borderRadius: theme.spacing.m,
    backgroundColor: theme.colors.background,
    padding: theme.spacing.m,
    width: '100%',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.18,
    shadowRadius: 1.0,
    position: 'relative',
    //elevation: Platform
  },
  container: {
    width: '100%',
  },
}))

interface IPaperProps extends PropsWithChildren<{}> {
  label?: string
  row?: boolean
  containerStyle?: StyleProp<ViewStyle>
  style?: StyleProp<ViewStyle>
  disableRadius?: boolean
  disableShadow?: boolean
  transparent?: boolean
}

interface IPaper extends IPaperProps {
  theme: ITheme
  styles: ReturnType<typeof useStyles>
}

const Paper = ({
  children,
  containerStyle,
  disableRadius,
  disableShadow,
  label,
  row,
  style,
  styles,
  transparent,
}: IPaper) => {
  return (
    <View style={[styles.container, containerStyle]}>
      {label && (
        <Box px="s" justifyContent="flex-start" py="s">
          <Typography text={label} size="m" bold />
        </Box>
      )}
      <View
        style={[
          styles.content,
          row && { flexDirection: 'row' },
          style,
          disableRadius && { borderRadius: 0 },
          disableShadow && {
            shadowOpacity: 0,
          },
          transparent && {
            backgroundColor: 'transparent',
          },
        ]}
      >
        {children}
      </View>
    </View>
  )
}

export default withWrapper<IPaperProps>(Paper, usePaperWrapper, { useStyles })
