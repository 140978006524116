import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react'

import { accountActionHooks, accountSelectorHooks } from '../../core/store/account'
import { isTokenExpired } from '../../core/helpers/token'
import { Storage } from '../../core/helpers/storage'
import { setupApi } from '../../core/api/setup'

interface ContextData {
  token: string | null
}

const data: ContextData = {
  token: null,
}

const AuthContext = createContext(data)

export const AuthProvider = ({ children }: PropsWithChildren<{}>) => {
  const [authData, setAuthData] = useState(data)

  const isAuthorized = accountSelectorHooks.getIsAuthorized()

  const setIsAuthorized = accountActionHooks.setIsAuthorized()
  const getUserDetails = accountActionHooks.getUserDetails()
  const logout = accountActionHooks.logout()

  useEffect(() => {
    setupApi(logout)

    Storage.getItem<string>('token').then((token) => {
      const isAuthorized = !!token && !isTokenExpired(token)
      setIsAuthorized(isAuthorized)
    })
  }, [])

  useEffect(() => {
    if (!isAuthorized) return

    getUserDetails()
    Storage.getItem<string>('token').then((token) => {
      setAuthData({ token })
    })
  }, [isAuthorized])

  return <AuthContext.Provider value={authData}>{children}</AuthContext.Provider>
}

export const useAuth = () => useContext(AuthContext)
