import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import { TFunction } from 'i18next'
import { AxiosError } from 'axios'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import YupPassword from 'yup-password'
import PhoneInput from 'qem-react-native-phone-number-input'
import { RouterNames } from '../../../core/constants'
import { OTPTypes } from '../OTP/types'
import { generateError } from '../../../core/helpers/utils'
import { checkSpaces } from '../../../core/helpers/validation'
import { IUseWrapper, IOptions } from '../../../core/helpers/withWrapper'
import { accountActionHooks, SignupAction } from '../../../core/store/account'

YupPassword(Yup)

const validateSpaces = (t: Function) => ({
  message: t('registration:form.validation.spaces'),
  test: (value?: string) => !checkSpaces(value || ''),
})

const CustomerSchema = (t: TFunction) =>
  Yup.object().shape({
    firstName: Yup.string().required(t('forms:common.required')).test(validateSpaces(t)),
    userName: Yup.string().required(t('forms:common.required')).test(validateSpaces(t)),
    lastName: Yup.string().required(t('forms:common.required')).test(validateSpaces(t)),
    email: Yup.string()
      .email(t('registration:form.validation.email'))
      .required(t('forms:common.required'))
      .test(validateSpaces(t)),
    phoneNumber: Yup.string()
      .required(t('forms:common.required'))
      .matches(/^[0-9]{3,}$/),
  })

const PasswordSchema = (t: TFunction) =>
  Yup.object().shape({
    password: Yup.string()
      .required(t('forms:common.required'))
      .min(8, t('registration:form.validation.password.main'))
      .minLowercase(1, t('registration:form.validation.password.minLowerCase'))
      .minUppercase(1, t('registration:form.validation.password.minUpperCase'))
      .minNumbers(1, t('registration:form.validation.password.minNumber'))
      .minSymbols(1, t('registration:form.validation.password.minSymbols')),
    repeatedPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], t('forms:common.passwordMatch'))
      .required(t('forms:common.required')),
    agreement: Yup.bool().oneOf([true], ''),
  })

export const useRegistrationScreenWrapper: IUseWrapper = (options?: IOptions) => {
  const { useStyles } = options!
  const { t } = useTranslation()
  const styles = useStyles()
  const { navigate } = useNavigation()
  const [step, setStep] = useState(0)
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = useState({})
  const signup = accountActionHooks.signin()
  const validateUser = accountActionHooks.validateUser()
  const [signUpError, setSignUpError] = useState<string | null>(null)

  const phoneInputRef = useRef<PhoneInput>(null)

  const openLoginScreen = () => {
    navigate(RouterNames.login)
  }

  const customer = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      userName: '',
      email: '',
      phoneNumber: '',
    },
    validationSchema: CustomerSchema(t),
    validate: ({ phoneNumber }) => {
      if (!phoneInputRef.current?.isValidNumber(phoneNumber)) {
        return {
          phoneNumber: 'Please write correct phone number',
        }
      }
    },
    onSubmit: (values) => {
      setLoading(true)
      const phoneNumber = `${phoneInputRef.current?.getCallingCode()}${values.phoneNumber}`
      setFormData({ ...formData, ...values, phoneNumber })

      validateUser({
        username: values.userName,
        email: values.email,
        callback: (error, valid) => {
          setLoading(false)
          if (error) {
            const errorMessage = generateError(error, {
              400: t(`registration:errors.${error.response.data.message}`),
              500: t('forms:common.500'),
            })

            return setSignUpError(errorMessage)
          }

          setStep(1)
        },
      })
    },
  })

  const password = useFormik({
    initialValues: {
      password: '',
      repeatedPassword: '',
      agreement: false,
    },
    validationSchema: PasswordSchema(t),
    onSubmit: (values) => {
      setLoading(true)
      const payload = {
        ...formData,
        ...values,
        callback: (error: AxiosError) => {
          if (error) {
            const errorMessage = generateError(error, {
              400: t('registration:form.validation.400'),
              500: t('forms:common.500'),
            })

            setSignUpError(errorMessage)
          } else {
            navigate(RouterNames.otp, { type: OTPTypes.registration })
            resetForm()
          }

          setLoading(false)
        },
      }

      signup(payload as SignupAction)
    },
  })

  const resetForm = () => {
    setStep(0)
    phoneInputRef.current?.setState({ number: '' })
    setFormData({})
    customer.resetForm()
    password.resetForm()
  }

  useEffect(() => resetForm, [])

  const isValid = useMemo(() => {
    let valid = customer.isValid

    if (step === 2) {
      valid = password.isValid
    }

    return valid
  }, [customer.isValid, step, password.isValid])

  const isDirty = useMemo(() => {
    let dirty = customer.dirty

    if (step === 1) {
      dirty = password.dirty
    }

    return dirty
  }, [customer.dirty, password.dirty, step])

  const nextStep = () => {
    const nextStep = step + 1
    if (nextStep === 1) {
      customer.handleSubmit()
    }

    if (nextStep === 2) {
      password.handleSubmit()
    }
  }

  return {
    styles,
    t,
    openLoginScreen,
    currentStep: step,
    nextStep,
    customer,
    password,
    isValid,
    loading,
    isDirty,
    signUpError,
    setSignUpError,
    phoneInputRef,
  }
}
