import { FlatList, Pressable, View } from 'react-native'
import { ISupportType } from '../../../../core/api/support/types'

import { DefaultWrapperProps, withWrapper } from '../../../../core/helpers/withWrapper'
import { withStyles } from '../../../../core/theme'
import { Box, Button, Checkbox, Spacer, TextField, Typography } from '../../../components'
import ModalContainer from '../../../components/common/ModalContainer'
import { Icon } from '../../../components/icon'
import { ISuppoRequestContentProps } from './types'

import { useSupportRequestComponentWrapper } from './wrapper'

const useStyles = withStyles((theme) => ({
  contentContainer: {
    flex: 1,
    padding: theme.spacing.m,
  },
  modalContent: {
    padding: theme.spacing.m,
  },
  dropDownContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderColor: theme.colors.border,
    borderWidth: 2,
    paddingHorizontal: theme.spacing.s,
    paddingVertical: theme.spacing.s,
    borderRadius: theme.spacing.s,
  },
  itemContainer: {
    flexDirection: 'row',
    paddingVertical: theme.spacing.m,
    justifyContent: 'space-between',
  },
  dangerBorder: {
    borderColor: theme.colors.danger,
  },
}))

interface ISupportRequestContent extends ISuppoRequestContentProps, DefaultWrapperProps {
  type: 'mobile' | 'web'
  styles: ReturnType<typeof useStyles>
  supportTypes: ISupportType[]
  selectedSupportType?: ISupportType
  onSupportTypePress: () => void
  onSupportTypeSelect: (type: ISupportType) => void
  generateField: (key: 'feedback') => any
  onSubmit: () => void
  showError: boolean
  loading: boolean
  isVisible: boolean
  setVisible: (value: boolean) => void
}

const Component = ({
  type,
  styles,
  onSupportTypePress,
  supportTypes,
  onSupportTypeSelect,
  selectedSupportType,
  generateField,
  onSubmit,
  showError,
  loading,
  t,
  isVisible,
  setVisible,
}: ISupportRequestContent) => (
  <>
    <View style={{ flex: 1, justifyContent: 'space-between' }}>
      <View>
        <Box justifyContent="flex-start" alignItems="flex-start" px="m" pb="s">
          <Typography
            text={t('feedback:supportType')}
            size="m"
            color={showError ? 'danger' : 'text'}
          />
        </Box>
        <Pressable
          style={[styles.dropDownContainer, showError && styles.dangerBorder]}
          onPress={onSupportTypePress}
        >
          <Typography
            text={selectedSupportType?.name || t('feedback:selectSupportType')}
            color={selectedSupportType?.name ? 'text' : 'secondaryText'}
          />
          <Icon family={'Feather'} name="chevron-down" size={20} />
        </Pressable>
        <Spacer top="m" />
        <TextField
          showSoftInputOnFocus
          maxLength={200}
          label={t('feedback:comments')}
          value=""
          multiline
          showCounter
          boxSize={120}
          {...generateField('feedback')}
        />
      </View>
      <Button label={t('buttons:submit')} onPress={onSubmit} loading={loading} />
    </View>

    <ModalContainer isVisible={isVisible} setVisible={setVisible}>
      <View style={styles.modalContent}>
        <FlatList
          data={supportTypes}
          ListHeaderComponent={<Typography text={t('feedback:supportType')} bold size={'l'} />}
          renderItem={({ item }) => (
            <Pressable style={styles.itemContainer} onPress={onSupportTypeSelect.bind(null, item)}>
              <Typography text={item.name} />
              <Spacer flex />
              <Checkbox
                value={item.supportTypeId === selectedSupportType?.supportTypeId}
                onChange={onSupportTypeSelect.bind(null, item)}
              />
            </Pressable>
          )}
          ListFooterComponent={
            supportTypes.length ? (
              <>
                <Button label={t('buttons:select')} onPress={setVisible.bind(null, false)} />
                {type === 'mobile' && <Spacer vertical={'s'} />}
              </>
            ) : null
          }
        />
      </View>
    </ModalContainer>
  </>
)

export const SupportRequestContent = withWrapper(Component, useSupportRequestComponentWrapper, {
  useStyles,
})
