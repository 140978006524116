import { DefaultWrapperProps, IOptions, IUseWrapper } from '../../../../core/helpers/withWrapper'
import { RouterNames } from '../../../../core/constants'

export const useSupportRequestScreenWrapper: IUseWrapper<
  DefaultWrapperProps<RouterNames.supportRequest>
> = ({ useStyles }: IOptions, { route }) => {
  const styles = useStyles()
  const { params } = route

  return { styles, chargeStationId: params?.chargeStationId }
}
