import { withStyles } from '../../../../core/theme'

export const useStyles = withStyles((theme) => ({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing.s,
  },
  checkbox: {
    borderRadius: theme.spacing.m,
    color: theme.colors.primary,
    height: 24,
    width: 24,
  },
}))
