import { createSelectorHook } from '../../helpers/store'
import { RootState } from '../rootReducer'

const getTransactions = () => (state: RootState) => state.transactions.list
const getRecentTransactions = () => (state: RootState) => state.transactions.recentList
const getTransactionLoading = () => (state: RootState) => state.transactions.loading
const getTransactionFilter = () => (state: RootState) => ({
  transactionEndTime: state.transactions.endTime,
  transactionStartTime: state.transactions.startTime,
  chargerStationsId: state.transactions.chargerStationsId,
  vehiclesId: state.transactions.vehiclesId,
})
const getCurrentTransaction = () => (state: RootState) => state.transactions.current
const getTransactionError = () => (state: RootState) => state.transactions.error
const getStats = () => (state: RootState) => state.transactions.stats

export const transactionsSelectorHooks = {
  getTransactions: createSelectorHook(getTransactions),
  getTransactionLoading: createSelectorHook(getTransactionLoading),
  getTransactionFilter: createSelectorHook(getTransactionFilter),
  getCurrentTransaction: createSelectorHook(getCurrentTransaction),
  getTransactionError: createSelectorHook(getTransactionError),
  getRecentTransactions: createSelectorHook(getRecentTransactions),
  getStats: createSelectorHook(getStats),
}
