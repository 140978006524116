import { View, TouchableOpacity } from 'react-native'
import { NativeStackHeaderProps } from '@react-navigation/native-stack'
import { BottomTabHeaderProps } from '@react-navigation/bottom-tabs'
import { useTranslation } from 'react-i18next'

import { Box, Typography } from '../../components/common'
import { Icon } from '../../components/icon'
import { useStyles } from './styles'

type Props = (NativeStackHeaderProps | BottomTabHeaderProps) & { right?: JSX.Element }

export const Header = (props: Props) => {
  const { options, navigation, right } = props

  const { t } = useTranslation()

  const styles = useStyles()

  return (
    <Box style={styles.container}>
      <TouchableOpacity style={[styles.absolute, styles.left]} onPress={navigation.goBack}>
        <Icon family={'Feather'} name="arrow-left" size={24} />
      </TouchableOpacity>
      {options.title && (
        <Box flex justifyContent="center">
          <Typography text={t(options.title)} size={18} bold />
        </Box>
      )}
      {right && <View style={[styles.absolute, styles.right]}>{right}</View>}
    </Box>
  )
}
