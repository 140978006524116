import axios from 'axios'

import { GOOGLE_MAPS_GEOCODE_API_KEY, GOOGLE_MAPS_GEOCODE_URL } from '@env'

import { Place } from '../../models'

export class MapAPI {
  static geocode = async (address: string) => {
    const { data } = await axios.get<{ results: Place[] }>(GOOGLE_MAPS_GEOCODE_URL, {
      params: { address, key: GOOGLE_MAPS_GEOCODE_API_KEY },
    })

    return data.results
  }
}
