import { withStyles } from '../../../core/theme'

export const useStyles = withStyles((theme, insets) => ({
  container: {
    backgroundColor: theme.colors.backgroundOffset,
  },
  content: {
    paddingTop: theme.spacing.s,
    paddingHorizontal: theme.spacing.m,
    paddingBottom: insets.bottom || theme.spacing.m,
  },
  gap: {
    gap: theme.spacing.s,
  },
  detail: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  text: {
    lineHeight: 21,
  },
  button: {
    marginTop: theme.spacing.m,
  },
}))
