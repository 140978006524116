import { SafeAreaView, View } from 'react-native'
import { withWrapper } from '../../../../core/helpers/withWrapper'
import { withStyles } from '../../../../core/theme'
import { useLanguagesScreenWrapper } from './wrapper'
import LanguagesContent from '../content'
import React from 'react'
import { Paper } from '../../../components'

const useStyles = withStyles((theme) => ({
  container: {
    flex: 1,
    backgroundColor: theme.colors.backgroundOffset,
  },
  content: {
    padding: theme.spacing.m,
  },
}))

interface ILanguagesScreen {
  styles: ReturnType<typeof useStyles>
}

const LanguagesScreen = ({ styles }: ILanguagesScreen) => (
  <SafeAreaView style={styles.container}>
    <View style={styles.content}>
      <Paper>
        <LanguagesContent />
      </Paper>
    </View>
  </SafeAreaView>
)

export default withWrapper(LanguagesScreen, useLanguagesScreenWrapper, { useStyles })
