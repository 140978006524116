import { createSelectorHook } from '../../helpers/store'
import { RootState } from '../rootReducer'

const getLanguage = () => (state: RootState) => {
  return state.settings.language
}

export const settingsSelectorHooks = {
  getLanguage: createSelectorHook(getLanguage),
}
