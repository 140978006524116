import { withStyles } from '../../../../core/theme'

export const useStyles = withStyles((theme) => ({
  container: {
    gap: theme.spacing.m,
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingVertical: theme.spacing.l,
  },
  list: {
    gap: theme.spacing.m,
  },
}))
