import { IOptions, IUseWrapper } from '../../../core/helpers/withWrapper'
import { useNavigation } from '@react-navigation/native'
import { ICar, vehiclesSelectorHooks } from '../../../core/store/vehicles'
import { RouterNames } from '../../../core/constants'

export interface IMyVehicle {
  name: string
  year?: string
  primary?: boolean
}

export const useCarsScreenWrapper: IUseWrapper = (options?: IOptions) => {
  const { useStyles } = options!
  const { navigate } = useNavigation()
  const styles = useStyles()

  const cars = vehiclesSelectorHooks.getCars()

  const onItemClick = ({ vehicleId }: ICar) => {
    navigate(RouterNames.carDetails, { carId: vehicleId })
  }

  const onAddCar = () => {
    navigate(RouterNames.addCar)
  }

  return { styles, cars, onItemClick, onAddCar }
}
