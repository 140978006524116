import React from 'react'
import { TFunction } from 'react-i18next'
import { View } from 'react-native'
import { DefaultWrapperProps, withWrapper } from '../../../../core/helpers/withWrapper'
import { withStyles } from '../../../../core/theme'
import SliderRangeLib, { Slider as SliderLib } from 'react-native-range-slider-expo'
import { Box, Spacer, Typography } from '../../common'
import { useSliderWrapper } from './wrapper'

const useStyles = withStyles((theme) => ({
  container: {
    width: '100%',
    marginVertical: -theme.spacing.xs,
    height: 46,
  },
  input: {
    padding: theme.spacing.m,
    marginTop: -10,
  },
  text: { backgroundColor: theme.colors.white },
  track: {
    height: 4,
    borderRadius: 2,
  },
  thumb: {
    width: 30,
    height: 30,
    borderRadius: 30 / 2,
    backgroundColor: 'white',
    borderColor: theme.colors.primary,
    borderWidth: 2,
  },
}))

export type OnChange = {
  fromValue: number
  toValue: number
}

export interface ISliderProps {
  min: number
  max: number
  step: number
  label?: string
  onChange?: (value: OnChange) => void
  initialToValue?: number
  initialFromValue?: number
  handleBlur?: () => void
  icon?: string
  range?: boolean
}
interface ISlider extends ISliderProps, DefaultWrapperProps {
  styles: ReturnType<typeof useStyles>
  focused: boolean
  onBlur: () => void
  onFocus: () => void
  t: TFunction
  toValue: number
  setToValue: (value: number) => void
  fromValue: number
  setFromValue: (value: number) => void
}

const Slider = ({
  styles,
  label,
  onChange,
  min,
  max,
  step,
  theme,
  toValue,
  setToValue,
  fromValue,
  range,
  setFromValue,
  initialFromValue,
  initialToValue,
}: ISlider) => {
  return (
    <View>
      <View style={styles.container}>
        {label && <Typography text={label} size="m" />}
        {range ? (
          <SliderRangeLib
            min={min}
            max={max}
            fromValueOnChange={setFromValue}
            toValueOnChange={setToValue}
            initialFromValue={initialFromValue ?? fromValue}
            initialToValue={initialToValue ?? toValue}
            knobSize={24}
            fromKnobColor={theme.colors.primary}
            toKnobColor={theme.colors.primary}
            inRangeBarColor={theme.colors.backgroundOffset}
            rangeLabelsTextColor={theme.colors.secondaryText}
            valueLabelsBackgroundColor={theme.colors.secondaryText}
            //barHeight={4}
            styleSize="small"
            showRangeLabels={false}
            containerStyle={styles.input}
          />
        ) : (
          <SliderLib
            min={min}
            max={max}
            valueOnChange={setToValue}
            initialValue={initialFromValue ?? toValue}
            knobColor={theme.colors.primary}
            outOfRangeBarColor={theme.colors.backgroundOffset}
            inRangeBarColor={theme.colors.backgroundOffset}
            rangeLabelsTextColor={theme.colors.secondaryText}
            valueLabelsBackgroundColor={theme.colors.secondaryText}
            styleSize="small"
            showRangeLabels={false}
            containerStyle={styles.input}
          />
        )}
      </View>
      <Box>
        <Spacer left="s" />
        <Typography text={min.toFixed()} />
        <Spacer flex />
        <Typography text={max.toFixed()} />
        <Spacer right="s" />
      </Box>
    </View>
  )
}

export default withWrapper<ISliderProps>(Slider, useSliderWrapper, {
  useStyles,
})
