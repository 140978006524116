import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { IOptions, IUseWrapper } from '../../../../core/helpers/withWrapper'
import { stationsSelectorHooks } from '../../../../core/store/stations'
import { RouterNames } from '../../../../core/constants'
import { RootStackParamList } from '../../../router/types'

export const useStationFeedbackScreenWrapper: IUseWrapper = (options?: IOptions) => {
  const { useStyles } = options!
  const styles = useStyles()

  const { navigate } = useNavigation()
  const { params } = useRoute<RouteProp<RootStackParamList, RouterNames.stationFeedback>>()

  const stationId = stationsSelectorHooks.getStation()?.id

  const showReport = () => {
    stationId && navigate(RouterNames.stationReport, { chargerStationId: stationId })
  }

  return { styles, stationId, showReport, session: params.session }
}
