import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import createSagaMiddleware from 'redux-saga'
import { reduxBatch } from '@manaflair/redux-batch'
import { PersistConfig, persistStore } from 'redux-persist'
import AsyncStorage from '@react-native-async-storage/async-storage'
import { RootState, rootReducer, rootSaga } from './rootReducer'
import persistReducer from 'redux-persist/es/persistReducer'
import i18n from '../helpers/i18n'

const sagaMiddleware = createSagaMiddleware()
const middleware = [
  ...getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
    thunk: true,
  }),
  sagaMiddleware,
]

const persistConfig: PersistConfig<RootState> = {
  key: 'root',
  storage: AsyncStorage,
  whitelist: ['notification', 'settings'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
  reducer: persistedReducer,
  middleware,
  devTools: process.env.NODE_ENV !== 'production',
  enhancers: [reduxBatch],
})

export const persistor = persistStore(store, null, () => {
  const { settings } = store.getState()

  i18n.changeLanguage(settings.language)
})

sagaMiddleware.run(rootSaga)

export default store
