import { FormikProps, useFormik } from 'formik'

import * as Yup from 'yup'
import { TFunction } from 'i18next'
import { useTranslation } from 'react-i18next'
import { showMessage } from 'react-native-flash-message'
import { useState } from 'react'
import { IOptions, IUseWrapper } from '../../../../core/helpers/withWrapper'
import { supportApi } from '../../../../core/api/support'
import { Keyboard } from 'react-native'
import { IStationReportContentProps } from './types'

const FeedbackSchema = (t: TFunction) =>
  Yup.object().shape({
    feedback: Yup.string().max(200).required(t('forms:common.required')),
  })

interface StationReport {
  feedback: string
}

function generateField<T = StationReport>(formik: FormikProps<T>, key: keyof T) {
  return {
    onChange: (text: string) => {
      formik.setFieldValue(key as string, text)
    },
    handleBlur: () => {
      if (!formik.touched[key]) {
        formik.setFieldTouched(key as string, true)
      }
    },
    value: formik.values[key],
    showMessage: formik.touched[key] ? formik.errors[key] : undefined,
    fullWidth: true,
  }
}

export const useStationReportContentWrapper: IUseWrapper<IStationReportContentProps> = (
  _: IOptions,
  { chargeStationId }
) => {
  const { t } = useTranslation()
  const [loading, setLoading] = useState(false)

  const feedback = useFormik({
    initialValues: {
      feedback: '',
    },
    validationSchema: FeedbackSchema(t),
    onSubmit: async (values, { resetForm }) => {
      setLoading(true)
      try {
        await supportApi.createTicket({
          description: values.feedback,
          chargeStationID: chargeStationId,
        })
        showMessage({
          type: 'success',
          message: t('support:success.sendReport'),
        })
      } catch {
        showMessage({
          type: 'danger',
          message: t('support:error.send'),
        })
      } finally {
        resetForm()
        setLoading(false)
      }
    },
  })

  const onSubmit = () => {
    Keyboard.dismiss()
    feedback.handleSubmit()
  }

  return {
    generateField: (key: keyof StationReport) => generateField(feedback, key),
    onSubmit,
    loading,
    length: feedback.values.feedback.length,
  }
}
