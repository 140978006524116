import { withStyles } from '../../../core/theme'

export const useStyles = withStyles((theme) => ({
  container: {
    paddingVertical: theme.spacing.m,
    paddingHorizontal: theme.spacing.m,
    flexDirection: 'row',
  },
  left: {
    borderLeftWidth: 2,
    borderColor: theme.colors.secondaryText,
    paddingLeft: 4,
    flex: 1,
  },
  right: {
    flex: 3,
  },
  textContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
}))
