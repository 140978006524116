import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TouchableOpacity } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { Carousel } from '../../components/carousel'
import { Box, InformationModal, Typography } from '../../components'
import { Icon } from '../../components/icon'
import { RouterNames } from '../../../core/constants'
import { Rfid } from '../../../core/models'
import { WalletAPI } from '../../../core/api/wallet'
import { showMessage } from 'react-native-flash-message'

interface Props {
  data: Rfid[]
  loading?: boolean
}

export const Rfids = ({ data, loading }: Props) => {
  const { navigate } = useNavigation()
  const { t } = useTranslation()

  const [id, setId] = useState<string | null>(null)

  const onItemPress = ({ id }: Rfid) => {
    navigate(RouterNames.rfid, { id, type: 'edit' })
  }

  const renderItem = ({ id, number }: Rfid) => {
    return (
      <Box alignItems={'flex-start'} justifyContent={'space-between'}>
        <Typography text={number} />
        <Box gap={'s'}>
          <TouchableOpacity hitSlop={10} onPress={() => setId(id)}>
            <Icon family={'Feather'} name={'trash'} size={14} />
          </TouchableOpacity>
        </Box>
      </Box>
    )
  }

  const onDeleteRfid = async () => {
    if (!id) return

    try {
      await WalletAPI.deleteRfid(id)
      showMessage({ type: 'success', description: t('card:rfid.delete.success'), message: '' })
    } catch {
      showMessage({ type: 'danger', description: t('card:rfid.delete.error'), message: '' })
    } finally {
      setId(null)
    }
  }

  return (
    <>
      <Carousel
        data={data}
        label={'RFID'}
        loading={loading}
        renderItem={renderItem}
        onItemPress={onItemPress}
      />
      <InformationModal
        bottom
        isVisible={!!id}
        setVisible={Promise.resolve}
        text={t('card:rfid.delete.message')}
        description={t('card:rfid.delete.description')}
        withButtons={{
          left: { text: t('buttons:no'), onClick: () => setId(null) },
          right: { text: t('buttons:yes'), onClick: onDeleteRfid },
        }}
      />
    </>
  )
}
