import { Text, TouchableOpacity, View } from 'react-native'
import { BottomTabBarProps } from '@react-navigation/bottom-tabs'
import { useTranslation } from 'react-i18next'

import { useCharging } from '../../providers/charging'
import { RouterNames } from '../../../core/constants'
import { useTheme } from '../../../core/theme'
import { Icon } from '../../components/icon'
import { useStyles } from './styles'
import { Typography } from '../../components'

const TabIcons = {
  [RouterNames.home]: 'map',
  [RouterNames.account]: 'user',
  [RouterNames.favorites]: 'star',
  [RouterNames.sessions]: 'zap',
  [RouterNames.scanQr]: 'camera',
}

export const TabBar = ({ state, descriptors, navigation }: BottomTabBarProps) => {
  const { t } = useTranslation()

  const theme = useTheme()
  const styles = useStyles()

  const { sessions } = useCharging()

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        {state.routes.map((route, index) => {
          const { options } = descriptors[route.key]

          const label = options.title || route.name
          const isFocused = state.index === index
          const color = isFocused ? theme.colors.primary : theme.colors.foreground
          const backgroundColor = isFocused ? theme.colors.primary : theme.colors.backgroundOffset
          const iconName = TabIcons[route.name as keyof typeof TabIcons] || 'help-circle'

          const onPress = () => {
            const event = navigation.emit({
              type: 'tabPress',
              target: route.key,
              canPreventDefault: true,
            })

            if (!isFocused && !event.defaultPrevented) {
              navigation.navigate(route.name, { merge: true })
            }
          }

          const onLongPress = () => {
            navigation.emit({ type: 'tabLongPress', target: route.key })
          }

          return (
            <TouchableOpacity
              key={route.key}
              onPress={onPress}
              onLongPress={onLongPress}
              style={styles.item}
            >
              <View style={[styles.icon, { backgroundColor }]}>
                <Icon
                  rtl={false}
                  family={'Feather'}
                  size={24}
                  name={iconName}
                  color={isFocused ? theme.colors.white : theme.colors.foreground}
                />
                {route.name === RouterNames.sessions && !!sessions.size && (
                  <View style={styles.badge}>
                    <Typography text={sessions.size.toString()} bold color={'white'} />
                  </View>
                )}
              </View>
              <Text numberOfLines={1} style={{ color }}>
                {t(label)}
              </Text>
            </TouchableOpacity>
          )
        })}
      </View>
    </View>
  )
}
