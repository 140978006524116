import { Platform } from 'react-native'
import { Buffer } from 'buffer'

import { FaqResponse, IAccountApi, LoginPayload, SignupPayload, UpdateUserPayload } from './types'
import * as CONSTANTS from './constants'
import { api } from '../setup'

export const accountApi: IAccountApi = {
  login: async ({ userName, password }: LoginPayload) => {
    const payload = new Buffer(`${userName}:${password}`).toString('base64')

    const url = Platform.select({ native: CONSTANTS.LOGIN_MOBILE, default: CONSTANTS.LOGIN_WEB })

    const { data } = await api.post(url, null, {
      headers: {
        Authorization: `Basic ${payload}`,
      },
    })

    return data
  },
  signup: async (payload: SignupPayload) => {
    const { data } = await api.post(CONSTANTS.SIGNUP, { ...payload, status: true })

    return data
  },
  refreshToken: () => {
    return api.post(CONSTANTS.REFRESH_TOKEN)
  },
  getCurrentUser: async () => {
    const { data } = await api.get(CONSTANTS.CURRENT_USER_DETAILS)

    return data
  },
  updateUser: async (payload: UpdateUserPayload) => {
    const { data } = await api.patch(CONSTANTS.UPDATE_USER, payload)

    return data
  },
  deactivateUser: async () => {
    await api.patch(CONSTANTS.DEACTIVATE_USER)
  },
  recovery: async (email: string) => {
    await api.post(CONSTANTS.RECOVERY(email))
  },
  validateUser: async (email: string, username: string) => {
    const { data } = await api.get(CONSTANTS.USER_VALIDATE, {
      params: {
        email,
        username,
      },
    })

    return data as boolean
  },
  confirmRegistration: async (otp: string) => {
    const { data } = await api.get(CONSTANTS.REGISTRATION_CONFIRM, { params: { otp } })
    return data
  },
  resetPassword: async (payload) => {
    const { data } = await api.post(CONSTANTS.RESET_PASSWORD, payload)
    return data
  },
  getFaq: async (lang: string) => {
    const { data } = await api.get(CONSTANTS.GET_FAQ, { params: { lang } })

    return data as FaqResponse
  },
}
