import { View } from 'react-native'
import Modal from 'react-native-modal'
import { withWrapper } from '../../../../core/helpers/withWrapper'
import { withStyles } from '../../../../core/theme'
import { useModalContainerWrapper } from './wrapper'
import Spacer from '../Spacer'
import { IModalContainerProps } from './types'

const useStyles = withStyles((theme, insets) => ({
  container: {
    justifyContent: 'flex-end',
    margin: 0,
  },
  content: {
    backgroundColor: theme.colors.white,
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    paddingBottom: insets.bottom ? theme.spacing.m : 0,
  },
  handleContainer: {
    alignItems: 'center',
  },
  handle: {
    height: 10,
    backgroundColor: theme.colors.border,
    borderRadius: 20,
    width: 75,
  },
}))

interface IModalContainer extends IModalContainerProps {
  styles: ReturnType<typeof useStyles>
  close: () => void
}

const Component = ({ isVisible, close, styles, contentStyles, children }: IModalContainer) => (
  <Modal
    isVisible={isVisible}
    swipeDirection={'down'}
    onSwipeComplete={close}
    hasBackdrop
    onBackButtonPress={close}
    onBackdropPress={close}
    hideModalContentWhileAnimating
    style={styles.container}
    propagateSwipe
  >
    <View style={styles.handleContainer}>
      <Spacer top={'s'} />
      <View style={styles.handle} />
      <Spacer bottom={'s'} />
    </View>
    <View style={[styles.content, contentStyles]}>{children}</View>
  </Modal>
)

export const ModalContainer = withWrapper<IModalContainerProps>(
  Component,
  useModalContainerWrapper,
  { useStyles }
)
