import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { Payload } from '../stations'

import * as CONSTANTS from './constants'
import { GetSessions } from './types'
import { setError, setSessions } from './actions'
import { sessionsApi } from '../../api/sessions'
import { ISession } from '../../api/sessions/types'
import { RootState } from '../rootReducer'
import { PagedData } from '../../models'

function* getSessionsSaga({ payload: { callback, ...data } }: Payload<GetSessions>) {
  try {
    const { list }: RootState['sessions'] = yield select(({ sessions }: RootState) => sessions)
    const { items, pages }: PagedData<ISession> = yield call(sessionsApi.sessions, data)

    yield put(setSessions([...list, ...items]))

    callback?.call(null, (data.page || 1) < pages)
  } catch (error) {
    yield put(setError(error as Error))
    if (callback) {
      callback(error)
    }
  }
}

export function* sessionsSagas(): Generator<any> {
  yield all([yield takeLatest<any>(CONSTANTS.GET_SESSIONS, getSessionsSaga)])
}
