import { createAction } from '@reduxjs/toolkit'
import { createActionHook, createEmptyActionHook } from '../../helpers/store'
import { SetPaymentMethods } from './types'
import * as CONSTANTS from './constants'

const deleteCard = createAction(CONSTANTS.DELETE_CARD)

const getPaymentMethods = createAction(CONSTANTS.GET_PAYMENT_METHODS)
const setPaymentMethods = createAction<SetPaymentMethods>(CONSTANTS.SET_PAYMENT_METHODS)

const setIsLoading = createAction<boolean>(CONSTANTS.SET_IS_LOADING)

export const paymentActionHooks = {
  deleteCard: createActionHook(deleteCard),
  getPaymentMethods: createEmptyActionHook(getPaymentMethods),
  setIsLoading: createActionHook(setIsLoading),
}

export { deleteCard, getPaymentMethods, setPaymentMethods, setIsLoading }
