import { View, Image } from 'react-native'
import Swiper from 'react-native-web-swiper'

import { DefaultWrapperProps, withWrapper } from '../../../../core/helpers/withWrapper'
import { withStyles } from '../../../../core/theme'

import { useGuideListWrapper } from './wrapper'
import Spacer from '../Spacer'
import Typography from '../Typography'

const useStyles = withStyles((theme) => ({
  content: {
    flex: 1,
  },
}))

interface IGuideListProps {
  guides: IGuideCard[]
}

interface IGuideList extends DefaultWrapperProps, IGuideListProps {
  styles: ReturnType<typeof useStyles>
}

interface IGuideCard {
  image: string
  title: string
  description: string
}

const GuideCard = ({ image, description, title }: IGuideCard) => (
  <View
    style={{
      flex: 1,
      width: '90%',
      alignSelf: 'center',
      alignItems: 'center',
      justifyContent: 'center',
    }}
  >
    <Image source={{ uri: image }} style={{ height: 220, width: 220 }} />
    <Spacer vertical={'m'} />
    <Typography text={title} bold align="center" size={22} numberOfLines={2} />
    <Spacer vertical={'m'} />
    <Typography text={description} align="center" size={18} numberOfLines={6} />
  </View>
)

const GuideList = ({ styles, theme, guides }: IGuideList) => (
  <Swiper
    containerStyle={styles.content}
    controlsProps={{
      nextTitleStyle: {
        color: theme.colors.primary,
      },
      dotActiveStyle: { backgroundColor: theme.colors.primary },
    }}
  >
    {guides.map((guide) => (
      <GuideCard
        key={guide.title}
        image={guide.image}
        description={guide.description}
        title={guide.title}
      />
    ))}
  </Swiper>
)

export default withWrapper<IGuideListProps>(GuideList, useGuideListWrapper, {
  useStyles,
})
