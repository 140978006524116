import { Pressable, View } from 'react-native'
import Checkbox from 'expo-checkbox'

import { withWrapper } from '../../../../../core/helpers/withWrapper'
import { withStyles } from '../../../../../core/theme'
import { ITheme } from '../../../../../core/theme/themes'
import { Typography } from '../../../../components/common'
import { IStationFilterItemProps } from './types'
import { useFilterWrapper } from './wrapper'

const useStyles = withStyles((theme) => ({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: theme.spacing.xs,
  },
}))

interface IFilterState extends IStationFilterItemProps {
  theme: ITheme
  styles: ReturnType<typeof useStyles>
  title: string
  subtitle: string
  handlePress: () => void
}

const Component = ({ item, styles, handlePress, theme, title, subtitle }: IFilterState) => (
  <Pressable style={styles.container} onPress={handlePress}>
    <View>
      <Typography text={title} size={14} fontWeight="400" />
      <Typography text={subtitle} size={12} color="secondaryText" style={{ maxWidth: '60%' }} />
    </View>
    <View
      style={{
        justifyContent: 'center',
        alignContent: 'center',
      }}
    >
      <Checkbox value={item.selected} color={theme.colors.primary} onValueChange={handlePress} />
    </View>
  </Pressable>
)

export const StationFilterItem = withWrapper<IStationFilterItemProps>(Component, useFilterWrapper, {
  useStyles,
})
