export const GET_STATIONS = 'web/chargestation'
export const GET_USER_STATIONS = 'web/chargestation/user'
export const GET_STATION = (id: string) => `web/chargestation/${id}/details`

export const CREATE_REVIEW = 'web/chargestation/review'
export const ADD_FAVORITE_STATION = 'web/chargestation/addfavorite'
export const REMOVE_FAVORITE_STATION = 'web/chargestation/removefavorite'
export const GET_FAVORITE_STATIONS = 'web/chargestation/favorites'
export const GET_LAST_VISITED_STATIONS = `web/chargestation/lastvisited`
export const GET_STATIONS_IMAGE_ID = (id: string) => `web/chargestation/${id}/images`
export const GET_STATION_IMAGE = (id: string) => `web/chargestation/images/${id}`
export const GET_STATIONID_FROM_QR = (chargerId: string) =>
  `web/chargestation?chargerId=${chargerId}`

export const GET_STATION_PRICE = (id: string) => `web/chargestation/price/${id}`

export const GET_CONNECTORS = 'connector/types'
