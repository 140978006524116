import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { showMessage } from 'react-native-flash-message'
import { DefaultWrapperProps, IOptions, IUseWrapper } from '../../../core/helpers/withWrapper'
import { RouterNames } from '../../../core/constants'
import { WalletAPI } from '../../../core/api/wallet'
import { paymentSelectorHooks } from '../../../core/store/payment'

export const useCardInfoScreenWrapper: IUseWrapper<DefaultWrapperProps<RouterNames.cardInfo>> = (
  { useStyles }: IOptions,
  { route, navigation }
) => {
  const { goBack } = navigation
  const { params } = route
  const { id } = params

  const { t } = useTranslation()
  const styles = useStyles()

  const { cards } = paymentSelectorHooks.getPaymentMethods()
  const card = cards.find(({ cardId }) => cardId === id)

  const [loading, setLoading] = useState(false)
  const [isActive, setActive] = useState(!!card?.default)

  const updateCard = async () => {
    if (!isActive) {
      try {
        setLoading(true)
        await WalletAPI.setDefaultCard(id)
        setActive((oldValue) => !oldValue)
      } catch {
        showMessage({ message: t('forms:common.500'), type: 'danger' })
      } finally {
        setLoading(false)
      }
    }
  }

  const deleteCard = async () => {
    try {
      setLoading(true)
      await WalletAPI.deleteCard(id)
      goBack()
      showMessage({ message: t('card:delete.success'), type: 'success' })
    } catch {
      showMessage({ message: t('card:delete.error'), type: 'danger' })
    } finally {
      setLoading(false)
    }
  }

  return {
    styles,
    card,
    updateCard,
    isActive,
    deleteCard,
    loading,
  }
}
