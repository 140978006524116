import { useState } from 'react'
import { IOptions, IUseWrapper } from '../../../../core/helpers/withWrapper'

export const useStationFeedbackModalWrapper: IUseWrapper = (options?: IOptions) => {
  const { useStyles } = options!
  const styles = useStyles()

  const [isReportShown, setIsReportShown] = useState(false)

  return { styles, isReportShown, setIsReportShown }
}
