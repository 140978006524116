import { useState } from 'react'
import { Image, TouchableOpacity, View, ActivityIndicator } from 'react-native'
import { showMessage } from 'react-native-flash-message'
import { useTranslation } from 'react-i18next'

import { AxiosError } from 'axios'

import { PLATFORM_ASSETS_URL } from '@env'
import { stationsActionHooks } from '../../../core/store/stations'
import { generateError, getJoinString } from '../../../core/helpers/utils'
import { Box, Spacer, Typography, InformationModal } from '../common'
import { useStationPrice } from '../../../core/hooks'
import { Station } from '../../../core/models'
import { Icon } from '../icon'

import { useStyles } from './styles'

interface Props {
  disableImage?: boolean
  disableCost?: boolean
  disableFavorite?: boolean
  station: Station
  showConnectors?: boolean
}

export const CommonStationCard = ({
  disableImage,
  disableCost,
  disableFavorite,
  showConnectors,
  station,
}: Props) => {
  const { id, image, name, favorite, connectors, availableConnectors, address } = station

  const addressStr = getJoinString([address?.streetAddress, address?.locality, address?.postCode])

  const [isFavorite, setIsFavorite] = useState(favorite)
  const [isModalVisible, setModalVisible] = useState(false)

  const addFavoriteStation = stationsActionHooks.addFavoriteStation()
  const removeFavoriteStation = stationsActionHooks.removeFavoriteStation()

  const { t } = useTranslation()

  const price = useStationPrice(id, disableCost)

  const callback = (error: AxiosError | null) => {
    setModalVisible(false)

    if (error) {
      const errorMessage = generateError(error, {
        400: t(`favorites:${isFavorite ? 'remove' : 'add'}.error`),
      })
      showMessage({ message: 'Error', description: errorMessage, type: 'danger' })
    } else {
      showMessage({
        message: t(`favorites:${isFavorite ? 'remove' : 'add'}.result`),
        description: t(`favorites:${isFavorite ? 'remove' : 'add'}.message`),
        type: 'success',
      })
    }
  }

  const handleRemove = () => {
    setIsFavorite(false)
    removeFavoriteStation({ chargeStationId: id, callback })
  }

  const handleStarPress = () => {
    if (isFavorite) {
      setModalVisible(true)
    } else {
      setIsFavorite(true)
      addFavoriteStation({ chargeStationId: id, callback })
    }
  }

  const uri = image ? `${PLATFORM_ASSETS_URL}/${image}` : null

  const styles = useStyles()

  return (
    <>
      <Box flex fullWidth gap={'m'} justifyContent={'flex-start'}>
        {!disableImage && (
          <View style={styles.left}>
            {uri ? (
              <Image source={{ uri }} style={styles.image} />
            ) : (
              <View style={[styles.image, styles.placeholder]} />
            )}
          </View>
        )}

        {!disableFavorite && (
          <TouchableOpacity onPress={handleStarPress} style={styles.icon}>
            <Icon
              size={20}
              color={'#F2C94C'}
              family={'FontAwesome'}
              name={isFavorite ? 'star' : 'star-o'}
            />
          </TouchableOpacity>
        )}

        <Box
          flex
          fullWidth
          direction={'column'}
          justifyContent={'flex-start'}
          alignItems={'flex-start'}
        >
          <Typography numberOfLines={1} text={name} bold size={18} />
          <Spacer vertical={'xs'} />
          <Typography text={addressStr} size={14} />

          {showConnectors && (
            <>
              <Spacer vertical={'xs'} />
              <Typography
                text={`${availableConnectors}/${connectors} ${t('stations:connectors')}`}
                color={availableConnectors ? 'success' : 'orange'}
              />
            </>
          )}

          {!disableCost && (
            <>
              <Spacer vertical={'xs'} />
              <Box justifyContent={'flex-start'} alignItems={'center'}>
                <Typography bold text={`${t('stations:from')} ₪ `} />
                {price === null ? (
                  <ActivityIndicator />
                ) : (
                  <Typography bold text={price.toString()} />
                )}
              </Box>
            </>
          )}
        </Box>
      </Box>

      <InformationModal
        isVisible={isModalVisible}
        setVisible={setModalVisible}
        text={t('favorites:remove.question')}
        description={t('favorites:remove.description')}
        bottom
        withButtons={{
          left: { text: t('buttons:no'), onClick: () => setModalVisible(false) },
          right: { text: t('buttons:yes'), onClick: handleRemove },
        }}
      />
    </>
  )
}
