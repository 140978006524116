import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { showMessage } from 'react-native-flash-message'
import { useNavigation } from '@react-navigation/native'

import { object, string } from 'yup'
import { TFunction } from 'i18next'
import { useFormik } from 'formik'
import { AxiosError } from 'axios'

import { accountActionHooks, accountSelectorHooks } from '../../../core/store/account'
import { IUseWrapper, IOptions } from '../../../core/helpers/withWrapper'
import { generateError } from '../../../core/helpers/utils'

const CustomerSchema = (t: TFunction) =>
  object().shape({
    userName: string().required(t('forms:common.required')),
    firstName: string().required(t('forms:common.required')),
    lastName: string().required(t('forms:common.required')),
    email: string().required(t('forms:common.required')),
    phoneNumber: string().required(t('forms:common.required')),
  })

export const useRegistrationScreenWrapper: IUseWrapper = (options?: IOptions) => {
  const { useStyles } = options!

  const { goBack, canGoBack } = useNavigation()

  const { t } = useTranslation()
  const styles = useStyles()

  const user = accountSelectorHooks.getUser()

  const updateUser = accountActionHooks.updateAccountProfile()

  const [loading, setLoading] = useState(false)

  const handleCallback = (error: AxiosError) => {
    setLoading(false)

    if (error) {
      const errorMessage = generateError(
        error,
        {
          400: t('editAccountDetails:messages.error.message'),
          401: t('editAccountDetails:messages.error.message'),
          500: t('forms:common.500'),
        },
        t('editAccountDetails:messages.error.message')
      )

      showMessage({
        message: t('editAccountDetails:messages.error.title'),
        description: errorMessage,
        type: 'danger',
      })
      return
    }

    showMessage({
      message: t('editAccountDetails:messages.success.title'),
      description: t('editAccountDetails:messages.success.message'),
      type: 'success',
    })

    canGoBack() && goBack()
  }

  const customer = useFormik({
    initialValues: {
      userName: user?.userName || '',
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      email: user?.email || '',
      phoneNumber: user?.phoneNumber || '',
    },
    validationSchema: CustomerSchema(t),
    onSubmit: (values) => {
      setLoading(true)
      updateUser({
        ...values,
        callback: handleCallback,
      })
    },
  })

  return {
    styles,
    customer,
    loading,
    submit: customer.handleSubmit,
    isValid: customer.isValid,
    isDirty: customer.dirty,
  }
}
