import { RefObject } from 'react'
import { Pressable, View, TextInput, Modal } from 'react-native'
import { FlatList } from 'react-native-gesture-handler'
import { SafeAreaView } from 'react-native-safe-area-context'
import { DefaultWrapperProps, withWrapper } from '../../../../core/helpers/withWrapper'
import { withStyles } from '../../../../core/theme'
import TextField from '../../forms/TextField'
import Button from '../Button'
import Divider from '../Divider'
import Spacer from '../Spacer'
import Typography from '../Typography'
import { useSearchableInputWrapper } from './wrapper'

const useStyles = withStyles((theme) => ({
  container: {
    width: '100%',
  },
  contentContainerStyle: {
    backgroundColor: theme.colors.background,
    paddingHorizontal: 15,
    marginTop: 10,
    borderRadius: 8,
    paddingVertical: 10,
  },
}))

export interface ISearchableInputProps {
  label?: string
  placeholder?: string
  value?: string
  showMessage?: string
  handleBlur?: () => void
  onChange?: (value: string) => void
  data?: string[]
}

interface ISearchableInput extends ISearchableInputProps, DefaultWrapperProps {
  styles: ReturnType<typeof useStyles>
  focused: boolean
  setFocused: (value: boolean) => void
  text: string
  filteredData: string[]
  fillText: (text: string) => void
  select: (item: string) => void
  textRef: RefObject<TextInput>
  closeModal: () => void
}

const Component = ({
  styles,
  focused,
  filteredData,
  fillText,
  setFocused,
  select,
  label,
  placeholder,
  handleBlur,
  text,
  showMessage,
  textRef,
  closeModal,
}: ISearchableInput) => {
  return (
    <View style={styles.container} key={label}>
      <TextField
        key={'main'}
        textRef={textRef}
        label={label}
        placeholder={placeholder}
        showMessage={showMessage}
        handleFocus={(v, e) => {
          e?.preventDefault()
          setFocused(true)
          textRef.current?.blur()
        }}
        accessible={false}
        value={text}
      />
      <Modal visible={focused} key={label}>
        <SafeAreaView style={{ flex: 1, paddingHorizontal: 15 }}>
          <Spacer vertical={'xs'} />
          <TextField
            label={label}
            placeholder={placeholder}
            onChange={fillText}
            value={text}
            handleBlur={handleBlur}
          />
          <FlatList
            style={{ flex: 1 }}
            contentContainerStyle={styles.contentContainerStyle}
            keyExtractor={(item) => item}
            data={filteredData}
            renderItem={({ item }) => (
              <Pressable style={{ paddingVertical: 15 }} onPress={() => select(item)}>
                <Typography text={item} size={'m'} />
              </Pressable>
            )}
            ItemSeparatorComponent={() => <Divider />}
          />
          <Spacer top={'m'} />
          <Button label={'Close'} onPress={closeModal} />
          <Spacer bottom={'m'} />
        </SafeAreaView>
      </Modal>
    </View>
  )
}

export default withWrapper<ISearchableInputProps>(Component, useSearchableInputWrapper, {
  useStyles,
})
