import { StyleSheet } from 'react-native'

import { withStyles } from '../../../../core/theme'

const SIZE = 40

export const useStyles = withStyles((theme, insets) => ({
  container: {
    ...StyleSheet.absoluteFillObject,
    top: -(insets.top || theme.spacing.s),
    bottom: -insets.bottom - theme.spacing.xl,
  },
  cluster: {
    backgroundColor: theme.colors.primary,
    justifyContent: 'center',
    alignItems: 'center',
    width: SIZE,
    height: SIZE,
    borderRadius: SIZE,
  },
}))
