import { withStyles } from '../../../core/theme'

export const useStyles = withStyles(() => ({
  left: {
    width: 110,
    height: 126,
    borderRadius: 10,
    overflow: 'hidden',
  },
  image: {
    flex: 1,
  },
  placeholder: {
    backgroundColor: '#e4e4e4',
  },
  icon: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
}))
