import React from 'react'
import { View } from 'react-native'
import { withWrapper } from '../../../../core/helpers/withWrapper'
import { withStyles } from '../../../../core/theme'
import { ITheme } from '../../../../core/theme/themes'

import { useSpacerWrapper } from './wrapper'

interface ISpacerProps {
  bottom?: keyof ITheme['spacing']
  left?: keyof ITheme['spacing']
  right?: keyof ITheme['spacing']
  top?: keyof ITheme['spacing']
  horizontal?: keyof ITheme['spacing'] | number
  vertical?: keyof ITheme['spacing'] | number
  flex?: boolean
}
interface ISpacer extends ISpacerProps {
  theme: ITheme
}

const Spacer = ({ theme, top, left, right, bottom, horizontal, vertical, flex }: ISpacer) => {
  return (
    <View
      style={[
        {
          flex: flex ? 1 : undefined,
          marginBottom: bottom && theme.spacing[bottom],
          marginTop: top && theme.spacing[top],
          marginRight: right && theme.spacing[right],
          marginLeft: left && theme.spacing[left],
          marginHorizontal:
            horizontal && (typeof horizontal === 'string' ? theme.spacing[horizontal] : horizontal),
          marginVertical:
            vertical && (typeof vertical === 'string' ? theme.spacing[vertical] : vertical),
        },
      ]}
    />
  )
}

export default withWrapper<ISpacerProps>(Spacer, useSpacerWrapper)
