import { useState } from 'react'
import { ScrollView } from 'react-native'

import ModalContainer from '../../../components/common/ModalContainer'
import { Paper } from '../../../components'
import { StationFeedbackModal } from '../../StationFeedback'
import StationDetailsContent from '../content'
import { useStyles } from './styles'
import { useStatuses } from '../../../../core/hooks'

interface Props {
  stationId: string
  connectorId: string
  isVisible: boolean
  setVisible: (value: boolean) => void
}

export const StationDetailsModal = ({ stationId, connectorId, isVisible, setVisible }: Props) => {
  const [feedbackShown, setIsFeedbackShown] = useState(false)

  const styles = useStyles()

  const { session } = useStatuses(connectorId)

  const toggleFeedback = (value: boolean) => {
    setVisible(false)
    setIsFeedbackShown(value)
  }

  return (
    <>
      <ModalContainer
        isVisible={isVisible}
        setVisible={setVisible}
        contentStyles={styles.container}
      >
        <Paper style={styles.paper}>
          <ScrollView showsVerticalScrollIndicator={false}>
            <StationDetailsContent
              stationId={stationId}
              connectorId={connectorId}
              toggleFeedback={toggleFeedback}
            />
          </ScrollView>
        </Paper>
      </ModalContainer>
      {!!session && (
        <StationFeedbackModal
          session={session}
          isVisible={feedbackShown}
          setVisible={toggleFeedback}
        />
      )}
    </>
  )
}
