import { createAction } from '@reduxjs/toolkit'

import { createActionHook, createEmptyActionHook } from '../../helpers/store'
import * as CONSTANTS from './constants'
import { IUpdateEmailPayload, IUpdateStatusPayload } from './types'
import { PushNotificationsPayload } from '../../api/notifications/types'

const getMontlySubscription = createAction(CONSTANTS.GET_MONTHLY_SUBSCRIPTION)

const setEmailSubscription = createAction<boolean>(CONSTANTS.SET_EMAIL_SUBSCRIPTION)
const updateEmailSubscription = createAction<IUpdateEmailPayload>(
  CONSTANTS.UPDATE_EMAIL_SUBSCRIPTION
)

const setStatusSubscription = createAction<boolean>(CONSTANTS.SET_STATUS_SUBSCRIPTION)
const updateStatusSubscription = createAction<IUpdateStatusPayload>(
  CONSTANTS.UPDATE_STATUS_SUBSCRIPTION
)

const subscribe = createAction<PushNotificationsPayload>(CONSTANTS.SUBSCRIBE_PUSH_NOTIFICATIONS)
const unsubscribe = createAction(CONSTANTS.UNSUBSCRIBE_PUSH_NOTIFICATIONS)

export const notificationActionHooks = {
  getMontlySubscription: createEmptyActionHook(getMontlySubscription),
  setEmailSubscription: createActionHook<boolean>(setEmailSubscription),
  updateEmailSubscription: createActionHook<IUpdateEmailPayload>(updateEmailSubscription),
  setStatusSubscription: createActionHook<boolean>(setStatusSubscription),
  updateStatusSubscription: createActionHook<IUpdateStatusPayload>(updateStatusSubscription),
  subscribe: createActionHook(subscribe),
  unsubscribe: createEmptyActionHook(unsubscribe),
}

export {
  getMontlySubscription,
  setEmailSubscription,
  updateEmailSubscription,
  setStatusSubscription,
  updateStatusSubscription,
  subscribe,
  unsubscribe,
}
