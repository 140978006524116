import { View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useNavigation } from '@react-navigation/native'

import { timeStampToString } from '../../../core/helpers/date'
import { ITransaction } from '../../../core/store/transactions'
import { Spacer, Typography, PaperListItem } from '../common'
import { RouterNames } from '../../../core/constants'
import { useStyles } from './styles'

interface Props {
  transaction: ITransaction
  first?: boolean
  last?: boolean
}

export const Transaction = ({ transaction, ...rest }: Props) => {
  const { sessionId, stationName, sessionStatus, cost, energy, endTimeOfCharge } = transaction

  const { navigate } = useNavigation()

  const { t } = useTranslation()

  const styles = useStyles()

  const status = t(`transaction:status.${sessionStatus}`)
  const endTime = timeStampToString(endTimeOfCharge)

  const onPress = () => {
    navigate(RouterNames.transactionInfo, { transactionId: sessionId })
  }

  return (
    <PaperListItem {...rest} onPress={onPress} style={styles.container}>
      <View>
        <Typography text={stationName} bold size={14} />
        <Spacer vertical={'xs'} />
        <Typography size={14} color={'secondaryText'} text={endTime} />
        <Spacer vertical={'xs'} />
        <Typography size={14} color={'secondaryText'} text={status} />
      </View>
      <View style={styles.right}>
        <Typography bold size={14} text={`₪ ${Number(cost).toFixed(2)}`} />
        <Spacer vertical={'xs'} />
        <Typography size={14} color={'secondaryText'} text={`${energy} ${t('connector:kw')}`} />
      </View>
    </PaperListItem>
  )
}
