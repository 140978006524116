import { SafeAreaView, View } from 'react-native'
import { withWrapper } from '../../../core/helpers/withWrapper'
import { withStyles } from '../../../core/theme'
import { useSelectModelScreenWrapper } from './wrapper'
import { ClickableList, Paper, ScreenLoader } from '../../components'
import { IModel } from '../../../core/store/vehicles'
import { IListItem } from '../../components/common/ClickableList/component'

const useStyles = withStyles((theme) => ({
  wrapper: {
    flex: 1,
    backgroundColor: theme.colors.backgroundOffset,
    height: '100%',
  },
  outerContainer: {
    padding: theme.spacing.m,
    backgroundColor: theme.colors.backgroundOffset,
    height: '100%',
  },
}))

interface ISelectModelProps {}

interface ISelectModelScreen extends ISelectModelProps {
  styles: ReturnType<typeof useStyles>
  models: IModel[]
  isLoading: boolean
  handleClick: (item: IListItem) => void
}

const SelectModelScreen = ({ styles, models, isLoading, handleClick }: ISelectModelScreen) => (
  <SafeAreaView style={styles.wrapper}>
    <View style={styles.outerContainer}>
      <Paper style={{ height: '100%' }} containerStyle={{ height: '100%' }}>
        {isLoading ? (
          <ScreenLoader />
        ) : (
          <ClickableList
            data={models.map((item) => ({
              firstString: item.modelName,
              id: item.modelId,
            }))}
            onItemPress={handleClick}
          />
        )}
      </Paper>
    </View>
  </SafeAreaView>
)

export default withWrapper(SelectModelScreen, useSelectModelScreenWrapper, {
  useStyles,
})
