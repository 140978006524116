import React from 'react'
import { View } from 'react-native'
import { withWrapper } from '../../../../core/helpers/withWrapper'
import { withStyles } from '../../../../core/theme'
import { ITheme } from '../../../../core/theme/themes'

import { useDividerWrapper } from './wrapper'

interface IDividerProps {
  bottom?: keyof ITheme['spacing']
  left?: keyof ITheme['spacing']
  right?: keyof ITheme['spacing']
  top?: keyof ITheme['spacing']
  horizontal?: keyof ITheme['spacing'] | number
  vertical?: keyof ITheme['spacing'] | number
  flex?: boolean
  outside?: boolean
}
interface IDivider extends IDividerProps {
  theme: ITheme
}

const Divider = ({
  theme,
  top,
  left,
  right,
  bottom,
  horizontal,
  vertical,
  flex,
  outside,
}: IDivider) => {
  return (
    <View
      style={[
        {
          width: '100%',
          height: 1,
          minHeight: 1,

          backgroundColor: theme.colors.border,
          flex: flex ? 1 : undefined,
          marginBottom: bottom && theme.spacing[bottom],
          marginTop: top && theme.spacing[top],
          marginRight: right && theme.spacing[right],
          marginLeft: left && theme.spacing[left],
          marginHorizontal:
            horizontal && (typeof horizontal === 'string' ? theme.spacing[horizontal] : horizontal),
          marginVertical:
            vertical && (typeof vertical === 'string' ? theme.spacing[vertical] : vertical),
        },
        outside && { marginHorizontal: -20, width: '200%' },
      ]}
    />
  )
}

export default withWrapper<IDividerProps>(Divider, useDividerWrapper)
