import { SectionListData } from 'react-native'
import { IUserStation } from '../../../../core/api/stations/types'
import { ICar } from '../../../../core/store/vehicles'

export interface ITransactionFilterContentProps {
  onFilter: () => void
}

export enum FilterType {
  car = 'car',
  station = 'station',
}

export interface IFilterItem {
  id: string
  item: ICar | IUserStation
  type: FilterType
  selected: boolean
}

export interface IFilter {
  [FilterType.station]: IFilterStationItem[]
  [FilterType.car]: IFilterCarItem[]
}

export interface IFilterCarItem extends IFilterItem {
  item: ICar
  type: FilterType.car
}

export interface IFilterStationItem extends IFilterItem {
  item: IUserStation
  type: FilterType.station
}

export type FilterSection = SectionListData<IFilterItem>

export type FilterItem = IFilterCarItem | IFilterStationItem
