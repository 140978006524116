import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { IUseWrapper, IOptions } from '../../../../core/helpers/withWrapper'
import { stationsActionHooks, stationsSelectorHooks } from '../../../../core/store/stations'
import { useDeviceType, useStatuses, useChargingButton } from '../../../../core/hooks'
import { vehiclesSelectorHooks } from '../../../../core/store/vehicles'
import { useCharging } from '../../../providers/charging'
import { IStationDetailsContentProps } from './types'
import { Charging } from '../../../../core/models'

export const useStationDetailsContentWrapper: IUseWrapper<IStationDetailsContentProps> = (
  { useStyles }: IOptions,
  { stationId, connectorId, toggleFeedback }
) => {
  const styles = useStyles()
  const { t } = useTranslation()

  const station = stationsSelectorHooks.getStation()
  const cars = vehiclesSelectorHooks.getCars()

  const getStation = stationsActionHooks.getStation()

  const { sessionData, startCharging, stopCharging } = useCharging()
  const { session, isCharging, isFinished, chargingStatus } = useStatuses(connectorId)
  const { text, setText } = useChargingButton(connectorId)

  const type = useDeviceType()

  useEffect(() => {
    !station && getStation({ stationId })
  }, [stationId, station])

  const onButtonPress = () => {
    switch (text) {
      case 'start': {
        if (!sessionData) return

        const data = sessionData.get(connectorId)

        if (!data) return

        const { car, station, card } = data

        const payload: Charging.StartChargingPayload = Object.assign(
          {
            connectorid: connectorId,
            vehicleid: car?.vehicleId,
            deferPayment: station.deferPayment,
            stationid: station.id,
          },
          !station.deferPayment && { cardid: card?.cardId }
        )

        startCharging(payload)
        setText('starting')
        break
      }
      case 'stop': {
        if (!session) return

        stopCharging(session.connectorId)
        setText('finishing')
        break
      }
      case 'feedback': {
        toggleFeedback(true)
        break
      }
    }
  }

  const spinnerText = useMemo(() => {
    switch (text) {
      case 'starting':
        return t(`charging:${text}`)
      case 'stop':
        return `${t('stations:charging')}...`
      case 'finishing':
        return t(`charging:${text}`)
      case 'feedback':
        return `${t('stations:success')}!`
    }
  }, [text])

  const chargingData = useMemo(() => {
    const data = sessionData.get(connectorId)

    if (data) return data

    const car = cars.find(({ vehicleId }) => vehicleId === session?.vehicleId)
    const connector = station?.connectors?.find(({ connectorId: id }) => id === connectorId)

    return { station, car, connector }
  }, [connectorId, sessionData, session, station])

  const buttonText = text ? t(`charging:${text}`) : undefined

  return {
    styles,
    type,
    isCharging,
    onButtonPress,
    spinnerText,
    isFinished,
    buttonText,
    chargingData,
    chargingStatus,
  }
}
