import { View } from 'react-native'
import { withWrapper, DefaultWrapperProps } from '../../../core/helpers/withWrapper'
import { useDeviceType } from '../../../core/hooks'
import { accountActionHooks } from '../../../core/store/account'
import { Button, Divider, Spacer } from '../common'
import { RouterNames } from '../../../core/constants'

interface IGeneralMenu extends DefaultWrapperProps {
  logout: () => void
}

const Component = ({ navigation, theme, i18n: { t } }: IGeneralMenu) => {
  const type = useDeviceType()

  return (
    <View style={{ paddingHorizontal: theme.spacing.s, width: '100%' }}>
      <Spacer vertical="s" />
      <Button
        label={t('profile:general.menu.personal')}
        fullWidth
        startIcon={{ name: 'user', family: 'Feather' }}
        endIcon={{ name: 'chevron-right', family: 'Feather' }}
        variant="menu"
        align="left"
        iconSize={24}
        onPress={() => navigation.navigate(RouterNames.accountDetails)}
      />
      <Divider vertical="l" />
      <Button
        label={t('profile:general.menu.vehicles')}
        fullWidth
        startIcon={{ name: 'car-outline', family: 'Ionicons' }}
        endIcon={{ name: 'chevron-right', family: 'Feather' }}
        variant="menu"
        align="left"
        iconSize={24}
        onPress={() => navigation.navigate(RouterNames.cars)}
      />
      <Divider vertical="l" />
      <Button
        label={t('profile:general.menu.wallet')}
        fullWidth
        startIcon={{ name: 'credit-card', family: 'Feather' }}
        endIcon={{ name: 'chevron-right', family: 'Feather' }}
        variant="menu"
        align="left"
        iconSize={24}
        onPress={() => navigation.navigate(RouterNames.wallet)}
      />
      <Divider vertical="l" />
      <Button
        label={t('profile:general.menu.support')}
        fullWidth
        startIcon={{ name: 'headphones', family: 'Feather' }}
        endIcon={{ name: 'chevron-right', family: 'Feather' }}
        variant="menu"
        align="left"
        iconSize={24}
        onPress={() => navigation.navigate(RouterNames.support)}
      />
      <Divider vertical="l" />
      <Button
        label={t('profile:general.menu.settings')}
        fullWidth
        startIcon={{ name: 'settings', family: 'Feather' }}
        endIcon={{ name: 'chevron-right', family: 'Feather' }}
        variant="menu"
        align="left"
        iconSize={24}
        onPress={() => navigation.navigate(RouterNames.settings)}
      />

      <Divider vertical="l" />
      <Button
        label={t('profile:general.menu.privacy')}
        fullWidth
        startIcon={{ name: 'lock', family: 'Feather' }}
        endIcon={{ name: 'chevron-right', family: 'Feather' }}
        variant="menu"
        align="left"
        iconSize={24}
        onPress={() => navigation.navigate(RouterNames.privacy)}
      />
      {type === 'web' && <Divider vertical={'l'} />}
    </View>
  )
}

const useGeneralMenuWrapper = () => {
  const logout = accountActionHooks.logout()

  return { logout }
}

export const GeneralMenu = withWrapper(Component, useGeneralMenuWrapper)
