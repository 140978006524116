import { api } from '../setup'
import * as CONSTANTS from './constants'
import { IApplicationApi } from './types'

export const applicationApi: IApplicationApi = {
  review: async (body) => {
    const { data } = await api.post(CONSTANTS.REVIEW, body)

    return data
  },
}
