import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NativeSyntheticEvent, TextInputFocusEventData } from 'react-native'
import { IUseWrapper } from '../../../../core/helpers/withWrapper'
import { ITextFieldProps } from './component'

export const useTextFieldWrapper: IUseWrapper<ITextFieldProps> = (
  options,
  { handleBlur, handleFocus }
) => {
  const { useStyles } = options!
  const styles = useStyles()
  const [focused, setFocused] = useState(false)
  const [showSecure, setShowSecure] = useState(false)

  const handleShowSecure = () => {
    setShowSecure(!showSecure)
  }

  const onFocus = (e: NativeSyntheticEvent<TextInputFocusEventData>) => {
    setFocused(true)

    if (handleFocus) {
      handleFocus(true, e)
    }
  }

  const onBlur = () => {
    setFocused(false)

    if (handleBlur) {
      handleBlur()
    }

    if (handleFocus) {
      handleFocus(false)
    }
  }

  return { styles, focused, onFocus, onBlur, showSecure, handleShowSecure }
}
