import { View } from 'react-native'

import { Typography } from '../../../components'
import { VerifoneLogo } from '../VerifoneLogo'
import { useStyles } from './styles'

export const Footer = () => {
  const styles = useStyles()

  return (
    <View style={styles.container}>
      <Typography text={'Secure payments provided by'} />
      <VerifoneLogo
        logoSize={{ width: 20, height: 20 }}
        textSize={{ height: 12, width: 50 }}
        style={styles.logo}
      />
    </View>
  )
}
