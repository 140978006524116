import { withStyles } from '../../../../core/theme'

export const useStyles = withStyles((theme, insets) => ({
  container: {
    gap: theme.spacing.s,
    paddingTop: theme.spacing.m,
    paddingHorizontal: theme.spacing.m,
    paddingBottom: insets.bottom || theme.spacing.m,
  },
  connector: {
    transform: [{ scale: 0.8 }],
  },
  switcher: {
    flex: 1,
  },
  buttons: {
    marginTop: theme.spacing.s,
    gap: theme.spacing.m,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  speed: {
    paddingHorizontal: 0,
  },
  option: {
    padding: theme.spacing.s,
    paddingHorizontal: theme.spacing.m,
    borderRadius: theme.spacing.l,
    backgroundColor: theme.colors.secondaryText,
  },
  selected: {
    backgroundColor: theme.colors.primary,
  },
  list: {
    paddingHorizontal: theme.spacing.m,
  },
  gap: {
    gap: theme.spacing.s,
  },
}))
