import { FAQ, PagedData } from '../../models'
import { api } from '../setup'
import { GetFaqParams } from './types'

export class FaqAPI {
  static getAll = async (params: GetFaqParams) => {
    const { data } = await api.get<PagedData<FAQ>>('faq', { params })
    return data
  }
}
