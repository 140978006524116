import { withStyles } from '../../../core/theme'

export const useStyles = withStyles((theme) => ({
  title: {
    alignSelf: 'flex-start',
  },
  content: {
    paddingVertical: theme.spacing.s,
    gap: theme.spacing.m,
  },
  list: {
    width: '100%',
  },
  item: {
    minWidth: 160,
    minHeight: 110,
  },
}))
