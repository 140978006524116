import { useEffect, useRef, useState } from 'react'
import { FlatList } from 'react-native'
import { useTranslation } from 'react-i18next'
import { IUseWrapper, IOptions } from '../../../core/helpers/withWrapper'
import { stationsSelectorHooks } from '../../../core/store/stations'
import { useLocation } from '../../providers/location'
import { Station } from '../../../core/models'

export const useHomeScreenWrapper: IUseWrapper = (options?: IOptions) => {
  const { useStyles } = options!
  const { t } = useTranslation()
  const styles = useStyles()
  const [focus, setFocus] = useState(false)
  const [selectedStations, setSelectedStations] = useState<Station[]>([])

  const [currentIndex, setCurrentIndex] = useState(0)
  const listRef = useRef<FlatList>(null)

  const stations = stationsSelectorHooks.getStations()
  const search = stationsSelectorHooks.getSearch()

  const { request } = useLocation()

  const goBack = () => {
    const index = currentIndex > 0 ? currentIndex - 1 : Math.ceil(selectedStations.length / 2) - 1
    setCurrentIndex(index)
    listRef.current?.scrollToIndex({ index })
  }

  const goNext = () => {
    const index = currentIndex < Math.ceil(selectedStations.length / 2) - 1 ? currentIndex + 1 : 0
    setCurrentIndex(index)
    listRef.current?.scrollToIndex({ index })
  }

  useEffect(() => {
    request()
  }, [])

  const onClusterPress = (stationIds: string[]) => {
    const selectedStations = stationIds.reduce<Station[]>((acc, item) => {
      const station = stations.find(({ id }) => id === item)
      station && acc.push(station)
      return acc
    }, [])

    setSelectedStations(selectedStations)
  }

  const onMapPress = () => {
    setSelectedStations([])
  }

  return {
    styles,
    t,
    focus,
    search,
    handleFocus: () => setFocus(true),
    setFocus,
    stations,
    selectedStations,
    onClusterPress,
    onMapPress,
    listRef,
    goBack,
    goNext,
  }
}
