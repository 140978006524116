import { ActivityIndicator, TouchableOpacity } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import { Icon } from '../icon'
import { Box, Spacer, Typography } from '../../components'
import { RouterNames } from '../../../core/constants'
import { getJoinString } from '../../../core/helpers/utils'
import { Station } from '../../../core/models'
import { useStationPrice } from '../../../core/hooks'

interface Props {
  search: string
  station: Station
}

export const SearchStationCard = ({ search, station }: Props) => {
  const { id, name, address, availableConnectors, connectors } = station

  const { t } = useTranslation()
  const { navigate } = useNavigation()

  const price = useStationPrice(id)

  const addressStr = getJoinString([address.streetAddress, address.country, address.postCode])

  const onPress = () => {
    navigate(RouterNames.station, { stationId: id })
  }

  return (
    <TouchableOpacity onPress={onPress}>
      <Box py="xs" alignItems="flex-start" justifyContent="flex-start" px={'xs'}>
        <Icon family={'Feather'} name={search ? 'map-pin' : 'clock'} size={20} />
        <Spacer horizontal={'s'} />
        <Box direction="column" flex justifyContent="flex-start" alignItems="flex-start">
          <Box flex justifyContent="space-between" fullWidth>
            <Typography text={name} bold size={18} />
            <Box justifyContent={'flex-start'} alignItems={'center'}>
              <Typography size={14} text={'from ₪ '} />
              {price ? <Typography size={14} text={price.toString()} /> : <ActivityIndicator />}
            </Box>
          </Box>
          <Spacer vertical={'xs'} />
          <Typography text={addressStr} size={14} />
          <Spacer vertical={'xs'} />
          <Typography
            text={`${availableConnectors}/${connectors} ${t('stations:connectors')}`}
            color={availableConnectors ? 'success' : 'orange'}
          />
        </Box>
      </Box>
    </TouchableOpacity>
  )
}
