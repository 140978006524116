import { useState } from 'react'
import { ActivityIndicator, Linking, View, StyleSheet, Platform } from 'react-native'
import { useTranslation } from 'react-i18next'
import { useIsFocused } from '@react-navigation/native'
import { showMessage } from 'react-native-flash-message'
import { Camera, BarCodeScanningResult } from 'expo-camera'
import { AxiosError } from 'axios'

import { Box, Button, Spacer, Typography } from '../../components'
import { stationsApi } from '../../../core/api/stations'
import { RouterNames } from '../../../core/constants'

import { useStyles } from './styles'
import { theme } from '../../../core/theme/themes'
import { BarCodeScanner } from 'expo-barcode-scanner'

export const ScanQrScreen = ({ navigation }: ScreenProps<RouterNames.scanQr>) => {
  const { navigate } = navigation

  const styles = useStyles()

  const { t } = useTranslation()

  const isFocused = useIsFocused()

  const [isLoading, setIsLoading] = useState(false)

  const onCodeScanned = async ({ data }: BarCodeScanningResult) => {
    if (isLoading) return

    setIsLoading(true)

    try {
      const stations = await stationsApi.getStationIdFromQR(data)

      if (stations.length) {
        navigate(RouterNames.station, { stationId: stations[0].id })
      } else {
        showMessage({ message: t('scan:incorrectQR'), type: 'warning', position: 'center' })
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        showMessage({ message: error.response?.data.message, type: 'warning', position: 'center' })
      }
    } finally {
      setIsLoading(false)
    }
  }

  const [permission, requestPermission] = Camera.useCameraPermissions()

  const onButtonPress = async () => {
    const granted = await requestPermission()
    !granted && Linking.openSettings()
  }

  if (!permission?.granted && Platform.OS !== 'web') {
    return (
      <Box flex px={'m'} direction={'column'}>
        <Typography align={'center'} text={t('scan:permission.text')} />
        <Spacer vertical={'s'} />
        <Button onPress={onButtonPress} fullWidth label={t('scan:permission.label')} />
      </Box>
    )
  }

  return (
    <Box flex alignItems={'stretch'} direction={'column'}>
      {isFocused && (
        <Camera
          ratio={'16:9'}
          onBarCodeScanned={onCodeScanned}
          barCodeScannerSettings={{
            interval: 5e3,
            barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr],
          }}
          style={StyleSheet.absoluteFill}
        />
      )}
      {isLoading && (
        <View style={styles.container}>
          <View style={styles.overlay} />
          <ActivityIndicator size={'large'} color={theme.colors.primary} style={styles.loader} />
        </View>
      )}
    </Box>
  )
}
