import { ServerError } from '../../api/setup'
import { ICallback } from '../stations'
import { Address } from '../account'

export const enum SessionStatus {
  Active = 'active',
  Finished = 'finished',
  Cancelled = 'cancelled',
  Upcoming = 'upcoming',
}

export interface PaymentResult {
  amount: number
  cardId: string
  message: string
}

export interface ITransaction {
  sessionId: string
  energy: number
  cost: number
  kwhPrice?: number
  paymentResults: Array<PaymentResult>
  paymentMethod: 'APP' | 'RFID'
  startTimeOfCharge: number
  endTimeOfCharge: number
  stationName: string
  sessionStatus: SessionStatus
  stationAddress: Address
  vehicleId?: string
  paymentCard?: string
  chargingTime?: number
}

export interface IStats {
  energy: number
  money: number
  totalTime: number
}

export interface TransactionsState {
  loading: boolean
  error: Error | null
  list: ITransaction[]
  recentList: ITransaction[]
  current: ITransaction | null
  endTime: number | null
  startTime: number | null
  chargerStationsId: string[]
  vehiclesId: string[]
  stats: IStats | null
}

export type OrderType = 'asc' | 'desc'

export interface GetTransactions {
  accountId?: string
  chargeStationId?: string[]
  vehicleId?: string[]
  userId?: string
  chargeSiteID?: string
  startTimeOfCharge?: number
  endTimeOfCharge?: number
  page?: number
  size?: number
  orderBy?: OrderType
  sortBy?: keyof ITransaction
}

export interface GetRecentTransactions extends ICallback<ServerError, ITransaction[]> {}

export type SetTransactions = ITransaction[]
export type SetFilter = {
  transactionEndTime?: number
  transactionStartTime?: number
  chargerStationsId?: string[]
  vehiclesId?: string[]
}

export type SetStats = IStats

export type ResetTransactionFilter = {}
export type SetError = Error | null

export interface GetTransaction {
  transactionId: string
}
