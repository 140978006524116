import { useEffect, useMemo, useState } from 'react'
import { View } from 'react-native'
import { TFunction, useTranslation } from 'react-i18next'
import { showMessage } from 'react-native-flash-message'

import { useFormik } from 'formik'
import { object, string } from 'yup'

import { Button, KeyboardAvoid, Paper, Selector, TextField } from '../../components'
import { KeyboardDismissView } from '../../components/KeyboardDismissView'
import { paymentActionHooks, paymentSelectorHooks } from '../../../core/store/payment'
import { RouterNames } from '../../../core/constants'
import { WalletAPI } from '../../../core/api/wallet'
import { Card } from '../../components/card'

import { useStyles } from './styles'

const AddRfifSchema = (t: TFunction) => {
  return object().shape({
    number: string().required(t('forms:common.required')),
  })
}

export const RfidScreen = ({ navigation, route }: ScreenProps<RouterNames.rfid>) => {
  const { goBack, setOptions } = navigation
  const { params } = route
  const { id, type } = params

  const { t } = useTranslation()

  const styles = useStyles()

  const { cards, rfids } = paymentSelectorHooks.getPaymentMethods()
  const getPaymentMethods = paymentActionHooks.getPaymentMethods()

  const rfid = rfids.find((rfid) => rfid.id === id)

  const [isLoading, setIsLoading] = useState(false)
  const [selectedCardId, setSelectedCardId] = useState<string>()

  useEffect(() => {
    setOptions({ title: t(`card:rfid.${type}`) })
  }, [type])

  useEffect(() => {
    setSelectedCardId(rfid?.cardId)
  }, [rfid])

  const options = useMemo(() => {
    return cards.map((card) => ({ value: card?.cardId, ...card }))
  }, [cards])

  const { touched, values, errors, setFieldTouched, setFieldValue, handleSubmit } = useFormik({
    initialValues: {
      number: rfid?.number || '',
    },
    validationSchema: AddRfifSchema(t),
    onSubmit: async ({ number }) => {
      try {
        setIsLoading(true)
        if (type === 'edit' && id) {
          await WalletAPI.editRfid({ id, number, cardId: selectedCardId })
        } else {
          await WalletAPI.createRfid({ number, cardId: selectedCardId })
        }
        showMessage({ type: 'success', message: t(`card:rfid.form.${type}.success`) })
        getPaymentMethods()
        goBack()
      } catch {
        showMessage({ type: 'danger', message: t(`card:rfid.form.${type}.error`) })
      } finally {
        setIsLoading(false)
      }
    },
  })

  const onChange = (field: keyof typeof values) => {
    return (value: string) => setFieldValue(field, value)
  }

  const handleBlur = (field: keyof typeof values) => {
    return () => setFieldTouched(field, true)
  }

  const renderOption = (option: (typeof options)[number]) => {
    return <Card card={option} />
  }

  return (
    <>
      <KeyboardDismissView>
        <View style={styles.container}>
          <KeyboardAvoid style={styles.content}>
            <Paper style={styles.form}>
              <TextField
                maxLength={20}
                keyboardType={'numeric'}
                value={values.number}
                onChange={onChange('number')}
                label={t('card:rfid.form.number')}
                handleBlur={handleBlur('number')}
                showMessage={touched.number ? errors.number : undefined}
              />
              <Selector
                options={options}
                placeholder={t('card:rfid.form.card')}
                value={selectedCardId}
                onChange={setSelectedCardId}
                renderOption={renderOption}
              />
            </Paper>
            <Button
              loading={isLoading}
              label={t('buttons:save')}
              onPress={handleSubmit.bind(null, undefined)}
            />
          </KeyboardAvoid>
        </View>
      </KeyboardDismissView>
    </>
  )
}
