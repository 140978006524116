import React from 'react'
import { SafeAreaView, View } from 'react-native'
import { withWrapper } from '../../../core/helpers/withWrapper'
import { withStyles } from '../../../core/theme'
import { useAddCarScreenWrapper } from './wrapper'
import { ClickableList, Paper } from '../../components'
import { IManufacturer } from '../../../core/store/vehicles'
import { IListItem } from '../../components/common/ClickableList/component'
import { TFunction } from 'react-i18next'
import { SYSTEM_ASSETS_URL } from '@env'

const useStyles = withStyles((theme) => ({
  wrapper: {
    flex: 1,
    backgroundColor: theme.colors.backgroundOffset,
    height: '100%',
  },
  outerContainer: {
    padding: theme.spacing.m,
    flex: 1,
    backgroundColor: theme.colors.backgroundOffset,
    height: '100%',
  },
  innerWrapper: {
    height: '100%',
    width: '100%',
    justifyContent: 'space-between',
  },
}))

interface IAddCarScreenProps {}

interface IAddCarScreen extends IAddCarScreenProps {
  styles: ReturnType<typeof useStyles>
  manufacturers: IManufacturer[]
  onListItemClick: (item: IListItem) => void
  t: TFunction<'translation', undefined>
}

const AddCarScreen = ({ styles, manufacturers, onListItemClick, t }: IAddCarScreen) => (
  <SafeAreaView style={styles.wrapper}>
    <View style={styles.outerContainer}>
      <Paper containerStyle={{ height: '100%' }} style={{ height: '100%' }}>
        <View style={styles.innerWrapper}>
          <ClickableList
            data={[
              ...manufacturers.map((item) => ({
                firstString: item.manufacturerName,
                img: `${SYSTEM_ASSETS_URL}/${item.urlManufacturerLogo}`,
                id: item.manufacturerId,
              })),
              { firstString: t('cars:addCar.other'), id: 'isOther' },
            ]}
            onItemPress={onListItemClick}
          />
        </View>
      </Paper>
    </View>
  </SafeAreaView>
)

export default withWrapper(AddCarScreen, useAddCarScreenWrapper, {
  useStyles,
})
