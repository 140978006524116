import { withStyles } from '../../../../core/theme'

export const useStyles = withStyles((theme) => ({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  logo: {
    height: 20,
    gap: theme.spacing.xs,
    marginLeft: theme.spacing.s,
  },
}))
