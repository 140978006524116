import { useDispatch, useSelector } from 'react-redux'
import isEqual from 'lodash.isequal'
import { ActionCreatorWithPayload, ActionCreatorWithoutPayload } from '@reduxjs/toolkit'
import { RootState } from '../store/rootReducer'

export function createActionHook<T>(action: ActionCreatorWithPayload<T, string>) {
  return function ActionHook() {
    const dispatch = useDispatch()

    return function (payload: T) {
      return dispatch(action(payload))
    }
  }
}

export function createEmptyActionHook(action: ActionCreatorWithoutPayload) {
  return function ActionHook() {
    const dispatch = useDispatch()

    return function () {
      return dispatch(action())
    }
  }
}

export function createSelectorHook<T, P = {}>(mapper: (p?: P) => (state: RootState) => T) {
  function SelectorHook(props?: P): T {
    const result = useSelector(mapper(props), isEqual)

    return result as T
  }

  return SelectorHook
}
