import { useEffect, useRef, useState } from 'react'
import { Animated } from 'react-native'
import { IOptions, IUseWrapper } from '../../../../core/helpers/withWrapper'
import { useTheme } from '../../../../core/theme'
import { ICustomSwitchProps } from './component'

const animationSpeed = 100

export const useCustomSwitchWrapper: IUseWrapper<ICustomSwitchProps> = (
  options: IOptions,
  customProps
) => {
  const { useStyles } = options
  const { onSwitch, value = false, disabled } = customProps
  const theme = useTheme()
  const styles = useStyles()
  const [toggle, setToggle] = useState(value)

  const colorAnim = useRef(new Animated.Value(0)).current
  const colorAnimInterpolation = useRef(
    colorAnim.interpolate({
      inputRange: [0, 1],
      outputRange: [theme.colors.border, theme.colors.primary],
    })
  ).current

  useEffect(() => {
    changeColor(value ? 1 : 0)
    setToggle(value)
  }, [value])

  const changeToggle = () => {
    !disabled && setToggle(!toggle)
    onSwitch && onSwitch(!toggle)
    changeColor(!toggle ? 1 : 0)
  }

  const changeColor = (value: number) => {
    Animated.timing(colorAnim, {
      toValue: value,
      duration: animationSpeed,
      useNativeDriver: false,
    }).start()
  }

  return {
    styles,
    theme,
    colorAnimInterpolation,
    changeToggle,
    toggle,
  }
}
