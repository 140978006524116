import { useState } from 'react'
import { View } from 'react-native'
import { useTranslation } from 'react-i18next'

import ModalContainer from '../../../components/common/ModalContainer'
import { Button, Spacer, Typography } from '../../../components'
import { StationsSortType } from '../../../../core/api/stations/types'
import { OrderType } from '../../../../core/store/transactions'
import { useStyles } from './styles'

interface SortData {
  sortBy: StationsSortType
  orderBy: OrderType
}

interface Props {
  isVisible: boolean
  sortBy: StationsSortType
  orderBy: OrderType
  setVisible: (value: boolean) => void
  onSortPress: (value: StationsSortType) => void
}

export const SortModal = ({ isVisible, sortBy, orderBy, setVisible, onSortPress }: Props) => {
  const styles = useStyles()

  const { t } = useTranslation()

  const getSortIcon = (type: StationsSortType) => {
    if (type !== sortBy) return
    switch (orderBy) {
      case 'asc':
        return 'arrow-up'
      case 'desc':
        return 'arrow-down'
    }
  }

  const handleSort = () => {
    setVisible(false)
  }

  return (
    <ModalContainer isVisible={isVisible} setVisible={setVisible}>
      <View style={styles.container}>
        <Typography text={t('sort:title')} size={20} bold />
        <Spacer vertical={'s'} />
        <View>
          <Button
            label={t('sort:price')}
            textSize={14}
            align={'left'}
            variant={'text'}
            textStyle={styles.buttonText}
            onPress={() => onSortPress('price')}
            endIcon={{ name: getSortIcon('price')!, family: 'Feather' }}
          />
          <Button
            label={t('sort:distance')}
            textSize={14}
            align={'left'}
            variant={'text'}
            textStyle={styles.buttonText}
            onPress={() => onSortPress('distance')}
            endIcon={{ name: getSortIcon('distance')!, family: 'Feather' }}
          />
        </View>
        <Spacer vertical={'s'} />
        <Button label={t('buttons:show')} onPress={handleSort} />
      </View>
    </ModalContainer>
  )
}
