import { ScaledSize } from 'react-native'
import { Region } from 'react-native-maps'

import GeoViewport from '@mapbox/geo-viewport'

import { BoundingBox, StationPoint } from './types'
import { Station } from '../../../../../core/models'

export const mapStationToGeoJson = (station: Station): StationPoint | null => {
  const { id, address } = station

  if (!address.latitude || !address.longitude) return null

  return {
    type: 'Feature',
    geometry: {
      type: 'Point',
      coordinates: [address.longitude, address.latitude],
    },
    properties: {
      id,
      cluster: false,
      type: 'point',
      address: { latitude: address.latitude, longitude: address.longitude },
    },
  }
}

export const calculateBBox = (region: Region): BoundingBox => {
  let longitudeDelta

  if (region.longitudeDelta < 0) longitudeDelta = region.longitudeDelta + 360
  else longitudeDelta = region.longitudeDelta

  return [
    region.longitude - longitudeDelta,
    region.latitude - region.latitudeDelta,
    region.longitude + longitudeDelta,
    region.latitude + region.latitudeDelta,
  ]
}

export const getMapZoom = (bBox: BoundingBox, { width, height }: ScaledSize) => {
  const { zoom } = GeoViewport.viewport(bBox, [width, height])

  return zoom
}
