import { createAction } from '@reduxjs/toolkit'
import { createActionHook } from '../../helpers/store'
import * as CONSTANTS from './constants'
import { GetSessions, SetSessions, SetError } from './types'

const getSessions = createAction<GetSessions>(CONSTANTS.GET_SESSIONS)
const setSessions = createAction<SetSessions>(CONSTANTS.SET_SESSIONS)
const setError = createAction<SetError>(CONSTANTS.SET_ERROR)

export const sessionsActionHooks = {
  getSessions: createActionHook(getSessions),
  setSessions: createActionHook(setSessions),
  setError: createActionHook(setError),
}

export { getSessions, setSessions, setError }
