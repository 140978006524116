import { DefaultWrapperProps, withWrapper } from '../../../../core/helpers/withWrapper'
import { Button, Paper, Spacer, TextField, Typography } from '../../../components'
import { IStationReportContentProps } from './types'
import { useStationReportContentWrapper } from './wrapper'

interface IStationReportContent extends DefaultWrapperProps {
  generateField: (key: 'feedback') => any
  onSubmit: () => void
  loading: boolean
  length: number
}

const StationReportContent = ({
  t,
  generateField,
  onSubmit,
  length,
  loading,
}: IStationReportContent) => (
  <Paper style={{ flex: 1 }} containerStyle={{ flex: 1 }}>
    <TextField
      showSoftInputOnFocus
      maxLength={200}
      placeholder={t('forms:placeholders.issue')}
      multiline
      boxSize={150}
      {...generateField('feedback')}
    />
    <Spacer vertical={'xs'} />
    <Typography text={`${length}/200`} fontWeight={'100'} size={'m'} align={'right'} />
    <Spacer flex vertical={'s'} />
    <Button label={t('buttons:submit')} onPress={onSubmit} loading={loading} />
  </Paper>
)

export default withWrapper<IStationReportContentProps>(
  StationReportContent,
  useStationReportContentWrapper
)
