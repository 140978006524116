import { createAction } from '@reduxjs/toolkit'
import { createActionHook, createEmptyActionHook } from '../../helpers/store'
import * as CONSTANTS from './constants'
import {
  GetFavoriteStations,
  CreateReviewPayload,
  RemoveFavoriteStation,
  AddFavoriteStation,
  SetStations,
  GetStation,
} from './types'
import { ConnectorType, Station, StationDetails } from '../../models'

const getStations = createAction<Partial<Station.Filter>>(CONSTANTS.GET_STATIONS)
const setStations = createAction<SetStations>(CONSTANTS.SET_STATIONS)

const getFavoriteStations = createAction<GetFavoriteStations>(CONSTANTS.GET_FAVORITE_STATIONS)
const setFavoriteStations = createAction<SetStations>(CONSTANTS.SET_FAVORITE_STATIONS)
const removeFavoriteStation = createAction<RemoveFavoriteStation>(CONSTANTS.REMOVE_FAVORITE)
const addFavoriteStation = createAction<AddFavoriteStation>(CONSTANTS.ADD_FAVORITE)

const createReview = createAction<CreateReviewPayload>(CONSTANTS.CREATE_REVIEW)

const getStation = createAction<GetStation>(CONSTANTS.GET_STATION)
const setStation = createAction<StationDetails | null>(CONSTANTS.SET_STATION)

const setFilters = createAction<Station.Filter>(CONSTANTS.SET_FILTERS)
const initFilters = createAction(CONSTANTS.INIT_FILTERS)

const setSearch = createAction<string>(CONSTANTS.SET_SEARCH)

const getConnectors = createAction(CONSTANTS.GET_CONNECTORS)
const setConnectors = createAction<ConnectorType[]>(CONSTANTS.SET_CONNECTORS)

export const stationsActionHooks = {
  addFavoriteStation: createActionHook(addFavoriteStation),
  createReview: createActionHook(createReview),
  getFavoriteStations: createActionHook(getFavoriteStations),
  getStation: createActionHook(getStation),
  getStations: createActionHook(getStations),
  removeFavoriteStation: createActionHook(removeFavoriteStation),
  setFavoriteStations: createActionHook(setFavoriteStations),
  setFilters: createActionHook(setFilters),
  setSearch: createActionHook(setSearch),
  initFilters: createEmptyActionHook(initFilters),
  setStation: createActionHook(setStation),
  setStations: createActionHook(setStations),
  getConnectors: createEmptyActionHook(getConnectors),
}

export {
  addFavoriteStation,
  createReview,
  getFavoriteStations,
  getStation,
  getStations,
  removeFavoriteStation,
  setFavoriteStations,
  setFilters,
  initFilters,
  setSearch,
  setStation,
  setStations,
  getConnectors,
  setConnectors,
}
