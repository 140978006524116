import { ScrollView, View } from 'react-native'

import { DefaultWrapperProps, withWrapper } from '../../../core/helpers/withWrapper'
import { withStyles } from '../../../core/theme'
import { Avatar, Box, Button, Divider, Paper, Spacer, Typography } from '../../components'
import { RouterNames } from '../../../core/constants'

import { useAccountScreenWrapper } from './wrapper'
import { Account } from '../../../core/store/account'

const useStyles = withStyles((theme) => ({
  container: {
    flex: 1,
    alignItems: 'flex-start',
    backgroundColor: theme.colors.backgroundOffset,
    paddingVertical: theme.spacing.m,
    paddingHorizontal: theme.spacing.m,
  },
  scroll: {
    flex: 1,
    backgroundColor: theme.colors.backgroundOffset,
  },
}))

interface IAccountScreen extends DefaultWrapperProps {
  account: Account | null
  image: string | null
  styles: ReturnType<typeof useStyles>
  onImageChange: (url: string) => void
  setImageLoading: (value: boolean) => void
  getCountryName: (country: string) => string
}

interface IInfoBlock {
  label: string
  sublabel: string
}

const InfoBlock = ({ label, sublabel }: IInfoBlock) => (
  <Box direction="row" justifyContent="space-between">
    <Box direction="column" alignItems="flex-start" flex>
      <Typography text={label} bold size={'m'} />
      <Spacer vertical={1} />
      <Typography text={sublabel ? sublabel : '-'} size={15} />
    </Box>
  </Box>
)

const AccountScreen = ({
  t,
  styles,
  navigation,
  image,
  account,
  onImageChange,
}: IAccountScreen) => (
  <ScrollView style={styles.scroll}>
    <View style={styles.container}>
      <Paper>
        <Box direction={'column'}>
          <Spacer vertical={'s'} />
          <Avatar
            placeholder={account?.firstName?.charAt(0) || ''}
            avatar={image}
            size={160}
            editable
            onImageChange={onImageChange}
          />
          <Spacer vertical={'s'} />
          <Typography text={account?.userName!} bold size={24} />
        </Box>
        <Divider vertical="l" />
        <InfoBlock label={t('forms:fields.firstName')} sublabel={account?.firstName!} />
        <Spacer vertical={'s'} />
        <InfoBlock label={t('forms:fields.lastName')} sublabel={account?.lastName!} />
        <Spacer vertical={'s'} />
        <InfoBlock label={t('forms:fields.phone')} sublabel={account?.phoneNumber!} />
        <Spacer vertical={'s'} />
        <InfoBlock label={t('forms:fields.email')} sublabel={account?.email!} />
        <Divider vertical="m" />
        <Button
          label={t('buttons:edit')}
          bold
          onPress={navigation.navigate.bind(this, {
            name: RouterNames.editAccountDetails,
            params: undefined,
          })}
        />
      </Paper>
    </View>
  </ScrollView>
)

export default withWrapper(AccountScreen, useAccountScreenWrapper, {
  useStyles,
})
