import React from 'react'
import { TouchableOpacity, View } from 'react-native'
import { withWrapper } from '../../../../core/helpers/withWrapper'
import CountryPicker, {
  Country,
  DARK_THEME,
  TranslationLanguageCode,
} from 'qem-react-native-country-picker-modal'
import { getCountry } from 'react-native-localize'

import { useCountrySelectorWrapper } from './wrapper'
import { SUPPORTED_COUNTRIES } from '../../../../core/config'
import { withStyles } from '../../../../core/theme'
import { Box, Spacer, Typography } from '../../common'
import { TFunction } from 'react-i18next'
import { Icon } from '../../icon'

const useStyles = withStyles((theme) => ({
  container: {
    backgroundColor: theme.colors.background,
    borderColor: theme.colors.border,
    borderWidth: 2,
    overflow: 'hidden',
    borderRadius: theme.spacing.s,
    width: '100%',
    height: 60,
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: theme.spacing.m,
  },
  active: {
    borderColor: theme.colors.primary,
  },
  error: {
    borderColor: theme.colors.danger,
  },
  flag: {
    backgroundColor: theme.colors.background,
    marginRight: -15,
  },
  text: {
    backgroundColor: theme.colors.background,
  },
  textInput: {
    color: theme.colors.foreground,
    height: 60,
    flex: 1,
    justifyContent: 'center',
  },
  codeInput: {
    color: theme.colors.foreground,
  },
}))

export interface ICountrySelectorProps {
  label?: string
  value?: string
  onChange?: (val: string) => void
}

interface ICountrySelector extends ICountrySelectorProps {
  setFormattedValue: (value: string) => void
  darkTheme: boolean
  styles: ReturnType<typeof useStyles>
  showMessage: boolean
  t: TFunction
  open: boolean
  handleOpen: () => void
  handleClose: () => void
  handleChange: (value: Country) => void
  getCountrySelectorLanguage: () => TranslationLanguageCode
}

const CountrySelector = ({
  darkTheme,
  label,
  styles,
  showMessage,
  t,
  open,
  handleOpen,
  handleClose,
  value,
  handleChange,
  getCountrySelectorLanguage,
}: ICountrySelector) => (
  <View>
    {label && (
      <Box justifyContent="flex-start" alignItems="flex-start" px="m" pb="s">
        <Typography text={label} />
      </Box>
    )}
    <TouchableOpacity style={[styles.container, showMessage && styles.error]} onPress={handleOpen}>
      <CountryPicker
        translation={getCountrySelectorLanguage()}
        theme={darkTheme ? DARK_THEME : undefined}
        visible={open}
        withCountryNameButton
        countryCode={value || (getCountry() as any)}
        countryCodes={SUPPORTED_COUNTRIES as any}
        containerButtonStyle={styles.textInput}
        onClose={handleClose}
        onSelect={handleChange}
        withEmoji={false}
      />
      <Spacer flex />
      <Icon family={'Feather'} name="chevron-down" size={18} />
    </TouchableOpacity>
    {showMessage && (
      <Box direction="column" px="m" pt="s" justifyContent="flex-start" alignItems="flex-start">
        <Typography text={t('forms:CountrySelector.messages.error')} color="danger" />
      </Box>
    )}
  </View>
)

export default withWrapper<ICountrySelectorProps>(CountrySelector, useCountrySelectorWrapper, {
  useStyles,
})
