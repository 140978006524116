import { createAction } from '@reduxjs/toolkit'
import * as CONSTANTS from './constants'
import { createActionHook, createEmptyActionHook } from '../../helpers/store'
import {
  IAddCustomVehicle,
  IAddVehicle,
  ICar,
  IDeleteVehicle,
  IGetById,
  IGetCars,
  IGetVersions,
  IManufacturer,
  IModel,
  IVersion,
} from './types'

const getManufacturers = createAction(CONSTANTS.GET_MANUFACTURERS)
const setManufacturers = createAction<IManufacturer[]>(CONSTANTS.SET_MANUFACTURERS)

const getModels = createAction<IGetById>(CONSTANTS.GET_MODELS)
const setModels = createAction<IModel[]>(CONSTANTS.SET_MODELS)

const getYears = createAction<IGetById>(CONSTANTS.GET_YEARS)
const setYears = createAction<string[]>(CONSTANTS.SET_YEARS)

const getVersions = createAction<IGetVersions>(CONSTANTS.GET_VERSIONS)
const setVersions = createAction<IVersion[]>(CONSTANTS.SET_VERSIONS)

const addVehicle = createAction<IAddVehicle | IAddCustomVehicle>(CONSTANTS.ADD_VEHICLE)

const getCars = createAction<IGetCars>(CONSTANTS.GET_CARS)
const setCars = createAction<ICar[]>(CONSTANTS.SET_CARS)

const deleteVehicle = createAction<IDeleteVehicle>(CONSTANTS.DELETE_VEHICLE)

const setPrimaryCar = createAction<string | undefined>(CONSTANTS.SET_PRIMARY_CAR)

const getVehicleDetails = createAction<string>(CONSTANTS.GET_VEHICLE_DETAILS)
const setVehicle = createAction<ICar>(CONSTANTS.SET_VEHICLE)

export const vehiclesActionHooks = {
  getManufacturers: createEmptyActionHook(getManufacturers),
  setManufacturers: createActionHook(setManufacturers),
  getModels: createActionHook(getModels),
  setModels: createActionHook(setModels),
  getYears: createActionHook(getYears),
  setYears: createActionHook(setYears),
  getVersions: createActionHook(getVersions),
  setVersions: createActionHook(setVersions),
  addVehicle: createActionHook(addVehicle),
  getCars: createActionHook(getCars),
  setCars: createActionHook(setCars),
  deleteVehicle: createActionHook(deleteVehicle),
  setPrimaryCar: createActionHook(setPrimaryCar),
  getVehicleDetails: createActionHook(getVehicleDetails),
}

export {
  getManufacturers,
  setManufacturers,
  getModels,
  setModels,
  getYears,
  setYears,
  getVersions,
  setVersions,
  addVehicle,
  getCars,
  setCars,
  deleteVehicle,
  setPrimaryCar,
  getVehicleDetails,
  setVehicle,
}
