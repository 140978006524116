export enum RouterNames {
  account = 'account',
  accountDetails = 'accountDetails',
  addCar = 'addCar',
  addCard = 'addCard',
  addOwnCar = 'addOwnCar',
  rfid = 'rfid',
  carDetails = 'carDetails',
  cardInfo = 'cardInfo',
  cars = 'cars',
  editAccountDetails = 'editAccountDetails',
  faq = 'faq',
  favorites = 'favorites',
  home = 'home',
  homeStations = 'homeStations',
  languages = 'languages',
  login = 'login',
  otp = 'otp',
  privacy = 'privacy',
  private = 'private',
  recovery = 'recovery',
  registration = 'registration',
  reportIssue = 'reportIssue',
  selectModel = 'selectModel',
  sendFeedback = 'sendFeedback',
  sessions = 'sessions',
  settings = 'settings',
  station = 'station',
  stationDetails = 'stationDetails',
  stationFeedback = 'stationFeedback',
  stationFilter = 'stationFilter',
  stationGuide = 'stationGuide',
  stationReport = 'stationReport',
  support = 'support',
  supportRequest = 'supportRequest',
  transactionFilter = 'transactionFilter',
  transactionHistory = 'transactionHistory',
  transactionInfo = 'transactionInfo',
  sessionInfo = 'sessionInfo',
  userGuide = 'userGuide',
  wallet = 'wallet',
  yearAndVersion = 'yearAndVersion',
  scanQr = 'scanQr',
}
