import { all } from 'redux-saga/effects'
import { combineReducers } from 'redux'
import { accountReducer, accountSagas } from './account'
import { settingsReducer, settingsSagas } from './settings'
import { vehicleSagas, vehiclesReducer } from './vehicles'
import { stationsReducer, stationsSagas } from './stations'
import { transactionsReducer, transactionsSagas } from './transactions'
import { sessionsReducer } from './sessions/reducer'
import { sessionsSagas } from './sessions'
import { notificationReducer, notificationSagas } from './notification'
import { paymentReducer, paymentSagas } from './payment'

const root = {
  account: accountReducer,
  notification: notificationReducer,
  sessions: sessionsReducer,
  settings: settingsReducer,
  stations: stationsReducer,
  transactions: transactionsReducer,
  vehicles: vehiclesReducer,
  payment: paymentReducer,
}

export type RootState = ReturnType<typeof rootReducer>
export const rootReducer = combineReducers(root)

export function* rootSaga() {
  yield all([
    accountSagas(),
    notificationSagas(),
    sessionsSagas(),
    settingsSagas(),
    stationsSagas(),
    transactionsSagas(),
    vehicleSagas(),
    paymentSagas(),
  ])
}
