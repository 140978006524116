import {
  ActivityIndicator,
  GestureResponderEvent,
  StyleProp,
  StyleSheet,
  Text,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from 'react-native'
import { withWrapper } from '../../../../core/helpers/withWrapper'
import { withStyles } from '../../../../core/theme'
import { Icon } from '../../icon'
import { useButtonWrapper } from './wrapper'
import { ITheme } from '../../../../core/theme/themes'
import Box from '../Box'

const useStyles = withStyles((theme) => ({
  fullWidth: {
    width: '100%',
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textContainer: {},
  textIcon: {},
  textTypography: {
    color: theme.colors.primary,
  },
  linkContainer: {},
  linkIcon: {},
  linkTypography: {
    color: theme.colors.secondary,
  },
  menuContainer: {
    paddingHorizontal: theme.spacing.s,
  },
  menuIcon: {
    color: theme.colors.foreground,
  },
  menuTypography: {
    color: theme.colors.text,
  },
  outlinedContainer: {
    paddingHorizontal: theme.spacing.s,
    backgroundColor: theme.colors.background,
    borderWidth: StyleSheet.hairlineWidth,
    borderColor: theme.colors.primary,
    padding: theme.spacing.m,
    borderRadius: theme.spacing.s,
  },
  outlinedIcon: {
    color: theme.colors.primary,
  },
  outlinedTypography: {
    color: theme.colors.primary,
    fontSize: 16,
  },
  containedTypography: {
    color: theme.colors.background,
    textAlign: 'center',
    fontWeight: '600',
    fontSize: 16,
  },
  containedIcon: {
    color: theme.colors.background,
  },
  containedContainer: {
    backgroundColor: theme.colors.primary,
    padding: theme.spacing.m,
    borderRadius: theme.spacing.s,
  },
  disabled: {
    opacity: 0.5,
  },
}))

interface IIcon {
  name: string
  family: IconFamily
}

interface IButtonProps {
  disabled?: boolean
  endIcon?: IIcon
  fullWidth?: boolean
  iconSize?: number
  label?: string
  onPress?: (event: GestureResponderEvent) => void
  startIcon?: IIcon
  variant?: 'contained' | 'text' | 'menu' | 'link' | 'outlined'
  loading?: boolean
  color?: keyof ITheme['colors']
  align?: 'center' | 'auto' | 'left' | 'right' | 'justify' | undefined
  textSize?: number
  textStyle?: StyleProp<TextStyle>
  style?: StyleProp<ViewStyle>
  bold?: boolean
  flex?: boolean
  disableTouchableOpacity?: boolean
}
interface IButton extends IButtonProps {
  styles: ReturnType<typeof useStyles>
  theme: ITheme
}

const Button = ({
  flex,
  disabled,
  endIcon,
  fullWidth,
  iconSize = 18,
  label,
  onPress,
  startIcon,
  styles,
  theme,
  variant = 'contained',
  loading,
  color,
  align = 'center',
  textSize = 14,
  bold,
  disableTouchableOpacity,
  textStyle,
  style,
}: IButton) => {
  const handlePress = (event: GestureResponderEvent) => {
    !loading && onPress && onPress(event)
  }

  return (
    <TouchableOpacity
      onPress={handlePress}
      style={[
        fullWidth && styles.fullWidth,
        flex && { flex: 1 },
        disabled && styles.disabled,
        style,
      ]}
      disabled={disableTouchableOpacity || disabled}
    >
      <View
        style={[
          styles.container,
          styles[`${variant}Container`],
          color && variant !== 'menu' && { backgroundColor: theme.colors[color] },
          color &&
            variant === 'outlined' && {
              backgroundColor: theme.colors.background,
              borderColor: theme.colors[color],
            },
        ]}
      >
        {!loading && startIcon && (
          <Box pl="xs">
            <Icon
              {...startIcon}
              size={iconSize}
              style={[
                styles[`${variant}Icon`],
                variant === 'menu' && color && { color: theme.colors[color] },
              ]}
            />
          </Box>
        )}
        {loading ? (
          <ActivityIndicator color={theme.colors.background} />
        ) : (
          <Text
            style={[
              styles[`${variant}Typography`],
              {
                flex: variant !== 'link' ? 1 : undefined,
                marginLeft: (startIcon || endIcon) && !loading && 12,
              },
              color === 'background' && { color: theme.colors.foreground },
              align && { textAlign: align },
              { fontSize: textSize },
              bold && { fontWeight: 'bold' },
              variant === 'menu' && color && { color: theme.colors[color] },
              textStyle,
              color && variant === 'outlined' && { color: theme.colors[color] },
              variant === 'contained' && {
                marginLeft: (startIcon || endIcon) && !loading && -12,
              },
            ]}
          >
            {label}
          </Text>
        )}

        {!loading && endIcon && (
          <Box pr={variant !== 'menu' ? 'xs' : undefined}>
            <Icon
              {...endIcon}
              size={iconSize}
              style={[
                styles[`${variant}Icon`],
                variant === 'menu' && { color: theme.colors.foreground },
                variant === 'menu' && color && { color: theme.colors[color] },
              ]}
            />
          </Box>
        )}
      </View>
    </TouchableOpacity>
  )
}

export default withWrapper<IButtonProps>(Button, useButtonWrapper, {
  useStyles,
})
