import { FlatList, TouchableOpacity, View } from 'react-native'
import { useTranslation } from 'react-i18next'
import { Connector } from '../../../components/connector'
import { Typography } from '../../../components'
import { Connector as ConnectorModel } from '../../../../core/models'
import { useStyles } from './styles'
import { ConnectorLoader } from '../../../components/common/SkeletonLoader/Connector'

interface Props {
  connectors: Map<string, ConnectorModel>
  connectorId: string
  onConnectorPress: (id: string) => void
  price?: number
}

export const Connectors = ({ connectors, connectorId, onConnectorPress, price }: Props) => {
  const { t } = useTranslation()

  const styles = useStyles()

  const connector = connectors.get(connectorId)

  const renderTab = ({ item }: { item: ConnectorModel }) => {
    const { connectorNumber } = item

    const isSelected = connectorId === item.connectorId

    return (
      <TouchableOpacity
        onPress={() => onConnectorPress(item.connectorId)}
        style={[styles.tab, isSelected && styles.selected]}
      >
        <Typography bold text={`${t('connector:title')} #${connectorNumber}`} />
      </TouchableOpacity>
    )
  }

  return (
    <View style={styles.gap}>
      <Typography bold text={t('connector:type')} size={'m'} />
      {connectors.size ? (
        <FlatList
          horizontal
          data={[...connectors.values()]}
          renderItem={renderTab}
          style={styles.tabs}
          contentContainerStyle={styles.gap}
        />
      ) : (
        <ConnectorLoader />
      )}
      {connector && <Connector showStatus connector={connector} price={price} />}
    </View>
  )
}
