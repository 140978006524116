import { withWrapper } from '../../../../core/helpers/withWrapper'
import { withStyles } from '../../../../core/theme'
import ModalContainer from '../../../components/common/ModalContainer'
import { SupportRequestContent } from '../content'
import { useSupportRequestModalWrapper } from './wrapper'

const useStyles = withStyles((theme) => ({
  content: {
    padding: theme.spacing.m,
    height: 360,
    width: 500,
    maxWidth: '90%',
  },
}))

interface ISupportRequestModalProps {
  isVisible: boolean
  setVisible: (value: boolean) => void
  chargeStationId?: string
}

interface ISupportRequestModal extends ISupportRequestModalProps {
  styles: ReturnType<typeof useStyles>
}

const Component = ({ styles, isVisible, setVisible, chargeStationId }: ISupportRequestModal) => (
  <ModalContainer isVisible={isVisible} setVisible={setVisible} contentStyles={styles.content}>
    <SupportRequestContent chargeStationId={chargeStationId} />
  </ModalContainer>
)

export const SupportRequestModal = withWrapper<ISupportRequestModalProps>(
  Component,
  useSupportRequestModalWrapper,
  { useStyles }
)
