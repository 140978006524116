import { ScrollView, View } from 'react-native'

import { RouterNames } from '../../../../core/constants'
import { useStatuses } from '../../../../core/hooks'
import StationDetailsContent from '../content'
import { Paper } from '../../../components'
import { useStyles } from './styles'

export const StationDetailsScreen = ({
  navigation,
  route,
}: ScreenProps<RouterNames.stationDetails>) => {
  const { navigate } = navigation
  const { stationId, connectorId } = route.params

  const styles = useStyles()

  const { session } = useStatuses(connectorId)

  const toggleFeedback = () => {
    session && navigate(RouterNames.stationFeedback, { session })
  }

  return (
    <View style={styles.container}>
      <ScrollView contentContainerStyle={styles.content} showsVerticalScrollIndicator={false}>
        <Paper>
          <StationDetailsContent
            stationId={stationId}
            connectorId={connectorId}
            toggleFeedback={toggleFeedback}
          />
        </Paper>
      </ScrollView>
    </View>
  )
}
