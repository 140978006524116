import { useLayoutEffect } from 'react'
import { TouchableOpacity, View, SectionList, Platform, SectionListData } from 'react-native'
import { DateData } from 'react-native-calendars'
import Checkbox from 'expo-checkbox'

import { withStyles } from '../../../../core/theme'
import { DefaultWrapperProps, withWrapper } from '../../../../core/helpers/withWrapper'
import { RouterNames } from '../../../../core/constants'
import { ITheme } from '../../../../core/theme/themes'
import { useTransactionFilterContentWrapper } from './wrapper'
import { MarkedDatesType, RnCalendar } from '../../../components/calendar'
import { Header } from '../../../router/Header'
import {
  Box,
  Button,
  Divider,
  EmptyPlaceholder,
  ScreenLoader,
  Spacer,
  TextField,
  Typography,
} from '../../../components'
import ModalContainer from '../../../components/common/ModalContainer'
import { StationFilterItem } from './StationFilterItem'
import { FilterItem, FilterType, IFilter, ITransactionFilterContentProps } from './types'

const useStyles = withStyles((theme, insets, _, isWeb) => ({
  contentContainer: {
    flex: 1,
    padding: theme.spacing.m,
    paddingBottom: insets.bottom ? 0 : theme.spacing.m,
  },
  container: {
    backgroundColor: theme.colors.background,
    paddingHorizontal: theme.spacing.m,
    width: '100%',
  },
  bottomButton: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    padding: theme.spacing.m,
    backgroundColor: theme.colors.background,
    zIndex: 1,
  },
  dateField: {
    flex: isWeb ? 0.49 : 0.48,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: theme.spacing.l,
    paddingBottom: theme.spacing.m,
  },
  dateInputs: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  modalContainer: {
    padding: theme.spacing.m,
    paddingBottom: insets.bottom || theme.spacing.m,
  },
  webResetBtnContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}))

interface ITransactionFilterScreen
  extends DefaultWrapperProps<RouterNames.transactionFilter>,
    ITransactionFilterContentProps {
  styles: ReturnType<typeof useStyles>
  theme: ITheme
  resetAll: () => void
  dates: DateData[]
  fillDate: (selectedDate: DateData) => void
  onDateInputPress: () => void
  markedDates?: MarkedDatesType
  filterData: IFilter
  sections: SectionListData<FilterItem, { title: FilterType }>[]
  getAllChecked: (key: FilterType) => boolean
  onItemPress: (item: FilterItem) => void
  onAllPress: (type: FilterType) => (value: boolean) => void
  filter: () => void
  loading: boolean
  isVisible: boolean
  setVisible: (value: boolean) => void
}

const Component = ({
  styles,
  navigation,
  resetAll,
  dates,
  fillDate,
  onDateInputPress,
  markedDates,
  filterData,
  sections,
  getAllChecked,
  onItemPress,
  theme,
  onAllPress,
  t,
  filter,
  loading,
  isVisible,
  setVisible,
}: ITransactionFilterScreen) => {
  useLayoutEffect(() => {
    if (Platform.OS !== 'web') {
      navigation.setOptions({
        header: (props) => (
          <Header
            {...props}
            right={
              <TouchableOpacity onPress={resetAll}>
                <Typography
                  text={t('transaction:filter.resetAll')}
                  size={14}
                  color="secondaryText"
                />
              </TouchableOpacity>
            }
          />
        ),
      })
    }
  }, [navigation])

  const { [FilterType.car]: cars, [FilterType.station]: stations } = filterData

  const renderItem = ({ item }: { item: FilterItem }) => (
    <StationFilterItem item={item} onPress={onItemPress} />
  )

  const renderHeader = ({ section: { title } }: { section: { title: FilterType } }) => {
    const checkbox = (
      <Checkbox
        value={getAllChecked(title)}
        color={theme.colors.primary}
        onValueChange={onAllPress(title)}
      />
    )

    switch (title) {
      case FilterType.car:
        return (
          <>
            <Divider top={'m'} />
            <View style={styles.header}>
              <Typography text={t('transaction:filter.vehicles')} bold size={'m'} />
              {!!cars.length && checkbox}
            </View>
            {!cars.length && (
              <Box my="m">
                <EmptyPlaceholder icon="truck" text={t('notFound:cars')} />
              </Box>
            )}
          </>
        )
      case FilterType.station:
        return (
          <>
            <Divider top={'m'} />
            <View style={styles.header}>
              <Typography text={t('transaction:filter.chargerStations')} bold size={'m'} />
              {!!stations.length && checkbox}
            </View>
            {!stations.length && (
              <Box my="m">
                <EmptyPlaceholder icon="map-pin" text={t('notFound:stations')} />
              </Box>
            )}
          </>
        )
    }
  }

  if (loading) return <ScreenLoader />

  return (
    <>
      <View style={styles.contentContainer}>
        <SectionList
          showsVerticalScrollIndicator={false}
          ListHeaderComponent={() => (
            <>
              <View style={styles.webResetBtnContainer}>
                <Typography text={t('transaction:filter.date')} bold size={'m'} />
                {Platform.OS === 'web' && (
                  <TouchableOpacity onPress={resetAll}>
                    <Typography
                      text={t('transaction:filter.resetAll')}
                      size={14}
                      color="secondaryText"
                    />
                  </TouchableOpacity>
                )}
              </View>
              <Spacer vertical={'s'} />
              <View style={styles.dateInputs}>
                <TextField
                  value={dates[0]?.dateString}
                  placeholder={t('transaction:filter.startDate')}
                  style={styles.dateField}
                  showSoftInputOnFocus={false}
                  handleFocus={onDateInputPress}
                />
                <TextField
                  value={dates[1]?.dateString}
                  placeholder={t('transaction:filter.finishDate')}
                  style={styles.dateField}
                  showSoftInputOnFocus={false}
                  handleFocus={onDateInputPress}
                />
              </View>
              <Spacer vertical={'xs'} />
            </>
          )}
          sections={sections}
          keyExtractor={({ id }) => id}
          stickySectionHeadersEnabled={false}
          renderSectionHeader={renderHeader}
          renderItem={renderItem}
        />
        <Spacer vertical={'s'} />
        <Button label={t('transaction:filter.filter')} onPress={filter} />
      </View>
      <ModalContainer
        isVisible={isVisible}
        setVisible={setVisible}
        contentStyles={styles.modalContainer}
      >
        <RnCalendar markingType="period" onDayPress={fillDate} markedDates={markedDates} />
        <Button label={t('buttons:select')} onPress={() => setVisible(false)} />
      </ModalContainer>
    </>
  )
}

export const TransactionFilterContent = withWrapper<ITransactionFilterContentProps>(
  Component,
  useTransactionFilterContentWrapper,
  { useStyles }
)
