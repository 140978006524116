import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useFocusEffect } from '@react-navigation/native'
import { IUseWrapper, IOptions } from '../../../core/helpers/withWrapper'
import {
  transactionsActionHooks,
  transactionsSelectorHooks,
} from '../../../core/store/transactions'
import { accountSelectorHooks } from '../../../core/store/account'

export const useProfileScreenWrapper: IUseWrapper = (options?: IOptions) => {
  const { useStyles } = options!
  const { t } = useTranslation()
  const styles = useStyles()

  const getStats = transactionsActionHooks.getStats()

  const user = accountSelectorHooks.getUser()
  const image = accountSelectorHooks.getImage()
  const stats = transactionsSelectorHooks.getStats()
  const isLoading = transactionsSelectorHooks.getTransactionLoading()

  useFocusEffect(
    useCallback(() => {
      getStats()
    }, [])
  )

  return { t, user, styles, image, isLoading, stats }
}
