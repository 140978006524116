import { api } from '../setup'
import * as CONSTANTS from './constants'
import { ITransactionApi } from './types'

export const transactionApi: ITransactionApi = {
  transactions: async (params) => {
    const { data } = await api.get(CONSTANTS.TRANSACTIONS_USER, {
      params,
    })

    return data
  },
  transaction: async ({ transactionId }) => {
    const { data } = await api.get(CONSTANTS.TRANSACTION(transactionId))

    return data
  },
  recentTransactions: async () => {
    const { data } = await api.get(CONSTANTS.TRANSACTIONS_USER, {
      params: {
        page: 1,
        size: 5,
        sortBy: 'endTimeOfCharge',
        orderBy: 'desc',
      },
    })

    return data
  },
  stats: async () => {
    const { data } = await api.get(CONSTANTS.USER_STATS)
    return data
  },
}
