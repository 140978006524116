import { FlatList, Pressable, StyleProp, ViewStyle } from 'react-native'

import ScreenLoader from '../ScreenLoader'
import { Box, Paper, Typography } from '../common'
import { useStyles } from './styles'

interface Props<T> {
  data: T[]
  label?: string
  placeholder?: JSX.Element
  renderItem: (item: T) => JSX.Element
  onItemPress?: (item: T) => void
  loading?: boolean
  style?: StyleProp<ViewStyle>
}

export const Carousel = <T,>({
  data,
  style,
  label,
  placeholder,
  renderItem,
  onItemPress,
  loading = false,
}: Props<T>) => {
  const styles = useStyles()

  const isEmpty = data.length === 0

  const title = label && <Typography text={label} style={styles.title} bold size={'m'} />

  if (loading) {
    return (
      <Box gap={'m'} direction={'column'}>
        {title}
        <ScreenLoader />
      </Box>
    )
  }

  const render = ({ item }: { item: T }) => {
    return (
      <Pressable onPress={onItemPress?.bind(null, item)}>
        <Paper style={styles.item}>{renderItem(item)}</Paper>
      </Pressable>
    )
  }

  return (
    <Box direction={'column'} alignItems={'flex-start'} style={style}>
      {title}
      <FlatList
        data={data}
        horizontal={!isEmpty}
        scrollEnabled={!isEmpty}
        renderItem={render}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={styles.content}
        ListEmptyComponent={placeholder}
        style={styles.list}
      />
    </Box>
  )
}
