import { SafeAreaView, View } from 'react-native'

import { DefaultWrapperProps, withWrapper } from '../../../core/helpers/withWrapper'
import { withStyles } from '../../../core/theme'
import { Button, Paper, ScreenLoader, Spacer } from '../../components'
import { Switcher } from '../../components/common/Switcher'
import { Card } from '../../components/card'
import { Card as CardModel } from '../../../core/models'
import { RouterNames } from '../../../core/constants'

import { useCardInfoScreenWrapper } from './wrapper'

const useStyles = withStyles((theme) => ({
  contentContainer: {
    flex: 1,
    padding: theme.spacing.m,
  },
  scroll: {
    flex: 1,
    backgroundColor: theme.colors.backgroundOffset,
  },
}))

interface ICardInfoScreen extends DefaultWrapperProps<RouterNames.cardInfo> {
  styles: ReturnType<typeof useStyles>
  card?: CardModel
  isActive: boolean
  updateCard: () => Promise<void>
  deleteCard: () => Promise<void>
  loading: boolean
}

const CardInfoScreen = ({
  styles,
  card,
  t,
  isActive,
  updateCard,
  deleteCard,
  loading,
}: ICardInfoScreen) => {
  if (loading || !card) return <ScreenLoader />

  return (
    <SafeAreaView style={styles.scroll}>
      <View style={styles.contentContainer}>
        <Paper>
          <Card card={card} />
        </Paper>
        <Spacer vertical={'s'} />
        <Paper>
          <Switcher checked={isActive} label={t('card:default')} onCheck={updateCard} />
        </Paper>
        <Spacer flex />
        <Button
          bold
          label={t('buttons:delete')}
          color="danger"
          onPress={deleteCard}
          loading={loading}
        />
      </View>
    </SafeAreaView>
  )
}

export default withWrapper(CardInfoScreen, useCardInfoScreenWrapper, {
  useStyles,
})
