import { SafeAreaView } from 'react-native'
import { DefaultWrapperProps, withWrapper } from '../../../../core/helpers/withWrapper'
import { withStyles } from '../../../../core/theme'
import { TransactionFilterContent } from '../content'
import { useTransactionFilterScreeWrapper } from './wrapper'

const useStyles = withStyles((theme) => ({
  container: {
    flex: 1,
    backgroundColor: theme.colors.background,
  },
}))

interface ITransactionFilterScreen extends DefaultWrapperProps {
  styles: ReturnType<typeof useStyles>
  onFilter: () => void
}

const Component = ({ styles, onFilter }: ITransactionFilterScreen) => (
  <SafeAreaView style={styles.container}>
    <TransactionFilterContent onFilter={onFilter} />
  </SafeAreaView>
)

export const TransactionFilterScreen = withWrapper(Component, useTransactionFilterScreeWrapper, {
  useStyles,
})
