import { TFunction } from 'react-i18next'
import { View } from 'react-native'
import { withWrapper } from '../../../../core/helpers/withWrapper'
import { withStyles } from '../../../../core/theme'
import { ITheme } from '../../../../core/theme/themes'
import { Typography } from '../../common'
import CustomSwitch from '../../common/CustomSwitch'
import { useSwitchWrapper } from './wrapper'

const useStyles = withStyles((theme) => ({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  text: {
    backgroundColor: theme.colors.white,
  },
}))

export interface ISwitchProps {
  placeholder?: string
  label?: string
  secure?: boolean
  onChange?: (value: boolean) => void
  value?: boolean
  showMessage?: string
  showMessageType?: keyof ITheme['colors']
  handleBlur?: () => void
  icon?: string
}
interface ISwitch extends ISwitchProps {
  styles: ReturnType<typeof useStyles>
  focused: boolean
  onBlur: () => void
  onFocus: () => void
  t: TFunction
}

const SwitchComponent = ({ styles, label, value = true, onChange }: ISwitch) => {
  return (
    <View style={styles.container}>
      {label && <Typography text={label} size="m" />}
      <CustomSwitch onSwitch={onChange} value={value} />
    </View>
  )
}

export default withWrapper<ISwitchProps>(SwitchComponent, useSwitchWrapper, {
  useStyles,
})
