import { Platform } from 'react-native'

import { MapProps } from './types'

type MapCallback = () => { Map: (props: MapProps) => JSX.Element }

export const { Map } = Platform.select<MapCallback>({
  native: () => require('./native'),
  default: () => require('./web'),
})()
