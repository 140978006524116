export const LOGIN_WEB = 'drivers/login'
export const LOGIN_MOBILE = 'drivers/login/mobile'
export const REFRESH_TOKEN = '/auth'
export const SIGNUP = 'drivers/signup'

export const UPDATE_ACCOUNT = '/user/update'
export const GET_ACCOUNT = '/user/'
export const CURRENT_USER_DETAILS = 'drivers/details'
export const UPDATE_USER = `drivers`
export const DEACTIVATE_USER = `drivers/deactivate`
export const USER_IMAGE = 'drivers/image'
export const RECOVERY = (email: string) => `drivers/forgotpassword/${email}`
export const USER_VALIDATE = 'drivers/validate'
export const REGISTRATION_CONFIRM = `drivers/signup/confirm`
export const RESET_PASSWORD = `drivers/resetpassword`

export const GET_FAQ = `faq`
