import { ActivityIndicator, FlatList } from 'react-native'
import { useTranslation } from 'react-i18next'

import { Box, Paper, Typography } from '../../components/common'
import { FAQ, PaginationParams } from '../../../core/models'
import { useInfiniteScroll } from '../../../core/hooks'
import { theme } from '../../../core/theme/themes'
import { FaqAPI } from '../../../core/api/faq'
import { useStyles } from './styles'

export const FAQScreen = () => {
  const { t, i18n } = useTranslation()

  const styles = useStyles()

  const fetch = (params: PaginationParams) => {
    return FaqAPI.getAll({ lang: i18n.language, ...params })
  }

  const { items, isLoading, isRefreshing, reset, fetchMore } = useInfiniteScroll({ fetch })

  const isEmpty = !items.length

  const renderQuestion = ({ item }: { item: FAQ }) => {
    return (
      <Box direction={'column'} alignItems={'flex-start'} gap={'s'}>
        <Typography bold size={'m'} text={item.question} />
        <Paper>
          <Typography bold size={'m'} text={item.answer} />
        </Paper>
      </Box>
    )
  }

  const spinner = <ActivityIndicator color={theme.colors.primary} />

  const footer = !isEmpty && isLoading ? spinner : null

  const placeholder = isLoading ? spinner : <Typography bold size={'l'} text={t('empty:faq')} />

  return (
    <FlatList
      data={items}
      refreshing={isRefreshing}
      scrollEnabled={!isEmpty}
      onRefresh={reset}
      onEndReached={fetchMore}
      renderItem={renderQuestion}
      ListEmptyComponent={placeholder}
      ListFooterComponent={footer}
      style={styles.list}
      contentContainerStyle={[styles.content, isEmpty && styles.empty]}
    />
  )
}
