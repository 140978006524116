import { useMemo, useState } from 'react'
import {
  ActivityIndicator,
  FlatList,
  Platform,
  StyleProp,
  TouchableOpacity,
  ViewStyle,
} from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import { stationsSelectorHooks } from '../../../../core/store/stations'
import { Charging, Station } from '../../../../core/models'
import { RouterNames } from '../../../../core/constants'
import { theme } from '../../../../core/theme/themes'
import { useCharging } from '../../../providers/charging'
import { Box, Paper, Typography } from '../../../components'
import { Icon } from '../../../components/icon'
import { StationDetailsModal } from '../../StationDetails'
import { Placeholder } from '../placeholder'
import { useStyles } from './styles'

interface Props {
  style?: StyleProp<ViewStyle>
}

export const ActiveSessions = ({ style }: Props) => {
  const { navigate } = useNavigation()

  const stations = stationsSelectorHooks.getStations()

  const { sessions, statuses } = useCharging()

  const chargingStations = useMemo(() => {
    return [...sessions.values()].reduce((acc, { chargingId, chargeStationId }) => {
      const station = stations.find(({ id }) => chargeStationId === id)

      if (station) {
        return acc.set(chargingId, station)
      }

      return acc
    }, new Map<string, Station>())
  }, [sessions, stations])

  const { t } = useTranslation()

  const [isVisible, setIsVisible] = useState(false)
  const [session, setSession] = useState<Charging.ActiveSession | null>(null)

  const styles = useStyles()

  const onSessionPress = (session: Charging.ActiveSession) => {
    if (Platform.OS === 'web') {
      setSession(session)
      setIsVisible(true)
    } else {
      navigate(RouterNames.stationDetails, {
        stationId: session.chargeStationId,
        connectorId: session.connectorId,
      })
    }
  }

  const renderItem = ({ item }: { item: Charging.ActiveSession }) => {
    const status = statuses.get(item.chargingId)
    const stationName = chargingStations.get(item.chargingId)?.name

    return (
      <TouchableOpacity key={item.chargingId} onPress={() => onSessionPress(item)}>
        <Paper row style={styles.container}>
          {stationName && <Typography text={stationName} />}
          <Box flex alignItems={'center'} justifyContent={'space-between'}>
            {status ? (
              <Box alignItems={'center'} gap={'s'}>
                <Typography text={`${status.energy} ${t('connector:kwh')}`} />
                <Typography text={t(status.status)} />
              </Box>
            ) : (
              <ActivityIndicator color={theme.colors.primary} />
            )}
            <Icon family={'Feather'} name={'chevron-right'} size={20} />
          </Box>
        </Paper>
      </TouchableOpacity>
    )
  }

  return (
    <>
      <FlatList
        data={[...sessions.values()]}
        renderItem={renderItem}
        ListEmptyComponent={<Placeholder />}
        contentContainerStyle={[styles.list, style]}
      />
      {Platform.OS === 'web' && session && (
        <StationDetailsModal
          isVisible={isVisible}
          stationId={session.chargeStationId}
          connectorId={session.connectorId}
          setVisible={setIsVisible}
        />
      )}
    </>
  )
}
