import { useEffect, useState } from 'react'
import { Platform, View, VirtualizedList } from 'react-native'
import { useTranslation } from 'react-i18next'

import { Box, Paper, ScreenLoader, Typography } from '../../components'
import { EmptyPlaceholder, KeyboardAvoid } from '../common'
import { stationsApi } from '../../../core/api/stations'
import { useDeviceType } from '../../../core/hooks'
import { withStyles } from '../../../core/theme'
import { Station } from '../../../core/models'
import { MapAPI } from '../../../core/api/map'
import { SearchStationCard } from './SearchStationCard'

const useStyles = withStyles((theme, insets, _, isWeb) => ({
  container: {
    position: 'absolute',
    top: (insets.top || theme.spacing.m) + 56,
    left: 0,
    zIndex: 1,
    ...(isWeb ? { maxWidth: 390, width: '100%' } : { right: 0 }),
    bottom: '25%',
  },
  wrapper: {
    padding: theme.spacing.m,
  },
  paper: {
    maxHeight: Platform.OS === 'web' ? '70vh' : '100%',
  },
  label: {
    marginLeft: theme.spacing.s,
    marginBottom: theme.spacing.s,
  },
}))

interface Props {
  search: string
}

export const Search = ({ search }: Props) => {
  const { t } = useTranslation()
  const styles = useStyles()

  const type = useDeviceType()

  const [lastVisited, setLastVisited] = useState<Station[]>([])
  const [searchResults, setSearchResults] = useState<Station[]>([])

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setIsLoading(true)
    stationsApi
      .getLastVisitedStations(5)
      .then((stations) => setLastVisited(stations))
      .finally(() => setIsLoading(false))
  }, [])

  useEffect(() => {
    if (!search) return

    setIsLoading(true)
    MapAPI.geocode(search)
      .then(async ([place]) => {
        if (!place) {
          setSearchResults([])
          return
        }

        const stations = await stationsApi.getStations({
          distanceMax: 20e3,
          latitude: place.geometry.location.lat,
          longitude: place.geometry.location.lng,
        })

        setSearchResults(stations)
      })
      .finally(() => setIsLoading(false))
  }, [search])

  const data = search ? searchResults : lastVisited

  const placeholder = (
    <Box my="m">
      {isLoading ? (
        <ScreenLoader />
      ) : (
        <EmptyPlaceholder icon="map-pin" text={t('notFound:stations')} />
      )}
    </Box>
  )

  return (
    <View style={styles.container}>
      <KeyboardAvoid offset={{ ios: 216 }} style={styles.wrapper}>
        {type !== 'web' && !search && (
          <Typography bold size={'m'} text={t('stations:recently')} style={styles.label} />
        )}
        <Paper style={styles.paper}>
          <VirtualizedList<Station>
            data={data}
            keyExtractor={(item) => item.id}
            getItemCount={() => data.length}
            getItem={(data, index) => data[index]}
            renderItem={({ item }) => <SearchStationCard station={item} search={search} />}
            ListEmptyComponent={placeholder}
          />
        </Paper>
      </KeyboardAvoid>
    </View>
  )
}
