import { StyleProp, View, ViewStyle } from 'react-native'

import { CommonStationCard } from '../CommonStationCard'
import { StationDetails } from '../../../core/models'
import { Gradient } from './Gradient'
import { withStyles } from '../../../core/theme'

interface Props {
  gradient?: boolean
  station: StationDetails
  style?: StyleProp<ViewStyle>
}

const useStyles = withStyles((theme) => ({
  container: {
    // backgroundColor: 'white',
    // position: 'absolute',
    // left: 0,
    // right: 0,
    // top: 0,
    // bottom: 0,
    // zIndex: 2,
    // backgroundColor: 'white',
  },
}))

export const StationInfoSection = ({ station, gradient, style }: Props) => {
  const styles = useStyles()

  return (
    <View style={[styles.container, style]}>
      {gradient && <Gradient reverse position={{ top: -50, left: 0, bottom: 0, right: 0 }} />}
      <CommonStationCard station={station} disableImage disableCost disableFavorite />
    </View>
  )
}
