import React, { useMemo } from 'react'
import { LinearGradient } from 'expo-linear-gradient'

interface IGradient {
  reverse?: boolean
  position?: {
    top?: number
    left?: number
    right?: number
    bottom?: number
  }
}

export const Gradient = ({ reverse, position }: IGradient) => {
  const colors = useMemo(() => {
    return ['#ffffff10', '#ffffff91', '#ffffff91', '#ffffffa3', '#fff']
  }, [reverse])

  const locations = useMemo(() => {
    let defaultLocations = [0.8, 0.5, 0.3, 0.3, 0.1]

    if (reverse) {
      defaultLocations = [...defaultLocations]
      defaultLocations.reverse()
    }

    return defaultLocations
  }, [reverse])

  return (
    <LinearGradient
      colors={colors}
      locations={locations}
      style={{
        position: 'absolute',
        left: position?.left || 0,
        top: position?.top || 0,
        right: position?.right || 0,
        bottom: position?.bottom || 0,
      }}
    />
  )
}
