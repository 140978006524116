import { IOptions, IUseWrapper } from '../../../core/helpers/withWrapper'

export const useUserGuideScreenWrapper: IUseWrapper = (options?: IOptions) => {
  const { useStyles } = options!
  const styles = useStyles()

  return {
    styles,
  }
}
