import { TFunction } from 'react-i18next'
import { View, Image } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { withWrapper } from '../../../core/helpers/withWrapper'
import { withStyles } from '../../../core/theme'
import { TextField } from '../../components/forms'
import HeaderLanguageSwitcher from '../../components/HeaderLanguageSwitcher'
import { useLoginScreenWrapper } from './wrapper'
import { Box, Button, KeyboardAvoid, Typography, Spacer } from '../../components/common'
import { KeyboardDismissView } from '../../components/KeyboardDismissView'

const useStyles = withStyles((theme, insets, width, isWeb) => ({
  container: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing.l,
    backgroundColor: theme.colors.background,
  },
  image: {
    width: '100%',
    height: 120,
  },
  form: {
    ...(isWeb ? { width: 400, maxWidth: 400 } : {}),
  },
  link: {
    color: theme.colors.secondary,
  },
}))

interface ILoginScreen {
  styles: ReturnType<typeof useStyles>
  t: TFunction
  loading: boolean
  openRegistrationScreen: () => void
  openRecoveryScreen: () => void
  handleSubmit: () => void
  handleChange: (key: string) => (text: string) => void
  handleBlur: (key: string) => () => void
  values: {
    userName: string
    password: string
  }
  isValid: boolean
  errors: {
    userName?: string
    password?: string
  }
  touched: {
    userName?: boolean
    password?: boolean
  }
}

const LoginScreen = ({
  t,
  styles,
  openRegistrationScreen,
  openRecoveryScreen,
  values,
  isValid,
  handleChange,
  handleSubmit,
  handleBlur,
  errors,
  touched,
  loading,
}: ILoginScreen) => (
  <SafeAreaView style={{ flex: 1 }}>
    <KeyboardAvoid>
      <KeyboardDismissView>
        <View style={styles.container}>
          <HeaderLanguageSwitcher />
          <Spacer flex />
          <Image
            style={styles.image}
            resizeMode={'contain'}
            source={require('../../../../assets/logo.png')}
          />
          <Spacer vertical="m" />
          <View style={styles.form}>
            <TextField
              icon="user"
              placeholder={t('login:form.usernameAndEmail')}
              onChange={handleChange('userName')}
              handleBlur={handleBlur('userName')}
              value={values.userName}
              showMessage={touched.userName ? errors.userName : undefined}
              autoCompleteType="name"
              textContentType="name"
              handleEnter={handleSubmit}
            />
            <Spacer vertical="s" />
            <TextField
              icon="lock"
              placeholder={t('forms:placeholders.password')}
              secure
              onChange={handleChange('password')}
              handleBlur={handleBlur('password')}
              value={values.password}
              showMessage={touched.password ? errors.password : undefined}
              autoCompleteType="password"
              textContentType="password"
              handleEnter={handleSubmit}
            />
            <Spacer vertical="xs" />
            <View style={{ width: '100%', alignItems: 'flex-end' }}>
              <Button
                label={t('login:form.button.recovery')}
                variant="link"
                align="right"
                onPress={openRecoveryScreen}
                textStyle={{ width: '100%', ...styles.link }}
              />
              {/* <Button
                label={t('login:form.button.recoveryUsername')}
                variant="link"
                align="right"
                onPress={openRecoveryScreen}
                textStyle={{ width: '100%' }}
              /> */}
            </View>
            <Spacer vertical="m" />
            <Button
              label={t('login:form.button.login')}
              endIcon={{ name: 'log-in', family: 'Feather' }}
              onPress={handleSubmit}
              disabled={!isValid}
              loading={loading}
            />
          </View>
          <Spacer flex />
          <Box>
            <Typography text={t('login:signup')} />
            <Spacer horizontal="xs" />
            <Button
              label={t('login:form.button.signup')}
              variant="link"
              onPress={openRegistrationScreen}
              textStyle={styles.link}
            />
          </Box>
        </View>
      </KeyboardDismissView>
    </KeyboardAvoid>
  </SafeAreaView>
)

export default withWrapper(LoginScreen, useLoginScreenWrapper, { useStyles })
