import { PropsWithChildren } from 'react'
import {
  Keyboard,
  Platform,
  StyleProp,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from 'react-native'

interface Props {
  style?: StyleProp<ViewStyle>
}

export const KeyboardDismissView = ({ children, style }: PropsWithChildren<Props>) => {
  return (
    <TouchableWithoutFeedback
      style={[{ flex: 1 }, style]}
      onPress={Platform.OS !== 'web' ? Keyboard.dismiss : undefined}
      accessible={false}
    >
      <View style={{ flex: 1 }}>{children}</View>
    </TouchableWithoutFeedback>
  )
}
