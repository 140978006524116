import { SafeAreaView, TouchableOpacity, View } from 'react-native'

import { DefaultWrapperProps, withWrapper } from '../../../core/helpers/withWrapper'
import { withStyles } from '../../../core/theme'

import { useCarsScreenWrapper } from './wrapper'

import { ClickableList, Paper, Typography } from '../../components'
import { Icon } from '../../components/icon'
import { ICar } from '../../../core/store/vehicles'
import { IListItem } from '../../components/common/ClickableList/component'

const useStyles = withStyles((theme) => ({
  wrapper: {
    flex: 1,
    backgroundColor: theme.colors.backgroundOffset,
    height: '100%',
  },
  contentContainer: {
    flex: 1,
    backgroundColor: theme.colors.backgroundOffset,
    padding: theme.spacing.m,
    height: '100%',
  },
  innerContainer: {
    width: '100%',
    height: '100%',
    flex: 1,
  },
  placeholder: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  placeholderText: {
    textAlign: 'center',
  },
  addCarBtn: {
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
  },
  iconAndTextWrapper: {
    flexDirection: 'row',
    alignItems: 'center',
    alignContent: 'center',
  },
  squareView: {
    height: 43,
    width: 43,
    backgroundColor: '#F6F8FA',
    borderRadius: 8,
    marginRight: theme.spacing.m,
    alignItems: 'center',
    alignContent: 'center',
    justifyContent: 'center',
  },
}))

interface ICarsScreen extends DefaultWrapperProps {
  styles: ReturnType<typeof useStyles>
  cars: ICar[]
  onItemClick: (item: IListItem) => void
  onAddCar: () => void
}

const CarsScreen = ({ styles, cars, onItemClick, onAddCar, t }: ICarsScreen) => (
  <SafeAreaView style={styles.wrapper}>
    <View style={styles.contentContainer}>
      <Paper style={{ flex: 1, height: '100%' }} containerStyle={{ height: '100%' }}>
        <View style={styles.innerContainer}>
          {!cars.length && (
            <View style={styles.placeholder}>
              <Icon family={'MaterialIcons'} name={'electric-car'} size={60} />
              <Typography style={styles.placeholderText} text={t('cars:noVehicles')} size={20} />
            </View>
          )}
          {!!cars.length && (
            <ClickableList
              data={cars.map((item) => ({
                firstString: `${item.vehicleVersion.vehicleManufacturerName} ${item.vehicleVersion.vehicleModelName}`,
                secondString: item.vehicleVersion.year,
                tag: item.primary ? t('cars:primary') : '',
                ...item,
              }))}
              onItemPress={onItemClick}
              withBorder
            />
          )}
          <TouchableOpacity style={styles.addCarBtn} onPress={onAddCar}>
            <View style={styles.iconAndTextWrapper}>
              <View style={styles.squareView}>
                <Icon family={'Ionicons'} name={'car-outline'} size={20} />
              </View>
              <Typography text={t('buttons:add')} bold />
            </View>
            <Icon family={'Feather'} name={'chevron-right'} size={20} />
          </TouchableOpacity>
        </View>
      </Paper>
    </View>
  </SafeAreaView>
)

export default withWrapper(CarsScreen, useCarsScreenWrapper, {
  useStyles,
})
