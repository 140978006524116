import { withStyles } from '../../../core/theme'

export const useStyles = withStyles((theme) => ({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderRadius: theme.spacing.m,
  },
  right: {
    alignItems: 'flex-end',
  },
}))
