/// <reference types="@types/google.maps" />

import { useEffect, useRef } from 'react'
import { useWindowDimensions } from 'react-native'

import { MarkerClusterer, Renderer, onClusterClickHandler } from '@googlemaps/markerclusterer'

import { useLocation } from '../../../providers/location'
import { theme } from '../../../../core/theme/themes'

import { Controls } from '../controls'
import { MapProps } from '../types'
import { styles } from './styles'

export const Map = ({
  disabled,
  position,
  stations,
  onListView,
  onMapPress,
  onStationsPress,
}: MapProps) => {
  const container = useRef<HTMLDivElement>(null)

  const map = useRef<google.maps.Map | null>(null)
  const positionMarker = useRef<google.maps.Marker | null>(null)
  const clusterer = useRef<MarkerClusterer | null>(null)

  const { width, height } = useWindowDimensions()
  const zoom = width / height

  const { location } = useLocation()

  useEffect(() => {
    let mapClickListener: google.maps.MapsEventListener | null = null

    google.maps.importLibrary('maps').then((library) => {
      const { Map } = library as google.maps.MapsLibrary

      if (!container.current) return

      map.current = new Map(container.current, {
        mapId: Math.random().toString(),
        disableDefaultUI: true,
        zoom,
        center: { lat: 0, lng: 0 },
        gestureHandling: disabled ? 'none' : undefined,
      })

      if (onMapPress) {
        mapClickListener = map.current.addListener('click', onMapPress)
      }
    })

    return () => {
      mapClickListener?.remove()
    }
  }, [disabled])

  useEffect(() => {
    const latitude = (position ?? location)?.latitude
    const longitude = (position ?? location)?.longitude

    if (latitude && longitude) {
      const center = new google.maps.LatLng(latitude, longitude)
      positionMarker.current = new google.maps.Marker({ position: center })
    }

    if (position) {
      positionMarker.current?.setMap(map.current)
    }

    const center = positionMarker.current?.getPosition()

    if (center) {
      map.current?.setCenter(center)
      map.current?.setZoom(position ? 16 : 8)
    }
  }, [location, position])

  const onClusterClick: onClusterClickHandler = (_, { markers }) => {
    const ids = markers?.map((marker) => (marker as google.maps.marker.AdvancedMarkerElement).id)
    ids && onStationsPress?.call(null, ids)
  }

  const renderer: Renderer = {
    render: ({ position, markers }) => {
      const marker = new google.maps.Marker({ position })

      const text = markers?.length.toString()
      text && marker.setLabel({ text, color: theme.colors.white })
      marker.setIcon({
        scale: 20,
        fillOpacity: 1,
        strokeWeight: 0,
        fillColor: theme.colors.primary,
        path: google.maps.SymbolPath.CIRCLE,
      })

      return marker
    },
  }

  useEffect(() => {
    google.maps.importLibrary('marker').then((library) => {
      const { AdvancedMarkerElement, PinElement } = library as google.maps.MarkerLibrary

      const markers =
        stations?.reduce<google.maps.marker.AdvancedMarkerElement[]>((acc, { id, address }) => {
          if (!address.latitude || !address.longitude) return acc

          const { element } = new PinElement({
            glyph: '',
            borderColor: theme.colors.primary,
            background: theme.colors.primary,
          })

          const position = new google.maps.LatLng(address.latitude, address.longitude)
          const marker = new AdvancedMarkerElement({
            position,
            map: map.current,
            content: element,
          })

          marker.id = id
          marker.addListener('click', () => onStationsPress?.call(null, [id]))
          acc.push(marker)

          return acc
        }, []) ?? []

      clusterer.current?.clearMarkers()
      clusterer.current?.unbindAll()

      clusterer.current = new MarkerClusterer({
        map: map.current,
        markers,
        onClusterClick,
        renderer,
      })
    })
  }, [disabled, stations])

  const onCrosshairPress = () => {
    const isLocationVisible = !!positionMarker.current?.getMap()
    const position = positionMarker.current?.getPosition()

    if (!position?.lat() || !position.lng()) return

    positionMarker.current?.setMap(isLocationVisible ? null : map.current)
    position && map.current?.setCenter(position)
    !isLocationVisible && map.current?.setZoom(15)
  }

  return (
    <>
      <div ref={container} style={styles} />
      {!disabled && (
        <Controls
          crosshairEnabled={!!location}
          onCrosshairPress={onCrosshairPress}
          onLayersPress={onListView}
        />
      )}
    </>
  )
}
