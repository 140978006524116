import { Platform, StyleProp, TouchableOpacity, View, ViewStyle } from 'react-native'
import { Box, IconButton } from '../common'
import { DefaultWrapperProps, IOptions, withWrapper } from '../../../core/helpers/withWrapper'
import { Gradient } from './Gradient'
import { stationsActionHooks } from '../../../core/store/stations'
import { openNavigator } from '../../../core/helpers/links'
import { useState } from 'react'
import { AxiosError } from 'axios'
import { generateError } from '../../../core/helpers/utils'
import { showMessage } from 'react-native-flash-message'
import { InformationModal } from '../'
import { t } from 'i18next'
import { RouterNames } from '../../../core/constants'
import { useDeviceType } from '../../../core/hooks'
import { StationDetails } from '../../../core/models'
import { Icon } from '../icon'

interface IHeaderSectionProps {
  station: StationDetails
  style?: StyleProp<ViewStyle>
}

interface IHeaderSection extends DefaultWrapperProps, IHeaderSectionProps {
  handleStarPress: () => void
  isModalVisible: boolean
  setModalVisible: (value: boolean) => void
  handleRemove: () => void
  favorited?: boolean
}

const Component = ({
  navigation,
  station,
  handleStarPress,
  theme,
  isModalVisible,
  setModalVisible,
  favorited,
  handleRemove,
  style,
}: IHeaderSection) => {
  const { canGoBack, goBack, navigate } = navigation

  const type = useDeviceType()

  const onArrowPress = () => {
    if (canGoBack()) {
      goBack()
    } else {
      const screen = station.favorite ? RouterNames.favorites : RouterNames.home
      navigate(RouterNames.private, { screen })
    }
  }

  return (
    <>
      <View style={[{ flexDirection: 'row', justifyContent: 'space-between' }, style]}>
        {Platform.OS === 'ios' && <Gradient />}
        <IconButton
          icon="arrow-left"
          onPress={onArrowPress}
          transparent={type !== 'web'}
          size={26}
          boxSize={0}
          padding={1}
          rounded={type === 'web'}
          shadow={type === 'web'}
        />
        <Box>
          <TouchableOpacity
            style={[
              {
                backgroundColor: theme.colors.background,
                padding: theme.spacing.s + 2,
                borderRadius: 50,
              },
              type === 'web' && {
                shadowColor: '#000',
                shadowOffset: {
                  width: 0,
                  height: 1,
                },
                shadowOpacity: 0.18,
                shadowRadius: 1.0,
                elevation: 1,
              },
            ]}
            onPress={handleStarPress}
          >
            <Icon
              size={20}
              color={'#F2C94C'}
              family={'FontAwesome'}
              name={favorited ? 'star' : 'star-o'}
            />
          </TouchableOpacity>

          <IconButton
            icon="navigation"
            rounded
            padding={1}
            size={18}
            onPress={() => {
              if (station.address.longitude && station.address.latitude) {
                openNavigator(station.address.latitude, station.address.longitude, station.name)
              }
            }}
            shadow={type === 'web'}
          />
        </Box>
      </View>
      <InformationModal
        isVisible={isModalVisible}
        setVisible={setModalVisible}
        text={t('favorites:remove.question')}
        description={t('favorites:remove.description')}
        bottom
        withButtons={{
          left: { text: t('buttons:no'), onClick: () => setModalVisible(false) },
          right: { text: t('buttons:yes'), onClick: handleRemove },
        }}
      />
    </>
  )
}

const useHeaderSectionWrapper = (_: IOptions, { station }: IHeaderSectionProps) => {
  const [favorited, setFavorited] = useState(station.favorite)

  const addFavoriteStation = stationsActionHooks.addFavoriteStation()
  const removeFavoriteStation = stationsActionHooks.removeFavoriteStation()
  const [isModalVisible, setModalVisible] = useState(false)

  const callback = (error: AxiosError) => {
    setModalVisible(false)

    const errorMessage = generateError(error, {
      400: t(`favorites:${favorited ? 'remove' : 'add'}.error`),
    })

    if (!!errorMessage) {
      showMessage({
        message: 'Error',
        description: errorMessage,
        type: 'danger',
      })
    } else {
      showMessage({
        message: t(`favorites:${favorited ? 'remove' : 'add'}.result`),
        description: t(`favorites:${favorited ? 'remove' : 'add'}.message`),
        type: 'success',
      })
    }
  }

  const handleRemove = () => {
    setFavorited(false)
    removeFavoriteStation({
      chargeStationId: station.id,
      callback,
    })
  }

  const handleStarPress = () => {
    if (favorited) {
      setModalVisible(true)
    } else {
      addFavoriteStation({
        chargeStationId: station.id,
        callback,
      })
      setFavorited(true)
    }
  }

  return {
    handleStarPress,
    favorited,
    isModalVisible,
    setModalVisible,
    handleRemove,
  }
}

export const HeaderSection = withWrapper<IHeaderSectionProps>(Component, useHeaderSectionWrapper)
