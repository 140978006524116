import { TFunction } from 'react-i18next'
import { KeyboardAvoidingView, Platform } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { DefaultWrapperProps, withWrapper } from '../../../core/helpers/withWrapper'
import { withStyles } from '../../../core/theme'
import { Box, Button, TextField, Typography } from '../../components'
import Spacer from '../../components/common/Spacer'
import { KeyboardDismissView } from '../../components/KeyboardDismissView'
import OTPInputView from './OTPView'
import { useOTPScreenWrapper } from './wrapper'
import { OTPTypes } from './types'

const useStyles = withStyles((theme) => ({
  container: {
    flex: 1,
    backgroundColor: theme.colors.background,
  },
  content: {
    flex: 1,
    alignSelf: 'center',
    padding: theme.spacing.l,
  },
  form: {
    maxWidth: 400,
  },
  link: {
    color: theme.colors.secondary,
  },
}))

interface IOTPScreen extends DefaultWrapperProps {
  styles: ReturnType<typeof useStyles>
  t: TFunction
  loading: boolean
  openLoginScreen: () => void
  handleSubmit: () => void
  handleChange: (key: string) => (text: string) => void
  handleBlur: (key: string) => () => void
  otpError: string
  values: {
    otp: string
  }
  isValid: boolean
  errors: {
    otp?: string
  }
  touched: {
    otp?: boolean
  }
  password: IFormik<Password, Password>
  setOTPError: (value: string | null) => void
  type: OTPTypes
}

interface IFormik<T, B> {
  handleSubmit: () => void
  setFieldValue: (key: string, value: string) => void
  setFieldTouched: (key: string, value: boolean) => void
  values: T
  isValid: boolean
  errors: B
  touched: B
}
interface Password {
  password: string
  repeatedPassword: string
  agreement: boolean
}

function generateField<T = Password>(
  formik: IFormik<T, T>,
  key: keyof T,
  setSingupError?: (value: string | null) => void
) {
  return {
    onChange: (text: string) => {
      formik.setFieldValue(key as string, text)

      if (setSingupError) {
        setSingupError(null)
      }
    },
    handleBlur: () => {
      if (!formik.touched[key]) {
        formik.setFieldTouched(key as string, true)
      }

      if (setSingupError) {
        setSingupError(null)
      }
    },
    value: formik.values[key],
    showMessage: formik.touched[key] ? formik.errors[key] : undefined,
    fullWidth: true,
  }
}

const OTPScreen = ({
  t,
  styles,
  values,
  isValid,
  openLoginScreen,
  handleChange,
  handleSubmit,
  loading,
  theme,
  otpError,
  password,
  setOTPError,
  type,
}: IOTPScreen) => (
  <SafeAreaView style={styles.container}>
    <KeyboardAvoidingView
      behavior={Platform.select({ ios: 'padding', android: 'height' })}
      style={styles.content}
    >
      <KeyboardDismissView>
        <Box flex direction={'column'} px={'l'} style={styles.form}>
          <Typography
            variant={'primary'}
            text={t('otp:form.title')}
            style={{ textAlign: 'center' }}
          />
          <Typography
            variant={'secondary'}
            text={t('otp:form.subtitle')}
            style={{ textAlign: 'center' }}
          />
          <Spacer vertical={'m'} />
          <OTPInputView
            inputCount={6}
            defaultValue={values.otp}
            tintColor={theme.colors.primary}
            offTintColor={theme.colors.secondaryText}
            handleTextChange={handleChange('otp')}
          />
          {type === OTPTypes.recovery && (
            <>
              <Spacer vertical={'s'} />
              <TextField
                label={t('forms:fields.new_password')}
                secure
                icon={'lock'}
                placeholder={t('forms:placeholders.new_password')}
                {...generateField(password, 'password', setOTPError)}
              />
              <Spacer vertical={'s'} />
              <TextField
                label={t('forms:fields.repeat_password')}
                secure
                icon={'lock'}
                placeholder={t('forms:placeholders.repeat_password')}
                {...generateField(password, 'repeatedPassword', setOTPError)}
              />
              <Spacer vertical={'s'} />
            </>
          )}

          {!!otpError && (
            <>
              <Typography color={'danger'} text={otpError} />
              <Spacer vertical={'s'} />
            </>
          )}
          <Spacer vertical={'m'} />
          <Button
            label={
              type === OTPTypes.recovery ? t('otp:form.button.reset') : t('otp:form.button.otp')
            }
            fullWidth
            endIcon={{ name: 'check', family: 'Feather' }}
            onPress={handleSubmit}
            disabled={!isValid || values.otp.length !== 6}
            loading={loading}
          />
        </Box>
        <Box>
          <Typography text={t('recovery:has_account')} />
          <Spacer horizontal="xs" />
          <Button
            label={t('recovery:form.button.login')}
            variant="link"
            onPress={openLoginScreen}
            textStyle={styles.link}
          />
        </Box>
      </KeyboardDismissView>
    </KeyboardAvoidingView>
  </SafeAreaView>
)

export default withWrapper(OTPScreen, useOTPScreenWrapper, {
  useStyles,
})
