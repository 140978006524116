import { useEffect, useState } from 'react'

import { ResponseMessageTypes } from '../api/charging'
import { useCharging } from '../../app/providers/charging'
import { useWebSocket } from '../../app/providers/websocket'

export const useStatuses = (connectorId: string) => {
  const { response } = useWebSocket()
  const { sessions, statuses, result } = useCharging()

  const session = sessions.get(connectorId)
  const chargingStatus = session ? statuses.get(session.chargingId) : null

  const [isCharging, setIsCharging] = useState(!!chargingStatus)

  const isFinished = !!session && !!result && session.chargingId === result.chargingId

  useEffect(() => {
    switch (response?.action) {
      case ResponseMessageTypes.confirmStartCharging:
      case ResponseMessageTypes.chargingStatus: {
        setIsCharging(true)
        break
      }
      case ResponseMessageTypes.confirmEndCharging: {
        setIsCharging(false)
        break
      }
    }
  }, [response])

  return { session, isCharging, isFinished, chargingStatus }
}
