import { Divider, Spacer, Typography } from '../common'
import { View } from 'react-native'
import React from 'react'
import { useTranslation } from 'react-i18next'

export const Operator = () => {
  const { t } = useTranslation()

  return (
    <>
      <Divider top={'m'} bottom={'m'} />
      <View style={{ width: '100%' }}>
        <Typography text={t('feedback:operator')} bold />
        <Spacer top={'m'} />
        <Typography text={'Brooklyn Simmons'} fontWeight={'300'} />
        <Spacer top={'s'} />
        <Typography text={'name@email.com'} fontWeight={'300'} />
      </View>
    </>
  )
}
