import { FC, memo, useMemo } from 'react'
import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'
import { TFunction, useTranslation } from 'react-i18next'
import { i18n } from 'i18next'

import { RootStackParamList } from '../../app/router/types'

import { useTheme } from '../theme'
import { ITheme } from '../theme/themes'

export type IOptions = Record<string, any>

export interface DefaultWrapperProps<T extends keyof RootStackParamList = any> {
  t: TFunction
  i18n: i18n
  theme: ITheme
  navigation: NativeStackNavigationProp<RootStackParamList, T, undefined>
  route: RouteProp<RootStackParamList, T>
}

export type IUseWrapper<T = {}> = (options: IOptions, customProps: T) => Record<any, any>

export function withWrapper<T extends {}>(
  Component: FC<any>,
  useWrapper: IUseWrapper<T> = () => ({}),
  options: IOptions = {}
) {
  const Wrapper = (customProps: T) => {
    const { t, i18n } = useTranslation()
    const theme = useTheme()
    const navigation = useNavigation()
    const route = useRoute()
    const props = useWrapper(options, customProps)

    const nextProps = useMemo(() => {
      return {
        t,
        i18n,
        theme,
        navigation,
        route,
        ...(props || {}),
        ...customProps,
      }
    }, [props, customProps])

    return <Component {...(nextProps as T)} />
  }

  return memo<T>(Wrapper)
}
