import { useEffect, useRef, useState } from 'react'
import {
  ActivityIndicator,
  FlatList,
  RefreshControl,
  StyleProp,
  View,
  ViewStyle,
} from 'react-native'
import { MarkingProps } from 'react-native-calendars/src/calendar/day/marking'
import { DateData } from 'react-native-calendars'
import { useNavigation } from '@react-navigation/native'

import { sessionsActionHooks, sessionsSelectorHooks } from '../../../../core/store/sessions'
import { getDay, getMonthFirstAndLastDateUTC } from '../../../../core/helpers/date'
import { ISession } from '../../../../core/api/sessions/types'
import { RouterNames } from '../../../../core/constants'
import { theme } from '../../../../core/theme/themes'
import { Divider, Paper, Spacer } from '../../../components'
import { RnCalendar } from '../../../components/calendar'
import { Session } from '../../../components/session'
import { Placeholder } from '../placeholder'
import { useStyles } from './styles'

interface Props {
  style?: StyleProp<ViewStyle>
}

const MAX_LIMIT_OF_SESSIONS = 20

export const CompletedSessions = ({ style }: Props) => {
  const { navigate } = useNavigation()

  const styles = useStyles()

  const ref = useRef<FlatList>(null)

  const [page, setPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [hasMore, setHasMore] = useState(true)
  const [timeInterval, setTimeInterval] = useState<{ first: number; last: number }>()
  const [markedDates, setMarkedDates] = useState<Record<string, MarkingProps>>()

  const getSessions = sessionsActionHooks.getSessions()
  const setSessions = sessionsActionHooks.setSessions()
  const sessions = sessionsSelectorHooks.getSessions()

  useEffect(() => {
    const interval = getMonthFirstAndLastDateUTC()
    setTimeInterval(interval)
  }, [])

  useEffect(() => {
    if (timeInterval) {
      const { first, last } = timeInterval
      fetchSessions(page, first, last)
    }
  }, [page, timeInterval])

  const fetchSessions = (page: number, startTimeOfCharge: number, endTimeOfCharge: number) => {
    setIsLoading(true)
    getSessions({
      page,
      size: MAX_LIMIT_OF_SESSIONS,
      endTimeOfCharge,
      startTimeOfCharge,
      callback: (hasMore) => {
        setIsLoading(false)
        setHasMore(hasMore)
      },
    })
  }

  const onDayPress = (date: DateData) => {
    if (markedDates && Object.keys(markedDates).includes(date.dateString)) {
      onMonthChange(date)
    } else {
      const { dateString, timestamp } = date
      setMarkedDates({
        [dateString]: {
          customStyles: {
            text: { color: theme.colors.background },
            container: { backgroundColor: theme.colors.primary },
          },
        },
      })
      setTimeInterval({
        first: getDay(timestamp, 'start'),
        last: getDay(timestamp, 'end'),
      })
      reset()
    }
  }

  const onMonthChange = (date: DateData) => {
    const interval = getMonthFirstAndLastDateUTC(date.timestamp)
    setTimeInterval(interval)
    setMarkedDates({})
    reset()
  }

  const reset = () => {
    setSessions([])
    setHasMore(true)
    setPage(1)
  }

  const onEndReached = () => {
    !isLoading && hasMore && setPage((page) => page + 1)
  }

  const header = (
    <>
      <Paper>
        <RnCalendar
          markingType="custom"
          onDayPress={onDayPress}
          onMonthChange={onMonthChange}
          markedDates={markedDates}
        />
      </Paper>
      <Spacer vertical={'s'} />
    </>
  )

  const renderItem = ({ item, index }: { item: ISession; index: number }) => {
    const onPress = () => {
      navigate(RouterNames.sessionInfo, { transactionId: item.sessionId })
    }

    return (
      <Session
        session={item}
        onPress={onPress}
        first={index === 0}
        last={index === sessions.length - 1}
      />
    )
  }

  const separator = () => {
    return (
      <View style={styles.separator}>
        <Divider />
      </View>
    )
  }

  const placeholder = () => {
    if (isLoading) {
      return (
        <>
          <Spacer top={'m'} />
          <ActivityIndicator size={'large'} />
        </>
      )
    }

    return <Placeholder />
  }

  const refreshControl = (
    <RefreshControl onRefresh={reset} refreshing={false} colors={[theme.colors.primary]} />
  )

  return (
    <FlatList
      ref={ref}
      data={sessions}
      renderItem={renderItem}
      onEndReached={onEndReached}
      ListHeaderComponent={header}
      ListEmptyComponent={placeholder}
      ItemSeparatorComponent={separator}
      showsVerticalScrollIndicator={false}
      refreshControl={refreshControl}
      contentContainerStyle={style}
    />
  )
}
