import { ActivityIndicator } from 'react-native'
import { Avatar, Box, Spacer, Typography } from '../common'
import { useTheme } from '../../../core/theme'
import { Icon } from '../icon'
import { IStats } from '../../../core/store/transactions'
import { Account } from '../../../core/store/account'
import { useTranslation } from 'react-i18next'

interface IStat {
  icon: string
  value?: string
  label: string
  loading: boolean
}

const Stat = ({ icon, value, label, loading }: IStat) => {
  const theme = useTheme()

  return (
    <Box direction="column" flex alignItems="center" justifyContent="center">
      <Icon rtl={false} family={'Feather'} name={icon} size={32} color={theme.colors.secondary} />
      <Spacer vertical={'s'} />
      {loading ? (
        <ActivityIndicator size={25} color={theme.colors.secondary} />
      ) : (
        <Typography text={(value ?? 0).toString()} bold size={18} />
      )}
      <Spacer vertical={2} />
      <Typography text={label} size={12} />
    </Box>
  )
}

interface Props {
  info: Account
  image: string | null
  isLoading: boolean
  stats: IStats | null
}

export const UserInfo = ({ info, image, stats, isLoading }: Props) => {
  const { t } = useTranslation()

  const { firstName, lastName, userName, email } = info

  return (
    <Box direction={'column'} justifyContent={'center'}>
      <Spacer vertical={'s'} />
      <Avatar size={120} placeholder={firstName.charAt(0) || ''} avatar={image} />
      <Spacer vertical={'s'} />
      <Box px="m" direction="column" alignItems="center">
        <Typography text={`${firstName} ${lastName}`} size={18} bold />
        <Spacer vertical={2} />
        <Typography text={email! || userName!} />
      </Box>
      <Spacer top={'xl'} />
      <Box direction="row" alignItems="center" justifyContent="space-between" fullWidth>
        <Stat
          icon="activity"
          loading={isLoading}
          value={stats?.energy.toFixed(2)}
          label={t('connector:kwh')}
        />
        <Stat
          icon="clock"
          loading={isLoading}
          value={stats?.totalTime.toString()}
          label={t('stats:hours')}
        />
        <Stat
          icon="dollar-sign"
          loading={isLoading}
          value={stats?.money.toFixed(2)}
          label={t('stats:totals')}
        />
      </Box>
    </Box>
  )
}
