import { useEffect, useRef } from 'react'
import {
  ActivityIndicator,
  Animated,
  I18nManager,
  StyleProp,
  TouchableWithoutFeedback,
  View,
  ViewStyle,
} from 'react-native'

import { withStyles } from '../../../../core/theme'
import { Spacer, Typography } from '../../common'
import { theme } from '../../../../core/theme/themes'

const TOGGLE_SIZE = 26
const { isRTL } = I18nManager

const useStyles = withStyles((theme) => ({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  switcher: {
    padding: 2,
    flexDirection: 'row',
    width: TOGGLE_SIZE * 2,
    borderRadius: TOGGLE_SIZE,
  },
  toggle: {
    width: TOGGLE_SIZE,
    height: TOGGLE_SIZE,
    borderRadius: TOGGLE_SIZE,
    backgroundColor: theme.colors.background,
  },
  loader: {
    position: 'absolute',
    alignSelf: 'center',
    transform: [{ scale: 0.8 }],
  },
}))

interface Props {
  label?: string
  checked: boolean
  loading?: boolean
  onCheck: () => void
  style?: StyleProp<ViewStyle>
}

export const Switcher = ({ label, loading, checked, onCheck, style }: Props) => {
  const styles = useStyles()

  const translateX = useRef(new Animated.Value(0)).current
  const backgroundColorValue = useRef(new Animated.Value(0)).current

  useEffect(() => {
    const targetTranslate = checked ? (TOGGLE_SIZE - 4) * (isRTL ? -1 : 1) : 0

    Animated.parallel([
      Animated.timing(backgroundColorValue, {
        toValue: checked ? 1 : 0,
        duration: 200,
        useNativeDriver: false,
      }),
      Animated.timing(translateX, {
        toValue: targetTranslate,
        duration: 200,
        useNativeDriver: false,
      }),
    ]).start()
  }, [checked])

  const backgroundColor = backgroundColorValue.interpolate({
    inputRange: [0, 1],
    outputRange: [theme.colors.border, theme.colors.primary],
  })

  const color = checked ? theme.colors.background : theme.colors.primary
  const position = +checked << +isRTL ? { left: 4 } : { right: 4 }

  return (
    <View style={[styles.container, style]}>
      {label && <Typography text={label} size={'m'} />}
      <Spacer flex />
      <TouchableWithoutFeedback onPress={onCheck}>
        <Animated.View style={[styles.switcher, { backgroundColor }]}>
          <Animated.View style={[styles.toggle, { transform: [{ translateX }] }]} />
          {loading && <ActivityIndicator color={color} style={[styles.loader, position]} />}
        </Animated.View>
      </TouchableWithoutFeedback>
    </View>
  )
}
