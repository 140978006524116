import React, { LegacyRef } from 'react'
import {
  View,
  TextInput,
  KeyboardTypeOptions,
  ViewStyle,
  StyleProp,
  NativeSyntheticEvent,
  TextInputFocusEventData,
  I18nManager,
} from 'react-native'
import { DefaultWrapperProps, withWrapper } from '../../../../core/helpers/withWrapper'
import { withStyles } from '../../../../core/theme'
import { ITheme } from '../../../../core/theme/themes'
import { useTextFieldWrapper } from './wrapper'
import Box from '../../common/Box'
import Typography from '../../common/Typography'
import IconButton from '../../common/IconButton'
import { Icon } from '../../icon'

const useStyles = withStyles((theme) => ({
  container: {
    width: '100%',
  },
  inputContainer: {
    height: 60,
    backgroundColor: theme.colors.background,
    borderColor: theme.colors.border,
    borderWidth: 2,
    overflow: 'hidden',
    borderRadius: theme.spacing.s,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  input: {
    padding: theme.spacing.m,
    width: '100%',
    height: 60,
    flex: 1,
    direction: I18nManager.isRTL ? 'rtl' : 'ltr',
    writingDirection: I18nManager.isRTL ? 'rtl' : 'ltr',
    textAlign: I18nManager.isRTL ? 'right' : 'left',
  },
  active: {
    borderColor: theme.colors.primary,
  },
  error: {
    borderColor: theme.colors.danger,
  },
  text: { backgroundColor: theme.colors.white },
  icon: {
    marginLeft: theme.spacing.m,
  },
  secureIcon: {
    width: 40,
    height: 40,
  },
  shadow: {
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 1,
    },
    shadowOpacity: 0.18,
    shadowRadius: 1.0,
    elevation: 1,
  },
}))

type TextContentType =
  | 'telephoneNumber'
  | 'givenName'
  | 'familyName'
  | 'name'
  | 'oneTimeCode'
  | 'password'
  | 'newPassword'
  | 'emailAddress'
  | 'postalCode'
  | 'fullStreetAddress'
  | 'newPassword'
  | 'username'
  | 'telephoneNumber'

type AutoCompleteType =
  | 'username'
  | 'street-address'
  | 'postal-code'
  | 'password'
  | 'name'
  | 'email'
  | 'tel'

export interface ITextFieldProps {
  placeholder?: string
  label?: string
  secure?: boolean
  showCounter?: boolean
  onChange?: (value: string) => void
  value?: string
  showMessage?: string
  showMessageType?: keyof ITheme['colors']
  handleBlur?: () => void
  keyboardType?: KeyboardTypeOptions
  style?: StyleProp<ViewStyle>
  maxLength?: number
  showSoftInputOnFocus?: boolean
  handleFocus?: (value: boolean, e?: NativeSyntheticEvent<TextInputFocusEventData>) => void
  handleEnter?: () => void
  icon?: string
  rounded?: boolean
  multiline?: boolean
  disableFocusStyles?: boolean
  disableBorders?: boolean
  iconSize?: number
  boxSize?: number
  shadow?: boolean
  autoFocus?: boolean
  textRef?: LegacyRef<TextInput>
  accessible?: boolean
  autoCompleteType?: AutoCompleteType
  textContentType?: TextContentType
}
interface ITextField extends ITextFieldProps, DefaultWrapperProps {
  styles: ReturnType<typeof useStyles>
  focused: boolean
  onBlur: () => void
  handleShowSecure: () => void
  showSecure: boolean
  onFocus: (e: NativeSyntheticEvent<TextInputFocusEventData>) => void
}

const TextField = ({
  styles,
  label,
  placeholder,
  showMessage,
  showMessageType = 'danger',
  secure,
  value,
  onChange,
  focused,
  onBlur,
  onFocus,
  icon,
  keyboardType,
  style: customStyle,
  maxLength,
  multiline,
  showSoftInputOnFocus,
  rounded,
  theme,
  disableFocusStyles,
  disableBorders,
  iconSize,
  handleEnter,
  boxSize,
  handleShowSecure,
  showSecure,
  showCounter,
  shadow,
  autoFocus,
  textRef,
  accessible,
  autoCompleteType,
  textContentType,
}: ITextField) => {
  return (
    <View style={[styles.container, !!boxSize && { height: boxSize }, customStyle]}>
      {label && (
        <Box justifyContent="flex-start" alignItems="flex-start" px="m" pb="s">
          <Typography text={label} size="m" color={!!showMessage ? 'danger' : undefined} />
        </Box>
      )}
      <View
        style={[
          styles.inputContainer,
          !disableFocusStyles && focused && styles.active,
          !!showMessage && styles.error,
          rounded && { borderRadius: theme.spacing.m },
          disableBorders && { borderWidth: 0 },
          !!boxSize && { height: boxSize },
          shadow && styles.shadow,
        ]}
      >
        {icon && (
          <Icon
            family={'Feather'}
            name={icon}
            size={!!iconSize ? iconSize : 24}
            style={styles.icon}
          />
        )}

        <TextInput
          ref={textRef}
          style={[
            styles.input,
            !!boxSize && { height: boxSize },
            multiline && {
              paddingTop: theme.spacing.m,
              textAlignVertical: 'top',
            },
          ]}
          placeholder={placeholder}
          autoCapitalize={'none'}
          autoCorrect={false}
          secureTextEntry={showSecure ? false : secure}
          onChangeText={onChange}
          value={value}
          onFocus={onFocus}
          onBlur={onBlur}
          keyboardType={keyboardType}
          maxLength={maxLength}
          multiline={multiline}
          showSoftInputOnFocus={showSoftInputOnFocus}
          onSubmitEditing={handleEnter}
          autoFocus={autoFocus}
          accessible={accessible}
          textContentType={textContentType}
          autoComplete={autoCompleteType}
        />
        {secure && (
          <IconButton size={24} icon={showSecure ? 'eye' : 'eye-off'} onPress={handleShowSecure} />
        )}
      </View>
      {showMessage && (
        <Box direction="column" px="m" pt="s" justifyContent="flex-start" alignItems="flex-start">
          <Typography text={showMessage} color={showMessageType} numberOfLines={4} />
        </Box>
      )}
      {maxLength !== undefined && typeof value === 'string' && showCounter && (
        <Box direction="column" px="m" pt="s" justifyContent="flex-end" alignItems="flex-end">
          <Typography
            text={`${value.length}/${maxLength}`}
            fontWeight={'100'}
            size={'m'}
            align={'right'}
          />
        </Box>
      )}
    </View>
  )
}

export default withWrapper<ITextFieldProps>(TextField, useTextFieldWrapper, {
  useStyles,
})
