import { I18nManager } from 'react-native'
import { initReactI18next } from 'react-i18next'
import RNLocalize from 'react-native-localize'

import i18n from 'i18next'

import { SUPPORTED_LANGUAGES, DEFALUT_LANGUAGE, RTL_LANGAUGES } from '../config'
import en from '../../../locales/en.json'
import ar from '../../../locales/ar.json'
import he from '../../../locales/he.json'
import ru from '../../../locales/ru.json'

import { LocaleConfig } from 'react-native-calendars'

I18nManager.allowRTL(true)

const resources = { en, ar, he, ru }

const detectedLanguage = RNLocalize.findBestAvailableLanguage(SUPPORTED_LANGUAGES)

i18n.use(initReactI18next).init({
  resources,
  lng: detectedLanguage?.languageTag || DEFALUT_LANGUAGE,
  supportedLngs: SUPPORTED_LANGUAGES,
  load: 'all',
  compatibilityJSON: 'v3',
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
})

const locales = {
  ru: {
    monthNames: [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентябрь',
      'Октябрь',
      'Ноябрь',
      'Декабрь',
    ],
    monthNamesShort: [
      'Янв',
      'Фев',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Авг',
      'Сент',
      'Окт',
      'Ноя',
      'Дек',
    ],
    dayNames: ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье'],
    dayNamesShort: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
  },
  en: {
    monthNames: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    monthNamesShort: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'June',
      'July',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  },
  ar: {
    monthNames: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    monthNamesShort: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'June',
      'July',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  },
  he: {
    monthNames: [
      '\u05d9\u05e0\u05d5\u05d0\u05e8',
      '\u05e4\u05d1\u05e8\u05d5\u05d0\u05e8',
      '\u05de\u05e8\u05e5',
      '\u05d0\u05e4\u05e8\u05d9\u05dc',
      '\u05de\u05d0\u05d9',
      '\u05d9\u05d5\u05e0\u05d9',
      '\u05d9\u05d5\u05dc\u05d9',
      '\u05d0\u05d5\u05d2\u05d5\u05e1\u05d8',
      '\u05e1\u05e4\u05d8\u05de\u05d1\u05e8',
      '\u05d0\u05d5\u05e7\u05d8\u05d5\u05d1\u05e8',
      '\u05e0\u05d5\u05d1\u05de\u05d1\u05e8',
      '\u05d3\u05e6\u05de\u05d1\u05e8',
    ],
    monthNamesShort: [
      '\u05d9\u05e0\u05d5',
      '\u05e4\u05d1\u05e8',
      '\u05de\u05e8\u05d5',
      '\u05d0\u05e4\u05e8',
      '\u05de\u05d0\u05d9',
      '\u05d9\u05d5\u05e0',
      '\u05d9\u05d5\u05dc',
      '\u05d0\u05d5\u05d2',
      '\u05e1\u05e4\u05d8',
      '\u05d0\u05d5\u05e7',
      '\u05e0\u05d5\u05d1',
      '\u05d3\u05e6\u05de',
    ],
    dayNames: [
      '\u05d9\u05d5\u05dd \u05e8\u05d0\u05e9\u05d5\u05df',
      '\u05d9\u05d5\u05dd \u05e9\u05e0\u05d9',
      '\u05d9\u05d5\u05dd \u05e9\u05dc\u05d9\u05e9\u05d9',
      '\u05d9\u05d5\u05dd \u05e8\u05d1\u05d9\u05e2\u05d9',
      '\u05d9\u05d5\u05dd \u05d7\u05de\u05d9\u05e9\u05d9',
      '\u05d9\u05d5\u05dd \u05e9\u05d9\u05e9\u05d9',
      '\u05e9\u05d1\u05ea',
    ],
    dayNamesShort: [
      '\u05d9\u05d5\u05dd \u05e8\u05e9',
      '\u05d9\u05d5\u05dd \u05e9',
      '\u05d9\u05d5\u05dd \u05e9\u05dc',
      '\u05d9\u05d5\u05dd \u05e8\u05d1',
      '\u05d9\u05d5\u05dd \u05d7',
      '\u05d9\u05d5\u05dd \u05e9',
      '\u05e9\u05d1\u05ea',
    ],
  },
}

LocaleConfig.locales = locales

i18n.on('languageChanged', (currentLang) => {
  LocaleConfig.defaultLocale = currentLang
  const rtlOn = RTL_LANGAUGES.includes(currentLang)
  I18nManager.isRTL = rtlOn
  I18nManager.forceRTL(rtlOn)
})

export default i18n
