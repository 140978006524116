import { PropsWithChildren } from 'react'
import { StyleProp, TouchableOpacity, ViewStyle } from 'react-native'

import { useStyles } from './styles'

interface Props {
  first?: boolean
  last?: boolean
  onPress?: () => void
  style?: StyleProp<ViewStyle>
}

export const PaperListItem = ({
  children,
  first,
  last,
  onPress,
  style,
}: PropsWithChildren<Props>) => {
  const styles = useStyles()

  return (
    <TouchableOpacity
      style={[styles.container, first && styles.first, last && styles.last, style]}
      onPress={onPress}
    >
      {children}
    </TouchableOpacity>
  )
}
