import { createReducer } from '@reduxjs/toolkit'
import { setSessions, getSessions, setError } from './actions'
import { SessionState } from './types'

const initialState: SessionState = {
  current: null,
  list: [],
  loading: false,
  error: null,
}

export const sessionsReducer = createReducer(initialState, (builder) => {
  builder.addCase(setSessions, (state, action) => {
    return { ...state, list: action.payload, loading: false }
  })
  builder.addCase(getSessions, (state, _action) => {
    return { ...state, loading: true, error: null }
  })
  builder.addCase(setError, (state, action) => {
    return { ...state, loading: false, error: action.payload, list: [] }
  })
})
