import dayjs from 'dayjs'
import { DateData } from 'react-native-calendars'
import utc from 'dayjs/plugin/utc'
import { TFunction } from 'i18next'

dayjs.extend(utc)

const toSeconds = (date: dayjs.Dayjs) => {
  return Math.floor(date.toDate().getTime() / 1e3)
}

const getDate = (timeStamp?: number) => {
  const date = dayjs(timeStamp)
  return date.subtract(date.utcOffset(), 'minutes')
}

export const getDurationString = (duration: number, t: TFunction) => {
  const hours = Math.floor(duration / 3600)
  const minutes = Math.floor((duration - hours * 3600) / 60)
  const seconds = duration - hours * 3600 - minutes * 60

  const res = []
  hours && res.push(`${hours} ${t('feedback:duration.hour')}`)
  minutes && res.push(`${minutes} ${t('feedback:duration.min')}`)
  res.push(`${seconds} ${t('feedback:duration.sec')}`)

  return res.join(' ')
}

export const timeStampToString = (timestamp: number, format = 'YYYY-MM-DD') => {
  return dayjs.unix(timestamp).local().format(format)
}

export const getDay = (timeStamp: number, moment: 'start' | 'end') => {
  return toSeconds(getDate(timeStamp)[moment === 'start' ? 'startOf' : 'endOf']('day'))
}

export const getMonthFirstAndLastDateUTC = (timeStamp?: number) => {
  const first = toSeconds(getDate(timeStamp).startOf('month'))
  const last = toSeconds(getDate(timeStamp).endOf('month'))

  return { first, last }
}

export const convertTimestampToDate = (timestamp: number) => {
  return dayjs(timestamp).toDate()
}

export const convertTimestampToDateData = (timeStamp?: number | null): DateData | null => {
  if (!timeStamp || timeStamp < 0) return null

  const newDate = getDate(timeStamp).add(12, 'hour')

  return {
    dateString: newDate.format('YYYY-MM-DD'),
    day: newDate.date(),
    month: newDate.month(),
    timestamp: newDate.unix() * 1e3,
    year: newDate.year(),
  }
}
