import { ActivityIndicator, FlatList } from 'react-native'
import { useTranslation } from 'react-i18next'

import { ITransaction } from '../../../../core/store/transactions'
import { EmptyPlaceholder, Paper, Spacer } from '../../../components/common'
import { Transaction } from '../../../components/transaction'
import { useStyles } from './styles'

interface Props {
  transactions: ITransaction[]
  isLoading: boolean
  isRefreshing: boolean
  onRefresh: () => void
  onEndReached: () => void
}

export const TransactionList = ({
  transactions,
  isLoading,
  isRefreshing,
  onRefresh,
  onEndReached,
}: Props) => {
  const { t } = useTranslation()

  const styles = useStyles()

  const renderItem = ({ item, index }: { item: ITransaction; index: number }) => {
    return (
      <Transaction
        transaction={item}
        first={index === 0}
        last={index === transactions.length - 1}
      />
    )
  }

  const placeholder = () => {
    if (isLoading && isRefreshing) return <ActivityIndicator size={'large'} />
    return (
      <Paper>
        <Spacer vertical={'s'} />
        <EmptyPlaceholder text={t('empty:sessions')} icon="hexagon" />
        <Spacer vertical={'s'} />
      </Paper>
    )
  }

  const separator = () => {
    return <Spacer vertical={'s'} />
  }

  const keyExtractor = (item: ITransaction) => {
    return item.sessionId
  }

  return (
    <FlatList
      data={transactions}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
      refreshing={isLoading && isRefreshing}
      onRefresh={onRefresh}
      onEndReachedThreshold={0}
      onEndReached={onEndReached}
      ListHeaderComponent={separator}
      ListEmptyComponent={placeholder}
      ItemSeparatorComponent={separator}
      style={styles.container}
      contentContainerStyle={styles.content}
    />
  )
}
