import { all, call, put, takeLatest } from 'redux-saga/effects'
import { notificationsApi } from '../../api/notifications'
import {
  IGetMonthlySubscriptionSaga,
  ISetEmailSubscriptionSaga,
  ISetStatusSubscriptionSaga,
  ISubscribeMobilePushNotificationsSaga,
  IUnsubscribeMobilePushNotificationsSaga,
} from './types'
import * as CONSTANTS from './constants'
import { setEmailSubscription, setStatusSubscription } from './actions'
import { IMonthlySubscriptionType } from '../../api/notifications/types'

function* getMonthlySubscriptionSaga({ payload }: IGetMonthlySubscriptionSaga) {
  try {
    const data: IMonthlySubscriptionType = yield call(notificationsApi.getMontlySubscription)

    yield put(setEmailSubscription(data.monthlyEmail))
    yield put(setStatusSubscription(data.statusNotification))

    if (payload.callback) {
      payload.callback(null, data)
    }
  } catch (error) {}
}

function* updateEmailSubscriptionSaga({
  payload: { emailNotification, callback },
}: ISetEmailSubscriptionSaga) {
  try {
    yield call(notificationsApi.setEmailSubscription, { emailNotification })

    yield put(setEmailSubscription(emailNotification))

    callback && callback(null)
  } catch (error) {
    callback && callback(error as Error)
  }
}

function* updateStatusSubscriptionSaga({
  payload: { statusNotification, callback },
}: ISetStatusSubscriptionSaga) {
  try {
    yield call(notificationsApi.setStatusSubscription, { statusNotification })

    yield put(setStatusSubscription(statusNotification))

    callback && callback(null)
  } catch (error) {
    callback && callback(error as Error)
  }
}

function* subscribePushNotificationsSaga({ payload }: ISubscribeMobilePushNotificationsSaga) {
  try {
    yield call(notificationsApi.subscribe, payload)
  } catch {}
}

function* unsubscribePushNotificationsSaga({ payload }: IUnsubscribeMobilePushNotificationsSaga) {
  try {
    yield call(notificationsApi.unsubscribe)

    if (payload.callback) {
      payload.callback(null)
    }
  } catch (error) {}
}

export function* notificationSagas(): Generator<any> {
  yield all([
    yield takeLatest<any>(CONSTANTS.GET_MONTHLY_SUBSCRIPTION, getMonthlySubscriptionSaga),
    yield takeLatest<any>(CONSTANTS.UPDATE_EMAIL_SUBSCRIPTION, updateEmailSubscriptionSaga),
    yield takeLatest<any>(CONSTANTS.UPDATE_STATUS_SUBSCRIPTION, updateStatusSubscriptionSaga),
    yield takeLatest<any>(CONSTANTS.SUBSCRIBE_PUSH_NOTIFICATIONS, subscribePushNotificationsSaga),
    yield takeLatest<any>(
      CONSTANTS.UNSUBSCRIBE_PUSH_NOTIFICATIONS,
      unsubscribePushNotificationsSaga
    ),
  ])
}
