import { RouteProp, useNavigation, useRoute } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import { IOptions, IUseWrapper } from '../../../core/helpers/withWrapper'
import { useState } from 'react'
import { vehiclesActionHooks, vehiclesSelectorHooks } from '../../../core/store/vehicles'
import { showMessage } from 'react-native-flash-message'
import { RouterNames } from '../../../core/constants'
import { RootStackParamList } from '../../router/types'

export const useCarDetailsWrapper: IUseWrapper = (options?: IOptions) => {
  const { useStyles } = options!
  const { t } = useTranslation()
  const styles = useStyles()

  const { navigate } = useNavigation()
  const { params } = useRoute<RouteProp<RootStackParamList, RouterNames.carDetails>>()
  const { carId } = params

  const [isLoading, setIsLoading] = useState(false)
  const [isModalVisible, setModalVisible] = useState(false)

  const selectedCar = vehiclesSelectorHooks.getCars().find(({ vehicleId }) => carId === vehicleId)

  const deleteVehicle = vehiclesActionHooks.deleteVehicle()
  const setPrimaryCar = vehiclesActionHooks.setPrimaryCar()

  const switchPrimary = () => {
    setPrimaryCar(carId)
  }

  const deleteCar = () => {
    setModalVisible(false)
    setIsLoading(true)
    deleteVehicle({
      carId,
      onSuccess() {
        setIsLoading(false)
        showMessage({
          type: 'success',
          message: t('cars:carDetails.message.success.title'),
          description: t('cars:carDetails.message.success.description'),
        })
        navigate(RouterNames.cars)
      },
      onError() {
        setIsLoading(false)
        showMessage({
          type: 'danger',
          message: t('cars:carDetails.message.error.title'),
          description: t('cars:carDetails.message.error.description'),
        })
      },
    })
  }

  return {
    styles,
    t,
    isModalVisible,
    selectedCar,
    isLoading,
    setModalVisible,
    deleteCar,
    switchPrimary,
  }
}
