import { Application } from './src/Application'

import { registerRootComponent } from 'expo'
const TextEncodingPolyfill = require('text-encoding')
Object.assign(global, {
  TextEncoder: TextEncodingPolyfill.TextEncoder,
  TextDecoder: TextEncodingPolyfill.TextDecoder,
})

registerRootComponent(Application)
