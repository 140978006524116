import { all, call, put, takeLatest } from 'redux-saga/effects'
import * as CONSTANTS from './constants'
import { vehiclesApi } from '../../api/vehicles'
import { setCars, setManufacturers, setModels, setVehicle, setVersions, setYears } from './actions'
import {
  IAddCustomVehicle,
  IAddVehicle,
  ICar,
  IDeleteVehicle,
  IGetVersions,
  IManufacturer,
  IModel,
  IGetById,
  IVersion,
  IGetCars,
} from './types'

function* manufacturersSaga() {
  try {
    const manufacturers: IManufacturer[] = yield call(vehiclesApi.getManufacturers)
    yield put(setManufacturers([...manufacturers]))
  } catch (error) {
    console.log(error)
  }
}

function* modelsSaga({ payload }: { payload: IGetById }) {
  const { id, onSuccess, onError } = payload
  try {
    const models: IModel[] = yield call(vehiclesApi.getModels, id)
    yield put(setModels(models))
    onSuccess && onSuccess()
  } catch (error) {
    onError && onError(error)
  }
}

function* yearsSaga({ payload }: { payload: IGetById }) {
  const { id, onSuccess, onError } = payload
  try {
    const years: string[] = yield call(vehiclesApi.getYears, id)
    yield put(setYears(years))
    onSuccess && onSuccess()
  } catch (error) {
    onError && onError(error)
  }
}

function* versionsSaga({ payload }: { payload: IGetVersions }) {
  const { id, year, onSuccess, onError } = payload
  try {
    const versions: IVersion[] = yield call(vehiclesApi.getVersions, id, year)
    yield put(setVersions(versions))
    onSuccess && onSuccess()
  } catch (error) {
    onError && onError(error)
  }
}

function* addVehicleSaga({ payload }: { payload: IAddVehicle | IAddCustomVehicle }) {
  const { onSuccess, onError, ...rest } = payload
  try {
    yield call(vehiclesApi.addVehicle, rest)
    yield call(carsSaga, { payload: {} })
    onSuccess && onSuccess()
  } catch (error) {
    onError && onError(error)
  }
}

function* carsSaga({ payload }: { payload: IGetCars }) {
  const { onSuccess, onError } = payload
  try {
    const cars: ICar[] = yield call(vehiclesApi.getCars)
    yield put(setCars(cars))
    onSuccess && onSuccess(cars)
  } catch (error) {
    console.log(error)
    onError && onError(error)
  }
}

function* deleteVehicleSaga({ payload }: { payload: IDeleteVehicle }) {
  const { carId, onSuccess, onError } = payload
  try {
    yield call(vehiclesApi.deleteVehicle, carId)
    yield call(carsSaga, { payload: {} })
    onSuccess && onSuccess()
  } catch (error) {
    onError && onError(error)
  }
}

function* setPrimaryCarSaga({ payload }: { payload: string }) {
  try {
    yield call(vehiclesApi.setPrimaryCar, payload)
    yield call(carsSaga, { payload: {} })
  } catch (error) {
    console.log(error)
  }
}

function* getVehicleDetailsSaga({ payload }: { payload: string }) {
  try {
    const vehicle: ICar = yield call(vehiclesApi.getVehicleDetails, payload)
    yield put(setVehicle(vehicle))
  } catch {}
}

export function* vehicleSagas(): Generator<any> {
  yield all([
    yield takeLatest<any>(CONSTANTS.GET_MANUFACTURERS, manufacturersSaga),
    yield takeLatest<any>(CONSTANTS.GET_MODELS, modelsSaga),
    yield takeLatest<any>(CONSTANTS.GET_YEARS, yearsSaga),
    yield takeLatest<any>(CONSTANTS.GET_VERSIONS, versionsSaga),
    yield takeLatest<any>(CONSTANTS.ADD_VEHICLE, addVehicleSaga),
    yield takeLatest<any>(CONSTANTS.GET_CARS, carsSaga),
    yield takeLatest<any>(CONSTANTS.DELETE_VEHICLE, deleteVehicleSaga),
    yield takeLatest<any>(CONSTANTS.SET_PRIMARY_CAR, setPrimaryCarSaga),
    yield takeLatest<any>(CONSTANTS.GET_VEHICLE_DETAILS, getVehicleDetailsSaga),
  ])
}
