import { createSelectorHook } from '../../helpers/store'
import { RootState } from '../rootReducer'

const getSessions = () => (state: RootState) => state.sessions.list
const getSessionsState = () => (state: RootState) => ({
  loading: state.sessions.loading,
  error: state.sessions.error,
})

export const sessionsSelectorHooks = {
  getSessions: createSelectorHook<RootState['sessions']['list']>(getSessions),
  getSessionsState: createSelectorHook(getSessionsState),
}
