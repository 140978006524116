import { useEffect, useState } from 'react'
import { Keyboard } from 'react-native'
import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'
import { showMessage } from 'react-native-flash-message'
import { IOptions, IUseWrapper } from '../../../../core/helpers/withWrapper'
import { stationsActionHooks, stationsSelectorHooks } from '../../../../core/store/stations'
import { vehiclesSelectorHooks } from '../../../../core/store/vehicles'
import { RouterNames } from '../../../../core/constants'
import { IStationFeedbackContentProps } from './types'
import { paymentSelectorHooks } from '../../../../core/store/payment'
import { useCharging } from '../../../providers/charging'

export const useStationFeedbackContentWrapper: IUseWrapper<IStationFeedbackContentProps> = (
  { useStyles }: IOptions,
  { session, closeCallback }
) => {
  const { t } = useTranslation()
  const { navigate } = useNavigation()
  const styles = useStyles()

  const [feedback, setFeedback] = useState({ stars: -1, comment: '' })

  const onFeedbackChange = (field: keyof typeof feedback) => {
    return (value: (typeof feedback)[typeof field]) => setFeedback({ ...feedback, [field]: value })
  }

  const { result, clear } = useCharging()

  const stationId = session.chargeStationId

  const station = stationsSelectorHooks.getStation()
  const sendReview = stationsActionHooks.createReview()
  const getStation = stationsActionHooks.getStation()

  const car = vehiclesSelectorHooks.getCarById(result?.vehicleId)
  const { cards } = paymentSelectorHooks.getPaymentMethods()

  const card = cards.find((card) => card.cardId === result?.cardId)

  useEffect(() => {
    getStation({ stationId })
  }, [stationId])

  const sendFeedback = () => {
    Keyboard.dismiss()
    try {
      sendReview({
        feedback: feedback.comment,
        rating: feedback.stars + 1,
        chargeStationId: stationId,
      })
      showMessage({
        type: 'success',
        message: t('feedback:message.title'),
        description: t('feedback:message.content'),
      })
      setTimeout(() => {
        closeCallback && closeCallback()
        clear(session)
        navigate(RouterNames.private, { screen: RouterNames.home })
      }, 150)
    } catch {
      showMessage({
        type: 'danger',
        message: t('feedback:error.title'),
        description: t('feedback:error.content'),
      })
    }
  }

  return {
    styles,
    t,
    feedback,
    car,
    card,
    station,
    onFeedbackChange,
    sendFeedback,
    result,
  }
}
