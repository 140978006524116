import {
  AuthRequest,
  ConnectorStatusRequest,
  EndChargingRequest,
  RequestMessageTypes,
  ResponseType,
  StartChargingRequest,
  StationStatusRequest,
  ChargingStatusRequest,
} from './types'

export * from './types'

export const authenticate = (ws: WebSocket, payload: AuthRequest) => {
  const request = { action: RequestMessageTypes.auth, ...payload }
  ws.send(JSON.stringify(request))
}

export const startCharging = (ws: WebSocket, payload: StartChargingRequest) => {
  const request = { action: RequestMessageTypes.startCharging, ...payload }
  ws.send(JSON.stringify(request))
}

export const stopCharging = (ws: WebSocket, payload: EndChargingRequest) => {
  const request = { action: RequestMessageTypes.endCharging, ...payload }
  ws.send(JSON.stringify(request))
}

export const requestChargingStatus = (ws: WebSocket, payload: ChargingStatusRequest) => {
  const request = { action: RequestMessageTypes.requestChargingStatus, ...payload }
  ws.send(JSON.stringify(request))
}

export const requestStationStatus = (ws: WebSocket, payload: StationStatusRequest) => {
  const request = { action: RequestMessageTypes.requestStationStatus, ...payload }
  ws.send(JSON.stringify(request))
}

export const requestConnectorStatus = (ws: WebSocket, payload: ConnectorStatusRequest) => {
  const request = { action: RequestMessageTypes.requestConnectorStatus, ...payload }
  ws.send(JSON.stringify(request))
}

export const processData = (data: any) => {
  const response = JSON.parse(data as string)
  return response as ResponseType
}
