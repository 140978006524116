import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react'
import { showMessage } from 'react-native-flash-message'

import { RequestMessageTypes, ResponseMessageTypes } from '../../core/api/charging'
import { accountSelectorHooks } from '../../core/store/account'
import { sessionsApi } from '../../core/api/sessions'
import { Charging } from '../../core/models'
import { useWebSocket } from './websocket'

export interface Context {
  /**
   * `{connectorId}` -> Session
   */
  sessions: Map<string, Charging.ActiveSession>
  /**
   * `{chargingId}` -> Status
   */
  statuses: Map<string, Charging.SessionStatus>
  /**
   * `{connectorId}` -> SessionData
   */
  sessionData: Map<string, Charging.SessionData>
  result: Charging.Result | null
  startCharging: (value: Charging.StartChargingPayload) => void
  stopCharging: (connectorId: string) => void
  setChargingData: (connectorId: string, value: Charging.SessionData) => void
  clear: (session: Charging.ActiveSession) => void
}

const initials: Context = {
  sessionData: new Map(),
  result: null,
  sessions: new Map(),
  statuses: new Map(),
  startCharging: Promise.resolve,
  stopCharging: Promise.resolve,
  setChargingData: Promise.resolve,
  clear: Promise.resolve,
}

const ChargingContext = createContext(initials)

export const ChargingProvider = ({ children }: PropsWithChildren) => {
  const { isAuthorized, send, response } = useWebSocket()

  const isLoggedIn = accountSelectorHooks.getIsAuthorized()

  const [sessionData, setSessionData] = useState<Map<string, Charging.SessionData>>(new Map())
  const [sessions, setSessions] = useState<Map<string, Charging.ActiveSession>>(new Map())
  const [statuses, setStatuses] = useState<Map<string, Charging.SessionStatus>>(new Map())
  const [result, setResult] = useState<Charging.Result | null>(null)

  useEffect(() => {
    if (!isLoggedIn || !isAuthorized) return

    sessionsApi.getActive().then((sessions) => {
      if (!sessions) return

      const map = sessions.reduce((acc, session) => {
        send({
          action: RequestMessageTypes.requestChargingStatus,
          connectorid: session.connectorId,
        })

        return acc.set(session.connectorId, session)
      }, new Map())

      setSessions(map)
    })
  }, [isAuthorized, isLoggedIn])

  useEffect(() => {
    switch (response?.action) {
      case ResponseMessageTypes.confirmStartCharging: {
        if (!sessionData) return

        const { chargingId, connectorId } = response

        const data = sessionData.get(connectorId)

        if (!data) return

        const { card, car, station } = data

        const session = {
          chargingId,
          connectorId,
          cardId: card?.cardId,
          vehicleId: car?.vehicleId,
          chargeStationId: station.id,
        }

        setSessions(new Map(sessions).set(connectorId, session))
        break
      }
      case ResponseMessageTypes.failStartCharging: {
        showMessage({ type: 'danger', message: 'Error', description: response.message })
        break
      }
      case ResponseMessageTypes.chargingStatus: {
        setStatuses(new Map(statuses).set(response.chargingId, response))
        break
      }
      case ResponseMessageTypes.confirmEndCharging: {
        const { chargingid, cost, energy, time } = response

        const session = [...sessions.values()].find((x) => x.chargingId === chargingid)
        const status = statuses.get(chargingid)

        if (!session || !status) return

        const newStatus: Charging.SessionStatus = {
          ...status,
          time,
          cost: +cost.toFixed(2),
          energy: +energy.toFixed(2),
          status: Charging.Status.Finished,
        }

        const result: Charging.Result = {
          ...newStatus,
          chargeStationId: session.chargeStationId,
          vehicleId: session.vehicleId,
          cardId: session.cardId,
        }

        setResult(result)
        setStatuses(new Map(statuses.set(chargingid, newStatus)))

        break
      }
    }
  }, [response])

  const startCharging = (value: Charging.StartChargingPayload) => {
    send({ action: RequestMessageTypes.startCharging, ...value, reserveid: null })
  }

  const stopCharging = (connectorId: string) => {
    send({ action: RequestMessageTypes.endCharging, connectorid: connectorId })
  }

  const setChargingData = (connectorId: string, value: Charging.SessionData) => {
    setSessionData(new Map(sessionData).set(connectorId, value))
  }

  const clear = (session: Charging.ActiveSession) => {
    const newSessions = new Map(sessions)
    newSessions.delete(session.connectorId)
    setSessions(newSessions)

    const newStatuses = new Map(statuses)
    newStatuses.delete(session.chargingId)
    setStatuses(newStatuses)
  }

  const value = {
    sessions,
    result,
    sessionData,
    statuses,
    startCharging,
    stopCharging,
    setChargingData,
    clear,
  }

  return <ChargingContext.Provider value={value}>{children}</ChargingContext.Provider>
}

export const useCharging = () => useContext(ChargingContext)
