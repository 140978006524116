import { useNavigation } from '@react-navigation/native'
import { useTranslation } from 'react-i18next'

import { openNavigator } from '../../../core/helpers/links'
import { CommonStationCard } from '../CommonStationCard'
import { Box, Button, Paper, Spacer } from '../common'
import { RouterNames } from '../../../core/constants'
import { Station } from '../../../core/models'

interface IMapStationCard {
  station: Station
}

export const MapStationCard = ({ station }: IMapStationCard) => {
  const { id, name, address } = station

  const { navigate } = useNavigation()
  const { t } = useTranslation()

  const onDirectionsPress = () => {
    if (address.longitude && address.latitude) {
      openNavigator(address.latitude, address.longitude, name)
    }
  }

  const onDetailsPress = () => {
    navigate(RouterNames.station, { stationId: id })
  }

  return (
    <Box flex>
      <Paper>
        <CommonStationCard station={station} />
        <Spacer vertical={'s'} />
        <Box>
          <Button
            flex
            fullWidth
            variant={'outlined'}
            onPress={onDirectionsPress}
            label={t('map:directions')}
          />
          <Spacer horizontal={'s'} />
          <Button flex fullWidth label={t('details:title')} onPress={onDetailsPress} />
        </Box>
      </Paper>
    </Box>
  )
}
