import React from 'react'
import { View } from 'react-native'
import { withWrapper } from '../../../../core/helpers/withWrapper'
import { withStyles } from '../../../../core/theme'
import ModalContainer from '../../../components/common/ModalContainer'
import LanguagesContent from '../content'
import { useLanguagesModalWrapper } from './wrapper'
import { ILanguagesModalProps } from './types'

const useStyles = withStyles((theme) => ({
  content: {
    padding: theme.spacing.m,
  },
}))

interface ILanguagesModal extends ILanguagesModalProps {
  styles: ReturnType<typeof useStyles>
  onChooseLanguage?: () => void
}

const LanguagesModal = ({ styles, onChooseLanguage, ...rest }: ILanguagesModal) => (
  <ModalContainer {...rest}>
    <View style={styles.content}>
      <LanguagesContent onChooseLanguage={onChooseLanguage} />
    </View>
  </ModalContainer>
)

export default withWrapper<ILanguagesModalProps>(LanguagesModal, useLanguagesModalWrapper, {
  useStyles,
})
