import { SafeAreaView, View } from 'react-native'
import { withWrapper } from '../../../../core/helpers/withWrapper'
import { withStyles } from '../../../../core/theme'
import { KeyboardAvoid } from '../../../components'
import { KeyboardDismissView } from '../../../components/KeyboardDismissView'
import StationReportContent from '../content'
import { useStationReportScreenWrapper } from './wrapper'

const useStyles = withStyles((theme) => ({
  container: {
    flex: 1,
    backgroundColor: theme.colors.backgroundOffset,
  },
  content: {
    flex: 1,
    padding: theme.spacing.m,
  },
}))

interface IStationReportScreen {
  styles: ReturnType<typeof useStyles>
  chargeStationId: string
}

const StationReportScreen = ({ styles, chargeStationId }: IStationReportScreen) => (
  <SafeAreaView style={styles.container}>
    <KeyboardDismissView>
      <KeyboardAvoid>
        <View style={styles.content}>
          <StationReportContent chargeStationId={chargeStationId} />
        </View>
      </KeyboardAvoid>
    </KeyboardDismissView>
  </SafeAreaView>
)

export default withWrapper(StationReportScreen, useStationReportScreenWrapper, { useStyles })
