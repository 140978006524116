import { View } from 'react-native'
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view'
import { DefaultWrapperProps, withWrapper } from '../../../core/helpers/withWrapper'
import { withStyles } from '../../../core/theme'
import { Button, TextField, Paper, Spacer } from '../../components'
import { KeyboardDismissView } from '../../components/KeyboardDismissView'

import { useRegistrationScreenWrapper } from './wrapper'

const useStyles = withStyles((theme) => ({
  container: {
    flex: 1,
    backgroundColor: theme.colors.backgroundOffset,
  },
  content: {
    padding: theme.spacing.m,
  },
}))

interface IFormik<T, B> {
  handleSubmit: () => void
  setFieldValue: (key: string, value: string) => void
  setFieldTouched: (key: string, value: boolean) => void
  values: T
  isValid: boolean
  errors: B
  touched: B
}

interface Customer {
  firstName: string
  lastName: string
  email: string
  phoneNumber: string
  userName: string
}

interface Address {
  country: string
  city: string
  address: string
  zip: string
  state: string
}

interface Password {
  password: string
  repeatedPassword: string
}

interface IRegistrationScreen extends DefaultWrapperProps {
  styles: ReturnType<typeof useStyles>
  submit: () => void
  customer: IFormik<Customer, Customer>
  loading: boolean
  isValid: boolean
  isDirty: boolean
}

function generateField<T = Customer | Password | Address>(formik: IFormik<T, T>, key: keyof T) {
  return {
    onChange: (text: string) => {
      formik.setFieldValue(key as string, text)
    },
    handleBlur: () => {
      if (!formik.touched[key]) {
        formik.setFieldTouched(key as string, true)
      }
    },
    value: formik.values[key],
    showMessage: formik.touched[key] ? formik.errors[key] : undefined,
    fullWidth: true,
  }
}

const EditAccountDetailsScreen = ({
  t,
  styles,
  submit,
  isValid,
  isDirty,
  customer,
  loading,
}: IRegistrationScreen) => (
  <View style={styles.container}>
    <KeyboardDismissView>
      <KeyboardAwareScrollView>
        <View style={styles.content}>
          <Paper>
            <View>
              <TextField
                label={t('forms:fields.username')}
                {...generateField(customer, 'userName')}
              />
              <Spacer vertical="s" />
              <TextField
                label={t('forms:fields.firstName')}
                {...generateField(customer, 'firstName')}
              />
              <Spacer vertical="s" />
              <TextField
                label={t('forms:fields.lastName')}
                {...generateField(customer, 'lastName')}
              />
              <Spacer vertical="s" />
              <TextField label={t('forms:fields.email')} {...generateField(customer, 'email')} />
              <Spacer vertical="s" />
              <TextField
                label={t('forms:fields.phone')}
                {...generateField(customer, 'phoneNumber')}
              />
            </View>
            <Spacer vertical={'m'} />
            <Button
              label={t('buttons:save')}
              fullWidth
              endIcon={{ name: 'save', family: 'Feather' }}
              iconSize={20}
              onPress={submit}
              loading={loading}
              disabled={!isValid || !isDirty || loading}
            />
          </Paper>
        </View>
      </KeyboardAwareScrollView>
    </KeyboardDismissView>
  </View>
)

export default withWrapper(EditAccountDetailsScreen, useRegistrationScreenWrapper, {
  useStyles,
})
