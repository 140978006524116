import { Card, Rfid } from '../../models'
import { api } from '../setup'

export class WalletAPI {
  static getCards = async () => {
    const { data } = await api.get<Card[]>('payment/cards')
    return data
  }

  static getRfids = async () => {
    const { data } = await api.get<Rfid[]>('web/rfid')
    return data
  }

  static getCard = async (id: string) => {
    const { data } = await api.get(`payment/card/${id}`)
    return data
  }

  static deleteCard = (id: string) => {
    return api.delete(`payment/card/${id}`)
  }

  static deleteRfid = (id: string) => {
    return api.delete(`web/rfid/${id}`)
  }

  static createRfid = (payload: Pick<Rfid, 'number' | 'cardId'>) => {
    return api.post('web/rfid', payload)
  }

  static editRfid = ({ id, number, cardId }: Rfid) => {
    return api.patch(`web/rfid/${id}`, { number, cardId })
  }

  static getCheckoutForm = async () => {
    const { data } = await api.get('payment/checkoutform')
    return data
  }

  static confirmCard = async (id: string) => {
    return api.post('payment/card', { id })
  }

  static setDefaultCard = async (cardId: string) => {
    const { data } = await api.post('payment/card/default', { cardId })
    return data
  }
}
