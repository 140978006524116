import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects'
import { transactionApi } from '../../api/transaction'
import { Payload } from '../stations'
import {
  setError,
  setRecentTransactions,
  setStats,
  setTransaction,
  setTransactions,
} from './actions'
import {
  GetRecentTransactions,
  GetTransaction,
  GetTransactions,
  IStats,
  ITransaction,
} from './types'

import * as CONSTANTS from './constants'
import { RootState } from '../rootReducer'
import { getDay } from '../../helpers/date'
import { PagedData } from '../../models'

function* getTransactionsSaga({
  payload: {
    callback,
    vehicleId: vehicles,
    chargeStationId: stations,
    startTimeOfCharge,
    endTimeOfCharge,
    ...rest
  },
}: Payload<GetTransactions>) {
  try {
    const { items }: PagedData<ITransaction> = yield call(transactionApi.transactions, {
      ...rest,
      startTimeOfCharge: startTimeOfCharge ? getDay(startTimeOfCharge, 'start') : undefined,
      endTimeOfCharge: endTimeOfCharge ? getDay(endTimeOfCharge, 'end') : undefined,
      vehicleId: vehicles?.length ? vehicles.join(',') : undefined,
      chargeStationId: stations?.length ? stations.join(',') : undefined,
    })

    const { list }: RootState['transactions'] = yield select(
      (state: RootState) => state.transactions
    )
    yield put(setTransactions([...list, ...items]))

    if (callback) {
      callback(null, items)
    }
  } catch (error) {
    yield put(setError(error as Error))
    if (callback) {
      callback(error)
    }
  }
}

function* getRecentTransactionsSaga({ payload: { callback } }: Payload<GetRecentTransactions>) {
  try {
    const { items }: PagedData<ITransaction> = yield call(transactionApi.recentTransactions)
    yield put(setRecentTransactions(items))
    if (callback) {
      callback(null, items)
    }
  } catch (error) {
    yield put(setError(error as Error))
    if (callback) {
      callback(error)
    }
  }
}

function* getTransactionSaga({ payload: { callback, ...data } }: Payload<GetTransaction>) {
  try {
    const transaction: ITransaction = yield call(transactionApi.transaction, data)

    yield put(setTransaction(transaction))

    callback && callback(null, transaction)
  } catch (error) {
    yield put(setError(error as Error))
    callback && callback(error)
  }
}

function* getUserStatsSaga() {
  try {
    const stats: IStats = yield call(transactionApi.stats)

    yield put(setStats({ ...stats, totalTime: +(stats.totalTime / 3600).toFixed(2) }))
  } catch (error) {
    yield put(setError(error as Error))
  }
}

export function* transactionsSagas(): Generator<any> {
  yield all([
    yield takeEvery<any>(CONSTANTS.GET_TRANSACTIONS, getTransactionsSaga),
    yield takeLatest<any>(CONSTANTS.GET_TRANSACTION, getTransactionSaga),
    yield takeLatest<any>(CONSTANTS.GET_RECENT_TRANSACTIONS, getRecentTransactionsSaga),
    yield takeLatest<any>(CONSTANTS.GET_USER_STATS, getUserStatsSaga),
  ])
}
