import { PropsWithChildren } from 'react'
import {
  KeyboardAvoidingView,
  KeyboardAvoidingViewProps,
  Platform,
  StyleProp,
  ViewStyle,
} from 'react-native'
import { useSafeAreaInsets } from 'react-native-safe-area-context'

interface IKeyboardAvoidProps {
  offset?: { ios?: number; android?: number }
  style?: StyleProp<ViewStyle>
}

type KeyboardAvoidProps = Pick<KeyboardAvoidingViewProps, 'behavior' | 'keyboardVerticalOffset'>

const KeyboardAvoid = ({ children, offset, style }: PropsWithChildren<IKeyboardAvoidProps>) => {
  const { bottom } = useSafeAreaInsets()

  const props = Platform.select<KeyboardAvoidProps>({
    ios: { behavior: 'padding', keyboardVerticalOffset: offset?.ios || bottom ? bottom * 3 : 70 },
    android: { behavior: 'height', keyboardVerticalOffset: offset?.android || -400 },
  })

  return (
    <KeyboardAvoidingView style={[{ flex: 1 }, style]} {...props}>
      {children}
    </KeyboardAvoidingView>
  )
}

export default KeyboardAvoid
